import {Modal} from "react-bootstrap";
import sent from "images/svg/float/patch-sent.svg";
import Status from "components/shared/Status";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";


export default function PatchModal ({show, onCancel}) {
    const navigate = useNavigate();
    
    return(
        <Modal show={show} size="md" onHide={onCancel} keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="p-[20px]">
                <div className="flex justify-between">
                    <img src={sent} alt="sent" />
                    <div><Status type="pending" text="In Progress" /></div>
                </div>
                <h5 className="mt-2.5 text-2xl font-semibold">Float Patch</h5>
                <p className="my-3">Your application has been sent to the Creditchek team for review</p>
                <div className="flex">
                    <button className="py-2 px-[20px] w-[150px] rounded-[7px] text--cc-grey-11 bg-white border-[1px] border-cc-grey-11" onClick={onCancel}>Cancel</button>
                    <button 
                        className={clsx(
                            "py-2 px-[20px] w-[150px] rounded-[7px] bg-cc-primary text-white ml-auto",
                        )}
                        onClick={()=>navigate('/float')}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </Modal>
    )
}