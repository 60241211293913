import clsx from 'clsx';
import loader from "images/gif/white-loader.gif";


// Props list:
// type = "button" | "submit"
// isValid, isLoading, disabled - boolean
// iconPosition = "left" | "right"
// text, className - string
// variant = "primary" | "secondary"
// icon - ReactNode

export default function Button ({ 
    variant="primary",
    fullwidth=true,
    ...props 
}) {
    return(
        <button
            type={props.type}
            className={clsx(
                "text-center rounded-lg text-base font-semibold py-2.5 flex items-center justify-center relative gap-x-1",
                (props.isValid && variant==="primary") ? "bg-cc-blue-6 cursor-pointer" 
                    : (!props.isValid && variant==="primary") ? "cursor-not-allowed bg-cc-grey-11"
                    : "",
                props.className,
                variant==="primary" ? "text-white":"text-cc-grey-5 !bg-white border-[1px] border-cc-grey-26",
                props.isLoading && "opacity-70",
                fullwidth && "w-full",
            )}
            disabled={props.isLoading || !props.isValid}
            onClick={props.onClick}
        >
            {(props.iconPosition==="left" && props.icon) && props.icon}
            {props.text}
            {(props.iconPosition==="right" && props.icon) && props.icon}
            {props.isLoading && <img src={loader} alt="loader" className='absolute top-[calc(50%-12px)] left-[calc(50%-12px)] w-[24px]' />}
        </button>
    )
}
