import { useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { IdentityService } from "services/identity.service";
import { logToBin } from "../../lib/logs";

function Guarantor () {
    const [bvn, setBvn] = useState("");
    const [searchParams] = useSearchParams();

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        const readTerms = document.getElementById('readTerms');
        if(readTerms.checked && bvn.length===11){
            btn.innerHTML=`Please Wait...`;
            let payload = JSON.stringify({
                bvn: bvn,
                businessId: searchParams.get("businessId"),
                borrowerId: searchParams.get("borrowerId")
            })
            IdentityService.submitGuarantorDetails(payload)
                .then((response) => response.data)
                .then((res) => {
                    btn.innerHTML=`Submit`;
                    if(res.status === true){
                        toast.success("Successful!")
                        setTimeout( function (){
                            handleClose()
                        }, 2000);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err)=>{
                    btn.innerHTML=`Submit`;
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                })
        } else {
            toast.error("Please mark the checkbox to give consent and make sure BVN is 11 characters long.")
        }
    }

    return(
        <div style={{
            background: "rgba(183, 233, 246, 0.25)",
            backdropFilter: "blur(16px)",
            padding:"100px 0 50px",
            minHeight:"100vh",
            marginBottom:"-20px"
        }}>
            <div style={{
                maxWidth:"400px", 
                display:"block", 
                margin:"auto",
                border: "1px solid #0046E6",
                background: "white",
                borderRadius: "10px",
            }}>
                <div className="m-[20px]">
                    <div className="myModal_main-widget-header">
                        <p className="text-[1em] text-center font-bold" style={{textAlign:"center", width:"100%"}}>Guarantor's Consent</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="myModal_main-widget-form">
                            <label style={{marginTop:"20px"}}>Bank Verification Number</label>
                            <input type="number" placeholder="BVN" onChange={(e)=>setBvn(e.target.value)} />
                        </div>
                        <div style={{display:"flex", alignItems:"flex-start", marginTop:"-20px"}}>
                            <input type="checkbox" style={{width:"45px", margin:"5px 7px 0 0"}} id="readTerms" />
                            <p>By execution hereof, I consent to these terms and conditions to guarantee this borrower’s application</p>
                        </div>
                        <div className="myModal_main-widget-action">
                            <button className="cancel" type='button' onClick={handleClose}>Decline</button>
                            <button className="publish" id="actionBtn" style={{width:"70px"}} type="submit">I agree</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Guarantor;