import padLock from "images/svg/mdi_password.svg";
import { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import { useParams, useNavigate } from "react-router-dom";
import { IdentityService } from "services/identity.service";
import {toast} from "react-toastify";
import { handleRequestErrors } from "utils/helpers";


export default function BVNIgree () {
    const params = useParams();
    let history = useNavigate()
    const [businessData, setBusinessData] = useState('');
    const [bvn, setBvn] = useState('');
    const [mode, setMode] = useState('INITIATE');
    const [channel, setChannel] = useState('');
    const [otpNumber, setOtpNumber] = useState('');
    const [channelsList, setChannelsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(()=>{
        IdentityService.getBusinessForm(params.appLink)
            .then((response) => response.data)
            .then((res) => {
                if (res.status) {
                    setBusinessData(res.data);
                }
            })
            .catch((err)=>{handleRequestErrors(err)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const initiateVerification = () => {
        let payload = JSON.stringify({
            bvn: bvn,
            mode: "INITIATE",
        })
        doSubmit(payload)
    }
    
    const sendOtpCode = () => {
        let payload = JSON.stringify({
            bvn: bvn,
            mode: "SET_CHANNEL",
            channel: channel,
        })
        doSubmit(payload)
    }

    const verifyCode = () => {
        let payload = JSON.stringify({
            bvn: bvn,
            mode: "FINALIZE",
            channel: channel,
            otp: otpNumber
        })
        doSubmit(payload)
    }

    const doSubmit = (payload) => {
        setIsLoading(true)
        IdentityService.customerBVNIgree(businessData.businessId, payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if (res.status === true) {
                    if(mode==="INITIATE"){
                        setMode('SET_CHANNEL')
                        setChannelsList(res.data.contacts)
                    } else if (mode==="SET_CHANNEL") {
                        setMode('FINALIZE')
                    } else {
                        toast.success("Request Successful!!!");
                        setTimeout(()=>{
                            history("/auth")
                        }, 2000);
                    }
                } else {
                    toast.error(res.message);
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
                setIsLoading(false)
            });
    }

    return(
        <div>
            <div className="w-[400px] md:w-full box-border p-[20px] block mx-auto mt-[150px]">
                {
                    mode==="INITIATE" ?
                    <div>
                        <img src={padLock} alt="lock" className="block mx-auto" />
                        <label className="mt-2.5 font-semibold text-base">Verify with BVN iGree</label><br/>
                        <input className="apps_create-input" type="text" placeholder="BVN" onChange={(e)=>setBvn(e.target.value)} /><br/>
                        <button className="apps_create-button" onClick={()=>initiateVerification()}>{isLoading?"Please wait...":"Next"}</button>
                    </div>
                    :
                    <div>
                        <img src={padLock} alt="lock" className="block mx-auto" />
                        {
                            mode==="SET_CHANNEL" ?
                            <div>
                                <h6 className="text-lg font-bold text-center">Choose channel to receive OTP</h6>
                                <div className="myModal_main-linkBank-radio">
                                    {
                                        channelsList.map((item, index)=>(
                                            <p className="mt-[10px] lowercase" key={index} onClick={()=>setChannel(item)}>
                                                <input type="radio" id={`${item}_${index}`} name="radio-group"/>
                                                <label for={`${item}_${index}`}>{item}</label>
                                            </p>
                                        ))
                                    }
                                </div>
                                <div className="box-border">
                                    <button className="apps_create-button" onClick={sendOtpCode}>{isLoading?"Please wait...":"Proceed"}</button>
                                </div>
                            </div>
                            :
                            <div className="box-border">
                                <h6 className="text-lg font-bold text-center">OTP Verification</h6>
                                <p className="text-cc-grey-5 text-sm text-center">Enter OTP sent to <span className="text-cc-primary font-semibold lowercase">{channel}</span></p>
                                <div className="auth_otp my-4 -ml-[20px]">
                                    <OTPInput value={otpNumber} onChange={setOtpNumber} autoFocus OTPLength={6} otpType="number" disabled={false} />
                                </div>
                                <button className="apps_create-button w-full" onClick={verifyCode}>{isLoading?"Please wait...":"Submit"}</button>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}