import { useEffect, useState } from "react";
import Table from "components/Table";
import { BillingService } from "services/billing.service";
import { toast } from "react-toastify";
import cancel from "../../../images/svg/apis/cancel.svg";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { logToBin } from "../../../lib/logs";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";
import Status from "components/shared/Status";


function ApiLogs () {
    const [data, setData] = useState({
        items: [],
        loaded: false
    })
    const [showDeets, setShowDeets] = useState(false)
    const [viewData, setViewData] = useState({});
    const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(()=>{
        BillingService.billingLogs()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setData({
                        ...data,
                        items: res.data,
                        loaded: true,
                    })
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setData({
                    ...data,
                    loaded: true,
                })
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    return (
        <div className="mt-10">
            <div className="relative">
                <Table
                    noTitleIcon={true}
                    tableTitle={"API Logs"}
                    data={data.items}
                    loading={!data.loaded}
                    hideSearch={true}
                    tableClassName="xl:min-w-[1000px]"
                    rows={[
                        {
                            header:'S/N',
                            view: (item, idx, offset) => (
                                <div className="py-[10px] pl-[10px] flex">
                                    {idx+1+offset}
                                </div>
                            ),
                        },
                        {
                            header:'SERVICE ENDPOINT',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item.action==="fico"?"FICO":item.action}
                                </p>
                            ),
                        },
                        {
                            header:'APP',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">
                                    {item?.appName || "Not available"}
                                </p>
                            ),
                        },
                        {
                            header:'TOTAL COST',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">{currencyFormatter(item.amount||0)}</p>
                            ),
                        },
                        {
                            header:'WHEN',
                            view: (item) => (
                                <p className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.created_at, 'dd/mm/yyyy')} {timeFormatter(item.created_at)}</p>
                            ),
                        },
                        {
                            header:'STATUS',
                            view: (item) => (
                                <Status
                                    text={item?.status}
                                    type={item.status==="success"?"success":"fail"}
                                />
                            ),
                        },
                    ]}
                    clickRowAction={(item)=>{
                        setShowDeets(true);
                        setViewData(item);
                    }}
                />
                {
                    showDeets &&
                    <div className="w-[450px] border-[1px] border-cc-grey-23 p-[15px] font-semibold absolute top-0 right-0 bg-white h-[calc(100%-30px)] rounded-[7px] overflow-y-scroll">
                        <img src={cancel} alt="" className="absolute top-[15px] right-[15px] cursor-pointer" onClick={()=>setShowDeets(false)} />
                        <p className="text-cc-grey-24 mb-1.5 mt-10">App <span className="text-cc-black-6 float-right">{viewData.appName}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Service Endpoint <span className="text-cc-black-6 float-right">{viewData.service}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Action <span className="text-cc-black-6 float-right">{viewData.action}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">HTTP Method <span className="text-cc-black-6 float-right">{viewData?.request?.method}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Borrower ID <span className="text-cc-black-6 float-right">{viewData.borrowerId}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Call time 
                            <span className="text-cc-black-6 float-right">
                                {new Date(viewData.created_at).getDate()} {months[new Date(viewData.created_at).getMonth()]} {new Date(viewData.created_at).getFullYear()}, {formatAMPM(new Date(viewData.created_at))}
                            </span>
                        </p>
                        <p className="text-cc-grey-24 mb-1.5">Status 
                            <span className="float-right">
                                <Status
                                    text={viewData?.status}
                                    type={viewData.status==="success"?"success":"fail"}
                                />
                            </span>
                        </p>
                        <div className="mb-[30px]">
                            <h5 className="my-[20px] text-[1.1em] text-[#0F123F]">Request</h5>
                            <SyntaxHighlighter 
                                language="json" 
                                style={github} 
                                showLineNumbers={true}
                                lineNumberStyle={{textAlign:'left', minWidth: '25px', padding:"0 10px ", marginRight:'15px'}}
                            >
                                {JSON.stringify(viewData.request||"No request available", null, 2)}
                            </SyntaxHighlighter>
                            <h5 className="my-[20px] text-[1.1em] text-[#0F123F]">Response</h5>
                            <SyntaxHighlighter 
                                language="json" 
                                style={github} 
                                showLineNumbers={true}
                                lineNumberStyle={{textAlign:'left', minWidth: '25px', padding:"0 10px ", marginRight:'15px'}}
                            >
                                {JSON.stringify(viewData.response||"No response available", null, 2)}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ApiLogs;