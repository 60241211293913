
import { useState, useEffect } from "react";

import arrow from "../../../images/svg/spectrum/arrow-left.svg";


function Pagination ({total, current, setCurrent}) {
    const [pages, setPages] = useState([])

    useEffect(()=>{
        for (let i = 1; i < (total+1); i++) {
            console.log(i);
            setPages(prev => [...prev, i])
        }
    }, [total])

    const next = () => {
        if((current+1) < pages[pages.length-1]+1){
            setCurrent(current+1)
        } else {
            console.log('The End')
        }
    }

    const prev = () => {
        if((current-1)!==0){
            setCurrent(current-1)
        } else {
            console.log('The End')
        }
    }


    return(
        <div className="spectrum_pagination">
            <button className="spectrum_pagination-prev flex items-center" onClick={prev}><img src={arrow} alt=""/> <span>Previous</span></button>
            <div style={{display:'flex'}}>
                {(pages?.length>0 && current>1) && <p>{pages[0]}</p>}
                {(pages?.length>1 && current>2) && <p>{pages[1]}</p>}
                {(pages?.length>3 && current>3) && <p>...</p>}
                <p className="active">{current}</p>
                {(pages?.length>3 && current<pages[pages?.length-3])  && <p>...</p>}
                {(current<pages[pages?.length-2] && pages.length>2) && <p>{pages[pages?.length-2]}</p>}
                {(current!==pages[pages?.length-1] && pages.length>1) && <p>{pages[pages?.length-1]}</p>}
            </div>
            <button className="spectrum_pagination-next flex items-center" onClick={next}><span>Next</span> <img src={arrow} alt=""/></button>
        </div>
    )
}

export default Pagination;