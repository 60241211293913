// import {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Back from "../../../components/shared/back";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { complianceContact } from "utils/validationSchemas";
import { useState } from "react";

function Contact ({handleChange, info, nextButton, pathBase}) {
    let navigate = useNavigate();
    const [isForm, setIsForm] = useState(true)

    const form = useFormik({
        initialValues: {
            email: info?.email || "",
            phone: info?.phone || "",
            website: info?.website || "",
            address: info?.address || "",
        },
        validationSchema: complianceContact, 
        onSubmit: (values) => {
            console.log(values)
            handleChange(values)
            sessionStorage.setItem("compliance_contact", JSON.stringify(values))
            setIsForm(false)
        }
    })

    return(
        <div className="box-border">
            <div style={{marginBottom:"-30px"}}>
                <Back  to={()=> isForm?navigate(`${pathBase}/profile`):setIsForm(true)} />
            </div>
            <h5 className="text-xl font-medium mb-6">Contact</h5>
            {isForm ?
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <TextField
                            label="Email Address"
                            name='email'
                            type='email'
                            placeholder="Email Address"
                            required
                        />
                        <TextField
                            label="Phone Number"
                            name='phone'
                            type='text'
                            placeholder="Phone Number"
                            required
                        />
                        <TextField
                            label="Website"
                            name='website'
                            type='text'
                            placeholder="Website"
                        />
                        <TextField
                            label="Office Address"
                            name='address'
                            type='text'
                            placeholder="Office Address"
                            required
                        />
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="submit"
                            text="Save"
                            className="mt-10 bg-cc-primary"
                        />
                    </form>
                </FormikProvider>
                :
                <div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Email Address</h6>
                        <p className="text-[#353535]">{info.email}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Phone Number</h6>
                        <p className="text-[#353535]">{info.phone}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Website (optional)</h6>
                        <p className="text-[#353535]">{info.website}</p>
                    </div>
                    <div className="mb-6">
                        <h6 className="text-[#5B5B5B] text-[1em]">Office Address</h6>
                        <p className="text-[#353535]">{info.address}</p>
                    </div>
                    <div className="flex justify-between">
                        <button  onClick={()=>setIsForm(true)} className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Edit</button>
                        <Link to={`${pathBase}/document`}>
                            <button onClick={()=>nextButton()} className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Next &#62;</button>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}


export default Contact;