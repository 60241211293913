// Library methods
import {Routes, Route, Navigate, useLocation, Link, useNavigate} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { setCachePopup } from "redux/login";

// Components
import Home from "./Home";
import Overview from "./Overview";
import Income from "./Income";
import Apps from "./Apps";
import Settings from "./Settings";
import Billing from "./Billing";
import AuditLogs from "./AuditLogs";
import Apis from "./Apis";
import Identity from "./Identity";
import CreditInsight from "./CreditInsight";
import Compliance from "./Compliance";
import IdleModal from "../../components/Modal/IdleUser";
import Float from "./Float";
import Spectrum from "./Spectrum";
import Radar from "./Radar";
import Recova from "./Recova";
import NotificationPanel from "components/Notification/NotificationPanel";
import SwitchApps from "components/Modal/SwitchApp";

// Utilities
import { AuthService } from "services/auth.service";
import { getGeolocation } from "../../lib/externalApis";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "../../lib/mixpanel";
import { FloatService } from "services/float.service";
import { persistor } from "redux/store";

import logo from "images/svg/white-logo.svg";
import home from "images/svg/sidebar/home.svg";
import dash from "images/svg/sidebar/dash.svg";
import float from "images/svg/sidebar/float.svg";
import spectrum from "images/svg/sidebar/spectrum.svg";
import dashboard from "images/svg/sidebar/dashboard.svg";
import income from "images/svg/sidebar/income.svg";
import creditInsight from "images/svg/sidebar/credit-insight.svg";
import app from "images/svg/sidebar/app.svg";
import auditLog from "images/svg/sidebar/audit-log.svg";
import billing from "images/svg/sidebar/billing.svg";
import setting from "images/svg/sidebar/setting.svg";
import identity from "images/svg/sidebar/identity.svg";
import radar from "images/svg/sidebar/radar.svg";
import logout from "images/svg/sidebar/logout.svg";
import telco from "images/svg/sidebar/telco.svg";
import mmo from "images/svg/sidebar/mmo.svg";
import mfi from "images/svg/sidebar/mfi.svg";
import recova from "images/svg/sidebar/recova.svg";
import drp from "images/svg/sidebar/drop-down.svg";
import profile from "images/svg/profile-dummy.svg";
import arrow from "images/svg/select-arrow.svg";
import cancel from "images/svg/cancel.svg";
import menu from "images/svg/menu.svg";
import verified from "images/svg/check-verified.svg";
import quickAction from "images/svg/bolt.svg";
import qa_switch from "images/svg/switch_app.svg";
import qa_fund from "images/svg/fund.svg";
import qa_threshold from "images/svg/threshold.svg";
import notificationIcon from "images/svg/float/notificationIcon.svg";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown, IoIosArrowRoundForward, IoIosCheckmarkCircleOutline, IoMdInformationCircleOutline } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { setRoleAndPermissions } from "redux/permissions";


function Dashboard ({setOnLive}) {
    const location = useLocation().pathname;
    const [path, setPath] = useState("");
    const [detailName, setDetailName] = useState("");
    const [showModal, setShowModal] = useState(false)
    const [showIcon, setShowIcon] = useState(false)
    const [showLive, setShowLive] = useState(false)
    const [profileLogo, setProfileLogo] = useState('')
    const [ip, setIP] = useState('');
    const [showMore, setShowMore] = useState(false)
    const [showQuickActions, setShowQuickActions] = useState(false);
    const [permissionKeys, setPermissionKeys] = useState([]);
    const [rolePermissions, setRolePermissions] = useState([]);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const activeApp = useSelector(state => state?.app?.active);
    const dispatch = useDispatch();
    const showCachePopup = useSelector(state => state?.loginData?.cachePopup);
    const showNotify = useSelector(state => state?.walletData?.wallet.notified);
    
    const [notificationData, setNotificationData] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [showNotificationPanel, setShowNotificationPanel] = useState(false);
    const [showAppSwitch, setShowAppSwitch] = useState(false)

    const navigate = useNavigate()
  
    let pendingStatus = '';
    let isComplied = false;
    let quickAppId = activeApp.appId;
    let quickAppStat = activeApp.isLive;
    let roleId = '';

    try {
        roleId = JSON.parse(sessionStorage.getItem('roleId'));
        
        let stat = sessionStorage.getItem('b2bStatus');
        pendingStatus = JSON.parse(stat).isActive;

        isComplied = JSON.parse(sessionStorage.getItem('b2bCompliance')).isComplied
    } catch (err) {
        //err
    }

    useEffect(()=>{
        if(roleId!==null){
            AuthService.getRolesById(roleId)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success === true) {
                        let keys = [];
                        res?.data?.permission?.map((item) => {
                            let key = Object.keys(item)
                            keys.push(key?.[0])
                            return key
                        })
                        setPermissionKeys(keys)
                        setRolePermissions(res?.data?.permission)
                        dispatch(setRoleAndPermissions({
                            roleId: res?.data?._id,
                            roleName: res?.data?.name,
                            permissions: res?.data?.permission
                        }));
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    if(err.response){
                        toast.error(err.response.data.message);
                    } else{
                        toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
                    }
                }); 
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNotificationChange = (id) => {
        FloatService.toggleNotifications(id)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success" && res.data !== null ){
                    navigate("/float/status")  
                    getNotification()
                }
            })
            .catch((err) => {});
    }

    const getNotification = useCallback(() => {
        if(pendingStatus!=="pending"){
            FloatService.getNotifications(b2b_data?.user?.businessId)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === "success" && res.data !== null ){
                        setNotificationData(res.data)
                        const filterData = res?.data?.filter((item) => item?.status === "unread")
                        setUnreadMessages(filterData)        
                    }
                })
                .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[],)
    
    useEffect(()=>{
        getNotification();
    },[getNotification])

    useEffect(()=>{
        if(activeApp.appLink!==null){
            IdentityService.getBusinessForm(activeApp.appLink)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === true) {
                        setProfileLogo(res.data.logo);
                    } else {
                    }
                })
                .catch((err) => {
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(()=>{
        setPath(location);   
    }, [location])
    
    const openNav = () => {
        document.getElementById("sidebar").style.left = "0px";
    }
    
    const closeNav = () => {
        document.getElementById("sidebar").style.left = "-300px";
    }

    const changePathAndClose = (path) => {
        if(window.innerWidth<=992){
            closeNav()
        }
        setPath(path);
        
    }

    const collapseBoard = () => {
        let board = document.getElementById('mainBoard');
        let drp = document.getElementById('drp');
        let insightText = document.getElementById('dashboard_sidebar-text1');
        var content = board.nextElementSibling;
        let iconDisplay = document.getElementById('iconDisplay');
        if (content.style.maxHeight){
          content.style.maxHeight = null;
          drp.style.transform = "rotate(0deg)";
          insightText.style.textTransform="";
          insightText.style.marginLeft= "15px";
          board.style.marginBottom= "15px";
          setShowIcon(true)
          iconDisplay.style.display="block"
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
          drp.style.transform = "rotate(180deg)";
          insightText.style.textTransform="uppercase"
          setShowIcon(false)
          iconDisplay.style.display="none"
          insightText.style.marginLeft= "0px";
          board.style.marginBottom= "-15px";
        }
    }

    const goToBoard = () => {
        if (detailName === "") {

        } else {
            if(path==="/income/details"){
                window.location = "/income"
            } else if(path==="/identity/details"){
                window.location = "/identity"
            } else if(path==="/credit-insight/FICO" || path==="/credit-insight/classic" || path==="/credit-insight/standard" || path==="/credit-insight/advanced"){
                window.location = "/credit-insight"
            }
        }
    }

    const logOut = () => {
        Mixpanel.track(`Logout button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        if (b2b_data?.tokens?.refresh?.token===undefined) {
            toast.success("Logging Out...");
            sessionStorage.clear();
            setTimeout( function (){
                persistor.purge()
                window.location="/auth"
            }, 1500);
        } else{
            let payload = JSON.stringify({
                refreshToken: b2b_data?.tokens?.refresh?.token
            })
            AuthService.logoutAction(payload)
                .then((response) => response.data)
                .then(resp=>{
                    if(resp.status==="success"){
                        toast.success("Logging Out...");
                        sessionStorage.clear();
                        persistor.purge()
                        setTimeout( function (){
                            window.location="/auth"
                        }, 1500);
                    } else{
                        toast.error("Could not log user out please try again later!!!")
                    }
                })
                .catch(err => {
                    toast.error("Could not log user out please try again later!!!")
                })
        }
    }

    const handleOnIdle = event => {
        console.log('user is idle', event)
        setShowModal(true)
        console.log('last active', getLastActiveTime())
    }
    
    const handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
        console.log('user did something', event)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000*60*10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect( () => {
        getGeolocation()
        .then(response => response.data)
        .then((res) => {
            setIP(res)
            sessionStorage.setItem( "ipDetails", JSON.stringify({ip}));
        })
        let payload = JSON.stringify({
            businessId: pendingStatus==="pending" ? b2b_data?.businessId : b2b_data?.user?.businessId
        })
        AuthService.getBusinessById(payload)
            .then(response => response.data)
            .then((res) => {
                sessionStorage.setItem( "compliance_autofill", JSON.stringify(res.data));
            })
            .catch((err)=>{
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return(
        <div>
            <div 
                className={clsx(
                    "dashboard_sidebar hide-scrollBar w-[200px] h-[100vh] fixed py-[20px] px-[30px] top-0 z-[3] left-0 overflow-y-scroll text-white bg-cc-primary lg:-left-[300px]",
                    "bg-[url('./images/svg/sidebar/bg-image.svg')] bg-no-repeat bg-cover"
                )} 
                id="sidebar"
            >
                <img src={cancel} alt="cancel" className="absolute top-[20px] right-[20px] w-[35px] lg_min:hidden" onClick={closeNav} />
                <Link to="/"><img src={logo} alt="logo" className="w-[150px] mt-2.5 mb-[30px]" /></Link>
                {
                    permissionKeys?.includes("home") &&
                    <Link className="link-decor" to="/home">
                        <div 
                            onClick={()=>changePathAndClose("home") }
                            className={clsx(
                                "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                (path.includes("home") || path==="home") && 'bg-[#FFFFFF54]',
                            )}
                        >
                            <img src={home} alt="icon" className="w-[20px] mr-[20px]" />
                            <p className="m-0 text-white">Home</p>
                        </div>
                    </Link>
                }
                
                { pendingStatus!=="pending" &&
                    <>
                        {
                            permissionKeys?.includes("dash") &&
                            <Link className="link-decor" to="/dashboard">
                                <div 
                                    onClick={()=>changePathAndClose("dashboard") }
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("dashboard") || path==="dashboard") && 'bg-[#FFFFFF54]',
                                    )}
                                >
                                    <img src={dashboard} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">Dash</p>
                                </div>
                            </Link>
                        }
                        
                        {
                            permissionKeys?.includes("apps") &&
                            <Link className="link-decor" to="/apps">
                                <div 
                                    onClick={()=>changePathAndClose("apps") }
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("apps") || path==="apps") && 'bg-[#FFFFFF54]',
                                    )}
                                >
                                    <img src={app} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">Apps</p>
                                </div>
                            </Link>
                        }
                            
                        <hr className="bg-white" />

                        {
                            permissionKeys?.includes("insights") &&
                                <div 
                                    id="mainBoard" 
                                    style={{marginBottom:"20px"}} 
                                    className={clsx(
                                        "cursor-pointer flex justify-between relative rounded-[7px] py-2.5 px-[15px]",
                                        showIcon ? "items-center mb-[20px]" : "cursor-pointer"
                                    )}
                                    onClick={collapseBoard}
                                >
                                    <div className="flex w-[85px] justify-between">
                                        <img src={dash} alt="icon" id="iconDisplay" className="w-[20px]" />
                                        <p className="text-[1em] text-white ml-[15px] !mb-0" id="dashboard_sidebar-text1">Insights</p>
                                    </div>
                                    <img src={drp} alt="dropdown" id="drp" />
                                </div>
                        }
                        
                        {
                            permissionKeys?.includes("insights") &&

                            <div className="dashboard_sidebar-main">
                                <div style={{margin:"20px 0"}}></div>

                                
                                    <Link className="link-decor" to="/credit-insight">
                                        <div 
                                            onClick={()=>changePathAndClose("credit") }
                                            className={clsx(
                                                "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                                (path.includes("credit") || path==="credit") && 'bg-[#FFFFFF54]',
                                            )}
                                        >
                                            <img src={creditInsight} alt="icon" className="w-[20px] mr-[20px]" />
                                            <p className="m-0 text-white">Credit Insight</p>
                                        </div>
                                    </Link>
                            
                                    <Link className="link-decor" to="income">
                                        <div 
                                            onClick={()=>changePathAndClose("income") }
                                            className={clsx(
                                                "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                                (path.includes("income") || path==="income") && 'bg-[#FFFFFF54]',
                                            )}
                                        >
                                            <img src={income} alt="icon" className="w-[20px] mr-[20px]" />
                                            <p className="m-0 text-white">Income Insight</p>
                                        </div>
                                    </Link>

                                    <Link className="link-decor" to="/identity">
                                        <div 
                                            className={clsx(
                                                "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                                (path.includes("identity") || path==="identity") && 'bg-[#FFFFFF54]',
                                            )}
                                            onClick={()=>changePathAndClose("identity") }
                                        >
                                            <img src={identity} alt="icon" className="w-[20px] mr-[20px]" />
                                            <p className="m-0 text-white">Identity</p>
                                        </div>
                                    </Link>
                                    
                                    <div 
                                        className="flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white cursor-default hover:z-[999]"
                                        data-tooltip-id="Telco"
                                    >
                                        <img src={telco} alt="icon" className="w-[20px] mr-[20px]" />
                                        <p className="m-0 text-white">Telco<span className="text-cc-yellow-1 text-[0.65em] absolute bottom-0 left-[55px]">coming soon</span></p>
                                        <MyToolTip 
                                            id="Telco"
                                            message="This feature enables you to verify the creditworthiness of unbanked customers who use various airtime and data prepaid services."
                                        />
                                    </div>
                                
                                    <div 
                                        className="flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white cursor-default hover:z-[999]"
                                        data-tooltip-id="MMO"
                                    >
                                        <img src={mmo} alt="icon" className="w-[20px] mr-[20px]" />
                                        <div>
                                            <p className="m-0 text-white">MMO<span className="text-cc-yellow-1 text-[0.65em] absolute bottom-0 left-[55px]">coming soon</span></p>
                                        </div>
                                        <MyToolTip 
                                            id="MMO"
                                            message="This feature enables you to verify the creditworthiness of unbanked customers who use various mobile money services."
                                        />
                                    </div>

                                    <div 
                                        className="flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white cursor-default hover:z-[999]"
                                        data-tooltip-id="MFIs"
                                    >
                                        <img src={mfi} alt="icon" className="w-[20px] mr-[20px]" />
                                        <div>
                                            <p className="m-0 text-white">MFIs<span className="text-cc-yellow-1 text-[0.65em] absolute bottom-0 left-[55px]">coming soon</span></p>
                                        </div>
                                        <MyToolTip 
                                            id="MFIs"
                                            message="This feature enables you to verify the creditworthiness of unbanked customers who use microfinance bank services."
                                        />
                                    </div>
                                
                            </div>

                        }
                        
                        <div 
                            className="flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white cursor-pointer hover:z-[999]"
                            data-tooltip-id="RecovaPRO"
                            onClick={()=>navigate("/recova")}
                        >
                            <img src={recova} alt="icon" className="scale-[1.3] translate-x-1 -translate-y-1 w-[20px] mr-[20px]" />
                            <div>
                                <p className="m-0 text-white">RecovaPRO<span className="text-cc-yellow-1 text-[0.65em] absolute bottom-0 left-[55px]">Beta</span></p>
                            </div>
                            <MyToolTip 
                                id="RecovaPRO"
                                message="Businesses can use RecovaPRO to automate loan reconciliation and repayments for their customers, on-time, anytime."
                            />
                        </div>

                        <Link className="link-decor" to="/radar">
                            <div 
                                onClick={()=>changePathAndClose("radar") }
                                className={clsx(
                                    "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                    (path.includes("radar") || path==="radar") && 'bg-[#FFFFFF54]',
                                )}
                            >
                                <img src={radar} alt="icon" className="w-[20px] mr-[20px]" />
                                <p className="m-0 text-white">Radar</p>
                            </div>
                        </Link>

                        <Link className="link-decor" to="/spectrum">
                            <div 
                                className={clsx(
                                    "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                    (path.includes("spectrum") || path==="spectrum") && 'bg-[#FFFFFF54]',
                                )}
                                onClick={()=>changePathAndClose("spectrum") }
                            >
                                <img src={spectrum} alt="icon" className="w-[20px] mr-[20px]" />
                                <div>
                                    <p className="m-0 text-white">Spectrum<span className="text-cc-yellow-1 text-[0.65em] absolute bottom-0 left-[55px]">Beta</span></p>
                                </div>
                            </div>
                        </Link> 

                        <Link className="link-decor" to="/float">
                            <div 
                                onClick={()=>changePathAndClose("float") }
                                className={clsx(
                                    "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                    (path.includes("float") || path==="float") && 'bg-[#FFFFFF54]',
                                )}
                            >
                                <img src={float} alt="icon" className="w-[20px] mr-[20px]" />
                                <p className="m-0 text-white">Float</p>
                            </div>
                        </Link>
                        
                        {
                            permissionKeys?.includes("audit log") &&
                            <Link className="link-decor" to="/auditLogs">
                                <div 
                                    onClick={()=>changePathAndClose("audit") }
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("audit") || path==="audit") && 'bg-[#FFFFFF54]',
                                    )}
                                >
                                    <img src={auditLog} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">Audit Log</p>
                                </div>
                            </Link>

                        }
                        
                        {
                            permissionKeys?.includes("billing") &&
                            <Link className="link-decor" to="/billing">
                                <div 
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("billing") || path==="billing") && 'bg-[#FFFFFF54]',
                                    )}
                                >
                                    <img src={billing} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">Billing</p>
                                </div>
                            </Link>

                        }
                        
                        {/* {
                            permissionKeys?.includes("api pricing") &&
                            <Link className="link-decor" to="/apis">
                                <div 
                                    onClick={()=>changePathAndClose("apis") }
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("apis") || path==="apis") && 'bg-[#FFFFFF54]',
                                    )}
                                >
                                    <img src={api} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">API Pricing</p>
                                </div>
                            </Link>

                        } */}

                        {
                            permissionKeys?.includes("settings") &&
                            <Link className="link-decor" to="/settings">
                                <div 
                                    className={clsx(
                                        "flex rounded-[7px] px-[15px] py-[10px] mb-[20px] relative !bg-opacity-30 hover:bg-white",
                                        (path.includes("settings") || path==="settings") && 'bg-[#FFFFFF54]',
                                    )}
                                    onClick={()=>changePathAndClose("settings") }
                                >
                                    <img src={setting} alt="icon" className="w-[20px] mr-[20px]" />
                                    <p className="m-0 text-white">Settings</p>
                                </div>
                            </Link>
                        }
                        
                    </>
                }
                <div className="flex rounded-[7px] px-[15px] py-[10px] mb-[70px] cursor-pointer relative !bg-opacity-30 hover:bg-white" onClick={logOut}>
                    <img src={logout} alt="icon" className="w-[20px] mr-[20px]" />
                    <p className="m-0 text-white">Logout</p>
                </div>
            </div>
            <div className="relative pl-[260px] lg:pl-0">
                <div className="flex items-center mx-10 md:mx-[20px] relative mt-4 mb-4">
                    <img src={menu} alt="menu"  className="w-[50px] lg_min:hidden" onClick={()=>openNav()}/>
                    {
                        pendingStatus==="pending" ?
                        <h4 className="font-bold text-[22px] lg:hidden">Welcome, {b2b_data?.businessName}</h4>
                        :
                        <h4 onClick={goToBoard} style={{cursor:"pointer"}} className="font-bold text-[22px] lg:hidden">
                            {
                                path.includes("dashboard")?"Dashboard"
                                :path.includes("income")?"Income"
                                :path.includes("identity")?"Identity"
                                :path.includes("radar")?"Radar"
                                :path.includes("credit")?"Credit Insight"
                                :path.includes("apps")?"Apps"
                                :path.includes("billing")?"Billing"
                                :path.includes("auditLogs")?"Audit Log"
                                :path.includes("apis")?"API Pricing"
                                :path.includes("settings")?"Settings"
                                :path.includes("float")?"Float"
                                :path.includes("spectrum")?"Spectrum"
                                :path.includes("recova")?"RecovaPRO"
                                :"Home"
                            }
                            {
                                path.includes("recova") &&
                                <span className="text-xs ml-0.5">Beta</span>
                            }
                            {/* commented this out in order to work on a more suitable breadcrumb component */}
                            {/* <span className="text-[17px] mx-2.5 text-[#0000005e] xl:hidden">{path.includes("spectrum")&&'(Alpha release)'}</span> */}
                            {/* {detailName===""?<span></span>:
                                <span className="text-[17px] mx-2.5 text-[#0000005e] xl:hidden">
                                    {" "}{">"} {detailName}
                                </span>
                            } */}
                        </h4>
                    }
                    <div className="flex items-center gap-x-3 ml-auto">
                        <div className="w-10 h-10 bg-[#dde7fe8a] rounded-[4px] flex items-center justify-center relative cursor-pointer" onClick={()=> setShowNotificationPanel(true)}>
                            <span className="absolute w-4 h-4 right-1 top-0 rounded-[50%] bg-[#FF0000] font-semibold text-[10px] leading-[8px] text-white flex justify-center items-center">{unreadMessages?.length}</span>
                            <img src={notificationIcon} alt="bell Icon" />
                        </div>
                        <div className="relative">
                            <div className="rounded-[8px] p-2 flex items-center cursor-pointer shadow-[3px_4px_18px_rgba(0,0,0,0.1)]" onClick={()=>{setShowQuickActions(!showQuickActions)}}>
                                <img src={quickAction} alt="" className="w-4.5" />
                                <p className="m-0 text-[#666666] text-base font-semibold whitespace-nowrap md:hidden">Quick Actions</p>
                            </div>
                            {
                                showQuickActions &&
                                <div className="bg-white absolute rounded-[12px] pt-[15px] px-[15px] w-[190px] top-[50px] md:right-0 md:top-[45px] z-[999] shadow-lg">
                                    {
                                        !quickAppStat &&
                                        <Link className="link-decor" to={`/apps/${quickAppId}`}>
                                            <div className="flex mb-[10px] cursor-pointer">
                                                <img src={qa_switch} alt="" className="mr-[5px]" />
                                                <p className="text-[#666666] font-semibold" onClick={()=>setShowQuickActions(false)}>Switch To Live</p>
                                            </div>
                                        </Link>
                                    }
                                    <div className="flex mb-[10px] cursor-pointer" onClick={()=>{setShowQuickActions(false); setShowAppSwitch(true)}}>
                                        <img src={qa_switch} alt="" className="mr-[5px]" />
                                        <p className="text-[#666666] font-semibold">Switch Active App</p>
                                    </div>
                                    <Link className="link-decor" to="/home/wallet">
                                        <div className="flex mb-[10px] cursor-pointer">
                                            <img src={qa_fund} alt="" className="mr-[5px]" />
                                            <p 
                                                className="text-[#666666] font-semibold"
                                                onClick={()=>{setShowQuickActions(false); Mixpanel.track('Quick Action (Fund Wallet) button was clicked', {
                                                    name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                    bussinessName: b2b_data?.businessName
                                                })}}
                                            >Fund Wallet</p>
                                        </div>
                                    </Link>
                                    <Link className="link-decor" to="/home/wallet">
                                        <div className="flex mb-[10px]">
                                            <img src={qa_threshold} alt="" className="mr-[5px]" />
                                            <p onClick={()=>{setShowQuickActions(false); Mixpanel.track('Quick Action (Set Wallet Threshold) button was clicked', {
                                                name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                bussinessName: b2b_data?.businessName
                                            })}}>Set Wallet Threshold</p>
                                        </div>
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="flex items-center gap-x-2 md:gap-x-3 rounded-[8px] py-0.5 px-2.5 relative md:p-0 md_min:shadow-[3px_4px_18px_rgba(0,0,0,0.1)]">
                            <div className="w-6 h-6 md:w-[38px] md:h-[38px] rounded-[50%] overflow-hidden shadow-sm">
                                <img src={profileLogo ? profileLogo : profile} alt="profile" className="w-full h-auto" />
                            </div>
                            <h6 className="font-semibold text-base capitalize whitespace-nowrap sm:hidden">{b2b_data?.user?.firstName ? b2b_data?.user?.firstName:b2b_data?.businessName} {b2b_data?.user?.lastName}</h6>
                            <div onClick={()=>setShowMore(!showMore)} className="py-3 cursor-pointer">
                                <img src={arrow} alt="arrow" className="w-2.5"/>
                            </div >
                            {
                                showMore &&
                                <div className="rounded-t-lg rounded-bl-[22px] absolute top-[44px] md:top-[42px] right-0 bg-white md:w-[250px] py-2.5 px-[15px] shadow-[3px_4px_18px_rgba(0,0,0,0.1)]">
                                    <div className="md_min:hidden">
                                        <h6 className="font-semibold text-md capitalize whitespace-nowrap">{b2b_data?.user?.firstName ? b2b_data?.user?.firstName:b2b_data?.businessName} {b2b_data?.user?.lastName}</h6>
                                        <p className="text-[#BABEC6] text-[0.8em] whitespace-nowrap">ID: {b2b_data?.user?.businessId}</p>
                                        <p className="font-bold text-[#BABEC6] text-[0.8em] whitespace-nowrap">Active App: <span className="text-cc-green-5 bg-cc-green-6 px-2 rounded-md py-.5">{activeApp.appName}</span></p>
                                    </div>
                                    <p className="text-[0.9em] text-[#2C2C2C] whitespace-nowrap">Business Name: {b2b_data?.businessName}</p>
                                    <p className="text-[#BABEC6] text-[0.8em] whitespace-nowrap">ID: {b2b_data?.user?.businessId}</p>
                                    <div className="flex items-center justify-center gap-x-1 max-w-[130px] mt-1 rounded-full px-1.5 border-[1px] border-cc-primary">
                                        { isComplied && <img src={verified} alt="verified" className="w-3" />}
                                        <p className="text-[0.8em] text-black">{isComplied ? "Verified Business" : "Unverified Business"}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={clsx(
                        "box-border flex flex-col items-center gap-y-4 px-10 md:px-[20px]",
                        (showCachePopup||showNotify||unreadMessages?.length>0) && "mb-10"
                    )}
                >
                    {
                        showCachePopup &&
                        <Notification
                            textIcon={<IoMdInformationCircleOutline className="w-3.5 scale-[1.4] shrink-0" />}
                            text="We are constantly releasing features or updates, and we noticed your web browser may have cached old content which could prevent you to experience the fixes or new feature releases, please clear your browser history, cache and reload this session."
                            hasOptions={true}
                            options={[
                                {
                                    label: "See guide",
                                    onClick: () => {
                                        dispatch(setCachePopup(false));
                                        window.open("https://helpcenter.trendmicro.com/en-us/article/tmka-20816", "_blank")
                                    }
                                },
                                {
                                    label: "Got it",
                                    onClick: () => {
                                        dispatch(setCachePopup(false))
                                    }
                                },
                            ]}
                            actionText="Take Action"
                            actionIcon={<IoIosArrowDown />}
                        />
                    }
                    {
                        showNotify &&
                        <Notification
                            textIcon={<IoWarningOutline className="w-3.5 scale-[1.4] shrink-0" />}
                            text="You have reached the set wallet limit"
                            hasOptions={false}
                            actionText="Fund Wallet"
                            actionIcon={<IoIosArrowRoundForward className="scale-[1.5]" />}
                            onActionClick={()=>navigate("/home/wallet")}
                        />
                    }
                    {
                        unreadMessages?.map((item)=>(
                            <Notification
                                key={item._id}
                                textIcon={
                                    item?.action === "approved" ? <IoIosCheckmarkCircleOutline className="w-3.5 scale-[1.4] shrink-0" /> :
                                    item?.action === "declined" ? <IoWarningOutline className="w-3.5 scale-[1.4] shrink-0" /> :
                                    <IoMdInformationCircleOutline className="w-3.5 scale-[1.4] shrink-0" />
                                }
                                text={item?.message}
                                hasOptions={false}
                                actionText={
                                    item?.action === "approved" ? "View Offer" :
                                    item?.action === "declined" ? "View Offer" : 
                                    item?.action === "overdue" ? "Make Payment" : 
                                    "Continue"
                                }
                                actionIcon={<IoIosArrowRoundForward className="scale-[1.5]" />}
                                onActionClick={()=>handleNotificationChange(item?._id)}
                            />
                        ))
                    }
                </div>
                <div>
                    <Routes>
                        <Route path="/" element={<Navigate to="/home" />}/>
                        <Route path="/home/*" element={<Home setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}/>} />
                        { 
                            pendingStatus==="pending" ?
                            <>
                                <Route path="/compliance/*" element={<Compliance from={"pending"} setDetailName={()=>setDetailName("")}/>} />
                            </>
                            :
                            <>
                                {
                                    permissionKeys?.includes("dash") &&
                                    <Route path="/dashboard" element={<Overview setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}/>} />
                                }
                                <Route path="/income/*" element={<Income setDetailName={(name)=>setDetailName(name)} rolePermissions={rolePermissions} />} />
                                <Route path="/apps/*" element={<Apps setDetailName={(name)=>setDetailName(name)} setOnLive={setOnLive} showLive={showLive} setShowLive={setShowLive} rolePermissions={rolePermissions}/>}  />
                                <Route path="/identity/*" element={<Identity setDetailName={(name)=>setDetailName(name)} rolePermissions={rolePermissions} />} />
                                <Route path="/settings/*" element={<Settings  setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}  />} />
                                <Route path="/billing/*" element={<Billing  setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}/>}  />
                                <Route path="/auditLogs/*" element={<AuditLogs setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}/>} />
                                <Route path="/apis/*" element={<Apis setDetailName={()=>setDetailName("")} rolePermissions={rolePermissions}/>} />
                                <Route path="/float/*" element={<Float />} />
                                <Route path="/credit-insight/*" element={<CreditInsight setDetailName={(name)=>setDetailName(name)} rolePermissions={rolePermissions}/>} />
                                <Route path="/spectrum/*" element={<Spectrum />} />
                                <Route path="/radar" element={<Radar rolePermissions={rolePermissions} />} />
                                <Route path="/recova/*" element={<Recova setDetailName={(name)=>setDetailName(name)} rolePermissions={rolePermissions} />} />
                            </>
                        }
                    </Routes>
                </div>
            </div>
            <IdleModal show={showModal} cancel={()=>{setShowModal(false);}} />
            <NotificationPanel
                visible={showNotificationPanel}
                setVisible={setShowNotificationPanel}
                notifications={notificationData}
                getNotification={getNotification}
            /> 
            <SwitchApps
                show={showAppSwitch}
                handleClose={()=>setShowAppSwitch(false)}
            />
        </div>
    )
}

const MyToolTip = ({id, message}) => {
    return(
        <Tooltip 
            id={id}
            render={()=>(
                <div className="text-xs w-[170px]">
                    <p className="font-semibold mb-1">{id}</p>
                    <p>{message}</p>
                </div>
            )}
            place="bottom"
        />
    )
}

const Notification = ({textIcon, text, hasOptions, options, actionText, actionIcon, onActionClick}) => {
    const [showOptions, setShowOptions] = useState(false)

    return (
        <div className="bg-[#E5EEFF] w-full rounded-[10px] flex items-center gap-x-2.5 max-w-[850px] p-2.5 text-sm">
            {textIcon}
            <p>{text}</p>
            <div className="relative ml-auto">
                <div 
                    className="flex items-center gap-x-1 text-sm font-bold cursor-pointer"
                    onClick={()=>{
                        if(hasOptions){
                            setShowOptions(!showOptions)
                        } else {
                            onActionClick()
                        }
                    }}
                >
                    <p className="whitespace-nowrap sm:hidden">{actionText}</p>
                    {actionIcon}
                </div>
                {
                    (showOptions && hasOptions) &&
                    <div className="absolute z-10 top-[20px] rounded-t-[10px] bg-white w-full text-sm font-bold cursor-pointer sm:w-[100px] sm:right-0">
                        {
                            options.map((item, idx)=>(
                                <p className="p-2.5" key={idx} onClick={item.onClick}>{item.label}</p>
                            ))
                        }
                    </div>
                }
                <div>

                </div>
            </div>
        </div>
    )
}

export default Dashboard;