import { Modal } from "react-bootstrap";
import { Formik, Form } from 'formik';
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { IdentityService } from "services/identity.service";
import {toast} from "react-toastify";
import { useSelector } from "react-redux";

import close from "images/svg/x-close.svg";
import email from "images/svg/email.svg";
import { forgotPasswordSchema } from "utils/validationSchemas";


export default function ShareIgreeLink ({
        show,
        onClose,
    }) {
    const businessLink = useSelector(state => state?.loginData?.data?.businessLink)
    
    const sendLink = (values) => {
        let payload = JSON.stringify({
            email: values.email,
            link: `${window.location.origin}/bvn-verification/${businessLink}`,
            type: "bvn"
        })
        IdentityService.shareLinkToMail(payload)
        .then((response) => response.data)
        .then((res) => {
            if(res.status){
                toast.success("Link sent successfully!")
                onClose();
            } else {
                toast.error("Unable to send link now, please try again later!")
            }
        })
        .catch(()=>{})
    }
 
    const initialValues = {
        email: '',
    }

    return(
        <Modal show={show} size="md" onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="p-[20px] box-border">
                <img src={close} alt="close" className="absolute w-[30px] top-[20px] right-[20px]" onClick={onClose} />
                <img src={email} alt="email" className="block mx-auto w-10 mb-6" />
                <Formik
                    initialValues={initialValues}
                    validationSchema = {forgotPasswordSchema}
                    onSubmit={(values) => {
                        sendLink(values)
                    }}
                >  
                    {(props) => (
                        <Form>
                            <TextField
                                label="Email Adress"
                                name='email'
                                type='text'
                                placeholder="Enter customer email address"
                            />
                            <Button
                                type="submit"
                                text='Send Link'
                                isLoading={false}
                                isValid={props.isValid}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}