import { Col, Modal, Row} from "react-bootstrap";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";


function ViewPermissions ({show, handleClose, permissionData}) {

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <Modal.Title>Permission</Modal.Title>
                <div className="settings-invite-mail">
                    <label>Role name</label>
                    <div>
                        <input type="text" className="mail" name="name" value={permissionData ? permissionData?.name : "Role Name"} readOnly style={{textTransform:'capitalize'}} />
                    </div>
                </div>
                <div className="settings-invite-mail" style={{marginTop:"-40px"}}>
                    <label>Description</label>
                    <div>
                        <input type="text" className="mail" name="description" value={permissionData ? permissionData?.description : "Description"} readOnly/>
                    </div>
                </div>
                
                <div className="settings-invite-role">
                    <div className="settings-invite-role-contain">
                        <Row>
                            {
                                permissionData?.permission < 1 ?
                                <h6 style={{textAlign:'center', marginBottom:'20px'}}>No permissions added for this role</h6>
                                : 
                                permissionData?.permission?.map(item=>(
                                    <Col md={6}>
                                        <div className="settings-invite-role-contain-views">
                                            <p style={{textTransform:'capitalize'}}><strong>{Object.keys(item)[0]==='dash'?'Dasbboard':Object.keys(item)[0]}</strong></p>
                                            <div>
                                                <Checkbox
                                                    icon={<Icon.FiCheck color="black" size={14} />}
                                                    name="my-input"
                                                    checked={permissionData?.permission?.find(obj => obj[`${Object.keys(item)[0]}`])?.[`${Object.keys(item)[0]}`]?.includes('view')}
                                                    disabled={true}
                                                    borderColor="black"
                                                    style={{ cursor: "pointer" }}
                                                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                                    label={`Can view ${Object.keys(item)[0]==='dash'?'dasbboard':Object.keys(item)[0]}`}
                                                />
                                                <Checkbox
                                                    icon={<Icon.FiCheck color="black" size={14} />}
                                                    name="my-input"
                                                    checked={permissionData?.permission?.find(obj => obj[`${Object.keys(item)[0]}`])?.[`${Object.keys(item)[0]}`]?.includes('edit')}
                                                    disabled={true}
                                                    borderColor="black"
                                                    style={{ cursor: "pointer" }}
                                                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                                    label={`Can edit ${Object.keys(item)[0]==='dash'?'dasbboard':Object.keys(item)[0]}`}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ViewPermissions;