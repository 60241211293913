//Library Methods
import React, { useEffect } from 'react'

//Components
import TransactionOverview from './TransactionOverview';
import HomeSetup from './HomeSetup';
import { RecovaService } from 'services/recova.service';
import RecovaHeaderLayout from 'pages/Dashboard/Recova/layout/RecovaHeaderLayout';

//Utilities
import { useRecovaSetup, useUser } from 'hooks/redux.hook';
import { handleRequestErrors } from 'utils/helpers';
import { toast } from 'react-toastify';


const Main = () => {
  const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
  const { activeApp } = useUser();
   
  useEffect(() => {
    RecovaService.getAppSetup(activeApp[0]?._id)
      .then(response=> response.data)
      .then(res => {
          if(res.status === true && !!res?.data){
            const currentStep = res?.data?.intention==="" ? 1
              : res?.data?.paymentOption==="" ? 5
              : res?.data?.bankCode==="" ? 9
              // : res?.data?.creditInsurance===false ? 13
              // : res?.data?.creditUseCase.length<1 ? 15 
              // : res?.data?.creditDefination.length<1 ? 17 
              : !res?.data?.acceptedTerms ? 21
              : 23;

            const linkedAcc = res?.data?.accountName ? {
                accountNumber: res?.data?.accountNumber,
                accountName: res?.data?.accountName,
                bankName: res?.data?.bankName,
                bankCode: res?.data?.bankCode,
            } : null

            updateSetupDispatch({
              currentStep: currentStep,
              useCase: res?.data?.intention || "",
              paymentOption: res?.data?.paymentOption || "",
              ewalletNumber: res?.data?.ewalletNumber || "",
              linkedAccount: linkedAcc,
              enabledCreditInsurance: res?.data?.creditInsurance ?? null, // true or false
              creditUseCase: res?.data?.creditUseCase || [], // should be an array of string
              // loanProdDefs: res?.data?.creditDefination || [], // should be an array of string
              termsAccepted: res?.data?.acceptedTerms || false,
              isCompleted: currentStep>20,
            })
          }
      })
      .catch((err) => {
          handleRequestErrors(err, true)
      });
      getProducts()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProducts = () => {
    RecovaService.getProducts()
        .then(response=> response.data)
        .then(res => {
            if(res.status){
              updateSetupDispatch({
                loanProdDefs: res?.data||[]
              })
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err, true)
        });
  }

  return (
    <RecovaHeaderLayout>
      {
        recovaSetupData?.isCompleted ? 
        <TransactionOverview /> :
        <HomeSetup />
      }
    </RecovaHeaderLayout>
  )
}

export default Main