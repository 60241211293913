import { useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import Back from "../../../components/shared/back";
import upload from "../../../images/svg/home/upload.svg";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { DateField } from "components/Form/DateField";
import { SelectField } from "components/Form/SelectField";
import { dateFormatter } from "utils/helpers";
import Button from "components/shared/Button";
import { complianceProfile } from "utils/validationSchemas";
import { toast } from "react-toastify";


function Profile ({handleChange, info, nextButton, setUpload, fileName, setFileName, pathBase, uploadedPhoto}) {
    const fileInput = useRef(null)
    const [isForm, setIsForm] = useState(true)

    const handleClick = () => {
        fileInput.current.click()
    }
    
    const onFileChange = (e) => {
        if(e.target.files[0].size < 1024*1024*2){
            setUpload(e)
            setFileName(e.target.files[0].name)
    
            const reader = new FileReader();
            reader.addEventListener("load", ()=>{
                sessionStorage.setItem("compliance_logo", reader.result)
            })
            reader.readAsDataURL(e.target.files[0])
        } else {
            toast.error("Maximum file allowed is 2mb")
        }
    }

    useEffect(()=>{
        form.setFieldValue("businessName", info?.businessName || "")
        form.setFieldValue("ownersName", info?.ownersName || "")
        form.setFieldValue("dob", info?.dob || "")
        form.setFieldValue("registrationType", info?.registrationType || "")
        form.setFieldValue("size", info?.size || "")
        form.setFieldValue("industry", info?.industry || "")
        form.setFieldValue("incorporationDate", info?.incorporationDate || "")
        form.setFieldValue("directorsBVN", info?.directorsBVN || "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info])

    const form = useFormik({
        initialValues: {
            businessName: info?.businessName || "",
            ownersName: info?.ownersName || "",
            dob: info?.dob || "",
            registrationType: info?.registrationType || "",
            size: info?.size || "",
            industry: info?.industry || "",
            incorporationDate: info?.incorporationDate || "",
            directorsBVN: info?.directorsBVN || "",
        },
        validationSchema: complianceProfile, 
        onSubmit: (values) => {
            if (!!uploadedPhoto) {
                handleChange(values)
                sessionStorage.setItem("compliance_profile", JSON.stringify(values))
                setIsForm(false)
            } else {
                toast.error("Please include a logo for your business!")
            }
        }
    })

    const regTypeOptions = ["Limited Liability Limited", "Business Name","Private Entity", "Incorporated Trustee", "Free Zone Entity", "Government Entity", "Cooperative Society"]
    const sizeOptions = ["1-5", "6-10", "11-20", "21-100", "101-250", "251 and above"]
    const industryOptions = ["Finance", "Retail & Distribution", "Agriculture", "Chemical (manufacturing)", "Pharmaceuticals", "Computing (software)", "Computinng (Hardware)", "Construction", "Education", "Energy", "Entertainment", "Food", "Health Care", "Hospitality", "Information", "Manufacturing", "Telecommunication", "Transport", "Sports Management & Event Promotion" ]

    return(
        <div className="box-border">
            {
                !isForm &&
                <div className="-mb-[30px]" style={{marginBottom:"-30px"}}>
                    <Back  to={()=>setIsForm(true)} />
                </div>
            }
            <h5 className="text-xl font-medium">Profile</h5>
            {isForm ?
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <p className="mb-[20px]">Your Full Name and Date Of Birth should match your bvn records</p>
                        <TextField
                            label="Business Name"
                            name='businessName'
                            type='text'
                            placeholder="Business Name"
                            required
                        />
                        <TextField
                            label="Business Owner Full Name"
                            name='ownersName'
                            type='text'
                            placeholder="Full Name"
                            required
                        />
                        <DateField
                            name='incorporationDate'
                            label="Incorporation Date"
                            required
                            placeholder="DD-MM-YYYY"
                            onChange={(value)=>{
                                console.log(value)
                                form.setFieldValue("incorporationDate", dateFormatter(value, 'yyyy-mm-dd'))
                            }}
                            value={form?.values?.incorporationDate}
                        />
                        <TextField
                            label="Directors Bvn"
                            name='directorsBVN'
                            type='text'
                            placeholder="Enter directors BVN"
                            required
                        />
                        <DateField
                            name='dob'
                            label="Owner's Date of Birth"
                            required
                            placeholder="DD-MM-YYYY"
                            onChange={(value)=>{
                                form.setFieldValue("dob", dateFormatter(value, 'yyyy-mm-dd'))
                            }}
                            value={form?.values?.dob}
                        />
                        <SelectField
                            name='registrationType'
                            options={regTypeOptions?.map(item=>({
                                label: item,
                                value: item
                            }))}
                            required
                            label="Registration Type"
                            onChange={(selected)=>{
                                form.setFieldValue( "registrationType", selected.value )
                            }}
                            value={form?.values?.registrationType}
                            placeholder="Registration Type"
                        />
                        <SelectField
                            name='size'
                            options={sizeOptions?.map(item=>({
                                label: item,
                                value: item
                            }))}
                            required
                            label="Size"
                            onChange={(selected)=>{
                                form.setFieldValue( "size", selected.value )
                            }}
                            value={form?.values?.size}
                            placeholder="Size"
                        />
                        <SelectField
                            name='industry'
                            options={industryOptions?.map(item=>({
                                label: item,
                                value: item
                            }))}
                            required
                            label="Business Sector"
                            onChange={(selected)=>{
                                form.setFieldValue( "industry", selected.value )
                            }}
                            placeholder="Business Sector"
                            value={form?.values?.industry}
                        />
                        <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={onFileChange} ref={fileInput} style={{display:"none"}} />
                        <div className="myModal_main-widget-upload !mt-4" onClick={() => handleClick()} style={{cursor:"pointer"}}>
                            <img src={upload} alt="upload" />
                            <p>{fileName}</p>
                        </div>
                        <p className="text-xs text-red-600 -mt-6">2MB maximum file size</p>
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="submit"
                            text="Save"
                            className="mt-10 bg-cc-primary"
                        />
                    </form>
                </FormikProvider>
                :
                <div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Business Name</h6>
                        <p className="text-[#353535]">{info.businessName}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Business Owner Full Name</h6>
                        <p className="text-[#353535]">{info.ownersName}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Incorporation Date</h6>
                        <p className="text-[#353535]">{info.incorporationDate}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Directors BVN</h6>
                        <p className="text-[#353535]">{info.directorsBVN}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Owner's Date of Birth</h6>
                        <p className="text-[#353535]">{info.dob}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Registration Type</h6>
                        <p className="text-[#353535]">{info.registrationType}</p>
                    </div>
                    <div className="mb-3">
                        <h6 className="text-[#5B5B5B] text-[1em]">Size</h6>
                        <p className="text-[#353535]">{info.size}</p>
                    </div>
                    <div className="mb-6">
                        <h6 className="text-[#5B5B5B] text-[1em]">Industry</h6>
                        <p className="text-[#353535]">{info.industry}</p>
                    </div>
                    <div className="flex justify-between">
                        <button onClick={()=>setIsForm(true)} className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Edit</button>
                        <Link to={`${pathBase}/contact`}>
                            <button onClick={()=>nextButton()} className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Next &#62;</button>
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}


export default Profile;