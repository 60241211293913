import axios from 'axios';


// get nigerian banks
export const getBanks = () => {
    return axios.get(`https://nigerianbanks.xyz/`)
}

// send message to whatsapp
export const shareToWhatsapp = (payload) => {
    return axios.post(`https://api.ng.termii.com/api/sms/send`, payload,{
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*'
        }
    })
}

//geolocation for ip address
export const getGeolocation = () => {
    return axios.get('https://geolocation-db.com/json/', {
        // withCredentials: true,  this causes CORS error
        // json: true,
        headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin' : '*'
        }
    })
}

// bank checker
export const bankAccountChecker = (account, code) => {
    return axios.get(`https://maylancer.org/api/nuban/api.php?account_number=${account}&bank_code=${code}`);
  };