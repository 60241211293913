
import tableIcon from "../../../../images/svg/loan-performance.svg";
import HistoryTable from "./components/HistoryTable";


export default function History () {

    return(
        <div className="mb-10">
            <HistoryTable 
                icon={tableIcon}
                title={"Mandate History"}
                reloadHistory={false}
            />
        </div>
    )
}