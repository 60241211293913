
import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import axios from 'axios';
import logo from "../../images/svg/logo.svg";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { logToBin } from "../../lib/logs";
import { FloatService } from "services/float.service";


function DirectorEmailConsent () {
    let params = useParams();
    const [data, setData] = useState({
        requestId: params?.floatId,
        type: params?.type,
        bvn: ''
    });
    const [isDisabled, setIsDisabled] = useState(true);
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    // const handleSelect = (name, item) => {
    //     setData({
    //         ...data,
    //         [name]: item
    //     })
    // }

    useEffect(() => {
     if( data?.bvn && isChecked === true ){
        setIsDisabled(false)
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isChecked])
    

    const submitForm = (e) => {
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        e.preventDefault();
      if(data?.bvn?.length !== 11){
          toast.error('Your BVN must be 11 letters')
          btn.innerHTML = "Submit"
      }else{
        FloatService.floatDirectorBVNCheck(data)
        .then(response=> response.data)
        .then(res => {
            if(res.status === 'success'){
              btn.innerHTML = "Submit"
              let payload = {
                requestId: data?.requestId,
                type: data?.type,
                bvn: data?.bvn
              }
              FloatService.floatVerifyConsent(payload)
              .then(response=> response.data)
              .then(res => {
                  if(res.status === 'success'){
                    toast.success('Consent verification successful!!!')
                    btn.innerHTML = "Submit"
                    setTimeout(() => {
                        window.open('about:blank', '_self');
                        window.close()
                    }, 1500);
                  } else {
                      toast.error(res.message)
                      btn.innerHTML = "Submit"
                  }
              })
              .catch((err) => {
                  if(err.response){
                      logToBin(err.response.data, window.location.href);
                      btn.innerHTML = "Submit"
                  } else{
                  if(err.message === "timeout of 15000ms exceeded"){
                      toast.error('Request timed out, please try again!')
                      btn.innerHTML = "Submit"
                  } else if (err.message==="Network Error"){
                      toast.error('Network error, please make sure you are connected to the internet!')
                      btn.innerHTML = "Submit"
                  } else{
                      toast.error("Your request can't be processed at this time, please try again later!");
                      btn.innerHTML = "Submit"
                  }
                  }
              }); 
            } else {
                toast.error(res.message)
                btn.innerHTML = "Submit"
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message)
                logToBin(err.response.data, window.location.href);
                btn.innerHTML = "Submit"
            } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
                btn.innerHTML = "Submit"
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
                btn.innerHTML = "Submit"
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
                btn.innerHTML = "Submit"
            }
            }
        }); 
       
      }  
    }



    return(
        <div style={{
            background: " rgba(252, 252, 252, 0.5)",
            backdropFilter: " blur(45.75px)",
            // padding:"50px 0",
            marginBottom:"-20px",
            height:"100vh",
            display: 'flex',
            alignItems:"center",
            justifyContent: "center"
            }}>
            <div style={{
                maxWidth:"480px", 
                display:"block", 
                margin:"auto",
                boxShadow: "0px 2px 11px rgba(0, 0, 0, 0.2)",
                background: "white",
                borderRadius: "10px",
            }}>
            <div className="m-[20px]">
                <div className="myModal_main-widget-header">
                    <h3 className="float-emailConsent-header">
                        Float Obligor’s Consent
                    </h3>
                </div>

                <form onSubmit={submitForm}>
                    <div className="myModal_main-widget-form">
                        <label className="float-emailConsent-label"> Bank Verification Number </label>
                        <input type="text" placeholder="BVN" name="bvn" onChange={handleChange} />
                    </div>
                    <div style={{display:"flex", alignItems:"flex-start", marginTop:"-20px"}}>
                            <input type="checkbox" style={{width:"45px", margin:"5px 7px 0 0"}} id="readTerms" checked={isChecked} onClick={()=>setIsChecked(!isChecked)} />
                            <p className="float-emailConsent-terms">By execution hereof, I consents to this terms and conditions to guarantee this business’s float applicatiion</p>
                        </div>
                    <div className="myModal_main-widget-action">
                        <button type="submit" className={isDisabled?"form-disable":"form-submit"} disabled={isDisabled} id="actionBtn">Submit</button>
                    </div>
                </form>
                <div className="my-[20px] flex justify-center">
                    <p className="text-[1em] text-center font-bold mr-2">Powered by </p><img width="90px" src={logo} alt="logo"/>
                </div>

               
            </div>
        </div>
        </div>
    )
}

export default DirectorEmailConsent;