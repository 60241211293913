import { useEffect, useState } from "react";
import skip from "images/svg/float/skip.svg";
import block from "images/svg/float/block.svg";
import change from "images/svg/float/change.svg";
import clsx from "clsx";
import ChangeBalance from "./forms/ChangeBalance";
import FreezeCollections from "./forms/FreezeCollections";
import SkipCollections from "./forms/SkipCollections";
import { FloatService } from "services/float.service";
import { useParams } from "react-router-dom";
import { logToBin } from "lib/logs";
import {toast} from "react-toastify";
import PatchModal from "./components/Modal";
import { Container } from "react-bootstrap";


export default function Spectrum () {
    const [path, setPath] = useState('change');
    const params = useParams();
    const [showModal, setShowModal] = useState(false)
    
    useEffect(()=>{
        getFloat()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getFloat = () => {
        FloatService.getFloatPatchReq(params.floatId)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.status === 'success') {
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Container>
            <div className="flex xl:block pt-[70px] pb-[30px] px-10 sm:pl-2.5 sm:pr-[30px] max-w-[1000px]">
                <div className="w-[330px] xl:flex xl:w-full xl:mb-[20px] xl:flex-wrap">
                    <div 
                        className={clsx(
                            "font-semibold text-cc-black-4 p-2.5 m-0 cursor-pointer flex items-center xl:w-[150px] md:w-[45%] xs:w-full",
                            path==='change' && "border-l-cc-primary border-l-[3px] bg-cc-grey-1",
                        )}
                    >
                        <img src={change} alt="" className="w-[20px] mr-[7px]"/>
                        <p onClick={()=>setPath("change")}>Change Balance Amount</p>
                    </div>
                    <div 
                        className={clsx(
                            "font-semibold text-cc-black-4 p-2.5 m-0 cursor-pointer flex items-center xl:w-[150px] md:w-[45%] xs:w-full",
                            path==='freeze' && "border-l-cc-primary border-l-[3px] bg-cc-grey-1",
                        )}
                    >
                        <img src={block} alt="" className="w-[20px] mr-[7px]" />
                        <p onClick={()=>setPath("freeze")}>Freeze Collections</p>
                    </div>
                    <div 
                        className={clsx(
                            "font-semibold text-cc-black-4 p-2.5 m-0 cursor-pointer flex items-center xl:w-[150px] md:w-[45%] xs:w-full",
                            path==='skip' && "border-l-cc-primary border-l-[3px] bg-cc-grey-1",
                        )}
                    >
                        <img src={skip} alt="" className="w-[20px] mr-[7px]" />
                        <p onClick={()=>setPath("skip")}>Skip Collections</p>
                    </div>
                </div>
                <div className="w-full ml-[30px] xl:ml-0">
                    <div className="border-[1px] box-border border-cc-grey-25 rounded-[15px] px-[20px] py-[25px] mb-[30px]">
                        {
                            path==="change" ?
                            <ChangeBalance
                                handleSuccess={()=>setShowModal(true)}
                            />
                            : path==="freeze" ?
                            <FreezeCollections
                                handleSuccess={()=>setShowModal(true)}
                            />
                            : path==="skip" ?
                            <SkipCollections
                                handleSuccess={()=>setShowModal(true)}
                            />
                            :<></>
                        }  
                    </div>
                </div>
                <PatchModal
                    show={showModal}
                    onCancel={()=>setShowModal(false)}
                />
            </div>
        </Container>
    )
}