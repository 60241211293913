import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import perf from "../../images/pdf-export/perf.png";
import crc from "../../images/pdf-export/crc.png";
import first_central from "../../images/pdf-export/first_central.png";
import credit_registry from "../../images/pdf-export/credit_registry.png";

const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

var formatMoneyForPDF = new Intl.NumberFormat('en-NG', {
    maximumFractionDigits: 2, 
});

export const jsPDFGenerator = (report, params, currentPath, reportType) => {
    const doc = new jsPDF({
        // orientation: "landscape",
        unit: "mm",
        format: [210, 297]
    });

    doc.setFont("DMSans-Regular", "normal", "bold");
    doc.setFillColor('#0046E6');
    doc.roundedRect(55, 115, 100, 10, 1, 1, 'F');
    doc.setTextColor("#ffffff")
    doc.setFont("DMSans-Regular", "normal", "normal");
    doc.text(`${report?.name}`, 105, 122, {align:"center"});
    doc.setTextColor("#000")
    doc.text(`${params.type==="CRC"?"CRC":params.type==="first-central"?"First Central":params.type==="advanced"?"Advanced":params.type==="credit-registry"?"Credit Registry":"Premium"} Credit Report - ${new Date().getDate()} ${months[new Date().getMonth()]}, ${new Date().getFullYear()}`, 105, 135, {align:"center"});

    report?.bvn && doc.text(`BVN - ${report?.bvn}`, 105, 148, {align:"center"});
    report?.phone && doc.text(`Phone Number - ${report?.phone}`, 105, 161, {align:"center"});
    report?.address && doc.text(`Address - ${report?.address}`, 105, 174, {align:"center"});
    
    var imgCrc = new Image();
    imgCrc.src = crc;
    var imgFC = new Image();
    imgFC.src = first_central;
    var imgCR = new Image();
    imgCR.src = credit_registry;
    
    var img2 = new Image()
    img2.src = perf

    if(params.type==="CRC" || params.type==="first-central" || params.type==="credit-registry"){
        // crc or first central report page m
        doc.addPage()
        doc.setFont("DMSans-Regular", "normal", "bold")
        doc.setFontSize(20)
        doc.addImage(params.type==="CRC"?imgCrc:params.type==="first-central"?imgFC:imgCR, 'png', 20, 15, 15, 15);
        doc.text(`${params.type==="CRC"?"CRC":params.type==="first-central"?"First Central":'Credit Registry'}`, 40, 20);
        doc.setFontSize(14)
        doc.setFont("DMSans-Regular", "normal", "normal")
        doc.text(`Credit Overview`, 40, 28);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of credit facilities`, 20, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfLoans || 0}`, 20, 60);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of open credit facilities`, 77, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfActiveLoans || 0}`, 77, 60);
        
        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of closed credit facilities`, 134, 50);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfClosedLoans || 0}`, 134, 60);

        
        doc.setDrawColor("#D6D6D6");
        doc.line(20,65, 190,65)

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of delinquent facilities`, 20, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfDelinquentFacilities || 0}`, 20, 90);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total no of credit grantors`, 77, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.totalNoOfInstitutions || 0}`, 77, 90);
        
        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Max no if days due`, 134, 80);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`${report.data.maxNoOfDays || 0}`, 134, 90);
        
        doc.line(20,95, 190,95)
        
        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Due (overdue)`, 20, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`N${params.type==="first-central" ?`${report.data.totalOverdue || 0}`:formatMoneyForPDF.format(report.data.totalOverdue || 0)}`, 20, 120);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Loan Value`, 77, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(`N${
                params.type==="first-central" || params.type==="credit-registry" ?
                    formatMoneyForPDF.format(report.data.totalBorrowed || 0)
                    :
                    `${report.data.totalBorrowed || 0}`
            }`, 77, 120);

        doc.setFontSize(11.5)
        doc.setTextColor("#667085")
        doc.setFont("Inter", "normal", "normal")
        doc.text(`Total Oustanding Value`, 134, 110);
        doc.setFont("Inter", "normal", "bold");
        doc.setTextColor("#101828")
        doc.setFontSize(16)
        doc.text(params.type==="credit-registry"? `N${formatMoneyForPDF.format(report.data.totalOutstanding || 0)}` : `N${report.data.totalOutstanding || 0}`, 134, 120);
        
        doc.line(20,125, 190,125)

        if(params.type==="CRC"){
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Last Reported Date`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report.data.lastReportedDate}`, 20, 150);
        }
        alert(reportType, params.type)

        if(reportType==="full"){
            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                    head: params.type==="first-central" ? [['Loan Provider', 'Inquiry Date']]
                        : [['Facility Type', 'Loan Type', 'Inquiry Date']],
                    body: params.type==="first-central" ? report?.data?.creditEnquiries?.map((item)=>[
                            item.loanProvider, item.date
                        ])
                        : report?.data?.creditEnquiries?.map((item)=>[
                            item.institutionType, item.loanType, item.date
                        ]),
            })
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.map((item)=>[
                    item.loanProvider, 
                    item.loanCount || 0, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.loanAmount||0)}`:item.loanAmount, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.outstandingBalance||0)}`:`NGN ${item.outstandingBalance}`, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.overdueAmount||0)}`:`NGN ${item.overdueAmount}`, 
                    item.status, 
                    item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [[
                    'Loan Provider',  
                    'Loan Amount', 
                    'Installment Amount', 
                    'Outstanding Balance', 
                    'Overdue Amount', 
                    'Type', 
                    ...(params.type==="first-central") ? ['Status']:[], 
                    'Date Reported'
                ]],
                body: report?.data?.loanHistory?.map((item)=>[
                    item.loanProvider,  
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.loanAmount||0)}`:`NGN ${item.loanAmount}`, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.installmentAmount||0)}`:`NGN ${item.installmentAmount}`, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.outstandingBalance||0)}`:`NGN ${item.outstandingBalance}`, 
                    params.type==="credit-registry" ? `NGN ${formatMoneyForPDF.format(item.overdueAmount||0)}`:`NGN ${item.overdueAmount}`, 
                    item.type, 
                    ...(params.type==="first-central") ? [item.status]:[], 
                    item.dateReported
                ])
            })
        }
    } else {

        if(report?.data?.bureauStatus?.crc==="success"){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgCrc, 'png', 20, 15, 15, 15);
            doc.text(`CRC`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[0]?.value || 0}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans?.[0]?.value || 0}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[0]?.value || 0}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[0]?.value || 0}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[0]?.value || 0}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Max no if days due`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.maxNoOfDays[0]?.value || 0}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalOverdue[0]?.value || 0)}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalBorrowed[0]?.value || 0)}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.highestLoanAmount[0]?.value || 0)}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalOutstanding[0]?.value || 0)}`, 20, 150);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Last Reported Date`, 77, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.lastReportedDate[0]?.value || 0}`, 77, 150);
    
    
            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Facility Type', 'Loan Type', 'Inquiry Date']],
                  body: report?.data?.creditEnquiries?.[0]?.value.map((item)=>[
                    item.institutionType, item.loanType, item.date
                  ])
            })
    
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[0]?.value.map((item)=>[
                    item.loanProvider, item.loanCount, item.loanAmount, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider',  'Loan Amount', 'Installment Amount', 'Outstanding Balance', 'Overdue Amount', 'Type', 'Date Reported']],
                body: report?.data?.loanHistory?.[0]?.value.map((item)=>[
                    item.loanProvider,  `NGN ${item.loanAmount}`, `NGN ${item.installmentAmount}`, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.type, item.dateReported
                ])
            })
        }
        
        if (report?.data?.bureauStatus?.firstCentral==="success"){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgFC, 'png', 20, 15, 15, 15);
            doc.text(`First Central`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[1]?.value || 0}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans?.[1]?.value || 0}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[1]?.value || 0}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[1]?.value || 0}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[1]?.value || 0}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Max no if days due`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.maxNoOfDays[1]?.value || 0}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOverdue[1]?.value || 0}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalBorrowed[1]?.value || 0)}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.highestLoanAmount[1]?.value || 0)}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOutstanding[1]?.value || 0}`, 20, 150);
    
    
            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Loan Provider', 'Inquiry Date']],
                  body: report?.data?.creditEnquiries?.[1]?.value.map((item)=>[
                    item.loanProvider, item.date
                  ])
            })
    
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[1]?.value.map((item)=>[
                    item.loanProvider, item.loanCount, item.loanAmount, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider',  'Loan Amount', 'Installment Amount', 'Outstanding Balance', 'Overdue Amount', 'Type','Status', 'Date Reported']],
                body: report?.data?.loanHistory?.[1]?.value.map((item)=>[
                    item.loanProvider,  `NGN ${item.loanAmount}`, `NGN ${item.installmentAmount}`, `NGN ${item.outstandingBalance}`, `NGN ${item.overdueAmount}`, item.type, item.status, item.dateReported
                ])
            })
        }
        
        if (params.type==="premium" && report?.data?.bureauStatus?.creditRegistry==="success"){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgCR, 'png', 20, 15, 15, 15);
            doc.text(`Credit Registry`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[0]?.value || 0}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans?.[0]?.value || 0}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[0]?.value || 0}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[0]?.value || 0}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[0]?.value || 0}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Max no if days due`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.maxNoOfDays[0]?.value || 0}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOverdue[0]?.value || 0}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalBorrowed[0]?.value || 0)}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.highestLoanAmount[0]?.value || 0)}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOutstanding[0]?.value || 0}`, 20, 150);


            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Facility Type', 'Loan Type', 'Inquiry Date']],
                  body: report?.data?.creditEnquiries?.[0]?.value.map((item)=>[
                    item.institutionType, item.loanType, item.date
                  ])
            })
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[0]?.value.map((item)=>[
                    item.loanProvider, item.loanCount||0, `NGN ${formatMoneyForPDF.format(item.loanAmount||0)}`, `NGN ${formatMoneyForPDF.format(item.outstandingBalance||0)}`, `NGN ${formatMoneyForPDF.format(item.overdueAmount||0)}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider',  'Loan Amount', 'Installment Amount', 'Outstanding Balance', 'Overdue Amount', 'Type', 'Date Reported']],
                body: report?.data?.loanHistory?.[0]?.value.map((item)=>[
                    item.loanProvider,  `NGN ${formatMoneyForPDF.format(item.loanAmount)}`, `NGN ${formatMoneyForPDF.format(item.installmentAmount)}`, `NGN ${formatMoneyForPDF.format(item.outstandingBalance)}`, `NGN ${formatMoneyForPDF.format(item.overdueAmount)}`, item.type, item.dateReported
                ])
            })
        }
        
        if (params.type==="advanced" && report?.data?.bureauStatus?.creditRegistry==="success"){
            doc.addPage()
            doc.setFont("DMSans-Regular", "normal", "bold")
            doc.setFontSize(20)
            doc.addImage(imgCR, 'png', 20, 15, 15, 15);
            doc.text(`Credit Registry`, 40, 20);
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Overview`, 40, 28);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.text(`Total no of credit facilities`, 20, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfLoans[2]?.value || 0}`, 20, 60);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of open credit facilities`, 77, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfActiveLoans?.[2]?.value || 0}`, 77, 60);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of closed credit facilities`, 134, 50);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfClosedLoans[2]?.value || 0}`, 134, 60);

            
            doc.setDrawColor("#D6D6D6");
            doc.line(20,65, 190,65)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of delinquent facilities`, 20, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfDelinquentFacilities[2]?.value || 0}`, 20, 90);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total no of credit grantors`, 77, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.totalNoOfInstitutions[2]?.value || 0}`, 77, 90);
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Max no if days due`, 134, 80);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`${report?.data?.maxNoOfDays[2]?.value || 0}`, 134, 90);
            
            doc.line(20,95, 190,95)
            
            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Due (overdue)`, 20, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOverdue[2]?.value || 0}`, 20, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Loan Value`, 77, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.totalBorrowed[2]?.value || 0)}`, 77, 120);

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Highest Loan Request`, 134, 110);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${formatMoneyForPDF.format(report?.data?.highestLoanAmount[2]?.value || 0)}`, 134, 120);
            
            doc.line(20,125, 190,125)

            doc.setFontSize(11.5)
            doc.setTextColor("#667085")
            doc.setFont("Inter", "normal", "normal")
            doc.text(`Total Oustanding Value`, 20, 140);
            doc.setFont("Inter", "normal", "bold");
            doc.setTextColor("#101828")
            doc.setFontSize(16)
            doc.text(`N${report?.data?.totalOutstanding[2]?.value || 0}`, 20, 150);


            // credit enquiries
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Credit Enquiries`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Loan Provider', 'Reason', 'Contact', 'Inquiry Date']],
                  body: report?.data?.creditEnquiries?.[2]?.value.map((item)=>[
                    item.loanProvider, item.reason, item.contactPhone, item.date
                  ])
            })
    
            // creditors
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Creditors`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                  head: [['Name', 'Phone', 'Address']],
                  body: report?.data?.creditors?.[2]?.value.map((item)=>[
                    item.Name, item.Phone, item.Address
                  ])
            })
    
    
            // loan performance
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan Performance`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider', 'Loan Count', 'Loan Amount', 'Oustanding Balance', 'Overdue Amount', 'Status', 'Performance Status']],
                body: report?.data?.loanPerformance?.[2]?.value.map((item)=>[
                    item.loanProvider, item.loanCount, `NGN ${formatMoneyForPDF.format(item.loanAmount)}`, `NGN ${formatMoneyForPDF.format(item.outstandingBalance)}`, `NGN ${formatMoneyForPDF.format(item.overdueAmount)}`, item.status, item.performanceStatus
                ])
            })
    
            // loan history
            doc.addPage();
            doc.addImage(img2, 'png', 15, 16, 5, 5, "perf2");
            doc.setFontSize(14)
            doc.setFont("DMSans-Regular", "normal", "normal")
            doc.text(`Loan History`, 22, 20);
            doc.setFont("Inter", "normal", "normal")
            autoTable(doc, {
                styles: {textColor: "#667085"},
                theme: 'grid',
                margin: { top: 25 },
                bodyStyles: {lineColor: "#EAECF0"},
                headStyles: { fillColor: "#0046E6", textColor: "white" },
                columnStyles: { 0: { textColor: "black" } },
                head: [['Loan Provider',  'Loan Amount', 'Installment Amount', 'Outstanding Balance', 'Overdue Amount', 'Type', 'Date Reported']],
                body: report?.data?.loanHistory?.[2]?.value.map((item)=>[
                    item.loanProvider,  `NGN ${formatMoneyForPDF.format(item.loanAmount)}`, `NGN ${formatMoneyForPDF.format(item.installmentAmount)}`, `NGN ${formatMoneyForPDF.format(item.outstandingBalance)}`, `NGN ${formatMoneyForPDF.format(item.overdueAmount)}`, item.type, item.dateReported
                ])
            })
        }
    }

    doc.save(`${report.name}_${params.type}_report.pdf`);

}