import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

export const RadarService = {
    getConnectedBanks: (payload) => {
        return APIKit(15000).post(`/radar/get-banks`, payload, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    getExistingBankRecords: (id) => {
        return APIKit(15000).get(`radar/borrower/${id}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }, 
        })
    },
    
    getAllBusinessRecords: () => {
        return APIKit(15000).get(`/radar/get-all`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    getConsentedRadarBanks: (payload) => {
        return APIKit(15000).post(`/radar/bank-data`, payload, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
}