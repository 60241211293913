import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

export const WalletService = {
    getWallet: () => {
        return APIKit(10000).get(`/wallet/`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
        
    getWalletHistory: (filterTerm) => {
        return APIKit(10000).get(`/wallet/transactions?type=${filterTerm}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    getInvoice: (invoiceId) => {
        return APIKit(10000).get(`/wallet/transactions/${invoiceId}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    walletNotifications: () => {
        return APIKit(15000).post(`wallet/toggle/alert`,{},
            {
                headers: {
                    Authorization: `Bearer ${getPublicKey()}`
                }
            }
        )
    },

    updateThreshold: (payload) => {
        return APIKit(15000).post(`wallet/update/threshold`,payload,
        {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    createWallet: (payload) => {
        return APIKit(15000).post(`wallet/account/create-static`,payload,
        {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    createDynamic: (payload) => {
        return APIKit(15000).post(`wallet/account/create-dynamic`, {},
        {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
}