import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';


export default function MonthChart ({data}) {
  const month = new Date().getMonth();
  const year = new Date().getFullYear();
  
  const fetchedData = [
    {
      month: `${data?.[0]?.month.substring(0,3) || ""} ${month+1>11? year : year-1}`,
      count: data?.[0]?.count
    },
    {
      month: `${data?.[1]?.month.substring(0,3) || ""} ${month+1>10? year : year-1}`,
      count: data?.[1]?.count
    },
    {
      month: `${data?.[2]?.month.substring(0,3) || ""} ${month+1>9? year : year-1}`,
      count: data?.[2]?.count
    },
    {
      month: `${data?.[3]?.month.substring(0,3) || ""} ${month+1>8? year : year-1}`,
      count: data?.[3]?.count
    },
    {
      month: `${data?.[4]?.month.substring(0,3) || ""} ${month+1>7? year : year-1}`,
      count: data?.[4]?.count
    },
    {
      month: `${data?.[5]?.month.substring(0,3) || ""} ${month+1>6? year : year-1}`,
      count: data?.[5]?.count
    },
    {
      month: `${data?.[6]?.month.substring(0,3) || ""} ${month+1>5? year : year-1}`,
      count: data?.[6]?.count
    },
    {
      month: `${data?.[7]?.month.substring(0,3) || ""} ${month+1>4? year : year-1}`,
      count: data?.[7]?.count
    },
    {
      month: `${data?.[8]?.month.substring(0,3) || ""} ${month+1>3? year : year-1}`,
      count: data?.[8]?.count
    },
    {
      month: `${data?.[9]?.month.substring(0,3) || ""} ${month+1>2? year : year-1}`,
      count: data?.[9]?.count
    },
    {
      month: `${data?.[10]?.month.substring(0,3) || ""} ${month+1>1? year : year-1}`,
      count: data?.[10]?.count
    },
    {
      month: `${data?.[11]?.month.substring(0,3) || ""} ${month+1>0? year : year-1}`,
      count: data?.[11]?.count
    },
  ]

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={150} height={40} data={fetchedData}>
          <XAxis dataKey="month" />
          <Tooltip />
          <Bar dataKey="count" fill="#A1BEFF" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    );
}
