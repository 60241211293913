import Table from "components/Table";
import tableIcon from "../../../../images/svg/loan-performance.svg";


function CreditEnquiries ({data, currentPath}) {
    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Credit Enquiries the last 12months"}
                data={data}
                hideSearch={true}
                tableClassName="min-w-[500px]"
                rows={[
                    {
                        header: currentPath==="CRC"?'Facility Type':'Loan Provider',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="CRC" ? item?.institutionType:(item?.loanProvider||"Not available")}
                            </p>
                        ),
                    },
                    {
                        header: currentPath==="CRC"?'Loan Type':currentPath==="CREDIT_REGISTRY"?'Reason':"",
                        view: (item) => (
                            currentPath==="FIRST_CENTRAL" ? "" :
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="CRC" ? item?.loanType:item?.reason}
                            </p>
                        ),
                    },
                    {
                        header:currentPath==="CREDIT_REGISTRY"?'Contact':"",
                        view: (item) => (
                            currentPath!=="CREDIT_REGISTRY" ? "" :
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.contactPhone?.substring(0,11)}
                            </p>
                        ),
                    },
                    {
                        header: "Inquiry Date",
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] whitespace-nowrap">
                                {currentPath==="CREDIT_REGISTRY" ? item?.date?.substring(0,10) : (item?.date||"Not available")}
                            </p>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default CreditEnquiries;