import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate, useParams } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";


export default function RCResult () {
    const navigate = useNavigate();
    const params = useParams();
    
    let rcRes = {};
    try {
        rcRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div className="bg-white w-[130px] h-[130px] mr-[20px] shrink-0 overflow-hidden rounded-full flex items-center justify-center md:w-[60px] md:h-[60px]">
                        <h1 className="text-[3.5em] md:text-[2em]">{rcRes?.company_name?.slice(0,1)}</h1>
                    </div>
                    <div>
                        <h5>{rcRes?.company_name}</h5>
                        <p>RC/BN Number: {rcRes?.rc_number}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text2">{rcRes?.email_address==="" || rcRes?.email_address===null ?"Not available":rcRes?.email_address}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">City</p>
                            <p className="text2">{rcRes?.city===""?"Not available":rcRes?.city}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Lga</p>
                            <p className="text2">{rcRes?.lga===""?"Not available":rcRes?.lga}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">State</p>
                            <p className="text2">{rcRes?.state===""?"Not available":rcRes?.state}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Registered On</p>
                            <p className="text2">{rcRes?.date_of_registration===""?"Not available":rcRes?.date_of_registration}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Company Status</p>
                            <p className="text2">{rcRes?.company_status===""?"Not available":rcRes?.company_status}</p>
                        </div>
                    </Col>
                    <Col lg={6} md={8}>
                        <div className="identity_result-box1">
                            <p className="text1">Address</p>
                            <p className="text2">{rcRes?.address===""?"Not available":rcRes?.address}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                {
                    params.type === "advanced" &&
                    <div>
                        <h5 style={{marginLeft:"10px"}} className="font-medium text-[1.3em] mb-[15px]">Directors</h5>
                        <div className="identity_result-tableBox ">
                            <table className="identity_result-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Gender</th>
                                        <th>Nationality</th>
                                        <th>Date of Appointment</th>
                                        <th>Date of Birth</th>
                                        <th>Number of Shares Alloted</th>
                                        <th>Type of Shares</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rcRes?.directors.map((item, idx)=>(
                                            <tr key={idx}>
                                                <td>{item.firstname===null && item.surname===null ? "Not Available": `${item.firstname} ${item.surname}`}</td>
                                                <td>{item.email===""||item.email===null?"Not Available":item.email}</td>
                                                <td>{item.phoneNumber===""||item.phoneNumber===null?"Not Available":item.phoneNumber}</td>
                                                <td>{item.gender===""||item.gender===null?"Not Available":item.gender}</td>
                                                <td>{item.nationality===""||item.nationality===null?"Not Available":item.nationality}</td>
                                                <td>{item.dateOfAppointment===""||item.dateOfAppointment===null?"Not Available":item.dateOfAppointment.slice(0,10)}</td>
                                                <td>{item.dateOfBirth===""||item.dateOfBirth===null?"Not Available":item.dateOfBirth.slice(0,10)}</td>
                                                <td>{item.numSharesAlloted===""||item.numSharesAlloted===null?"Not Available":item.numSharesAlloted}</td>
                                                <td>{item.typeOfShares===""||item.typeOfShares===null?"Not Available":item.typeOfShares}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            {  
                                rcRes?.directors?.length < 1 && <h5 style={{textAlign:"center", margin:"30px 0"}}>No directors data available</h5>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}