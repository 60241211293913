import FloatOverview from "./MainPages/Overview";
import FloatStatus from "./MainPages/FloatStatus";
import PaymentHistory from "./MainPages/PaymentHistory";
import {Routes, Route} from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import home from "../../../images/svg/float/floatHome.svg";
import status from "../../../images/svg/float/floatStatus.svg";


function Main () {
    const location = useLocation();
    const [path, setPath] = useState('home');

    useEffect(() => {
        if(location.pathname === "/float/status"){
            setPath('status')
        }else if(location.pathname === "/float/payment-history"){
            setPath('history')
        }else{
            setPath('home')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])
    
   

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div className="float_overview">
                <p className="float_overview-toptext">Only available to fully incorporated organizations</p>
                <div className="float_overview-topNav mt-4 overflow-x-auto">
                    <Link to="/float" className="link-decor"> <div onClick={()=>setPath('home')} className={path==="home"?'active items-center flex mr-[30px]':'items-center flex mr-[30px]'}><img src={home} alt="" className="mr-[5px] -mt-[3px]" /><p className="whitespace-nowrap">Home</p></div></Link>
                    <Link to="/float/status" className="link-decor"><div onClick={()=>setPath('status')} className={path==="status"?'active items-center flex mr-[30px]':'items-center flex mr-[30px]'}><img src={status} alt="" className="mr-[5px]" /><p className="whitespace-nowrap">Float Status</p></div></Link>
                    <Link to="/float/payment-history" className="link-decor"><div onClick={()=>setPath('history')} className={path==="history"?'active items-center flex mr-[30px]':'items-center flex mr-[30px]'}><img src={status} alt="" className="mr-[5px]" /><p className="whitespace-nowrap">Payment History</p></div></Link>
                </div>
                <Routes>
                    <Route path="/" element={<FloatOverview />} />
                    <Route path="/status" element={<FloatStatus />} />
                    <Route path="/payment-history" element={<PaymentHistory/>} />
                </Routes>
            </div>
        </div>
    )
}
export default Main;