import { useEffect, useState } from "react";
import Table from "components/Table";
import { logToBin } from "lib/logs";
import { IncomeService } from "services/income.service";
import { useSelector } from "react-redux";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";
import creditChek from "images/svg/apps/creditChek.svg";


function Main () {
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [loading, setLoading] = useState(false)
    const ngBanks = useSelector(state => state?.banksData?.banks)
    
    useEffect(()=>{
        setLoading(true)
        IncomeService.encryptText(JSON.stringify({text: b2b_data?.user?.businessId})).then(resp => {
            let payload = JSON.stringify({
                businessId: resp
            })
            IncomeService.getLinkedAcccounts(payload)
                .then((response) => response.data)
                .then((res) => {
                    setLoading(false)
                    if (res.status === "success") {
                        const filteredData = res.data?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
                        setData(filteredData)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    logToBin(err.response.data, window.location.href);
                });
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div>
                <div className="home_main-topText">
                    <h5>Linked Bank Accounts</h5>
                    <div className="home_main-topText-search" >
                        <p>All bank account linked to {b2b_data?.businessName}</p>
                    </div>
                </div>
                <Table
                    tableTitle={"Accounts"}
                    searchPlaceHolder={"Search by customer name"}
                    onSearchChange={handleChange}
                    data={data}
                    hideSearch={false}
                    loading={loading}
                    tableClassName="2xl:min-w-[1000px]"
                    rows={[
                        {
                            header:'CUSTOMER NAME',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.name||"---"}</span>
                            ),
                        },
                        {
                            header:'ACCOUNT NUMBER',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.accountNumber||"---"}</span>
                            ),
                        },
                        {
                            header:'BALANCE',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.balance ? currencyFormatter(item?.balance/100):currencyFormatter(0)}</span>
                            ),
                        },
                        {
                            header:'DATE',
                            view: (item) => (
                                <span className="py-[5px] pl-[10px] px-[5px]">{dateFormatter(item.updatedAt, 'dd/mm/yyyy')} {timeFormatter(item.updatedAt)}</span>
                            ),
                        },
                        {
                            header:'BANK',
                            view: (item) => (
                                <img src={ngBanks.find(o => o.code === item.institution?.bankCode)?.logo?.url ? ngBanks.find(o => o.code === item.institution?.bankCode)?.logo?.url : creditChek} alt="banklogo" width="40px" className="py-1.5" />
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default Main;