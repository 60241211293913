import { useRecovaSetup, useUser } from 'hooks/redux.hook'
import React from 'react'
import { useSteps } from 'react-step-builder'
import comingSoon from "images/svg/recova/comingSoon.svg"
import timer from "images/svg/recova/timer.svg"
import Button from 'components/shared/Button'

const NotifyMe = () => {
    const { jump } = useSteps();
    const { user } = useUser();
    const { updateSetupDispatch } = useRecovaSetup()

    const handleNotify = (email) => {
        // const payload ={
        //   email: user?.email
        // }
        // RecovaService.checkoutWaitlist(payload)
        // .then(response=> response.data)
        // .then(res => {
        // console.log(res)
        //     if(res.status === true){
        //     toast.success("added to waitlist.")
        //     } 
        //     if(res.status === false){
        //     toast.error(res.message ?? "something went wrong, please try again")
        //     }else{
        //         toast.error("something went wrong, please try again")
        //     }
        // })
        // .catch((err) => {
        //     console.log(err.response)
        //     if(err.response){
        //         logToBin(err.response.data, window.location.href);
        //         toast.error(err.response.data.message);
        //     } else{
        //         if(err.message === "timeout of 15000ms exceeded"){
        //             toast.error('Request timed out, please try again!')
        //         } else if (err.message==="Network Error"){
        //             toast.error('Network error, please make sure you are connected to the internet!')
        //         } else{
        //             toast.error("Your request can't be processed at this time, please try again later!");
        //         }
        //     }
        // });
    }
    
  return (
    <div className='flex flex-col justify-center items-center py-10 pb-8'>
        <div 
            style={{
                border: `1px solid #D9D9D9`,
                filter: `dropShadow(0px 4px 4px rgba(0, 0, 0, 0.25))`
            }}     
            className='rounded-t-2xl pt-4 flex flex-col justify-center items-center relative max-w-[330px]'
        >
            <div className='absolute top-4 right-4'>
                <img src={timer} alt="satelite icon" />
            </div>
            <img src={comingSoon} alt="satelite icon" />
            <p className='mt-2.5 mb-4 text-cc-primary'>COMING SOON...</p>

            <div className="w-full flex justify-between" >
                <input 
                    className="w-3/4 border-[1px] text-cc-grey-3 border-solid px-1 focus:outline-none focus:border-cc-primary border-cc-primary"
                    value={user?.email}
                    disabled={true}
                />
                <div className='w-[120px]'>
                    <Button 
                        text={"Notify me"}
                        onClick={handleNotify}
                        isValid={true}
                        className="bg-cc-primary rounded-0 py-1"
                    />
                </div>
            </div>
        </div>
        <div className='mt-8 w-[150px]'>
            <Button 
                text={"Back"}
                onClick={()=> {
                    updateSetupDispatch({
                        currentStep: 1,
                    })
                    jump(1)
                }}
                isValid={true}
                className="bg-cc-primary"
            />
        </div>
    </div>
  )
}

export default NotifyMe