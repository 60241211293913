import uploadComplete from 'images/svg/upload-complete.svg';


export default function EDocSuccess () {
    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    return (
        <div className="max-w-[500px] p-6 block mx-auto bg-white border border-cc-primary rounded-[10px]" >
            <div className="myModal_main-widget-shareLeft" >
                <img src={uploadComplete} alt="icon" />
                <h4>Statement Upload Succesful</h4>
                {/* <p>Would you like to upload another bank statement</p> */}
            </div>
            <div className="myModal_main-widget-share-action mt-10">
                <button className="goBack w-[100%]" type='button' onClick={handleClose}>Close Page</button>
                {/* <button className="share" type='submit' style={{marginLeft:"auto", width:"39%"}}>Add Another Statement</button> */}
            </div>
        </div>
    )
} 