import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import {toast} from 'react-toastify';
import { AuthService } from "services/auth.service";

import setup from "images/svg/home/setup.svg";
import logo from "images/svg/logo.svg";
import back from "images/svg/back-widget.svg";
import widgetInfo from "images/svg/widget-info.svg";
import cancelModal from "images/cancel-widget.png";
import info from "images/svg/info-widget.svg";
import Button from "components/shared/Button";
import { FormikProvider, useFormik } from 'formik';
import ToggleButton from "components/shared/ToggleButton";
import { SelectField } from "components/Form/SelectField";
import { CurrencyField } from "components/Form/CurrencyField";
import { MultipleValueTextField } from "components/Form/MultipleValueTextField";
import { Tooltip } from "react-tooltip";
import { TextField } from "components/Form/TextField";
import { BillingService } from "services/billing.service";
import { currencyFormatter } from "utils/helpers";



export default function Widget ({show, cancel, changeAction, appId}) {
    const [loading, setLoading] = useState(false)
    const [widgetAction, setWidgetAction] = useState("Setup");
    const [appActive, setAppActive] = useState(false);
    const [bureauPricing, setBureauPricing] = useState({
        crc: 300,
        firstCentral: 300,
        creditRegistry: 300,
    })

    const options = [
        { value: "crc", label: "CRC" },
        { value:  "firstCentral", label:  "First Central" },
        { value: "creditRegistry", label: "Credit Registry" },
    ];

    useEffect(()=>{
        if(appId!==""){
            doWidgetGet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appId])

    useEffect(()=>{
        creditBureauPrices("crc", "credit crc full report")
        creditBureauPrices("firstCentral", "credit first central full report")
        creditBureauPrices("creditRegistry", "credit registry full report")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const creditBureauPrices = (name, action) => {
        let payload = {
            action: action
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success === true){
                    setBureauPricing({
                        ...bureauPricing,
                        [name]: res.data.price
                    })
                } else {
                    toast.error(res.message)
                }
            })
    }

    const form = useFormik({
        initialValues: {
            page: 1,
            theme: "light",
            maximumAvailableLoan: "0",
            paymentDuration: [],
            existingLoanOverdue: "yes",
            minimumAverageMonthlyIncome: "0",
            minimumMonthlySittingBalance: "0",
            maximumOutstandingLoanValue: "0",
            totalActiveLoans: 0,
            creditHistorySource: [],
            
            // side checkboxes
            minAMI: true,
            minMSB: true,
            preferredCHS: true,
            minOLV: true,
            TAL: true,
            ECO: true,

            // other
            appId: "",
            widgetId: ""
        },
        onSubmit: (values) => {
            if(values.page===1){
                form.setFieldValue("page", 2)
            } else {
                actionEdit(values)
            }
        }
    })

    const doWidgetGet = () => {
        AuthService.getWidget(appId)
            .then((response) => response.data)
            .then((res) => {
                if (res.success===true) {
                    if(res?.data?.widget){
                        form.setFieldValue("theme", res?.data?.widget?.theme || "light")
                        form.setFieldValue("maximumAvailableLoan", res?.data?.widget?.maximumAvailableLoan || "0")
                        form.setFieldValue("existingLoanOverdue", res?.data?.widget?.existingLoanOverdue || "yes")
                        form.setFieldValue("paymentDuration", res?.data?.widget?.paymentDuration || [])
                        form.setFieldValue("minimumAverageMonthlyIncome", res?.data?.widget?.minimumAverageMonthlyIncome || "0")
                        form.setFieldValue("minimumMonthlySittingBalance", res?.data?.widget?.minimumMonthlySittingBalance || "0")
                        form.setFieldValue("maximumOutstandingLoanValue", res?.data?.widget?.maximumOutstandingLoanValue || "0")
                        form.setFieldValue("totalActiveLoans", res?.data?.widget?.totalActiveLoans || 0)
                        form.setFieldValue("creditHistorySource", res?.data?.widget?.creditHistorySource || [])
                        form.setFieldValue("appId", res?.data?.widget?.appId || "")
                        form.setFieldValue("widgetId", res?.data?.widget?._id || "")

                        setAppActive(res.data.widget.status);

                        // using createdAt and updatedAt to tell if its a new widget setup or edit
                        if(res.data.widget.createdAt===res.data.widget.updatedAt){
                            setWidgetAction("Setup")
                            changeAction("Setup")
                        } else {
                            setWidgetAction("Edit")
                            changeAction("Edit")
                        }
                    }
                } else {
                    toast.error(res.data.widget.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const actionEdit = (values) => {
        setLoading(true)
        let payload = JSON.stringify({
            theme: values.theme,
            maximumAvailableLoan: values.maximumAvailableLoan,
            paymentDuration: values.paymentDuration,
            existingLoanOverdue: values.existingLoanOverdue,
            minimumAverageMonthlyIncome: values.minimumAverageMonthlyIncome,
            minimumMonthlySittingBalance: values.minimumMonthlySittingBalance,
            maximumOutstandingLoanValue: values.maximumOutstandingLoanValue,
            totalActiveLoans: values.totalActiveLoans,
            creditHistorySource: values.creditHistorySource,
        });
        AuthService.editWidget(values.widgetId, payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success===true) {
                    toast.success(res.message);
                    doWidgetGet();
                    cancel();
                } else {
                    toast.error(res.data.message)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doStatusChange = () => {
        AuthService.toggleWidgetStatus(form.values.widgetId)
            .then((response) => response.data)
            .then((res) => {
                if (res.success===true) {
                    toast.success(res.message);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={show} onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Body>
                <img src={cancelModal} alt="" className="absolute w-[20px] cursor-pointer -right-[30px] -top-[15px]" onClick={()=>cancel()} />
                <div className="m-[20px]">
                    {
                        form?.values?.page===2 &&
                        <div className="flex items-center gap-x-1.5 cursor-pointer" onClick={()=>form.setFieldValue("page", 1)}>
                            <img src={back} alt=""/>
                            <p>Back</p>
                        </div>
                    }
                    <div className="text-center mb-[20px]">
                        <img src={setup} alt='' className="block mx-auto mb-2.5" />
                        <h4 className="font-semibold text-2xl">{widgetAction} your Widget</h4>
                        <p>Automate your credit decision engine <br/>Click on checkboxes to select compulsory criteria</p>
                    </div>
                    <FormikProvider value={form}>  
                        <form onSubmit={form.handleSubmit}>
                            {
                                form?.values?.page===1 ?
                                <div className="box-border">
                                    <div>
                                        <label>Widget Mode</label><br/>
                                        <ToggleButton
                                            checked={appActive}
                                            onChange={()=>{setAppActive(!appActive); doStatusChange()}}
                                            text={appActive ? "Live Mode":"Dev Mode" }
                                        />
                                    </div>
                                    <SelectField
                                        name='theme'
                                        options={[
                                            { value: 'dark', label: 'Dark Mode' },
                                            { value: 'light', label: 'Light Mode' },
                                        ]}
                                        value={form?.values?.theme}
                                        label="Select Widget Theme"
                                        onChange={(selected)=>{
                                            form.setFieldValue( "theme", selected.value )
                                        }}
                                    />
                                    <CurrencyField
                                        onChange={(val)=>{
                                            form.setFieldValue( "maximumAvailableLoan", val.value )
                                        }}
                                        value={form.values.maximumAvailableLoan}
                                        name='maximumAvailableLoan'
                                        label="Maximum Available Amount"
                                    />
                                    <MultipleValueTextField
                                        onChange={(vals)=>{
                                            form.setFieldValue("paymentDuration", vals)
                                        }}
                                        values={form.values.paymentDuration}
                                        name='paymentDuration'
                                        label="Payment Duration Options"
                                    />
                                </div>
                                :
                                <div>
                                    <div className="box-border">
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("minAMI", !form.values.minAMI)}
                                                checked={form.values.minAMI}
                                            />
                                            <div className="w-full">
                                                <TooltipLabel
                                                    name="Minimum average monthly income"
                                                    tooltip_message={"This is the minimum monthly income on average the customer will have, to apply for a loan"}
                                                />
                                                <CurrencyField
                                                    onChange={(val)=>{
                                                        form.setFieldValue( "minimumAverageMonthlyIncome", val.value )
                                                    }}
                                                    value={form.values.minimumAverageMonthlyIncome}
                                                    name='minimumAverageMonthlyIncome'
                                                />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("minMSB", !form.values.minMSB)}
                                                checked={form.values.minMSB}
                                            />
                                            <div className="w-full">
                                                <TooltipLabel
                                                    name="Minimum monthly sitting balance"
                                                    tooltip_message={"This is the minimum balance a customer will have to apply for a loan."}
                                                />
                                                <CurrencyField
                                                    value={form.values.minimumMonthlySittingBalance}
                                                    onChange={(val)=>{
                                                        form.setFieldValue( "minimumMonthlySittingBalance", val.value )
                                                    }}
                                                    name='minimumMonthlySittingBalance'
                                                />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("preferredCHS", !form.values.preferredCHS)}
                                                checked={form.values.preferredCHS}
                                            />
                                            <div className="w-full">
                                                <label className="text-base text-cc-black-4 font-semibold">Choose preferred credit history source</label>
                                                <SelectField
                                                    name='creditHistorySource'
                                                    selectMultiple={true}
                                                    options={options}
                                                    placeholder="Select source"
                                                    onChange={(selected)=>{
                                                        form.setFieldValue( "creditHistorySource", selected.map(x => x.value) || [] )
                                                    }}
                                                    value={form?.values?.creditHistorySource}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("minOLV", !form.values.minOLV)}
                                                checked={form.values.minOLV}
                                            />
                                            <div className="w-full">
                                                <TooltipLabel
                                                    name="Maximum outstanding loan value"
                                                    tooltip_message={"When we search for credit bureau history, what would your maximum acceptable limit on the borrower’s overall outstanding loan value be?"}
                                                />
                                                <CurrencyField
                                                    value={form.values.maximumOutstandingLoanValue}
                                                    onChange={(val)=>{
                                                        form.setFieldValue( "maximumOutstandingLoanValue", val.value )
                                                    }}
                                                    name='maximumOutstandingLoanValue'
                                                />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("TAL", !form.values.TAL)}
                                                checked={form.values.TAL}
                                            />
                                            <div className="w-full">
                                                <TooltipLabel
                                                    name="Total active loans"
                                                    tooltip_message={"Want to know the total number of active (open) loans for this borrower?"}
                                                />
                                                <TextField
                                                    name='totalActiveLoans'
                                                    type='number'
                                                    placeholder="Input value"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <WidgetCheckbox
                                                onClick={()=>form.setFieldValue("ECO", !form.values.ECO)}
                                                checked={form.values.ECO}
                                            />
                                            <div className="w-full">
                                                <TooltipLabel
                                                    name="Existing credit overdue"
                                                    tooltip_message={"When we search the credit bureau history, want to know if this borrower has defaulted on any active (open) loan from his repayment history?"}
                                                />
                                                <div className='flex mt-2.5 setup_radio'>
                                                    <p className="mr-[20px]" onClick={()=>form.setFieldValue("existingLoanOverdue", 'yes')}>
                                                        <input type="radio" name="radio-group" checked={form.values?.existingLoanOverdue==='yes'} />
                                                        <label>Yes</label>
                                                    </p>
                                                    <p onClick={()=>form.setFieldValue("existingLoanOverdue", 'no')}>
                                                        <input type="radio" name="radio-group" checked={form.values?.existingLoanOverdue==='no'} />
                                                        <label>No</label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="flex mt-2 mb-[30px]">
                                        <img src={info} alt="" className="mr-1"/> 
                                        <span>
                                            This would cost {" "}
                                            <span className="text-cc-blue-7">
                                                { 
                                                    currencyFormatter(
                                                        (form?.values?.creditHistorySource?.includes('crc') ? bureauPricing.crc:0)+
                                                        (form?.values?.creditHistorySource?.includes('firstCentral') ? bureauPricing.firstCentral:0)+
                                                        (form?.values?.creditHistorySource?.includes('creditRegistry') ? bureauPricing.creditRegistry:0)
                                                    )
                                                }
                                            </span> 
                                            {" "}per assessment 
                                        </span>
                                    </p>
                                    {/* ₦{form?.values?.creditHistorySource?.length===1? form?.values?.creditHistorySource.includes('crc')?"320":'290' : form?.values?.creditHistorySource?.length===2? form?.values?.creditHistorySource.includes('crc')?"520":'490' : '890'} */}
                                </div>
                            }
                            <Button
                                isValid={true}
                                text={form?.values?.page===1 ? 'Continue':`${widgetAction} Widget`}
                                isLoading={loading}
                                className="bg-cc-primary"
                                type="submit"
                            />
                        </form>
                    </FormikProvider>
                    <div className="my-[20px] flex justify-center">
                        <p className="text-[1em] text-center font-bold mr-2">Powered by </p><img className="w-[90px]" src={logo} alt="logo"/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

function TooltipLabel ({name, tooltip_message}) {
    return (
        <div className="flex items-center gap-x-1">
            <label className="text-base text-cc-black-4 font-semibold">{name}</label>
            <img 
                className="mt-1 z-[2]"  
                data-tooltip-id={name} 
                src={widgetInfo} 
                alt=''
                data-tooltip-place="top"
            />
            <Tooltip 
                id={name} 
                render={()=>(
                    <div className="text-xs w-[200px]">
                        <p><strong>{name}</strong></p>
                        <p>{tooltip_message}</p>
                    </div>
                )}
            />
        </div>
    )
}

function WidgetCheckbox ({checked, onClick}) {
    return (
        <div className="setup_check flex items-start" onClick={onClick}>
            <input 
                type="checkbox" 
                id="html" 
                checked={checked} 
                className="p-0 m-0 hidden cursor-pointer"
            />
            <span className="relative cursor-pointer"></span>
        </div>
    )
}