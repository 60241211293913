import creditChek from "images/svg/apps/creditChek.svg";

function BankAccounts ({bankImage, bankName, bankAcc}) {
    const obscureAccNo = numberr => {
        return `${numberr[0]}${numberr[1]}${numberr[2]}${new Array(numberr.length - 5).join("*")}${numberr[7]}${numberr[8]}${numberr[9]}`;
    };

    return(
        <div className="flex items-center gap-x-2.5 text-base mb-2.5">
            <div className="w-[30px] h-[30px] rounded-full overflow-hidden">
                <img src={bankImage ? bankImage : creditChek} alt={bankName} className="w-full" />         
            </div>
            <p>{bankName ? bankName :null}</p>
            <p className="ml-auto">{bankAcc ? obscureAccNo(bankAcc) : null}</p>
        </div>
    )
}

export default BankAccounts;