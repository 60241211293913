
import Button from "components/shared/Button";
import { useState } from "react";
import clsx from "clsx";

import notvisible from "images/svg/not-visible.svg";
import visible from "images/svg/visible.svg";


export default function FormSection ({title, onInputChange, onButtonClick, buttonClass}) {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <p className="text-[20px] font-bold mt-[50px] mb-2.5 capitalize">
                {title}
            </p>
            <label className="mb-2.5 text-cc-grey-3">Type in BVN</label><br/>
            <div className="flex sm:block gap-x-2">
                <div className="w-[300px] relative">
                    <input 
                        type={showPassword?"text":"password"} 
                        placeholder="BVN" 
                        className="w-full box-border h-[44px] border-[1px] border-solid border-[#DADADA] rounded-[4px] mr-2.5 py-[3px] px-[15px] text-[#8A8A8A]" 
                        onChange={(e)=>onInputChange(e.target.value)} 
                    />
                    <img 
                        src={showPassword?visible:notvisible} 
                        alt="visible" 
                        className={clsx(
                            "right-2.5 absolute w-[20px] cursor-pointer",
                            showPassword?"top-3.5":"top-[20px]"
                        )} 
                        onClick={()=>setShowPassword(!showPassword)} 
                    />
                </div>
                <div>
                    <Button
                        isValid={true}
                        type="button"
                        text="Generate"
                        onClick={()=>onButtonClick()}
                        className={buttonClass}
                    />
                </div>
            </div>
        </div>
    )
}