import React, { useEffect, useState } from "react";
import {Routes, Route} from "react-router-dom";
import Main from "./Main";
import Create from "./Create";
import Keys from "./Keys";



function Apps ({setDetailName, showLive, setShowLive, rolePermissions, setOnLive}) {
    const [permissionValues, setPermissionValues] = useState([]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "apps"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])
 
    return(
        <div className="p-10 md:px-[20px]">
            <Routes>
                <Route 
                    path="/" 
                    element={
                        <Main
                            showLive= {showLive}
                            setShowLive={setShowLive}
                            setDetailName={setDetailName}
                        />
                    }
                />

                <Route 
                    path="/create" 
                    element={
                        <Create 
                            setDetailName={setDetailName} 
                            permissionValues={permissionValues}
                        />
                    } 
                />
                <Route 
                    path="/:appId" 
                    element={
                        <Keys 
                            setDetailName={setDetailName} 
                            showLive= {showLive}
                            setShowLive={setShowLive}
                            setOnLive={setOnLive}
                            permissionValues={permissionValues}
                        />
                    } 
                />
            </Routes>
        </div>
    )
}

export default Apps;