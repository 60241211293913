import { useState } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { handleRequestErrors } from "utils/helpers";
import { passportVerificationSchema } from "utils/validationSchemas";


export default function PassportInterface ({price}) {
    let history = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [disableAction, setDisableAction] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)

    const form = useFormik({
      initialValues: {
          last_name: "",
          passport_number:"",
      },
      validationSchema: passportVerificationSchema, 
      onSubmit: (values) => {
          Mixpanel.track(`Identity Insight (Verify International Passport) button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        setShowModal(true);
      }
  })
  
    const doSubmit = () => {
        IdentityService.doPassportVerification(
          form.values.passport_number,
          form.values.last_name,
        )
            .then((response) => response.data)
            .then((res) => {
                setShowModal(false)
                if (res.status === true) {
                  sessionStorage.setItem("identity", JSON.stringify(res.data))
                    toast.success("Request Successful!!!")
                    setTimeout( function (){
                        history(`/identity/international-passport`)
                    }, 100);
                } else {
                    toast.error(res.message)
                    setDisableAction(false)
                }
            })
            .catch((err) => {
                setDisableAction(false);
                setShowModal(false)
                handleRequestErrors(err)
            });
    }
  
    return(
      <div className="box-border max-w-[400px]">
        <FormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>
                <TextField
                    name='last_name'
                    type='text'
                    placeholder="Last name"
                    label="Last name"
                />
                <TextField
                    name='passport_number'
                    type='text'
                    placeholder="Passport Number"
                    label="Passport Number"
                />
                <Button
                    isValid={form.isValid}
                    type="submit"
                    text="Verify Passport"
                    className="mt-2.5"
                />
            </form>
        </FormikProvider>
        <ConfirmAction
          show={showModal}
          onCancel={()=>setShowModal(false)}
          onAction={()=>{
            doSubmit()
            setDisableAction(true);
          }}
          loading={disableAction}
          title={`International Passport Verification`}
          content={`Using the passport verification service would attract a charge of ₦${price}.`}
        />
      </div>
    )
}