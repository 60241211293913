import Button from "components/shared/Button";
import { TextArea } from "components/Form/TextArea";
import { TextField } from "components/Form/TextField";
import { Formik, Form } from 'formik';
import { FloatService } from "services/float.service";
import { logToBin } from "lib/logs";
import {toast} from "react-toastify";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { freezeCollectionSchema } from "utils/validationSchemas";


export default function FreezeCollections ({handleSuccess}) {
    const initialValues ={
        remarks: '',
        duration: '',
    }
    const params = useParams();
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId);
    let navigate = useNavigate();

    const doSubmit = (values) => {
        const payload = JSON.stringify({
            businessId,
            floatId: params.floatId,
            category: "freezeCollection",
            remarks: values.remarks,
            freezeDuration: `${values.duration}`,
        })
        FloatService.floatPatchRequest(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    handleSuccess()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={freezeCollectionSchema}
                onSubmit={(values) => {
                    console.log(values)
                    doSubmit(values)
                }}
            >  
                {(props) => (
                    <Form>
                        <TextArea 
                            name='remarks'
                            label="Remarks"
                            placeholder="Justification notes..."
                        />
                        <TextField 
                            name='duration'
                            label="Freeze Duration"
                            type="number"
                            placeholder="number of days ...30"
                        />
                        <hr/>
                        <div className="flex gap-x-3 justify-end mt-2.5">
                            <div className="w-[120px]">
                                <Button
                                    isValid={false}
                                    isLoading={false}
                                    type="button"
                                    text="Go Back"
                                    variant="secondary"
                                    className="mb-[30px] mt-2.5"
                                    onClick={()=>navigate(-1)}
                                />
                            </div>
                            <div className="w-[120px]">
                                <Button
                                    isValid={props.isValid}
                                    isLoading={false}
                                    type="submit"
                                    text="Submit"
                                    className="mb-[30px] mt-2.5"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}