
//Library methods
import React from 'react'
import { useSteps } from 'react-step-builder'

//Utilities
import successTick from "images/svg/recova/successTick.svg"
import Button from 'components/shared/Button'
import { useRecovaSetup } from 'hooks/redux.hook'

const LinkedSuccessCard = () => {
  const { jump } = useSteps();
  const {updateSetupDispatch} = useRecovaSetup()
  
  return (
    <div className="rounded-3xl px-2.5 py-10 sm:px-0">
        <div 
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
          className='flex flex-col justify-between items-center p-8 rounded-2xl my-12'
        >
          <img src={successTick} alt="icon" />
          <p className='text-sm font-medium pt-2'>Account linked successfully</p>

        </div>
        <Button
          text={"Continue"}
          onClick={()=>{
            updateSetupDispatch({
                currentStep: 13,
            })
            jump(13)
          }}
          isValid={true}
          className="bg-cc-primary"
        />
    </div>
  )
}

export default LinkedSuccessCard