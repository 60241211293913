import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from 'recharts';


export default function Chart ({data, type}) {
  let sampledata = [];
  
  if(data.length===1){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      }
    ];
  } else if(data.length===2){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      }
    ];
  } else if(data.length===3){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      }
    ];
  } else if(data.length===4){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      }
    ];
  } else if(data.length===5){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      }
    ];
  } else if(data.length===6){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      }
    ];
  } else if(data.length===7){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      }
    ];
  } else if(data.length===8){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      },
      {
        name: data?.[7]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[7]?.[1]:data?.[7]?.[1]/100
      }
    ];
  } else if(data.length===9){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      },
      {
        name: data?.[7]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[7]?.[1]:data?.[7]?.[1]/100
      },
      {
        name: data?.[8]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[8]?.[1]:data?.[8]?.[1]/100
      }
    ];
  } else if(data.length===10){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      },
      {
        name: data?.[7]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[7]?.[1]:data?.[7]?.[1]/100
      },
      {
        name: data?.[8]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[8]?.[1]:data?.[8]?.[1]/100
      },
      {
        name: data?.[9]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[9]?.[1]:data?.[9]?.[1]/100
      }
    ];
  } else if(data.length===11){
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      },
      {
        name: data?.[7]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[7]?.[1]:data?.[7]?.[1]/100
      },
      {
        name: data?.[8]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[8]?.[1]:data?.[8]?.[1]/100
      },
      {
        name: data?.[9]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[9]?.[1]:data?.[9]?.[1]/100
      },
      {
        name: data?.[10]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[10]?.[1]:data?.[10]?.[1]/100
      }
    ];
  } else {
    sampledata = [
      {
        name: data?.[0]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[0]?.[1]:data?.[0]?.[1]/100
      },
      {
        name: data?.[1]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[1]?.[1]:data?.[1]?.[1]/100
      },
      {
        name: data?.[2]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[2]?.[1]:data?.[2]?.[1]/100
      },
      {
        name: data?.[3]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[3]?.[1]:data?.[3]?.[1]/100
      },
      {
        name: data?.[4]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[4]?.[1]:data?.[4]?.[1]/100
      },
      {
        name: data?.[5]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[5]?.[1]:data?.[5]?.[1]/100
      },
      {
        name: data?.[6]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[6]?.[1]:data?.[6]?.[1]/100
      },
      {
        name: data?.[7]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[7]?.[1]:data?.[7]?.[1]/100
      },
      {
        name: data?.[8]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[8]?.[1]:data?.[8]?.[1]/100
      },
      {
        name: data?.[9]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[9]?.[1]:data?.[9]?.[1]/100
      },
      {
        name: data?.[10]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[10]?.[1]:data?.[10]?.[1]/100
      },
      {
        name: data?.[11]?.[0]?.substring(0, 3),
        uv: type==='expense'?data?.[11]?.[1]:data?.[11]?.[1]/100
      }
    ];
  }

  return (
    <>
    {/* <ResponsiveContainer width="100%" aspect={1}> */}
      <AreaChart
        width={630}
        height={350}
        data={sampledata.reverse()}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Area type="monotone" dataKey="uv" stroke="#0046E6" fill="#D3E1FF" />
      </AreaChart>
    {/* </ResponsiveContainer> */}
    </>
  );
  
}
