import React from "react";
import { Steps, StepsProvider } from "react-step-builder";
import SwitchService from "./SwitchService";
import AccountDetails from "./AccountDetails";
import SuccessModal from "../SuccessModal";
import ModalLayout from "../../../layout/ModalLayout";

const SwitchServiceModal = ({ from, modalOpen, setModalOpen }) => {
  return (
    <ModalLayout show={modalOpen} handleClose={setModalOpen} >
      <StepsProvider>
        <Steps>
            <SwitchService from={from} closeModal={()=>setModalOpen()}/>
            <AccountDetails />
            <SuccessModal
              title={
                from==="self-service" ? 
                "Account setup successfully!"
                : "Self-Service mode activated"
              }
              message={
                from==="self-service" ? 
                "Payouts will now be settled into your linked account"
                : "Payouts will now be settled into your eWallet Account"
              }
              onClose={()=>setModalOpen()}
            />
        </Steps>
      </StepsProvider>
    </ModalLayout>
  );
};

export default React.memo(SwitchServiceModal);
