import {Routes, Route} from "react-router-dom";
import Main from "./Main";
import Create from "./Create";
import Details from "./Details";
import RecovaHeaderLayout from "../layout/RecovaHeaderLayout";


export default function Disputes () {

    return(
        <RecovaHeaderLayout>
            <div>
                <h1 className="text-[25px] font-bold">Dispute Resolutions</h1>
                <Routes>
                    <Route path="/" element={<Main />}/>
                    <Route path="/create" element={<Create />}/>
                    <Route path="/:id" element={<Details />}/>
                </Routes>
            </div>
        </RecovaHeaderLayout>
    )
}