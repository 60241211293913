import {Routes, Route} from "react-router-dom";
import { useState } from "react";
import CustomerMain from "./Customer/Main";
import CustomerInsightDetails from "./Customer/CreditInsightDetails";
import CustomerScoreDetails from "./Customer/CreditScoreDetails";
import BusinessMain from "./Business/Main";
import BusinessInsightDetails from "./Business/CreditInsightDetails";
import Selection from "./Selection";


function CreditInsight ({setDetailName, rolePermissions}) {
    const [path, setPath] = useState("first-central");
    const [regNo, setRegNo] = useState("");

    return (
        <div>
            <Routes>
                <Route path="/" element={<Selection />} />
                <Route path="/customer" element={<CustomerMain setDetailName={setDetailName} rolePermissions={rolePermissions} />}/>
                <Route path="/customer/details/:type" element={<CustomerInsightDetails setDetailName={setDetailName} />} />
                <Route path="/customer/score/:type" element={<CustomerScoreDetails setDetailName={setDetailName} />} />
                <Route path="/business" element={<BusinessMain regNo={regNo} doRegNo={(data)=>setRegNo(data)} setDetailName={setDetailName} path={path} setPath={(name)=>setPath(name)} rolePermissions={rolePermissions} />}/>
                <Route path="/business/details/:type" element={<BusinessInsightDetails setDetailName={setDetailName} />} />
            </Routes>
        </div>
    )
}

export default CreditInsight;