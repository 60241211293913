import Table from "components/Table";
import tableIcon from "../../../../images/svg/loan-performance.svg";


function ProfileOverview ({data}) {

    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Credit Profile Overview"}
                data={data}
                hideSearch={true}
                tableClassName="min-w-[600px]"
                rows={[
                    {
                        header: 'Indicator',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.indicator}
                            </p>
                        ),
                    },
                    {
                        header: "Value",
                        view: (item) => (
                            <p className="py-[10px] whitespace-nowrap pl-[10px] pr-[5px]">
                                {item?.value}
                            </p>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default ProfileOverview;