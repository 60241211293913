import {Modal} from "react-bootstrap";
import { copyText } from "utils/helpers";
import Button from "components/shared/Button";


export default function ShareConsentLink ({
    show, 
    onCancel,
    shareLink,
    onAction,
    isLoading,
}) {
    return (
        <Modal show={show} size="md" onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <div>
                    <h4 className="font-medium text-[1.3em]">Share Consent Link</h4>
                    <p className="mt-2 mb-3">Share this link with your customer to provide consent to access their data.</p>
                    <div className="flex items-center gap-x-2.5 mt-2 mb-6">
                        <div className="overflow-hidden text-sm text-[#98A2B3] font-medium">
                            <p id="textToCopy" className="whitespace-nowrap">
                                {shareLink}
                            </p>
                        </div>
                        <div className="text-cc-primary text-base font-medium flex gap-2">
                            <p onClick={()=>copyText(shareLink, "Consent link copied!")}>Copy</p>
                            <a 
                                target="_blank" 
                                rel="noreferrer"  
                                href={shareLink}
                            >
                                <p className="underline text-cc-primary">Open</p>
                            </a>
                        </div>
                    </div>
                    <Button
                        text="Consent Received"
                        isValid={true}
                        isLoading={isLoading}
                        onClick={onAction}
                    />
                </div>
            </div>
        </Modal>
    )
}