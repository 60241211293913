import { createSlice } from '@reduxjs/toolkit'


export const bankSlice = createSlice({
    name: 'banks',
    initialState: {
        banks: []
    },
    reducers: {
        setBanks: (state, action) => {
            return { 
                ...state, 
                banks: action.payload
            }
        },
    },
})

export const { setBanks } = bankSlice.actions

export default bankSlice.reducer