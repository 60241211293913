import {Modal} from "react-bootstrap";
import clsx from 'clsx';
import Button from "components/shared/Button";
import { FaCheck } from "react-icons/fa6";
import { useState } from "react";
import { MdClear } from "react-icons/md";
import { handleRequestErrors } from "utils/helpers";
import { CreditService } from "services/credit.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


export default function BusinessesModal ({
    show, handleClose, businesses, action
}) {
    let history = useNavigate();
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false)

    const callFetch = () => {
        if(selected.length>0){
            setLoading(true)
            const payload = JSON.stringify({
                data: selected
            })
            CreditService.businessFirstCentralFetch(payload)
                .then((response) => response.data)
                .then((res) => {
                    setLoading(false)
                    if (res.status === true) {
                        sessionStorage.setItem( "report", JSON.stringify({ 
                            data: res?.data?.score,
                            name: res?.data?.name,
                            phone: res.data.phone,
                            address: res?.data?.address,
                            dor: res?.data?.dateOfRegistration,
                            businessType: res?.data?.businessType,
                            taxIdentificationNumber: res?.data?.taxIdentificationNumber,
                            email: res?.data?.email,
                            nod: res?.data?.noOfDirectors,
                            regNo: res?.data?.business_reg_no
                        })); 
                        toast.success("Request Successful!!!")
                        setTimeout( function (){
                            history(`/credit-insight/business/details/first-central`)
                        }, 100);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    handleRequestErrors(err)
                });
        } else {
            toast.error("Please select at least one business to proceed.")
        } 
    }

    const findSelected = (matchEngineId, commercialID) => {
        const exists = selected.some(item => item.MatchingEngineID === matchEngineId&&item.CommercialID === commercialID)
        return exists;
    }

    const handleSelection = (item) => {
        console.log(findSelected(item.MatchingEngineID, item.CommercialID))
        const formattedItem = {
            MatchingEngineID: item.MatchingEngineID,
            SubscriberEnquiryID: item.SubscriberEnquiryID,
            CommercialID: item.CommercialID,
            BusinessName: item.BusinessName,
            RegistrationNo: item.RegistrationNo,
        }
        if (findSelected(item.MatchingEngineID, item.CommercialID)) {
            const filteredData = selected.filter(x => x.MatchingEngineID!==item.MatchingEngineID)
            setSelected(filteredData)
        } else {
            setSelected([...selected, formattedItem])
        }
    }

    return(
        <Modal show={show} size="md" onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <MdClear className="absolute top-6 right-6 w-6 cursor-pointer text-[#667085]" onClick={handleClose} />
                <h3 className="text-[24px] font-bold">Business Name Search</h3>
                <p className="text-[16px] text-[#98A2B3]">Select your business name from the list below</p>
                <div className="max-h-[60vh] overflow-y-auto flex flex-col gap-y-2.5 my-6 businessSearch-scroll">
                    {
                        businesses.map((item, idx)=>(
                            <div 
                                key={idx}
                                className={clsx(
                                    "p-2.5 cursor-pointer rounded-[6px] hover:bg-[#F9FAFB] flex items-center justify-between gap-x-2.5",
                                    findSelected(item?.MatchingEngineID, item.CommercialID) && "bg-[#F9FAFB]"
                                )}
                                onClick={()=>handleSelection(item)}
                            >   
                                <p className="text-sm">{item?.BusinessName} {item?.RegistrationNo ? `(${item?.RegistrationNo})`:""}</p>
                                <div className="relative shrink-0 w-[16px]">
                                    <input 
                                        type="checkbox" 
                                        readOnly
                                        checked={false}
                                        className="scale-[1.2] cursor-pointer" 
                                    />
                                    {
                                        findSelected(item?.MatchingEngineID, item.CommercialID) &&
                                        <FaCheck className="w-[9px] text-cc-primary absolute top-[3.5px] left-[2px]" />
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="grid grid-cols-2 gap-x-6">
                    <Button
                        isValid={true}
                        text="Cancel"
                        className="py-1.5 px-[20px] bg-white border-[1px] border-[#9D9D9D] text-base font-bold !text-[#7D7D7D]"
                        fullwidth={false}
                        onClick={()=>{
                            handleClose()
                            setSelected([])
                        }} 
                    />
                    <Button
                        isValid={true}
                        text="Generate Report"
                        isLoading={loading}
                        className="py-1.5 px-[20px] bg-cc-primary border-[1px] border-cc-primary text-base font-bold"
                        fullwidth={false}
                        onClick={()=>{
                            callFetch()
                        }} 
                    />
                </div>
            </div>
        </Modal>
    )
}