import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import {combineReducers} from "redux"; 
import { persistStore } from 'redux-persist';
import appSlice from './apps'
import loginSlice from './login'
import walletSlice from './wallet'
import radarSlice from './radar'
import recovaSetupSlice from './recova/setup'
import miscSlice from './miscellaneous';
import bankSlice from './banks';
import permissionSlice from './permissions';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: sessionStorage,
}

const rootReducer = combineReducers({
  app: appSlice,
  loginData: loginSlice,
  walletData: walletSlice,
  radarData: radarSlice,
  recovaSetupData: recovaSetupSlice,
  banksData: bankSlice,
  permissionData: permissionSlice,
  miscData: miscSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store);