import { useRef, useEffect } from 'react';
import { toast } from "react-toastify";
import failed from "../../../../images/svg/float/verificationFailed.svg";
import success from "../../../../images/svg/float/verificationSuccess.svg";
import process from "../../../../images/svg/float/verificationProcess.gif";
import cloud from '../../../../images/svg/cloud.svg';
import avatar from "../../../../images/svg/float/avatar.svg";
import { useNavigate,  } from 'react-router-dom';
import { logToBin } from '../../../../lib/logs';
import { FloatService } from 'services/float.service';
import { useSelector } from "react-redux";
import { DateField } from 'components/Form/DateField';
import { FormikProvider, useFormik } from 'formik';
import { TextField } from 'components/Form/TextField';
import Button from 'components/shared/Button';
import clsx from 'clsx';
import { CurrencyField } from 'components/Form/CurrencyField';
import { SelectField } from 'components/Form/SelectField';
import { useState } from 'react';


function FloatDetails (
    {
        verification, 
        triggerPriceModal, 
        data, 
        ngBanks, 
        setData, 
        setPath, 
        uploaded, 
        setUploaded, 
        fileName, 
        setFileName,
        amountType
    }) {
    const drop = useRef(null);
    let navigate = useNavigate();
    const [nextLoading, setNextLoading] = useState(false)
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    let floatId = '';
    let floatRequest;
    try {
        floatId = JSON.parse(sessionStorage.getItem('floatId'))
        floatRequest = JSON.parse(sessionStorage.getItem('floatRequest'))
    } catch (error) {
    }

    const options = [
        { value: "4 months", label: "4 months" },
        { value: "5 months", label: "5 months" },
        { value: "6 months", label: "6 months" },
        { value: "7 months", label: "7 months" },
        { value: "8 months", label: "8 months" },
        { value: "9 months", label: "9 months" },
        { value: "10 months", label: "10 months" },
        { value: "11 months", label: "11 months" },
    ];

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
      
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('dragover', handleDragOver);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    });
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;
      
        if (files && files.length===1) {
            if(files[0].type==="application/pdf"){
                setFileName(files[0].name)
                setUploaded(files[0])
            } else {
                toast.error("Only PDF files are allowed!")
            }
        } else {
            toast.error("You can't upload more than one file!")
        }
    };

    useEffect(() => {
        sessionStorage.setItem('floatRequest', JSON.stringify(data))
        form.setFieldValue("rcNumber", data.rcNumber||"")
        form.setFieldValue("dateOfRegistration", data.dateOfRegistration||"")
        form.setFieldValue("loanAmount", data.loanAmount||"")
        form.setFieldValue("loanDuration", data.loanDuration||"")
        form.setFieldValue("bankAccountNumber", data.bankAccountNumber||"")
        form.setFieldValue("bankName", data.bankName||"")
        form.setFieldValue("password", data.password||"")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const saveDetails = (password) => {
        if(uploaded===null){
            toast.error("Please upload a PDF statement!")
        } else {
            setNextLoading(true)
            console.log(uploaded)
            let value = new FormData();
            value.append('floatId', floatId);
            value.append('businessId', businessId);
            value.append('identityUser', 'pdfStatement');
            value.append('file', uploaded);
            value.append('password', password ? password : floatRequest?.password);
            sessionStorage.setItem("float_upload", uploaded)
            FloatService.accountStatementVerification(value)
                .then((response) => response.data)
                .then((res) => {
                    setNextLoading(false)
                    if (res.status === 'success') {
                        toast.success('Successful')
                        setPath('director1')
                        navigate(`/float/new-request/director1?amountType=${amountType}`)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setNextLoading(false)
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                    }
                });
        }
    }

    const form = useFormik({
        initialValues: {
            rcNumber: "",
            dateOfRegistration: "",
            loanAmount: "",
            loanDuration: "",
            bankName: "",
            bankAccountNumber: '',
            password: "",
        },
        onSubmit: (values) => {
            saveDetails(values.password)
            saveValues()
        }
    })

    const saveValues = () => {
        setData({
            ...data,
            rcNumber: form.values.rcNumber,
            dateOfRegistration: form.values.dateOfRegistration,
            loanAmount: form.values.loanAmount,
            loanDuration: form.values.loanDuration,
            bankName: form.values.bankName,
            bankAccountNumber: form.values.bankAccountNumber,
            password: form.values.password,
        })
    }

    return (
        <div className='border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)] rounded-[10px] mt-2.5 box-border'>
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit}>
                    <div className='p-[20px]'>
                        <div className='flex items-center relative gap-x-2.5'>
                            <div 
                                className={clsx(
                                    (!!data?.rcNumber && verification.rc==="success")?'w-full':'w-[calc(100%-110px)]'
                                )}
                            >
                            <TextField 
                                label="RC Number"
                                name='rcNumber'
                                type='text'
                                placeholder="e.g 1234567"
                                extraClassName={clsx(
                                    verification.rc==='processing'?'!border-cc-primary'
                                        : verification.rc==='success'?'!border-[#12B76A]'
                                        : verification.rc==='failed'?'!border-[#FDA29B]'
                                        : ''
                                )}
                                disabled={verification.rc==="success"}
                                readOnly={verification?.rc==='processing'}
                            />
                            </div>
                            {
                                !(!!data?.rcNumber && verification.rc==="success") && 
                                <div className='w-[100px] mt-0.5'>
                                    <Button
                                        isValid={true}
                                        type="button"
                                        text="Verify"
                                        isLoading={verification?.rc==='processing'}
                                        className="h-10 !rounded-[8px]"
                                        onClick={()=>{
                                            triggerPriceModal('identity cac verification')
                                            saveValues()
                                        }}
                                    />
                                </div>
                            }   
                            {
                                verification.rc !== "none" &&
                                <p
                                    className={clsx(
                                        "absolute bottom-0 text-[0.85em]",
                                        verification.rc==='processing' ? "text-[#7C7C7C]" 
                                            : verification.rc==='success' ? "text-[#12B76A]"
                                            : "text-[#F04438]"
                                    )}
                                >
                                    {
                                        verification.rc==='processing'?'Processing...'
                                        :verification.rc==='success'?'Verification Successful'
                                        :'RC Number is incorrect'
                                    }
                                </p>
                            }
                            {
                                verification.rc!=="none" &&
                                <img 
                                    src={verification.rc==='processing'?process:verification.rc==='success'?success:failed} 
                                    alt=""
                                    className={clsx(
                                        'absolute w-4 bottom-[36px]',
                                        (!!data?.rcNumber && verification.rc==="success") ?'right-[10px]':'right-[120px]'
                                    )}
                                />
                            }
                        </div>
                        <DateField
                            name='dateOfRegistration'
                            placeholder="Select date"
                            onChange={(value)=>{
                                form.setFieldValue("dateOfRegistration", value)
                            }}
                            label="Date of Registration"
                            value={form?.values?.dateOfRegistration}
                        />
                        <CurrencyField
                            onChange={(val)=>{
                                form.setFieldValue("loanAmount", val.value )
                            }}
                            value={form.values.loanAmount}
                            name='loanAmount*'
                            label={`Loan Amount (in ${amountType})`}
                        />
                        <SelectField
                            name='loanDuration'
                            options={options}
                            label="Loan Duration"
                            placeholder="Loan Duration"
                            onChange={(selected)=>{
                                form.setFieldValue( "loanDuration", selected.value )
                            }}
                            value={form?.values?.loanDuration}
                        />
                        <SelectField
                            searchable
                            name='bankName'
                            options={ngBanks?.map(item=>({
                                label: item.name,
                                value: item.name
                            }))}
                            label="Select Bank"
                            placeholder={form.values.bankName || "Select Bank"}
                            onChange={(selected)=>{
                                form.setFieldValue( "bankName", selected.value )
                            }}
                            value={form?.values?.bankName}
                        />
                        <div>
                            <label className='font-semibold text-[#344054]'>Corporate Bank Account Number (recommended to use the most operational)</label>
                        </div>
                        <div className='flex items-center relative gap-x-2.5'>
                            <div 
                                className={clsx(
                                    !!data?.rcNumber?'w-full':'w-[calc(100%-110px)]'
                                )}
                            >
                                <TextField
                                    name='bankAccountNumber'
                                    type='text'
                                    placeholder="Enter Bank Account Number"
                                    extraClassName={clsx(
                                        verification.acc==='processing'?'!border-cc-primary'
                                            : verification.acc==='success'?'!border-[#12B76A]'
                                            : verification.acc==='failed'?'!border-[#FDA29B]'
                                            : ''
                                    )}
                                    readOnly={verification?.acc==='processing'}
                                />
                            </div>
                            <div className='w-[100px] -mt-[20px]'>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Verify"
                                    className="h-10 !rounded-[8px]"
                                    onClick={()=>{
                                        saveValues()
                                        triggerPriceModal('account number basic verification')
                                    }}
                                    isLoading={verification?.acc==='processing'}
                                />
                            </div>   
                            {
                                verification.acc !== "none" &&
                                <p
                                    className={clsx(
                                        "absolute bottom-0 text-[0.85em]",
                                        verification.acc==='processing' ? "text-[#7C7C7C]" 
                                            : verification.acc==='success' ? "text-[#12B76A]"
                                            : "text-[#F04438]"
                                    )}
                                >
                                    {
                                        verification.acc==='processing'?'Processing...'
                                        :verification.acc==='success'?'Verification Successful'
                                        :'Bank Account Number is incorrect'
                                    }
                                </p>
                            }
                            {
                                verification.acc!=="none" &&
                                <img 
                                    src={verification.acc==='processing'?process:verification.acc==='success'?success:failed} 
                                    alt=""
                                    className='absolute w-4 right-[110px] bottom-[36px]'
                                />
                            }
                        </div>
                        <div className='box-border mb-3'>
                            <label className='font-semibold text-[#344054]'>Upload recent 6 Months Bank Statement</label>
                            <div className="mt-[15px] items-start flex box-border">
                                <img src={avatar} alt="" className="w-[60px]" />
                                <div className="box-border pt-[15px] w-full ml-[15px] text-ellipsis overflow-hidden whitespace-nowrap">
                                    <input type="file" accept="application/pdf" id='input_file' onChange={(e)=>{setUploaded(e.target.files[0]);setFileName(e.target.files[0].name)}} hidden />
                                    <div className="myModal_main-widget-form-dragUpload" ref={drop} onClick={()=>document.getElementById('input_file').click()}>
                                        <img src={cloud} alt="" />
                                        <p><span className="text1">Click to upload</span> or drag and drop</p>
                                        <p className="text2">PDF files ONLY &#40;max. 10MB&#41;</p>
                                    </div>
                                    <p className='-mt-2.5'>{fileName}</p>
                                </div>
                            </div>  
                        </div>
                        <TextField
                            label="PDF Password(if available)"
                            name='password'
                            type='password'
                            placeholder="Enter PDF password (optional)"
                        />
                    </div>
                    <hr/>
                    <div className='p-[20px] flex'>
                        <div className='w-[120px] ml-auto'>
                            <Button
                                isValid={verification.rc==='success' && verification.acc==='success' && form.isValid}
                                text="Next Step"
                                type="submit"
                                isLoading={nextLoading}
                            />
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </div>
    )
}

export default FloatDetails;