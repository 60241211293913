import { Modal } from "react-bootstrap";
import close from "images/svg/x-close.svg";
import padLock from "images/svg/mdi_password.svg";
import { useState } from "react";
import OTPInput from "otp-input-react";

export default function ChooseChannel ({
        show,
        onClose,
        mode,
        onSubmit,
        bvn,
        channels,
}) {

    const [chosenChannel, setChosenChannel] = useState('');
    const [otpNumber, setOtpNumber] = useState('');
    
    const sendOtpCode = () => {
        let payload = JSON.stringify({
            bvn: bvn,
            mode: "SET_CHANNEL",
            channel: chosenChannel,
        })
        onSubmit(payload)
    }

    const verifyCode = () => {
        let payload = JSON.stringify({
            bvn: bvn,
            mode: "FINALIZE",
            channel: chosenChannel,
            otp: otpNumber
        })
        onSubmit(payload)
    }
 
    return(
        <Modal show={show} size="md" onHide={onClose} backdrop={"static"} keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="relative p-[20px]">
                <img src={close} alt="close" className="absolute w-[30px] top-[20px] right-[20px]" onClick={onClose} />
                <img src={padLock} alt="lock" className="block mx-auto" />
                {
                    mode==="SET_CHANNEL" ?
                    <div>
                        <h6 className="text-lg font-bold text-center">Choose channel to receive OTP</h6>
                        <div className="myModal_main-linkBank-radio">
                            {
                                channels.map((item, index)=>(
                                    <p className="mt-[10px] lowercase" key={index} onClick={()=>setChosenChannel(item)}>
                                        <input type="radio" id={`${item}_${index}`} name="radio-group"/>
                                        <label for={`${item}_${index}`}>{item}</label>
                                    </p>
                                ))
                            }
                        </div>
                        <div className="box-border">
                            <button className="apps_create-button" onClick={sendOtpCode}>Proceed</button>
                        </div>
                    </div>
                    :
                    <div className="box-border">
                        <h6 className="text-lg font-bold text-center">OTP Verification</h6>
                        <p className="text-cc-grey-5 text-sm text-center">Enter OTP sent to <span className="text-cc-primary font-semibold lowercase">{chosenChannel}</span></p>
                        <div className="auth_otp my-4 ml-[30px]">
                            <OTPInput value={otpNumber} onChange={setOtpNumber} autoFocus OTPLength={6} otpType="number" disabled={false} />
                        </div>
                        <button className="apps_create-button w-full" onClick={verifyCode}>Submit</button>
                    </div>
                }
            </div>
        </Modal>
    )
}