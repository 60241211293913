import React from "react";
import floatIcon from "../../images/svg/float/floatIcon.svg"


const NotificationCard = ({ id, text, status, time, date, handleStatusChange }) => {
  return (
    <div
      style={{
        boxShadow: "0px 20px 25px rgba(0, 0, 0, 0.05)",
        borderRadius: "0.625rem",
        gap: "5px",
        padding: "20px 10px",
        marginBottom: ".2rem",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        display: "flex"
      }}
    >
    <img src={floatIcon} alt="float icon" />
      <span
        style={{
          width: "0.625rem",
          height: "0.625rem",
          borderRadius: "50%",
          backgroundColor: status,
          display: "inline-block",
          position: "absolute",
          top: 10,
          right: 10
        }}
      ></span>
      <div className="ml-4">
        <h3 style={{fontSize: "24px", color: "#000000", lineHeight: "28px", fontWeight: 700}}>Float</h3>
        <p style={{fontSize: "16px", color: "#868484", }}>{text}</p>
        <div className="flex mt-2">
          <div
            style={{
              background: "rgba(255, 234, 182, 0.47)",
              borderRadius: "20px",
              width: "56px",
              display: "flex",
              justifyContent:"center",
              alignItems: "center",
            }}
          >
            <p style={{
              color: "#F1AF04",
              fontWeight: 500,
              fontSize: "14px",
            }}>
              {time}
            </p>
          </div>

          <div>
          <p 
          style={{
              color: "#BBB3B3",
              fontSize: "14px",
              marginLeft: "10px"
            }}>
              {date}
            </p>
          </div>

        </div>
        
      </div>
      {/* <Link to={linkTo}> */}
        <div
          style={{
            width: "80px",
            height: "40px",
            background: "#0349FD",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onClick={()=> handleStatusChange(id)}
        >
          <p 
          style={{
            color: "#FFFFFF",
            fontSize: "16px",
            cursor: "pointer"
          }}
          >view</p>
        </div>

      {/* </Link> */}
      
    </div>
  );
};

export default NotificationCard;
