import { WalletService } from 'services/wallet.service';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import logo from '../../../images/logo.png';
import goback from "../../../images/svg/back.svg";
import {jsPDFGenerator} from '../../../lib/pdfGenerate/invoice';
import download from "../../../images/svg/credit/download.svg";
import { logToBin } from "../../../lib/logs";
import Status from 'components/shared/Status';


export default function InvoiceDetails () {
    let { id } = useParams();
    const [data, setData] = useState({});

    let invoiceItem = {};
    try {
        invoiceItem = JSON.parse(sessionStorage.getItem('invoice'));
    } catch (err) {
        //err
    }

    useEffect(()=>{
        WalletService.getInvoice(id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setData(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        maximumFractionDigits: 2, 
    });

    return(
        <div className="box-border m-10 md:m-[20px]">
            <div className='flex sm:block'>
                <div onClick={()=>window.history.back()} className='flex cursor-pointer'>
                    <div className='w-[20px] h-[34px]'>
                        <img src={goback} alt="back" className='w-[100%] h-[100%]'/>
                    </div>
                    <p className='text-[1.4em] ml-1.5 font-bold'>Invoices</p>
                </div>
                <div className="sm:mt-7 sm:-mb-4 ml-auto">
                    <button className="flex items-center border-[1px] border-solid border-cc-primary rounded-[7px] py-[5px] px-4 text-[1em] bg-white gap-x-1" onClick={()=>jsPDFGenerator(data)} > 
                        <img src={download} alt="download" className="w-[20px]" />
                        <p>Download Invoice</p>
                    </button>
                </div>
            </div>
            <div className='h-10'></div>
            <div className="flex xs:block">
                <div>
                    <img src={logo} alt="" className='mb-[30px]' />
                    <p><span className='font-bold'>{data.businessName}</span><br/>{data.address}</p>
                </div>
                <h2 className='text-[24px] font-medium ml-auto xs:mt-7'>Invoice<br/>#{data.invoiceReference}</h2>
            </div>
            <hr className="my-[30px]" />
            <div className="flex items-center md:block">
                <div>
                    <h5 className='font-semibold mb-4'>Invoice To</h5>
                    <p>{data.ownerFullName}<br/>{data.phoneNumber}<br/>{data.email}</p>
                </div>
                <div className='text-[#626262] ml-auto w-[350px] shrink-0 md:w-full md:mt-7'>
                    <p className='mb-2.5'>Issue Date <span className="text-black float-right">{new Date(data.created_at).getDate() < 10 && '0'}{new Date(data.created_at).getDate()}.{new Date(data.created_at).getMonth() < 10 && '0'}{new Date(data.created_at).getMonth()}.{new Date(data.created_at).getFullYear()} - {formatAMPM(new Date(data?.created_at))}</span></p>
                    <p className='mb-2.5'>Paid Date <span className="text-black float-right">{new Date(data.created_at).getDate() < 10 && '0'}{new Date(data.created_at).getDate()}.{new Date(data.created_at).getMonth() < 10 && '0'}{new Date(data.created_at).getMonth()}.{new Date(data.created_at).getFullYear()} - {formatAMPM(new Date(data?.created_at))}</span></p>
                    <p className='mb-2.5'>
                        Invoice Status 
                        <span className='float-right'>
                            <Status type="success" text="Paid" />
                        </span>
                    </p>
                </div>
            </div>
            <hr className="my-[30px]" />
            <div className='capitalize'>
                <p className='font-bold'>Item <span className='float-right'>Amount</span></p>
                <hr className="my-[30px]"/>
                <p>Wallet Funding<span className='float-right'>{formatMoney.format(parseInt(invoiceItem?.amountPaid))}</span></p>
                <p className='font-bold'>Total<span className='float-right'>{formatMoney.format(parseInt(invoiceItem?.amountPaid))}</span></p>
            </div>
        </div>
    )
}