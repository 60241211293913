import Button from "components/shared/Button"
import { Modal } from "react-bootstrap";
import { FormikProvider, useFormik } from "formik";
import { CurrencyField } from "components/Form/CurrencyField";
import { DateField } from "components/Form/DateField";
import { initiatedebitSchema } from "utils/validationSchemas";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";


export default function InitiateDebit ({show, handleClose, mandateId}) {
    const form = useFormik({
        initialValues: {
            amount: "",
            debitDate: "",
        },
        validationSchema: initiatedebitSchema, 
        onSubmit: (values) => {
            let payload = JSON.stringify({
                debitDate: values.debitDate, 
                mandateId: mandateId,
                amount: Number(values.amount)
            })
            RecovaService.initiateDebit(payload)
                .then(response=> response.data)
                .then(res => {
                    if(res.status===true) {
                        handleClose()
                        form.resetForm()
                        toast.success(res.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err)
                });
        }
    })

    return (
        <Modal
            show={show}
            onHide={() => handleClose()}
            aria-labelledby="contained-modal-title-vcenter" 
            centered
        >
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit} className="box-border py-10 px-[20px]">
                    <CurrencyField
                        onChange={(val)=>{
                            form.setFieldValue("amount", val.value )
                        }}
                        value={form.values.amount}
                        name='amount*'
                        label="Enter Amount"
                        placeholder="Enter Amount"
                    />
                    <DateField
                        name='debitDate'
                        label="Debit Date"
                        placeholder="Select Debit Date"
                        onChange={(value)=>{
                            form.setFieldValue("debitDate", dateFormatter(new Date(value), 'yyyy-mm-dd'))
                        }}
                        value={form?.values?.debitDate}
                    />
                    <Button
                        isValid={true}
                        isLoading={false}
                        type="submit"
                        text="Apply Debit"
                        className="bg-cc-primary"
                    />
                </form>
            </FormikProvider>
        </Modal>
    )
}