
import { LineChart, Line, XAxis, YAxis, CartesianGrid, BarChart, Bar } from 'recharts';
import {useState} from "react";
import arrowUp from "images/svg/overview/arrow-up.svg";
import activeIcon from "images/svg/recova/recova-active.svg";
import bgIcon from "images/svg/recova/recova-bg.svg";
import graphDot from "images/svg/recova/graphDot.svg";
import { HiChevronDown } from "react-icons/hi";
import { FaCalendarAlt } from "react-icons/fa";
import ModalLayout from "./layout/ModalLayout";
import RecovaSetup from "./components/recovaSetup";
import Button from "components/shared/Button";
import { RxPencil1 } from "react-icons/rx";
import { Modal } from 'react-bootstrap';
import ManageProduct from './components/ManageProducts';


export default function TransactionOverview () {
    const [filter, setFilter] = useState({
        view: 'mandates',
        showViewOption: false,
        dayFilter: 'last 7 days',
        showDayFilter: false,
    })
    const [isOpen, setIsOpen] = useState(false)
    const step = 1
    const [showProductModal, setShowProductModal] = useState(false);

    const data = [
        {
          name: 'Mon',
          count: 0,
        },
        {
          name: 'Tue',
          count: 0,
        },
        {
          name: 'Wed',
          count: 0,
        },
        {
          name: 'Thur',
          count: 0,
        },
        {
          name: 'Fri',
          count: 0,
        },
        {
          name: 'Sat',
          count: 0,
        },
        {
          name: 'Sun',
          count: 0,
        },
        {
          name: 'Mon',
          count: 0,
        },
        {
          name: 'Tue',
          count: 0,
        },
        {
          name: 'Wed',
          count: 0,
        },
        {
          name: 'Thur',
          count: 0,
        },
        {
          name: 'Fri',
          count: 0,
        },
        {
          name: 'Sat',
          count: 0,
        },
        {
          name: 'Sun',
          count: 0,
        },
    ];

    const handleModalOpen = ()=> {
        setIsOpen(true)
    }

    return(
        <div>
            <div className='flex gap-[100px] relative 2xl:justify-between md:block'>
                <div>
                    <div className='flex items-end gap-[12px]'>
                        <h1 className="text-2xl font-semibold">RecovaPRO is now active</h1>
                        <img src={activeIcon} alt="icon" className="w-8 mb-1.5 xs:hidden" />
                    </div>
                    <div className='flex justify-between mb-4'>
                        <p className='mr-2 lg:w-full'>
                            Start receiving direct debits from your borrowers/payers.<br/>
                            Send advance notifications to them  ahead of scheduled collections
                        </p>
                    </div>
                </div>
                <div className='relative md:w-[150px] md:mb-10'>
                    <Button
                        isValid={true}
                        text="Edit profile setup"
                        className="bg-[#2BBD35] px-[20px] whitespace-nowrap"
                        onClick={handleModalOpen}
                    />
                    <RxPencil1 className="w-[20px] text-white absolute top-0 right-0"/>
                </div>
                <img src={bgIcon} alt="icon" className="absolute right-0 -top-10 w-[200px] 2xl:hidden" />
            </div>
            <div className='mb-6 flex justify-between items-center'>
                <h1 className="text-2xl font-semibold">Overview</h1>
                {/* <div className='w-[170px] relative'>
                    <Button
                        isValid={true}
                        text="Manage Product"
                        className="bg-cc-primary px-[20px] whitespace-nowrap"
                        onClick={()=>setShowProductModal(true)}
                    />
                    <RxPencil1 className="w-[20px] text-white absolute top-0 right-0"/>
                </div> */}
            </div>
            <div className="bg-cc-grey-19 p-[20px] grid grid-cols-2 md:grid-cols-1 max-w-[700px] gap-[20px] block mx-auto">
                <div className=" bg-cc-blue-13 pb-1 rounded-lg shadow-md">
                    <div className=" bg-white p-4 rounded-lg">
                        <div className="flex justify-between">
                            <p className="text-cc-black-3 font-bold">Advanced Notice sent</p>
                            <p className="text-cc-black-3 text-xl font-bold">0</p>
                        </div>
                        <div className="flex gap-x-6">
                            <div className="bg-cc-primary rounded-full w-[80px] h-[80px]">
                                <p className="text-white text-center font-bold">0</p>
                                <div className="bg-cc-blue-14 rounded-full w-[50px] h-[50px] block mx-auto">
                                    <p className="text-center font-bold pt-3">0</p>
                                </div>
                            </div>
                            <div>
                                <p className="text-cc-primary text-base font-semibold">0%</p>
                                <p className="text-sm">Successful</p>
                                <p className="text-sm text-cc-grey-3">0%</p>
                                <p className="text-sm text-cc-red-10">Failed</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-cc-blue-13 pb-1 rounded-lg shadow-md">
                    <div className=" bg-white p-4 rounded-lg">
                        <div className="flex justify-between">
                            <p className="text-cc-black-3 font-bold">Total Dispute sent</p>
                            <p className="text-cc-black-3 text-xl font-bold">0</p>
                        </div>
                        <p className="text-cc-black-3 font-bold mt-[36px]">Settled <span className="ml-5 text-cc-primary">0</span></p>
                        <p className="text-cc-black-3 font-bold">Ongoing <span className="ml-5 text-cc-green-10">0</span></p>
                    </div>
                </div>
                <div className=" bg-cc-blue-13 pb-1 rounded-lg shadow-md">
                    <div className=" bg-white p-4 rounded-lg">
                        <div className="flex justify-between">
                            <p className="text-cc-black-3 font-bold">Total collections</p>
                            <p className="text-cc-black-3 text-xl font-bold">0</p>
                        </div>
                        <p className="text-cc-primary font-semibold text-[25px] mt-4">N0</p>
                        <div className="text-cc-grey-4 flex text-xs block ml-auto">
                            <img src={arrowUp} alt="" className="w-3" />
                            {/* red-9, green-5 */}
                            <p><span  className="text-cc-green-5">0%</span> last month</p>
                        </div>
                    </div>
                </div>
                <div className=" bg-cc-blue-13 pb-1 rounded-lg shadow-md">
                    <div className=" bg-white p-4 rounded-lg">
                        <div className="flex justify-between">
                            <p className="text-cc-black-3 font-bold">Total payouts</p>
                            <p className="text-cc-black-3 text-xl font-bold">0</p>
                        </div>
                        <p className="text-cc-primary font-semibold text-[25px] mt-4">0</p>
                        <div className="text-cc-grey-4 flex text-xs block ml-auto">
                            <img src={arrowUp} alt="" className="w-3" />
                            {/* red-9, green-5 */}
                            <p><span  className="text-cc-green-5">0%</span> last month</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex gap-2.5 mt-4 relative sm:flex-col'>
                <div className='flex gap-x-4 items-center justify-between capitalize rounded-[4px] px-2.5 py-1 shadow-md'>
                    <p>{filter.view}</p>
                    <HiChevronDown className='w-[20px] scale-[1.6]' onClick={()=>setFilter({...filter, showViewOption: !filter.showViewOption})} />
                </div>
                <div className='flex gap-x-4 items-center justify-between rounded-[4px] px-2.5 py-1 shadow-md'>
                    <p className="whitespace-nowrap">Last 7 days</p>
                    <HiChevronDown className='w-[20px] scale-[1.6]' />
                    <div className='flex items-center'>
                        <FaCalendarAlt className='w-[20px] mr-1' />
                        <p className="whitespace-nowrap">May 18 to May 23</p>
                    </div>
                </div>
                {
                    filter.showViewOption &&
                    <div className='absolute bg-white w-[100px] top-[40px] p-2.5 cursor-pointer z-50 shadow-md left-0'>
                        <p>Mandates</p>
                        <p>Payouts</p>
                        <p>Collections</p>
                        <p>Dispute</p>
                    </div>
                }
            </div>
            <div className="w-full overflow-x-scroll hide-scrollBar">
                <div className="w-[1000px] block mx-auto my-10">
                    <p className='text-lg font-bold mb-4'>Transaction Count</p>
                    <LineChart
                        width={1000}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        {/* <Tooltip />
                        <Legend /> */}
                        <Line type="monotone" dataKey="count" stroke="#0046E6" strokeWidth={2} dot={<img src={graphDot} alt="" className='w-10' />} />
                    </LineChart>
                </div>
            </div>
            <div className="w-full overflow-x-scroll hide-scrollBar">
                <div className="w-[1000px] block mx-auto my-10">
                    <p className='text-lg font-bold mb-4'>Transaction Amount</p>
                    <BarChart width={1000} height={300} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Bar dataKey="count" fill="#0046E6" />
                    </BarChart>
                </div>
            </div>
            <ModalLayout
                show={isOpen}
                handleClose={() => {setIsOpen(false)}}
            >
                <RecovaSetup
                    step={step}
                    closeModal={()=>setIsOpen(false)}
                />
            </ModalLayout>
            <Modal show={showProductModal} onHide={()=>setShowProductModal(false)} aria-labelledby="contained-modal-title-vcenter" centered >
                <ManageProduct onSuccess={()=>setShowProductModal(false)} />
            </Modal>
        </div>
    )
}