import { createSlice } from '@reduxjs/toolkit'


export const permissionSlice = createSlice({
  name: 'permissions',
  initialState: {
    roleId: "",
    roleName: "",
    permissions: []
  },
  reducers: {
    setRoleAndPermissions: (state, action) => {
      return {
        ...state,
        roleId: action.payload.roleId,
        roleName: action.payload.roleName,
        permissions: action.payload.permissions
      }
    },
  },
})

export const { setRoleAndPermissions } = permissionSlice.actions

export default permissionSlice.reducer