// import { Col, Row } from "react-bootstrap";
import Table from "components/Table/OldTable";
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect, useState } from "react";
import InsightLoader from "components/insightLoader";
import { FloatService } from "services/float.service";
import { useSelector } from "react-redux";

import filter from "../../../../images/svg/filter.svg";
import { toast } from "react-toastify";
import { logToBin } from "../../../../lib/logs";


function PaymentHistory () {
    const [data, setData] = useState({
        tableFor: "float-paymentHistory",
        headings: ["Transaction ID", "Amount", "Paid On", "Status"],
        items: []
    });
    const [showFilter, setShowFilter] = useState(false);
    const [filterTerm, setFilterTerm] = useState('all');
    const [loading, //setLoading
    ] = useState(false);
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    useEffect(() => {
        FloatService.paymentHistory(businessId)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === 'success') {
                setData({
                    ...data,
                    items: res.data.reverse()
                })
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            if(err.response){
                logToBin(err.response.data, window.location.href);
                toast.error(err.response.data.message);
            } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
            }
        });
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    return(
        <div>
            <div>
                <div className="table_top">
                    <div className="home_main-topText-toggles">
                        <div>
                            <h4>Successful Payment History</h4>
                        </div>
                        <div className="home_main-topText-filter flex item-center" style={{marginLeft:'auto'}} onClick={() => {setShowFilter(true) }}>
                            <img src={filter} alt="" className="w-[20px]" /><p className="w-[20px] mt-[2px]">{filterTerm==='all'?'Filter':filterTerm==='seven_days'?'Last 7 days':filterTerm==='last_month'?'Last month':filterTerm==='last_three_months'?'Last 3 months': 'Custom'}</p>
                            {
                                showFilter &&
                                <div className="home_main-topText-filter-options" style={{top:'65px', right:'10px'}}>
                                    <OutsideClickHandler
                                        onOutsideClick={() => {
                                            setShowFilter(false)
                                        }}
                                    >
                                        <p onClick={()=>{setFilterTerm("seven_days")}}>Last 7 days</p>
                                        <hr/>
                                        <p onClick={()=>{setFilterTerm("last_month")}}>Last Month</p>
                                        <hr/>
                                        <p onClick={()=>{setFilterTerm("last_three_months")}}>Last 3 months</p>
                                        {/* <hr/> */}
                                        {/* <p onClick={()=>{setShow(true)}}>Custom</p> */}
                                    </OutsideClickHandler>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    loading ? <InsightLoader />
                    :
                    <Table 
                        data={data} 
                    />
                }
            </div>
        </div>
    )
}

export default PaymentHistory;