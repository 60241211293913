import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

const timeout = 120000;

export const CreditService = {
    sendReport: (payload) => {
        return APIKit(60000).post(`/credit/share`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getCRCReport: (bvn, type) => {
        let endpointUrl = type==="score" ? `/credit/crc-fico?bvn=${bvn}`
        : type==="full" ? `/credit/crc-premium?bvn=${bvn}`
        : `/credit/crc?bvn=${bvn}`;
        return APIKit(timeout).get(endpointUrl, {
            headers: {
                token: `${getPublicKey()}`,
                'x-api-source': 'dashboard'
            }
        })
    },
    
    getFirstCentralReport: (bvn, type) => {
        let endpointUrl = type==="score" ? `/credit/firstCentral-Iscore?bvn=${bvn}`
            : type==="full" ? `/credit/firstCentral-premium?bvn=${bvn}`
            : `/credit/first-central?bvn=${bvn}`;
        return APIKit(timeout).get(endpointUrl, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getCreditRegistryReport: (bvn, type) => {
        let endpointUrl = type==="full" ? `/credit/creditRegistry-premium?bvn=${bvn}`:`/credit/credit-registry?bvn=${bvn}`;
        return APIKit(timeout).get(endpointUrl, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getPremiumReport: (bvn) => {
        return APIKit(timeout).get(`/credit/premium?bvn=${bvn}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getAdvancedReport: (bvn) => {
        return APIKit(timeout).get(`/credit/advanced?bvn=${bvn}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getSMEReport: (type, query) => {
        return APIKit(timeout).get(`/credit/sme/${type}${query}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getCreditHistory: (type) => {
        return APIKit(timeout).get(`/credit/history?type=${type}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteCreditData: (payload) => {
        return APIKit(timeout).delete(`/credit/delete-report`, {
            headers: {
                token: `${getPublicKey()}` 
            },
            data: payload 
        }, 
        )
    },
    
    getCreditSMEHistory: (type) => {
        return APIKit(timeout).get(`/credit/sme/history?type=${type}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteCreditSMEData: (payload) => {
        return APIKit(timeout).delete(`/credit/sme/delete-report`, {
            headers: {
                token: `${getPublicKey()}` 
            },
            data: payload 
        }, 
        )
    },

    businessFirstCentralFetch: (payload) => {
        return APIKit(timeout).post(`/credit/sme/first-central/fetch`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
}