import { useState, useEffect } from "react";
import Table from "../../../components/Table";
import { AuthService } from "services/auth.service";
import {toast} from "react-toastify";
import { logToBin } from "../../../lib/logs";
import { useSelector } from "react-redux";
import { dateFormatter, timeFormatter } from "utils/helpers";


function Authentication () {
    const [data, setData] = useState({
        items: [],
        loaded:false,
    })
    const id = useSelector(state => state?.loginData?.data?.user?.id)

    useEffect(()=>{
        let payload = JSON.stringify({
            userId: id
        })
        AuthService.authLogs(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setData({
                        ...data,
                        items: res.data,
                        loaded: true,
                    })
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setData({
                    ...data,
                    loaded: true,
                })
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="mt-10">
            <Table
                noTitleIcon={true}
                tableTitle={"Authentication Events"}
                data={data.items}
                loading={!data.loaded}
                hideSearch={true}
                tableClassName="xl:min-w-[1000px]"
                rows={[
                    {
                        header:'WHEN',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.logDate, 'dd/mm/yyyy')} {timeFormatter(item.logDate)}</p>
                        ),
                    },
                    {
                        header:'MEMBER',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] lowercase">
                                {item.email || "Not available"}
                            </p>
                        ),
                    },
                    {
                        header:'EVENT TYPE',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.event || "Not available"}
                            </p>
                        ),
                    },
                    {
                        header:'COUNTRY',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{item.country||"Not available"}</p>
                        ),
                    },
                    {
                        header:'IP ADDRESS',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{item.ipAddress||"Not available"}</p>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default Authentication;