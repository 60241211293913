import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";
import BankAccounts from "components/shared/BankAccounts";
import { useSelector } from "react-redux";

import info from "../../../../images/svg/identity/info.svg";


export default function BVNResult () {
    const navigate = useNavigate();
    const ngBanks = useSelector(state => state?.banksData?.banks)

    let bankRes = {};
    try {
        bankRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!bankRes.photo ? `url(${bankRes.photo})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{bankRes.account_name}</h5>
                        <p>Bank Account Number: {bankRes.account_number}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{bankRes?.dob===""?"Not available":bankRes.dob}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{bankRes?.gender===""?"Not available":bankRes.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Marital Status</p>
                            <p className="text2">{bankRes?.marital_status===""?"Not available":bankRes.marital_status}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Number</p>
                            <p className="text2">{bankRes?.phone===""?"Not available":bankRes.phone}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Nationality</p>
                            <p className="text2">{bankRes?.nationality===""?"Not available":bankRes.nationality}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text3">{bankRes?.email===""?"Not available":bankRes.email}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Bank Name</p>
                            {/* <p className="text2">{ngBanks?.find(o => o.code === bankRes?.bank_code)?.name} </p> */}
                            <BankAccounts 
                                bankImage={ngBanks?.find(o => o.code === bankRes?.bank_code)?.logo?.url }
                                bankName={ngBanks?.find(o => o.code === bankRes?.bank_code)?.name} 
                            />
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Identity Type</p>
                            <p className="text2">{bankRes?.identity_type===""?"Not available":bankRes.identity_type}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Identity Number</p>
                            <p className="text2">{bankRes?.identity_number===""?"Not available":bankRes.identity_number}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Name On Card</p>
                            <p className="text2">{bankRes?.account_name===""?"Not available":bankRes.account_name}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Original Location</p>
                            <p className="text2">{bankRes?.lga_of_origin===""?"Not available":bankRes.lga_of_origin}, {bankRes?.state_of_origin}</p>
                        </div>
                    </Col>
                    <Col lg={6} md={8}>
                        <div className="identity_result-box1">
                            <p className="text1">Residential Address</p>
                            <p className="text2">{bankRes?.address_1===""?"Not available":bankRes.address_1}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}