import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthService } from "services/auth.service";
import { Link } from "react-router-dom";

import { logToBin } from "../../lib/logs";


function Login () {
    const [formValid, setFormValid] = useState(false)
    const [email, setEmail] = useState("");

    useEffect(()=>{
        if (email.length) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [email])

    const handleChange = (e) => {
        setEmail(e.target.value)
        if(email.length){
            setFormValid(true)
        }
    }

    const sendMail = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        
        let payload = JSON.stringify({
            email: email?.toLowerCase()
        });

        AuthService.ForgotPasswordAction(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    toast.success(`Password reset instructions sent to your email!!!`);
                    btn.innerHTML=`Send Mail`;
                } else {
                    toast.error(res.message)
                    btn.innerHTML=`Send Mail`;
                }
            })
            .catch((err) => {
                btn.innerHTML=`Send Mail`;
                if(err.response){
                    toast.error(err.response.data.message);
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <form className="auth_signup" onSubmit={sendMail} >
                <input type="email" required placeholder="Email Address" name="email" onChange={handleChange} />
                <button 
                    className= {formValid?"auth_signup-active":"auth_signup-submit"}
                    id="actionBtn"
                    disabled={!formValid}
                >
                    Send Mail
                </button>
                <p className="text-cc-black-5">Remember your password? <Link to="/auth/login"><span className="cursor-pointer text-cc-primary font-semibold no-underline">Login</span></Link></p>
            </form>
        </div>
    )
}

export default Login;