import { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { Modal } from "react-bootstrap";
import { DateRange } from 'react-date-range';

import filter from "images/svg/filter.svg";
import clsx from "clsx";


export default function Filter ({
    filterValue, onChange, options, hideCustom,
    extraClass, // optional
    containerClass, // optional
    optionClass, // optional
}) {
    const [showFilter, setShowFilter] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [show, setShow] = useState(false);
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    const handleClose = () => setShow(false);
    
    const handleChange = (value) => {
        onChange(value)
        setShowFilter(false)
    }

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    return (
        <div 
            className={clsx(
                "border-[1px] relative border-solid border-[#D0D5DD] rounded-[10px] capitalize cursor-pointer ml-auto h-9",
                containerClass
            )}
        >
            <div 
                className={clsx(
                    "flex justify-center items-center gap-x-1 px-3 pt-[5px] pb-[2px]",
                    extraClass
                )} 
                onClick={() => {setShowFilter(true) }}
            >
                <img src={filter} alt="" />
                <p>
                    {
                        options?.find(item => item?.value === filterValue)?.label || "Custom"
                    }
                </p>
            </div>
            {
                showFilter &&
                <div className="absolute top-10 right-0 min-w-[100px] z-[2] bg-white border-[1px] border-solid border-[#EAECF0] shadow-[0px_4px_8px_-2px_rgba(16,24,40,0.1),0px_2px_4px_-2px_rgba(16,24,40,0.06)]">
                    <OutsideClickHandler
                        onOutsideClick={() => {
                            setShowFilter(false)
                        }}
                    >
                        {
                            options?.map(item=>(
                                item.showOnList &&
                                <div key={item.value}>
                                    <p 
                                        className={clsx(
                                            "my-1.5 mx-2.5 whitespace-nowrap cursor-pointer",
                                            optionClass
                                        )}
                                        onClick={()=>{handleChange(item.value)}}
                                    >{item.label}</p>
                                    <hr className="m-0 border-[1px] border-solid border-[#EAECF0]"/>
                                </div>
                            ))
                        }
                        {
                            !hideCustom && 
                            <p 
                                className={clsx(
                                    "my-1.5 mx-2.5 whitespace-nowrap cursor-pointer",
                                    optionClass
                                )}
                                onClick={()=>{setShow(true)}}
                            >Custom</p>
                        }
                    </OutsideClickHandler>
                </div>
            }
            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                            setStartDate(formatPickerDate(item.selection.startDate))
                            setEndDate(formatPickerDate(item.selection.endDate))
                            setState([item.selection])
                        }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button 
                    className="text-white py-[7px] px-6 text-center border-[1px] border-solid border-cc-primary bg-cc-primary"
                    onClick={()=>{   
                        handleClose();
                        handleChange(`custom&start=${startDate}&end=${endDate}`)
                    }}
                >Proceed</button>
            </Modal>
        </div>
    )
}