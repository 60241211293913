import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Back from "components/shared/back";
import Tab from "components/shared/Tab";
import FirstCentral from "./components/FirstCentral";
import CRC from "./components/CRC";
import CreditRegistry from "./components/CreditRegistry";
import CreditScore from "./components/CreditScore";
import Premium from "./components/Premium";
import Advanced from "./components/Advanced";


function Main ({setDetailName, rolePermissions}) {
    let history = useNavigate();
    const [searchParams] = useSearchParams();
    const path = searchParams.get("tab")

    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);

    return (
        <div className="mx-10 mb-[50px] md:mx-[20px]">
            <p className="xl_min:w-[350px] mb-[60px] -mt-7 text-cc-grey-4 xl:mt-10 xl:mb-[20px]">Generate Customer(Individuals) Credit Insight.</p>
            <div className="mt-[20px]">
                <Back to={()=> history("/credit-insight")} />
            </div>
            <Tab 
                tabs={[
                    { label: "First Central", key: "first-central" },
                    { label: "CRC", key: "CRC" },
                    { label: "Credit Registry", key: "credit-registry" },
                    { label: "Premium", key: "premium" },
                    { label: "Advanced", key: "advanced" },
                    { label: "Credit Scores", key: "scores" },
                ]}
            />
            {
                path === "first-central" ?
                <FirstCentral rolePermissions={rolePermissions} />
                :
                path === "CRC" ?
                <CRC rolePermissions={rolePermissions} />
                :
                path === "credit-registry" ?
                <CreditRegistry rolePermissions={rolePermissions} />
                :
                path === "premium" ?
                <Premium rolePermissions={rolePermissions} />
                :
                path === "advanced" ?
                <Advanced rolePermissions={rolePermissions} />
                :
                path === "scores" ?
                <CreditScore rolePermissions={rolePermissions} />
                :
                null
            }
        </div>
    )
}

export default Main;