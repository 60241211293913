import { SelectField } from "components/Form/SelectField";
import { TextArea } from "components/Form/TextArea";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { FormikProvider, useFormik } from "formik";

export default function Create () {
    const form = useFormik({
        initialValues: {
        },
        // validationSchema: selectMandateBankSchema,
        onSubmit: (values) => {
        }
    })

    return (
        <div className="max-w-[935px] grid grid-cols-2 relative">
            <div>
                <p className="text-[20px] my-3">Create New Case</p>
                <p className="font-bold text-base mb-6">Case Details</p>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <TextField
                            label="Subject"
                            name="subject"
                            placeholder="Enter subject"
                        />
                        <SelectField
                            label="Case Type"
                            name="caseType"
                            placeholder="Select case type"
                            options={[]}
                            value={form.values.caseType}
                            searchable={true}
                            onChange={(val)=> {
                                form.setFieldValue("caseType", val?.value)
                            }}
                        />
                        <SelectField
                            label="Severity"
                            name="severity"
                            placeholder="Select severity of case"
                            options={[]}
                            value={form.values.severity}
                            searchable={true}
                            onChange={(val)=> {
                                form.setFieldValue("severity", val?.value)
                            }}
                        />
                        <TextField
                            label="Created By"
                            name="createdBy"
                            placeholder="Enter email"
                        />
                        <TextField
                            label="Phone Number"
                            name="phone"
                            placeholder="Enter phone number"
                        />
                        <TextField
                            label="Date Created"
                            name="dateCreated"
                            placeholder="10/10/2010"
                        />
                        <TextArea
                            label="Message"
                            name="message"
                            placeholder="Enter more details about case"
                        />
                        <div className="absolute right-0 bottom-8">
                            <Button
                                isValid={true}
                                isLoading={false}
                                type="button"
                                text="Create New Case"
                                className="bg-cc-primary !py-2.5 !px-6 text-sm"
                                fullwidth={false}
                            />
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    )
}