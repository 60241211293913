import {Container} from "react-bootstrap";
import {Routes, Route, Navigate, useLocation, Link} from "react-router-dom";
import { useState, useEffect } from "react";
import Member from "./Member";
import Roles from "./Roles";

function Team ({permissionValues}) {
    const location = useLocation().pathname;
    const [path, setPath] = useState("member"); 
    
    useEffect(()=>{
        setPath(location);
    }, [path, location]);

    return(
        <div>
            <div className="settings_subNav">
                <Link to="/settings/team/member" className="link-decor"><p className={path.includes("member")?"active":""} onClick={()=>setPath("member")}>Members</p></Link>
                <Link to="/settings/team/roles" className="link-decor"><p className={path.includes("roles")?"active":""} onClick={()=>setPath("roles")}>Roles</p></Link>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<Navigate to="/settings/team/member" />}/>
                    <Route path="/member" element={<Member permissionValues={permissionValues} />}/>
                    <Route path="/roles" element={<Roles permissionValues={permissionValues}  />} />
                </Routes>
            </Container>
        </div>
    )
}

export default Team;