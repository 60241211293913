import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import { useState, useEffect } from "react";
import Profile from "./Profile";
import Contact from "./Contact";
import Document from "./Document";
import { IdentityService } from "services/identity.service";
import { toast } from "react-toastify";

import initial from "../../../images/svg/compliance/initial.svg";
import current from "../../../images/svg/compliance/current.svg";
import completed from "../../../images/svg/compliance/completed.svg";
import alldone from "../../../images/svg/compliance/alldone.svg";
import loading from "../../../images/gif/insight-loading.gif";
import { logToBin } from "../../../lib/logs";


function Compliance ({from}) {
    const location = useLocation().pathname;
    let history = useNavigate();
    const [profileState, setProfileState]= useState("current");
    const [contactState, setContactState]= useState("initial");
    const [documentState, setDocumentState]= useState("initial");
    const [isDone, setIsDone] = useState(false);
    const [isComplied, setIsComplied] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [profileInfo, setProfileInfo] = useState({
        businessName: "",
        ownersName: "",
        dob: "",
        registrationType:"",
        size: "",
        industry: "",
        incorporationDate: "",
        directorsBVN: "",
    })
    const [contactInfo, setContactInfo] = useState({
        email: "",
        phone: "",
        website: "",
        address: ""
    })
    const [documentInfo, setDocumentInfo] = useState({
        regNo: "",
        regType: "",
        CACRegisteredBusinessName: "",
    });
    const [photoUpload, setPhotoUpload] = useState(null);
    const [fileName, setFileName] = useState('Upload business logo');
    const [pathBase, setPathBase] = useState("");
    let businessData = JSON.parse(sessionStorage.getItem("compliance_autofill"));

    useEffect(()=>{
        if(profileInfo?.businessName==="" || profileInfo?.businessName===undefined){
            // set the information in the form since the user already filled the info on signup
            setProfileInfo({
                businessName: businessData?.businessName || "",
                ownersName: businessData?.ownerFullName || "",
                dob: "",
                registrationType: "",
                size: businessData?.size || "",
                industry: businessData?.businessSector || "",
                incorporationDate: businessData?.incorporationDate || "",
                directorsBVN: businessData?.directorBVN || "",
            })
            setContactInfo({
                email: businessData?.email || "",
                phone: businessData?.phoneNumber || "",
                website: businessData?.website || "",
                address: businessData?.address||""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[businessData])
    
    useEffect(()=>{
        if(from==="pending"){
            setPathBase("/compliance")
        } else {
            setPathBase("/settings/compliance")
        }

        try{
            // set the data if user has filled the form before
            const lastImage = sessionStorage.getItem("compliance_logo")
            function dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), 
                    n = bstr.length, 
                    u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            }
            
            var file = dataURLtoFile(lastImage,'business-logo.png');
            setPhotoUpload(file);
            setFileName(file.name)

            setProfileInfo(JSON.parse(sessionStorage.getItem("compliance_profile")));
            setContactInfo(JSON.parse(sessionStorage.getItem("compliance_contact")));
            setDocumentInfo(JSON.parse(sessionStorage.getItem("compliance_document")));
        } catch {
            // err
        }    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleProfileChange = (val) => {
        setProfileInfo(val)
        console.log(profileInfo)
    }

    const handleContactChange = (val) => {
        setContactInfo(val)
    }

    const handleDocumentChange = (val) => {
        setDocumentInfo(val)
    }

    const submitCompliance = () => {
        console.log(profileInfo, contactInfo, documentInfo)
        setIsSubmitting(true);
        let data = new FormData();
        data.append('businessName', profileInfo.businessName);
        data.append('ownerFullName', profileInfo.ownersName);
        data.append('dateOfBirth', profileInfo.dob);
        data.append('registrationType', profileInfo.registrationType);
        data.append('size', profileInfo.size);
        data.append('industry', profileInfo.industry);
        data.append('incorporationDate', profileInfo.incorporationDate);
        data.append('directorBVN', profileInfo.directorsBVN);
        data.append('email', contactInfo.email);
        data.append('phoneNumber', contactInfo.phone);
        if(contactInfo.website!==''){
            data.append('website', contactInfo.website);
        }
        data.append('address', contactInfo.address);
        data.append('RCNumber', `${documentInfo.regType}${documentInfo.regNo}`);
        data.append('documentType', "CAC");
        data.append('CACRegisteredBusinessName', documentInfo.CACRegisteredBusinessName);
        if(!!photoUpload){
            data.append('file', photoUpload);
        }

        IdentityService.sendComplianceForm(data)
            .then((response) => response.data)
            .then((res) => { 
                setIsSubmitting(false);
                if (res.status === true) {
                    setIsDone(true);
                    
                    if(from==="pending"){
                        toast.success(`${res.message}`);
                        // window.open(res?.data?.url, "_blank").focus();
                        setTimeout( function (){
                            history("/")
                        }, 1500);
                    } else {
                        toast.success(`${res.message}. All done...`);
                        setIsComplied(true);
                        sessionStorage.setItem( "b2bCompliance", JSON.stringify({ isComplied: true }));
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setIsSubmitting(false);
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    useEffect(() => {
        let isComplied = JSON.parse(sessionStorage.getItem('b2bCompliance')).isComplied
            if(isComplied === true){
                setIsComplied(true)
            }else{
                setIsComplied(false)
            }
    },[])

    return(
        <>
            {
                isComplied ?
                <div>
                    <div className="compliance mr-[20px]">
                        <div className="compliance_top">
                            { photoUpload &&
                                <div className="compliance_top-circle" style={{overflow:"hidden"}}>
                                    <img src={URL.createObjectURL(photoUpload)} alt="logo" width="100%" />
                                </div>
                            }
                            <div className="compliance_top-progress">
                                <img src={completed} alt="compliance" className="icon1" />
                                <img src={completed} alt="compliance" className="icon2" />
                                <img src={completed}  alt="compliance" className="icon3" />
                                <p className="text1">Profile</p>
                                <p className="text2">Contact</p>
                                <p className="text3">Documents</p>
                            </div>
                        </div>
                        <div className="compliance_main text-center">
                            <div>
                                <h2 className="text-lg font-medium">Business is already compliant</h2>
                            </div>
                        </div>
                        { <div className="compliance_alldone"> <img src={alldone} alt="icon" className="block mx-auto" /></div>}
                    </div>
                </div>
                :
                <div className="compliance">
                    <div className="compliance_top">
                        { photoUpload &&
                            <div className="compliance_top-circle" style={{overflow:"hidden"}}>
                                <img src={URL.createObjectURL(photoUpload)} alt="logo" width="100%" />
                            </div>
                        }
                        <div className="compliance_top-progress">
                            <img 
                                onClick={()=>history(`${pathBase}/profile`)}
                                src={
                                    profileState==="initial" ? initial
                                    : profileState==="current" ? current
                                    : completed} 
                                alt="compliance" 
                                className="icon1" 
                            />
                            <img 
                                onClick={()=>history(`${pathBase}/contact`)}
                                src={
                                    contactState==="initial" ? initial
                                    : contactState==="current" ? current
                                    : completed}  
                                alt="compliance"
                                className="icon2" 
                            />
                            <img 
                                onClick={()=>history(`${pathBase}/document`)}
                                src={
                                    documentState==="initial" ? initial
                                    : documentState==="current" ? current
                                    : completed}  
                                alt="compliance" 
                                className="icon3" 
                            />
                            <p className="text1">Profile</p>
                            <p className="text2">Contact</p>
                            <p className="text3">Documents</p>
                        </div>
                    </div>
                    {
                        isSubmitting ? 
                        <div style={{display:"flex", flexShrink:"0", justifyContent:"center", marginTop:"100px"}}>
                            <img src={loading} alt="" style={{width:"30px", height:"30px", marginTop:"-3px"}}/>
                            <p style={{color:"#85a2b6"}}>Please Wait</p>
                        </div>
                        :
                        <div>
                            <div className={true?"compliance_main":"compliance_main compliance_main-infoBg"}> {/**true was isForm */}
                                <Routes>
                                    <Route path="/" element={<Navigate to={from==="pending"?"/compliance/profile":"/settings/compliance/profile"} />}/>
                                    <Route 
                                        path="/profile"
                                        element={
                                            <Profile 
                                                handleChange={handleProfileChange}
                                                info={profileInfo}
                                                nextButton={()=>{
                                                    setContactState("current");
                                                    setProfileState(completed)
                                                }}
                                                setUpload={(e)=>{setPhotoUpload(e.target.files[0])}}
                                                fileName={fileName}
                                                uploadedPhoto={photoUpload}
                                                setFileName={(name)=>setFileName(name)}
                                                pathBase={pathBase}
                                            />
                                        } 
                                    />
                                    <Route 
                                        path="/contact" 
                                        element={
                                            <Contact 
                                                handleChange={handleContactChange} 
                                                info={contactInfo} 
                                                nextButton={()=>{
                                                    setDocumentState("current");
                                                    setContactState(completed)
                                                }} 
                                                pathBase={pathBase}
                                            />
                                        } 
                                    />
                                    <Route 
                                        path="/document" 
                                        element={
                                            <Document
                                                handleChange={handleDocumentChange} 
                                                info={documentInfo} 
                                                nextButton={()=>{
                                                    setDocumentState(completed)
                                                    submitCompliance()
                                                }} 
                                                pathBase={pathBase}
                                            />
                                        }
                                    />
                                </Routes>
                            </div>

                            { isDone && <div className="compliance_alldone"> <img src={alldone} alt="icon" /></div>}
                        </div>
                    }
                </div>
            }
        </>
    )
    
}

export default Compliance;