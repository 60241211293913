import { copyText, handleRequestErrors } from "utils/helpers"
import ModalLayout from "../../layout/ModalLayout"
import Button from "components/shared/Button"
import { useState } from "react"
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";

export default function ShareMandateModal ({show, link, handleClose}) {
    const [email, setEmail] = useState("");
    const [sendLoading, setSendLoading] = useState(false)

    const handleSendEmail = (e) => {
        e.preventDefault()
        let payload = JSON.stringify({
            link,
            email
        })
        setSendLoading(true)
        RecovaService.sendMandateLink(payload)
            .then(response=> response.data)
            .then(res => {
                setSendLoading(false)
                if(res.status===true) {
                    toast.success(res.message)
                    handleClose()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setSendLoading(false)
                handleRequestErrors(err)
            });
    }

    return (
        <ModalLayout
            show={show}
            handleClose={() => handleClose()}
        >
            <div className="pt-8 pb-10">
                <h5 className="text-[20px] font-medium">Send or share link via email </h5>
                <div className="mt-6">
                    <p className="text-[15px] font-medium">Customer E-mail</p>
                    <form className="mt-2 flex" onSubmit={handleSendEmail}>
                        <input type="email" required onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email address" className="w-full rounded-l-[12px] bg-cc-blue-18 border-[1px] border-cc-blue-18 py-2 px-[20px] text-sm text-cc-grey-21" />
                        <div className="w-[150px] bg-cc-primary rounded-r-[12px] overflow-hidden">
                            <Button
                                type="submit"
                                isValid={true}
                                text="Send Link"
                                isLoading={sendLoading}
                                className="bg-cc-primary !pt-1 !pb-0 h-[30px]"
                            />
                        </div>
                    </form>
                </div>
                <div className="mt-6">
                    <p className="text-[15px] font-medium">Copy Link</p>
                    <div className="mt-2 flex">
                        <input value={link} disabled className="w-full rounded-l-[12px] bg-white font-medium border-[1px] border-cc-blue-18 py-2 px-[20px] text-sm text-cc-grey-21" />
                        <div className="w-[150px] bg-cc-primary rounded-r-[12px] overflow-hidden">
                            <Button
                                type="button"
                                isValid={true}
                                text="Copy Link"
                                onClick={()=>copyText(link)}
                                className="bg-cc-primary !pt-1 !pb-0 h-[30px]"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-6 text-center">
                    <p className="text-[15px]">This link will expire in 24 hours, but you can generate a new one when it does</p>
                    {/* <p className="text-[20px]">00:00:04</p> */}
                </div>
            </div>
        </ModalLayout>
    )
}