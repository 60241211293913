import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    loggedIn: false,
    cachePopup: true,
  },
  reducers: {
    initial: (state, action) => {
      return { 
        ...state, 
        loggedIn: true,
        data: action.payload
      }
    },
    editFirstAndLastName: (state, action) => {
      return { 
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
          }
        }
      }
    },
    setDpprCompliance: (state, action) => {
      return { 
        ...state,
        data: {
          ...state.data,
          isDpprCompliant: action.payload
        }
      }
    },
    setLoginNotification: (state, action) => {
      return { 
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            loginNotification: action.payload
          }
        }
      }
    },
    multiTenant: (state, action) => {
      return { 
        ...state, 
        multiTenant: action.payload,
      }
    },
    setCachePopup: (state, action) => {
      return { 
        ...state, 
        cachePopup: action.payload,
      }
    },
  },
})

export const { initial, editFirstAndLastName, setDpprCompliance, setLoginNotification, multiTenant, setCachePopup } = loginSlice.actions

export default loginSlice.reducer