import clsx from "clsx"
import { useEffect, useState } from "react"
import { capitalizeFirstLetter, copyText, currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers"
import dropdown from "images/svg/table_dropdown.svg";
import copy from "images/svg/recova/copy.svg"
import { RecovaService } from "services/recova.service";
import OutsideClickHandler from "react-outside-click-handler";
import Status from "components/shared/Status";

export default function TableDropdown ({item, expand}) {
    const [show, setShow] = useState(false) 
    const [repaymentDetails, setRepaymentDetails] = useState([]);

    useEffect(()=>{
        if(show){
            getMandate()
        }
        expand(show)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const getMandate = () => {
        RecovaService.getMandateById(item?._id)
            .then(response=> response.data)
            .then(res => {
                if(res.status===true) {
                    setRepaymentDetails(res?.data?.repayment)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }

    function getMonthsBetweenDates(date1_, date2_) {
        let date1 = new Date(date1_)
        let date2 = new Date(date2_)
        if (date1 > date2) {
          let temp = date1;
          date1 = date2;
          date2 = temp;
        }
        
        let yearsDifference = date2?.getFullYear() - date1?.getFullYear();
        let monthsDifference = date2?.getMonth() - date1?.getMonth();
      
        return yearsDifference * 12 + monthsDifference;
    }

    return (
        <div>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setShow(false)
                }}
            >
                <img 
                    src={dropdown} 
                    alt="view" 
                    className={clsx(
                        "cursor-pointer w-[24px] shrink-0 transition-all absolute top-3 right-3",
                        show && "rotate-180"
                    )}
                    onClick={()=>{
                        setShow(!show)
                        setRepaymentDetails([])
                    }} 
                />
                {
                    show &&
                    <div 
                        className={clsx(
                            "absolute top-[45px] bg-white z-10 w-[calc(100%-40px)] left-[20px] shadow-md border-[1px] border-cc-primary rounded p-[20px]",
                        )}
                    >
                        <div className="grid grid-cols-5 gap-[20px]">
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Product Name</p>
                                <p>{item?.productId?.name||"Not available"}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">BVN</p>
                                <p>{item.bvn}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Amount Due</p>
                                <p>{currencyFormatter(item?.totalAmountDue||0)}</p>
                            </div>
                            <div className="text-sm">
                                <p className="font-bold">Mandate Type</p>
                                <p className="capitalize">Variable</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Advanced Notice Sent</p>
                                <p>True</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Collection Mode</p>
                                <p>{item?.collectionMode==="auto" ? "Automated":"Manual"}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Mandate Status</p>
                                <p 
                                    className={clsx(
                                        "font-bold",
                                        item?.status==="pending" ? "text-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "text-cc-green-2" : "text-cc-red-8"
                                    )}
                                >{item?.status}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Total Oustanding</p>
                                <p>{currencyFormatter(item?.outstandingBalance||0)}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Total Collected</p>
                                <p>{currencyFormatter(item?.collectedAmount||0)}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Duration</p>
                                <p className="lowercase">{getMonthsBetweenDates(item?.startDate, item?.endDate)} month(s)</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Mandate Ref</p>
                                <div className="flex items-center gap-x-1">
                                    <p>{item?.reference}</p>
                                    <img src={copy} alt="copy" className="cursor-pointer w-[20px]" onClick={()=>copyText(item?.reference, "Mandate reference copied!")} />
                                </div>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">Start Date</p>
                                <p>{dateFormatter(item?.startDate, 'dd/mm/yyyy')}</p>
                            </div>
                            <div className="text-sm overflow-hidden">
                                <p className="font-bold">End Date</p>
                                <p>{dateFormatter(item?.endDate, 'dd/mm/yyyy')}</p>
                            </div>
                            {
                                !!item.businessName &&
                                <div className="text-sm overflow-hidden">
                                    <p className="font-bold">Business Name</p>
                                    <p>{item.businessName||"Not available"}</p>
                                </div>
                            }
                            {
                                !!item.rcNumber &&
                                <div className="text-sm overflow-hidden">
                                    <p className="font-bold">RC Number</p>
                                    <p>{item.rcNumber||"Not available"}</p>
                                </div>
                            }
                            {
                                !!item.businessEmail &&
                                <div className="text-sm overflow-hidden">
                                    <p className="font-bold">Business Email</p>
                                    <p>{item.businessEmail||"Not available"}</p>
                                </div>
                            }
                            {
                                !!item.businessPhone &&
                                <div className="text-sm overflow-hidden">
                                    <p className="font-bold">Business Phone Number</p>
                                    <p>{item.businessPhone||"Not available"}</p>
                                </div>
                            }
                        </div>
                        <div className="flex gap-x-10">
                            {
                                (repaymentDetails?.length>0) &&
                                    <div>
                                        <p className="font-bold text-[20px] mt-7 mb-2">Mandate Breakdown</p>
                                        <div>
                                            <div className="flex flex-col gap-y-1 text-cc-grey-4 text-sm">
                                                <div className="grid grid-cols-3 gap-x-10 bg-[#EAECF0] font-bold py-1 px-2.5">
                                                    <p>Due Date</p>
                                                    <p>Amount</p>
                                                    <p>Status</p>
                                                </div>
                                                {
                                                    repaymentDetails?.map(item=>(
                                                        <div className="grid grid-cols-3 gap-x-10 mb-1.5 px-2.5">
                                                            <p>{dateFormatter(item.dueDate, 'dd-mm-yyyy')}</p>
                                                            <p>{currencyFormatter(item?.totalAmount || 0)}</p>
                                                            <div className="flex">
                                                                <Status
                                                                    type={
                                                                        item.repaymentStatus==="pending" || 
                                                                            item.repaymentStatus==="scheduled" || 
                                                                                item.repaymentStatus==="partially paid" ? "pending"
                                                                        : item.repaymentStatus==="paid" ? "success"
                                                                        : item.repaymentStatus==="processing" ? "active"
                                                                        : "fail"
                                                                    }
                                                                    hideDot={true}
                                                                    text={capitalizeFirstLetter(item.repaymentStatus)}  
                                                                    className="text-[10px] py-0 px-2 text-center"
                                                                />
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                (item?.consents?.length>0) &&
                                    <div className="min-w-[200px]">
                                        <p className="font-bold text-[20px] mt-7 mb-2">BVN Linked Banks</p>
                                        <div>
                                            <p className="text-sm font-bold text-cc-grey-4 mb-1.5">Primary Account</p>
                                            <div className="flex items-center gap-x-2.5">
                                                <img src={item?.consents?.[0]?.bankLogo} alt="primary_acc" className="rounded w-[20px] h-[20px]" />
                                                <p className="text-sm folt-bold text-cc-grey-4">{item?.consents?.[0]?.bankName}</p>
                                                <div className="flex items-center gap-x-1.5">
                                                    <div 
                                                        className={clsx(
                                                            "rounded-xl h-[22px] py-[2px] px-2.5 text-xs ml-auto",
                                                            item?.consents?.[0]?.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                        )}
                                                    >
                                                        <p>{item?.consents?.[0]?.active ? "Active":"Inactive"}</p>
                                                    </div>
                                                    {
                                                        // !item?.consents?.[0]?.active &&
                                                        false &&
                                                        <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                            <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                        </svg>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (item?.consents?.length>1) &&
                                                <div>
                                                    <p className="text-sm font-bold text-cc-grey-4 mb-1.5 mt-2.5">Backup Account(s)</p>
                                                    {
                                                        item?.consents?.map((bank, idx)=>(
                                                            <div 
                                                                className={clsx(
                                                                    "flex items-center gap-x-2.5 mb-1.5",
                                                                    idx===0 && "hidden"
                                                                )}
                                                                key={idx}
                                                            >
                                                                <img src={bank?.bankLogo} alt="backup_acc" className="rounded w-[20px] h-[20px]" />
                                                                <p className="text-sm folt-bold text-cc-grey-4">{bank?.bankName}</p>
                                                                <div className="flex items-center gap-x-1.5">
                                                                    <div 
                                                                        className={clsx(
                                                                            "rounded-xl h-[22px] py-[2px] px-2.5 text-xs ml-auto",
                                                                            bank.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                                        )}
                                                                    >
                                                                        <p>{bank?.active ? "Active":"Inactive"}</p>
                                                                    </div>
                                                                    {
                                                                        // !bank?.active &&
                                                                        false &&
                                                                        <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                            <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                        </svg>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                            }
                            {/* <div className="cursor-pointer flex gap-1 items-end">
                                <p className="font-extrabold text-sm text-cc-grey-3">View/Download e-Consent</p>
                                <img src={consentDownload} alt="" className="w-7" />
                            </div> */}
                        </div>
                    </div>
                }
            </OutsideClickHandler>
        </div>
    )
}