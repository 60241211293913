import Button from "components/shared/Button";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";


export default function Details () {
    const navigate = useNavigate()

    return (
        <div className="max-w-[715px] !mt-4 relative">
            <Back to={()=> navigate(-1)}/>
            <p className="text-[24px] font-medium -mt-6 mb-3">Case ID 171281890756372 </p>
            <p className="font-bold text-base mb-6">Case Details</p>
            <div className="grid grid-cols-2 gap-x-2.5 gap-y-[20px]">
                {
                    [
                        {
                            title: "Subject",
                            content: "Payout Seetlement",
                        },
                        {
                            title: "Email",
                            content: "ninolenno@gmail.com",
                        },
                        {
                            title: "Case ID",
                            content: "171281890756372",
                        },
                        {
                            title: "Status",
                            content: "Resolved",
                        },
                        {
                            title: "Date Created",
                            content: "26-09-2023 11:00",
                        },
                        {
                            title: "Severity",
                            content: "High",
                        },
                        {
                            title: "Case Type",
                            content: "Multiple debit error",
                        },
                        {
                            title: "Opened by",
                            content: "Helen Ibe",
                        },
                        {
                            title: "Message",
                            content: "There was multiple debit attempts on my customers account which has resulted into the collection of more than the stipulated amount.",
                        },
                    ].map((item, idx)=>(
                        <div key={idx} className="text-sm font-medium">
                            <p className="text-cc-grey-4">{item.title}</p>
                            <p>{item.content}</p>
                        </div>
                    ))
                }
            </div>
            <div className="absolute right-0 -bottom-12">
                <Button
                    isValid={true}
                    isLoading={false}
                    type="button"
                    text="Reopen Case"
                    className="bg-cc-primary !py-2.5 !px-6 text-sm"
                    fullwidth={false}
                />
            </div>
        </div>
    )
}