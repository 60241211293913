import React, { useState } from "react"
import RecovaHeaderLayout from "pages/Dashboard/Recova/layout/RecovaHeaderLayout"
import Create from "./Create";
import History from "./History";
import RegisteredCustomers from "./RegisteredCustomers";
import clsx from "clsx";

import linkBlue from "images/svg/recova/linkBlue.svg"
import linkWhite from "images/svg/recova/linkWhite.svg"


function Mandates () {
    const [path, setPath] = useState("customers");
    const [creationBVN, setCreationBVN] = useState("")
    
    return(
        <RecovaHeaderLayout>
            <div>
                <h1 className="text-[25px] font-bold">Mandates</h1>
                <div className="flex items-center text-[15px] mt-3 sm:flex-wrap">
                    <div className={clsx(
                            "flex items-center mr-[20px] px-2 py-1 cursor-pointer",
                            path==="customers"&&"bg-cc-primary rounded-2xl text-white",
                        )}
                        onClick={()=>{
                            setPath("customers")
                            setCreationBVN("");
                        }}
                    >
                        <p>Registered Customers</p>
                    </div>
                    <div className={clsx(
                            "flex items-center mr-[20px] px-2 py-1 cursor-pointer",
                            path==="create"&&"bg-cc-primary rounded-2xl text-white",
                        )}
                        onClick={()=>{
                            setPath("create")
                            setCreationBVN("");
                        }}
                    >
                        <img src={path==="create"?linkWhite:linkBlue} alt="link" className="w-[15px] mr-1.5" />
                        <p>Create</p>
                    </div>
                    <div className={clsx(
                            "flex items-center mr-[20px] px-2 py-1 cursor-pointer",
                            path==="history"&&"bg-cc-primary rounded-2xl text-white",
                        )}
                        onClick={()=>{
                            setPath("history")
                            setCreationBVN("");
                        }}
                    >
                        <p>History</p>
                    </div>
                </div>
                {
                    path==="customers" ?
                    <RegisteredCustomers
                        goToCreate={(bvn)=>{
                            setCreationBVN(bvn);
                            setPath("create");
                        }}
                    />
                    :
                    path==="create" ?
                    <Create
                        creationBVN={creationBVN}
                    />
                    :
                    <History/>
                }
            </div>
        </RecovaHeaderLayout>
        
    )
}

export default Mandates