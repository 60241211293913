import {Modal} from "react-bootstrap";
import { SelectField } from "components/Form/SelectField";
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import Button from "components/shared/Button";
import { setActiveApp } from "redux/apps";
import {useState} from "react";

import switchTop from "images/svg/switch_icon.svg";
import cancel from "images/svg/x-close.svg";
import changed from "images/svg/app_changed.svg";


export default function SwitchApps ({show, handleClose}) {
    const apps = useSelector(state => state?.app);
    const dispatch = useDispatch();
    const [view, setView] = useState("form");

    console.log(apps)

    const initialValues = {
        activeApp: apps?.active,
    }

    return(
        <Modal show={show} size="sm" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="my-[30px] mx-4">
                <img src={cancel} alt="switch" className=" absolute top-[10px] cursor-pointer right-[10px] w-[28px]" onClick={()=>handleClose()}/>
                <img src={switchTop} alt="switch" className="block mx-auto w-10"/>
                <h5 className="font-semibold text-[18px] text-center mt-2.5">Switch Active App</h5>
                {
                    view==="form" ?
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            dispatch(setActiveApp(values.activeApp))
                            setView("changed")
                        }}
                    >  
                        {(props) => (
                            <Form>
                                <div className="mt-[50px] mb-[70px]">
                                    <SelectField
                                        name='activeApp'
                                        options={apps?.apps?.map(item => ({
                                            value: item.appName,
                                            label: item.appName,
                                            obj: item,
                                        }))}
                                        label="Select App"
                                        searchable={false}
                                        placeholder={apps.active.appName}
                                        value={props?.values?.activeApp?.appName}
                                        onChange={(selected)=>{
                                            props.setFieldValue( "activeApp", selected.obj )
                                        }}
                                    />
                                </div>
                                <Button
                                    isValid={props.isValid}
                                    type="submit"
                                    text="Submit"
                                    className="mb-[30px] mt-2.5"
                                />
                            </Form>
                        )}
                    </Formik>
                    :
                    <div>
                        <img src={changed} alt="changed" className="block mx-auto mt-[60px] w-10"/>
                        <p className="text-md font-semibold mb-[60px] text-center">App change to “{apps.active.appName}” was successful</p>
                        <div onClick={()=>window.location.reload()}>
                            <Button
                                isValid={true}
                                type="button"
                                text="Finish"
                                className="mb-[30px] mt-2.5"
                            />
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}