import { useEffect, useState } from "react";
import {Row, Col, Container} from 'react-bootstrap';

import mark from "../../../images/svg/apis/mark.svg";


function Apis({ setDetailName }) {
  const [path, setPath] = useState("income")
  useEffect(() => {
    setDetailName("");
  }, [setDetailName]);

  return (
    <div className="pricing">
      <Container>
        <div className="settings_navMain">
            <p className={path.includes("income")?"active":""} onClick={()=>setPath("income")}>Income Insight</p>
            <p className={path.includes("credit")?"active":""} onClick={()=>setPath("credit")}>Credit Insight</p>
            <p className={path.includes("radar")?"active":""} onClick={()=>setPath("radar")}>Radar</p>
            <p className={path.includes("identity")?"active":""} onClick={()=>setPath("identity")}>Identity</p>
            {/* <p className={path.includes("recova")?"active":""} onClick={()=>setPath("recova")}>Recova</p> */}
            <p className={path.includes("mfis")?"active":""} onClick={()=>setPath("mfis")}>MFIs</p>
            <p className={path.includes("telco")?"active":""} onClick={()=>setPath("telco")}>TELCO</p>
        </div>
        <div className="pricing_space">
          <Row>
            {path==="income" && 
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">PDF Linked Insight</h5>
                  <h5 className="font-semibold text-xl">Free <span style={{fontSize:"17px"}}>/Account Linking</span></h5>
                  <h5 className="font-semibold text-xl">Free <span style={{fontSize:"17px"}}>/Transactions pull</span></h5>
                  <h5 className="font-semibold text-xl">₦60 <span style={{fontSize:"17px"}}>/Assessment</span></h5>
                  <p className="my-3">Easily analyse consumer transaction data to get insights</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Linked Accounts</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Monthly Income</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Prescribed Eligible Amount</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Monthly Recurring Expense</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Monthly Sitting Balance</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Transaction Statements</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Debt Burden Ratio</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Residual Income</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Money Spent</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Money Received</p>
                  </div>
                </div>
              </Col>
            }
            {path==="income" && 
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">Bank Linked Insight</h5>
                  <h5 className="font-semibold text-xl">₦60 <span style={{fontSize:"17px"}}>/Account Linking</span></h5>
                  <h5 className="font-semibold text-xl">₦60 <span style={{fontSize:"17px"}}>/Transactions pull</span></h5>
                  <h5 className="font-semibold text-xl">₦60 <span style={{fontSize:"17px"}}>/Assessment</span></h5>
                  <p className="my-3">Easily analyse consumer transaction data to get insights</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Linked Accounts</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Monthly Income</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Prescribed Eligible Amount</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Monthly Recurring Expense</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Monthly Sitting Balance</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Transaction Statements</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Debt Burden Ratio</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Residual Income</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Money Spent</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Money Received</p>
                  </div>
                </div>
              </Col>
            }
            {path==="credit" && 
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">Credit Summary</h5>
                  <p className="mb-2"><span>₦200</span>/First Central Credit Bureau</p>
                  <p className="mb-2" style={{marginTop:"-20px"}}><span>₦230</span>/CRC Credit Bureau</p>
                  <p className="my-3">Get a snapshot of your customer’s credit data accross selected credit bureaus</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of credit facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of open facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of closed facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of credit grantors</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Overdue Days</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Amount Due</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Loan Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Outstanding Loan Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Last Reported Date</p>
                  </div>
                </div>
              </Col>
            }
            {path==="credit" &&
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">Credit Premium</h5>
                  <p><span>₦500</span>/Call</p>
                  <p className="my-3">Get advanced and full credit insight accross all 3 accredited credit bureaus</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of credit facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of open facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of closed facilities</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total number of credit grantors</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Overdue Days</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Amount Due</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Total Loan Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Outstanding Loan Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Last Reported Date</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Highest Loan Request</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Performance Status</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Provider History</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Enquiry History</p>
                  </div>
                </div>
              </Col>
            }
            {path==="radar" &&
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">Radar</h5>
                  <p><span>₦10</span>/Call</p>
                  <p className="my-3">Get insight into your customer’s banking footprints using thier phone number</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Financial Institution Name</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Account Number</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Bank Code</p>
                  </div>
                </div>
              </Col>
            }
            {path==="identity" &&
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">Identity</h5>
                  <h5 className="font-semibold text-xl">NIN <span style={{fontSize:"17px"}}>₦50/call</span></h5>
                  <h5 className="font-semibold text-xl">BVN <span style={{fontSize:"17px"}}>₦30/call</span></h5>
                  <h5 className="font-semibold text-xl">CAC <span style={{fontSize:"17px"}}>verification ₦30/call</span></h5>
                  <h5 className="font-semibold text-xl">Bank <span style={{fontSize:"17px"}}>basic verification ₦20/call</span></h5>
                  <h5 className="font-semibold text-xl">Bank <span style={{fontSize:"17px"}}>advanced verification ₦60/call</span></h5>
                  <h5 className="font-semibold text-xl">Voter's Card <span style={{fontSize:"17px"}}>₦80/call</span></h5>
                  <h5 className="font-semibold text-xl">Driver's License <span style={{fontSize:"17px"}}>₦80/call</span></h5>
                  <p className="my-3">Unearth the true identity of potential borrowers</p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Full Name</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />       
                    <p>Date of Birth</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Marital Status</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Verified Current Address</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Residence Address History</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Mobile Number</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Financial Institutions Used</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Employmet Status</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Employment History</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Bank Account Details</p>
                  </div>
                </div>
              </Col>
            }
            {path==="mfis" &&
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">MFIs</h5>
                  <p className="my-3"><span>Coming Soon</span></p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Financial Institution Name</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Master Dataset</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Performance Status</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Account Balance</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Customer’s Data</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Amount</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Term</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Monthly Payment Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Balance</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Issuance Date</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Loan Closing Date</p>
                  </div>
                </div>
              </Col>
            }
            {path==="telco" &&
              <Col md={4}>
                <div className="pricing_box">
                  <h5 className="font-semibold text-xl mb-2.5">TELCO</h5>
                  <p className="my-3"><span>Coming Soon</span></p>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Borrowed Airtime</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Airtime Issued Date</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Borrowed Status</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Borrowed Data Value</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Issued Date</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Closed Date</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Monthy Airtime Expense</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Annual Data Expense</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Monthly Call Duration</p>
                  </div>
                  <div className="pricing_box-item mb-2">
                    <img src={mark} alt="" />
                    <p>Average Annual Data Subscription</p>
                  </div>
                </div>
              </Col>
            }
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Apis;
