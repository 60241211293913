import { Col, Row, Modal } from "react-bootstrap";
import Table from "components/Table";
import {useEffect, useState} from "react";
import { BillingService } from "services/billing.service";
import { toast } from "react-toastify";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { mkConfig, generateCsv, download } from "export-to-csv";
import apiBlue from "../../../images/svg/api-blue.svg";
import { logToBin } from "../../../lib/logs";
import Status from "components/shared/Status";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";
import Filter from "components/Table/Filter";
import clsx from "clsx";


function Main () {
    const [data, setData] = useState({
      items: [],
      loading: true
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [logCount, setLogCount] = useState({});
    const [countQuery, setCountQuery] = useState("all");
    const [startDateee, setStartDateee] = useState("");
    const [endDateee, setEndDateee] = useState("");
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);
    const [show, setShow] = useState(false);
    const [filterTerm, setFilterTerm] = useState("all");
    const [allHistory, setAllHistory] = useState([]);
    
    const csvConfig = mkConfig({ 
        useKeysAsHeaders: true,
        filename: "billing-transactions"
    });

    const handleClose = () => setShow(false);

    useEffect(()=>{
        BillingService.getBillingLog(countQuery)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setLogCount(res.data.count);
                    setData({
                        ...data,
                        items: res.data.history,
                        loading: false,
                    });

                    setAllHistory(res.data.history);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setData({
                    ...data,
                    loading: false,
                });
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.error);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countQuery])

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        if (filterTerm==="all"){
            setData({
                ...data,
                items: allHistory
            })
        } else {
            const filteredData = allHistory.filter((item) => item?.service.includes(filterTerm) );
            setData({
                ...data,
                items: filteredData
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterTerm])

    
    useEffect(() => {
        if (searchTerm===""){
            setData({
                ...data,
                items: allHistory
            })
        } else {
            const filteredData = allHistory?.filter((item) => item?.action.includes(searchTerm) );
            setData({
                ...data,
                items: filteredData
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    function formatPickerDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    const queryVals = [
        {
            label: 'This month',
            value: 'this_month'
        },
        {
            label: 'Last month',
            value: 'last_month'
        },
    ]

    return(
        <div className="box-border m-10 md:mx-[20px]">
            <Modal show={show} onHide={handleClose}>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                            setStartDateee(formatPickerDate(item?.selection?.startDate))
                            setEndDateee(formatPickerDate(item?.selection?.endDate))
                            setState([item?.selection])
                        }
                    }
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
                <button 
                    className="text-white px-[25px] py-2 text-center border-none bg-cc-primary"
                    onClick={()=>{   
                        handleClose();
                        setCountQuery(`custom&start=${startDateee}&end=${endDateee}`)
                    }}
                >Proceed</button>
            </Modal>
            <Row>
                <Col md={7}>
                    <div className="flex border-b-[1px] border-b-[#EAECF0] xl:block">
                        <h4 className="text-[#101828] text-lg xl:mb-2.5 font-bold">API Calls</h4>
                        <div className="flex ml-auto sm:block">
                            {
                                queryVals?.map((item)=>(
                                    <p onClick={()=>setCountQuery(item.value)} className="text-[#1D2939] px-2.5 py-1 text-sm border-[1px] border-[#D0D5DD] cursor-pointer flex sm:mb-0" >
                                        {countQuery===item.value && <div className="bg-[#12B76A] rounded-full shrink-0 w-2.5 mt-2 mr-1.5 h-2.5"></div>}
                                        {item.label}
                                    </p>
                                ))
                            }
                            <p onClick={()=>setShow(true)} className="text-[#1D2939] px-2.5 py-1 text-sm border-[1px] border-[#D0D5DD] cursor-pointer flex sm:mb-0">
                                {   
                                    countQuery==='last_month' || countQuery==='this_month' ? 
                                    <span className="mr-2">+</span>:<div className="bg-[#12B76A] rounded-full shrink-0 w-2.5 mt-2 mr-1.5 h-2.5"></div>
                                }
                                    Custom
                            </p>
                        </div>
                    </div>
                    <div className="mt-10 box-border flex gap-x-10 justify-center sm:block xl:mt-4">
                        <div className="w-[230px] h-[230px] border-[15px] border-solid border-cc-primary rounded-full sm:block sm:mx-auto sm:mb-6">
                            <div className="block mt-2.5 mx-auto w-[180px] h-[180px] border-[15px] border-solid border-[#4C8BF5] rounded-full text-center">
                                <p className="text-[#667085] font-[1.1em] mt-[50px]">Total calls</p>
                                <h4 className="text-[1.7em] font-bold">{logCount?.overAll}</h4>
                            </div>
                        </div>
                        <div>
                            <div className="grid grid-cols-3 xl:grid-cols-2 gap-[20px]">
                                {
                                    ["Identity", "Radar", "Credit Insight", "Income"].map(item=>(
                                        <div className="flex gap-x-2 items-start" key={item}>
                                            <div 
                                                className={clsx(
                                                    "rounded-full w-2.5 h-2.5 shrink-0",
                                                    item==="Identity" ? "bg-[#2E90FA]"
                                                        : item==="Credit Insight" ? "bg-[#EE46BC]"
                                                        : item==="Radar" ? "bg-[#F79009]"
                                                        : item==="Income" ? "bg-[rgb(179,0,255)]"
                                                        : "bg-cc-primary"
                                                )}
                                            ></div>
                                            <div>
                                                <p className="-mt-2">{item}</p>
                                                <h5 className="text-xl font-medium">
                                                    {
                                                        item==="Identity" ? logCount?.identityCount
                                                        : item==="Credit Insight" ? logCount?.creditCount
                                                        : item==="Radar" ? logCount?.radarCount
                                                        : logCount?.incomeCount
                                                    }
                                                </h5>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="flex border-b-[1px] border-b-[#EAECF0] lg:block md:mt-10">
                        <div>
                            <h4 className="text-[#101828] text-lg font-bold">Recent API Calls</h4>
                        </div>
                    </div>
                    {data?.items?.length<1 ?
                        <p className="text-center my-[20px] md:mt-10">No recent API calls</p>
                        :
                        <div>
                            {
                                data?.items?.slice(0, 5)?.map((item, idx)=>(
                                    <div className="border-b-[1px] border-solid border-[#EAECF0] py-2.5 xl_min:flex md:flex" key={idx}>
                                        <div>
                                            <p className="font-semibold capitalize">{item?.action||"--"}</p>
                                            <p>{currencyFormatter(item?.amount||0)}</p>
                                        </div>
                                        <p className="whitespace-nowrap ml-auto">{days[new Date(item?.created_at).getDay()]}, {timeFormatter(item?.created_at)}</p>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </Col>
            </Row>
            <Table
                titleIcon={apiBlue}
                tableTitle={"Transaction Details"}
                data={data.items}
                loading={data.loading}
                hideSearch={false}
                onSearchChange={handleChange}
                searchPlaceHolder="Search by action"
                tableClassName="min-w-[900px]"
                slot={
                    <Filter
                        filterValue={filterTerm}
                        onChange={(v)=>setFilterTerm(v)}
                        options={[
                            { label: "Filter", value: "all", showOnList: false },
                            { label: "All", value: "all", showOnList: true },
                            { label: "Identity", value: "identity", showOnList: true },
                            { label: "Radar", value: "radar", showOnList: true },
                            { label: "Income", value: "income", showOnList: true },
                            { label: "Credit", value: "credit", showOnList: true },
                        ]}
                        hideCustom={true}
                    />
                }
                rows={[
                    {
                        header:'Action',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{item.action||"Not available"}</p>
                        ),
                    },
                    {
                        header:'Request',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.request?.method ? <Status text={item?.request?.method} type={item?.request?.method==="GET"?"success":"pending"} /> : "--"}
                            </p>
                        ),
                    },
                    {
                        header:'Time',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.created_at, 'dd/mm/yyyy')} {timeFormatter(item.created_at)}</p>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <Status
                                text={item?.status}
                                type={item?.status==="success"?"success":"fail"}
                            />
                        ),
                    },
                    {
                        header:'Cost',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{currencyFormatter(item.amount||0)}</p>
                        ),
                    },
                ]}
                showDownload={true}
                onDownloadClick={()=>{
                    const csv = generateCsv(csvConfig)(allHistory);
                    download(csvConfig)(csv)
                }}
            />
        </div>
    )
}

export default Main;