import OutsideClickHandler from 'react-outside-click-handler';
import { useState, useRef, useEffect } from 'react';
import { toast } from "react-toastify";
import axios from 'axios';
import { logToBin } from '../../../lib/logs';
import { SpectrumService } from 'services/spectrum.service';
import Pagination from "./Pagination";
import Back from '../../../components/shared/back.js';
import { useNavigate } from 'react-router-dom';
import { dateFormatter, timeFormatter, formatArrayPaging } from 'utils/helpers';
import { useSelector } from 'react-redux';

import view from "../../../images/svg/spectrum/view.svg";
import mark from "../../../images/svg/spectrum/markFull.svg";
import file from "../../../images/svg/spectrum/file.svg";
import csvFile from "../../../images/svg/spectrum/csvFile.svg";
import down from "../../../images/svg/spectrum/chevron-down.svg";
import user from "../../../images/svg/spectrum/user.svg";
import businessUser from "../../../images/svg/spectrum/business-user.svg";
import check from "../../../images/svg/spectrum/check.svg";


function ImportCSV () {

    let isDev = process.env.REACT_APP_NODE_ENV === 'development'
    let baseUrl = isDev ? 
        process.env.REACT_APP_Dev_Cluster
        :
        process.env.REACT_APP_Production_Cluster
    let navigate = useNavigate();
    const [option, setOption] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const drop = useRef(null);
    const [uploaded, setUploaded] = useState(null);
    const [fileProgress, setFileProgress] = useState(null);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    let publicKey = useSelector(state => state?.app?.active?.livePublicKey);

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
      
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('dragover', handleDragOver);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    });
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;
      
        if (files && files.length===1) {
            if(files[0].type==="text/csv"){
                setUploaded(files[0])
            } else {
                toast.error("Only CSV files are allowed!")
            }
        } else {
            toast.error("You can't upload more than one file at a time!")
        }
    };

    useEffect(()=>{
        let data = new FormData();
        data.append('file', uploaded);
        if(uploaded!==null){
            axios.post(`${baseUrl}dln/data/${option==="business"?"commerce":"consumer"}/submit-csv`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        token: `${publicKey}`
                    },
                    onUploadProgress: (progressEvent) => setFileProgress(progressEvent)
                })
                .then((response) => response.data)
                .then((res) => {
                    console.log(res)
                    getHistory()
                })
                .catch((err) => {
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploaded])

    useEffect(()=>{
        getHistory();
    },[])

    const getHistory = () => {
        SpectrumService.getCSVHistory()
            .then(response=> response.data)
            .then(res => {
                if(res.status === true){
                    setData(formatArrayPaging(res.data.reverse(), 10));
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <Back to={()=>navigate('/spectrum')} />
            <h5 className='text-lg font-medium'>Import historical data</h5>
            <div className="spectrum_import-box">
                <div className="spectrum_import-box-csv">
                    <h5 className='text-lg font-medium'>Upload by CSV</h5>
                    <div className="main">
                        <div className="spectrum_import-box-csv-form">
                            <input className={option===''?'notSelected':'selected'} placeholder="Choose lending data to upload" value={option} readOnly onClick={()=>setShowOptions(true)} />
                            <img src={down} alt="" className="down" onClick={()=>setShowOptions(true)}/>
                            <a
                                download
                                href={option==='individual'?'https://res.cloudinary.com/creditchek-africa/raw/upload/v1675784977/individualCsv.csv':'https://res.cloudinary.com/creditchek-africa/raw/upload/v1675785678/commercialCsv.csv'}
                            >
                                <button
                                    className={option===''?'aaa':'bbb'}
                                    disabled={option===''}
                                >
                                    Download Template
                                </button>
                            </a>
                            {
                                showOptions &&
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setShowOptions(false)
                                    }}
                                >
                                    <div className="spectrum_import-box-csv-form-options">
                                        <div onClick={()=>setOption("individual")}>
                                            <img src={user} alt="" className="img1"/>
                                            <p>Individual</p>
                                            {option==="individual" && <img src={check} alt="" className="img2"/>}
                                        </div>
                                        <div onClick={()=>setOption("business")}>
                                            <img src={businessUser} alt="" className="img1"/>
                                            <p>Business</p>
                                            {option==="business" && <img src={check} alt="" className="img2"/>}
                                        </div>
                                    </div>
                                </OutsideClickHandler>
                            }
                        </div>
                        <div className="spectrum_import-box-csv-upload" ref={drop} onClick={()=>document.getElementById('input_file').click()}>
                            <img src={csvFile} alt="" className='block mx-auto' />
                            <p>Select a CSV file to upload <br/><span>or drag and drop it here</span></p>
                            <input type="file" accept="text/csv" id='input_file' onChange={(e)=>setUploaded(e.target.files[0])} hidden />
                        </div>
                        {
                            uploaded &&
                            <div className="spectrum_import-box-csv-progress">
                                <img src={file} alt="" className="file"/>
                                <div>
                                    <p>{uploaded?.name}<img src={mark} alt='' className="mark"/></p>
                                    <p>{Math.round(uploaded?.size/1024)}KB</p>
                                    <div style={{display:'flex'}}>
                                        <progress value={fileProgress?.loaded} max={fileProgress?.total}></progress>
                                        <p>{Math.round((fileProgress?.loaded/fileProgress?.total)*100)}%</p>
                                    </div>
                                    {/* <p>Failed to upload, try again.</p> */}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                data.length>0 &&
                <div className="spectrum_import-tableBox">
                    <div className="spectrum_import-tableBox-topLayer"></div>
                    <div className="spectrum_overview-dataTable-main">
                        <div className="spectrum_overview-dataTable-main-scroll">
                            <table>
                                    <tr>
                                        <th>File Name</th>
                                        <th>Record Count</th>
                                        <th>Processed On</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        data[currentPage-1]?.map(item=>(
                                            <tr key={item.createdAt}>
                                                <td>{item.fileName}</td>
                                                <td>{item.count}</td>
                                                <td>{dateFormatter(item.createdAt, 'dd-M-yyyy')}, {timeFormatter(item.createdAt)}</td>
                                                <td>
                                                    <a href={item.url} download>
                                                        <button className="view flex items-center">
                                                            <img src={view} alt="" /> 
                                                            <span>Download</span>
                                                        </button>
                                                    </a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                            </table>
                        </div>
                    </div>
                    {
                        data.length>1 &&
                        <Pagination total={data.length} current={currentPage} setCurrent={(item)=>setCurrentPage(item)} />
                    }
                </div>
            }
        </div>
    )
}

export default ImportCSV;