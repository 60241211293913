import clsx from "clsx";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FiPlusCircle } from "react-icons/fi";

export default function FAQModal ({show, handleClose}) {
    const [active, setActive] = useState(0);

    const items = [
        {
            title: "After Customers authorize mandate setup vai the micro- deposit, what is next?",
            content: "You will wait for the mandate to be activated, after that; the business will go ahead and approve the loan for the customer. It is expected that the customer’s bank MUST approve the mandate within six(6) hours from the time it is successfully authorised."
        },
        {
            title: "Does the mandate cover all banks? (We'd like to know the bank coverage)?",
            content: <div>
                <p>We cover major commercial banks - See the list of covered banks below (last updated on August 1st 2024)</p>
                <div className="grid grid-cols-2 gap-4 mt-2.5">
                    {
                        [
                            "FIRST BANK OF NIGERIA PLC",
                            "POLARIS BANK LIMITED",    
                            "UNION BANK OF NIGERIA PLC",
                            "PROVIDUS BANK",
                            "GUARANTY TRUST BANK PLC",
                            "STANBIC IBTC",
                            "ACCESS BANK PLC",
                            "STANDARD CHARTERED BANK PLC",
                            "ZENITH INTERNATIONAL BANK PLC",
                            "STERLING BANK PLC",
                            "ECOBANK PLC",
                            "SUNTRUST BANK",
                            "FIDELITY BANK PLC",
                            "TAJBANK LTD",
                            "FIRST CITY MONUMENT BANK PLC",
                            "TITAN TRUST BANK",
                            "GLOBUS BANK LTD",
                            "UNITED BANK FOR AFRICA PLC",
                            "JAIZBANK",
                            "UNITY BANK PLC",
                            "KEYSTONE BANK",
                            "WEMA BANK",
                        ].map((item, idx)=>(
                            <p key={idx} className="text-xs">{item}</p>
                        ))
                    }
                </div>
            </div>
        },
        {
            title: "How long after the mandate activation date will the Direct Debit System begin trying to debit the primary account and the secondary accounts?",
            content: "Direct Debit starts from the 'Start Date' you selected while creating the Mandate."
        },
        {
            title: "After 10 minutes authorization window countdown on the customers ends, can the session expire?",
            content: "It is most likely that the mandate authorisation window will expire. Advise the customer to restart the session and generate a new payment account for micro-deposit."
        },
        {
            title: "For Manual Mandate, can I “apply debit” before the debit start date?",
            content: " Based on the Direct Debit scheme as defined by the Central bank, you are only allowed to execute any debit from the “Start Date” of the mandate arrangement."
        },
        {
            title: "What happens when a Mandate End date has passed, but the amount due was not fully debited?",
            content: " The mandate status will automatically be changed to “Closed”. This means that the mandate is no longer active for any direct debit. We’d recommend initiating another authorisation and arrangement with the payer."
        },
        {
            title: "What happens to the mandate when the amount due has been fully debited before the end date?",
            content: "The status of the mandate will automatically change to “Completed” and no direct debit will be executed onward. "
        },
        {
            title: "Can I set mandates for both individual and business accounts?",
            content: "Yes, you can set mandates for both personal and business bank accounts on the RecovaPRO platform."
        },
        {
            title: "Is it possible to create mandates for multiple products?",
            content: "Yes, you can create mandates for multiple products you have created as part of your profile; via the business portal or API."
        },
        {
            title: "What are the minimum account balance requirements for any successful direct debit collections??",
            content: "The customer's (Payer) account must have a minimum available balance above #500 to avoid failure. The system will leave a minimum of #250 in the customer's account after direct debit collection. This is also to align with consumer rights while honouring Direct debit scheme arrangements."
        },
        {
            title: "How does the collection process work, and where does the collected money go?",
            content: "Collection can be done automatically or manually. The collected money is transferred to your RecovaPRO merchant e-wallet where you can request a payout to any of your business bank accounts for onward settlements (transfer charges apply). "
        },
        {
            title: "How does the system handle situations where the full amount is not collected on the first day?",
            content: "The system runs multiple times a day for debt recovery. The system automatically knows all linked authorised accounts with an active e-mandate arrangement for the remaining, within a 24-hour window, this runs on three(3) cycle attempts until the full amount is collected."
        },
        {
            title: "How can I reduce error while creating a mandate?",
            content: <div>
                {
                    [
                        "Don’t use the same email address required to create a business mandate that is already associated with the customer’s BVN (personal details).",
                        "Make sure the name in your customer BVN record matches the name on the bank account you want to use for the mandate arrangement.",
                        "For business mandates, be sure to use a valid email address, phone number, RC/BN number and business name. Also, maintain these details if you want to create a new mandate for the same business.",
                    ].map((item, i)=>(
                        <p>{i+1}. {item}</p>
                    ))
                }
            </div>
        },
    ]

    return (
        <Modal
            show={show}
            onHide={() => handleClose()}
            size="md"
        >
            <div>
                <div className="grid grid-cols-2 gap-x-[20px] px-4 pt-10 pb-10">
                    <div className="font-bold">
                        <p className="text-[14px] text-cc-primary">Current as of 01 08 2024</p>
                        <p className="text-[30px]">RecovaPRO FAQs</p>
                    </div>
                    <div className="text-base font-normal text-cc-grey-4">
                        <p>Everything you need to know about RecovaPRO</p>
                        <p>Can’t find the answer you’re looking for? Please <span className="text-cc-primary underline">chat to our friendly team</span>.</p>
                    </div>
                </div>
                <div className="flex flex-col gap-y-4">
                    {
                        items.map((item, idx)=>(
                            <div key={idx} className="bg-[#F9FAFB] rounded-lg p-4 flex gap-x-2.5">
                                <div className="shrink-0 cursor-pointer" onClick={()=>setActive(idx)}>
                                    {
                                        active===idx ?
                                        <svg width="24" height="26" className="scale-[0.8]" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 14H16M22 14C22 19.5228 17.5228 24 12 24C6.47715 24 2 19.5228 2 14C2 8.47715 6.47715 4 12 4C17.5228 4 22 8.47715 22 14Z" stroke="#8A8A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        :
                                        <FiPlusCircle className="w-6 text-[#8A8A8A] scale-[1.2] mt-1.5" />
                                    }
                                </div>
                                <div>
                                    <p className="text-[18px] font-bold">{item.title}</p>
                                    <div 
                                        className={clsx(
                                            "text-sm font-normal overflow-hidden transition-all duration-[500ms] ease-in-out",
                                            active===idx ? "max-h-full":"max-h-0"
                                        )}
                                    >{item.content}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}