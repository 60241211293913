import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";


export default function PassportResult () {
    const navigate = useNavigate();
    
    let passport = {};
    try {
        passport = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!passport.photo ? `url(${passport.photo})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{passport?.first_name||""} {passport?.middle_name||""} {passport?.last_name||""}</h5>
                        <p>Passport Number: {passport?.passport_number}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <div className="grid grid-cols-5 gap-[30px]">
                    <div className="identity_result-box1">
                        <p className="text1">Passport Number</p>
                        <p className="text2">{passport?.passport_number||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Date of Birth</p>
                        <p className="text2">{passport?.dob||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Expiry Date</p>
                        <p className="text2">{passport?.expiry_date||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">First Name</p>
                        <p className="text2">{passport?.first_name||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Middle Name</p>
                        <p className="text2">{passport?.middle_name||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Last Name</p>
                        <p className="text2">{passport?.last_name||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Gender</p>
                        <p className="text2">{passport?.gender||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Issued at</p>
                        <p className="text2">{passport?.issued_at||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Issued Date</p>
                        <p className="text2">{passport?.issued_date||"Not available"}</p>
                    </div>
                    <div className="identity_result-box1">
                        <p className="text1">Phone Number</p>
                        <p className="text2">{passport?.phone||"Not available"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}