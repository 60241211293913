import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";


const useAuth = () => {
    const user = useSelector(state => state?.loginData?.loggedIn)
    return user;
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoutes;