import {Modal} from "react-bootstrap";

import deleteIcon from "../../images/svg/settings/delete.svg";


function WarningModal ({show, type, amount, handleClose, handlePayment, acceptAction, declineAction}) {
    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
    });
    
    return(
        <div>
            <Modal show={show} onHide={handleClose} size={type==="decline-float"||type==="accept-float"?'sm':'md'} aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body>
                    <div style={type==="delete-member"?{textAlign:"center"}:{}}>
                        {
                            type==="delete-member" &&
                            <img 
                                src={deleteIcon} 
                                alt="" 
                                style={{margin:"auto", display:"block"}} 
                            />
                        }
                        {
                            type==="delete-member"?
                            <h4 className="font-medium text-[1.3em] my-2">Delete Request</h4>
                            :
                            type==="decline-float"?
                            <h5 className="font-medium text-[1.3em] my-2" style={{marginTop:'15px'}}>Decline Float Offer</h5>
                            :
                            type==="accept-float"?
                            <h5 className="font-medium text-[1.3em] my-2" style={{marginTop:'15px'}}>Accept Float Offer</h5>
                            :
                            type==="make-repayment"?
                            <h5 className="font-medium text-[1.3em] my-2" style={{marginTop:'15px'}}>Make Repayment</h5>
                            :
                            <p></p>
                        }
                        <p style={{color:"#667085"}}>
                            {
                                type==="decline-float"?
                                "Are you sure you want to decline this offer? This action cannot be undone."
                                :
                                type==="accept-float"?
                                "Are you sure you want to accept this offer? This action cannot be undone."
                                :
                                type==="delete-member"?
                                'Are you sure you want to delete this person? This action cannot be undone.'
                                : 
                                type==="make-repayment"?
                                `Your wallet will be charged ${formatMoney.format(amount)}. This action is unreversible`
                                : 
                                ""
                                    
                            }
                        </p>
                    </div>
                    <div className="settings-invite-action">
                        <button className="cancel" onClick={handleClose}>Cancel</button>
                        <button className={type==="accept-float"  || type==="make-repayment" ?"publish":"delete"} onClick={type === 'decline-float' ? declineAction :type === 'make-repayment' ? handlePayment : acceptAction}>{type==="decline-float"?'Decline':type==="accept-float"?'Accept':type==="make-repayment"?'Continue':'Delete'}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default WarningModal;