import React, { useMemo, useState } from "react";
import NotificationCard from "./NotificationCard";
import cancelIcon from "../../images/svg/float/cancelIcon.svg"
import { getDate, getTime } from "utils/helpers";
import { FloatService } from "services/float.service";
import { useNavigate } from "react-router-dom";

const NotificationPanel = ({ visible, setVisible, notifications, getNotification }) => {
  const [path, setPath] = useState("general");
  const navigate = useNavigate()

  const values = useMemo(
    () => {
      if(path === "general"){
        return notifications;
      }
      const filteredData = notifications?.filter(
        (item) => item?.status === path
      );
      return filteredData
    },
    [notifications, path]
  );

  const unreadMessages = useMemo(() => notifications?.filter(
    (item) => item?.status === "unread"
  ), [notifications])
  const readMessages = useMemo(() => notifications?.filter(
    (item) => item?.status === "read"
  ), [notifications])

  const handleNotificationChange = (id) => {
    navigate("/float/status")   
    FloatService.toggleNotifications(id)
    .then((response) => response.data)
        .then((res) => {
          if (res.status === "success" && res.data !== null ){
            setVisible(false)  
            getNotification()
          }
        })
        .catch((err) => {});
  }

  return (
    <>
      {visible ? (
        <div
          onClick={() => {
            setVisible(false);
          }}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        ></div>
      ) : (
        ""
      )}
      <div
        className="min-w-[500px]"
        style={{
          height:"100%",
          position: "fixed",
          top: 0,
          bottom: 0,
          right: 0,
          zIndex: 1000,
          display: "inline-block",
          transform: visible ? "translateX(0)" : "translateX(500px)",
          transition: "transform .6s ease-in-out",
          backgroundColor: "#FFFFFF",
          boxShadow: "-20px 0px 25px rgba(0, 0, 0, 0.05)",
          overflowX: "hidden",
        }}
      >
        <div 
          style={{
            marginTop:"2rem", 
            padding:"1rem", 
            direction:"column",
            position: "relative"
          }}>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
              <p
               style={{
                fontWeight:700,
                color:"#000000",
                fontSize: "32px"
               }}
              >
                {" "}
                Notifications
              </p>
          </div>
          <div 
            style={{
              position: "absolute",
              top: 0,
              right: 15,
              cursor: "pointer"
            }}
          >
          <img 
                src={cancelIcon} 
                alt="cancel icon" 
                onClick={(e) => {
                    e.stopPropagation();
                    setVisible(false);
                  }}
              />
              
          </div>
            <div className="settings_navMain" style={{padding: 0}}>
                <div 
                  style={{padding: 0, alignItems:"flex-end"}} 
                  className="link-decor flex" 
                  onClick={()=>setPath("general")}
                >
                  <p style={{marginRight: 0, fontSize: "20px"}} className={path === "general"?"active":""} >General</p>
                  <div
                    style={{
                      width: "32px",
                      height: "28px",
                      background: path === "general" ? "#0349FD" : "rgba(202, 205, 212, 0.21)",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "0.7rem"
                    }}
                  >
                    <p
                      style={{
                        color: path === "general" ?"#FFFFFF" : "#949191",
                        fontSize: "14px",
                        padding: 0,
                      }}
                    >{notifications?.length}</p>
                  </div>
                </div>
                <div style={{padding: 0, alignItems:"flex-end"}}  className="link-decor flex" onClick={()=>setPath("read")}>
                  <p style={{marginRight: 0, fontSize: "20px"}} className={path === "read"?"active":""}>Read</p>
                  <div
                    style={{
                      width: "32px",
                      height: "28px",
                      background: path === "read" ? "#0349FD" : "rgba(202, 205, 212, 0.21)",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "0.7rem"
                    }}
                  >
                    <p
                      style={{
                        color: path === "read" ?"#FFFFFF" : "#949191",
                        fontSize: "14px",
                        padding: 0,
                      }}
                    >{readMessages?.length}</p>
                  </div>
                </div>
                <div style={{padding: 0, alignItems:"flex-end"}} className="link-decor flex" onClick={()=>setPath("unread")}>
                  <p style={{marginRight: 0, fontSize: "20px"}}className={path === "unread"?"active":""} >Unread</p>
                  <div
                   style={{
                      width: "32px",
                      height: "28px",
                      background: path === "unread" ? "#0349FD" : "rgba(202, 205, 212, 0.21)",
                      borderRadius: "4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "0.7rem"
                    }}
                  >
                    <p
                      style={{
                        color: path === "unread" ?"#FFFFFF" : "#949191",
                        fontSize: "14px",
                        padding: 0,
                      }}
                    >{unreadMessages?.length}</p>
                  </div>
                </div>
            </div>
          {values.map((notification, index) => {
            const status =
              notification["status"] === "read" ? "#D9D9D9" : "#18B71E";
              const time = getTime(new Date(notification?.createdAt))
              const date = getDate(new Date(notification?.createdAt))
            return (
              <NotificationCard
                key={index}
                text={notification.message}
                status={status}
                time={time}
                date={date}
                id={notification?._id}
                handleStatusChange={handleNotificationChange}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default NotificationPanel;
