import { Col, Row, Modal } from "react-bootstrap";
import Table from "components/Table/OldTable";
import { useEffect, useState } from "react";
import InsightLoader from "components/insightLoader";
import floatDisbursed from "../../../../images/svg/float/float-disbursed.svg";
import floatDeclined from "../../../../images/svg/float/float-declined.svg";
import { logToBin } from "../../../../lib/logs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ConfirmAction from "components/Modal/ConfirmAction";
import { FloatService } from "services/float.service";
import { useSelector } from "react-redux";
import { currencyFormatter } from "utils/helpers";


function FloatStatus () {
    const [data, setData] = useState({
        tableFor: "float-request-history",
        headings: ["Request ID", "Requested Amount", "Approved Amount", "Requested Tenure", "Approved Tenure", "Date", "Status", 'Action'],
        items: []
    });
    const [disableAction, setDisableAction] = useState(false);
    const [showLowBalance, setShowLowBalance] = useState(false);
    const [loading, //setLoading
    ] = useState(false);
    const [show, setShow] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [isDeclined, setIsDeclined] = useState(false)
    const [stats, setStats] = useState([])
    // const [showRequestOptions, setShowRequestOptions] = useState(false);
    let navigate = useNavigate();
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const wallet = useSelector(state => state?.walletData?.wallet);
 

    useEffect(() => {
      FloatService.floatStats(businessId)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === 'success') {
              setStats(res.data)
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
              logToBin(err.response.data, window.location.href);
              toast.error(err.response.data.message);
          } else{
          if(err.message === "timeout of 15000ms exceeded"){
              toast.error('Request timed out, please try again!')
          } else if (err.message==="Network Error"){
              toast.error('Network error, please make sure you are connected to the internet!')
          } else{
              toast.error("Your request can't be processed at this time, please try again later!");
          }
          }
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      FloatService.floatHistory(businessId)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === 'success') {
            setData({
                ...data,
                items: res.data.reverse()
            })
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
              logToBin(err.response.data, window.location.href);
              toast.error(err.response.data.message);
          } else{
          if(err.message === "timeout of 15000ms exceeded"){
            toast.error('Request timed out, please try again!')
          } else if (err.message==="Network Error"){
            toast.error('Network error, please make sure you are connected to the internet!')
          } else{
              toast.error("Your request can't be processed at this time, please try again later!");
          }
          }
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const initiateRequest = (amountType) => {
        let payload = {
            businessId
        }
      FloatService.floatInitiateRequest(payload)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === 'success') {
            sessionStorage.setItem('floatId', JSON.stringify(res.data._id))
            navigate(`/float/new-request?amountType=${amountType}`)
          } else {
              toast.error(res.message)
          }
          setDisableAction(false)
      })
      .catch((err) => {
        if(err.response){
            logToBin(err.response.data, window.location.href);
            toast.error(err.response.data.message)
        } else{
        if(err.message === "timeout of 15000ms exceeded"){
            toast.error('Request timed out, please try again!')
        } else if (err.message==="Network Error"){
            toast.error('Network error, please make sure you are connected to the internet!')
        } else{
            toast.error("Your request can't be processed at this time, please try again later!");
        }
        }
        setDisableAction(false)
      });
    }

    const viewRequest = (id, state) => {
        if(state === 'approved'){
          FloatService.getFloatById(id)
          .then(response=> response.data)
          .then(res => {
              if(res.status === 'success'){
                  sessionStorage.setItem('floatOffer', JSON.stringify(res.data))
                  navigate('/float/offer-details')
              } else {
                  toast.error(res.message)
              }
          })
          .catch((err) => {
              if(err.response){
                  logToBin(err.response.data, window.location.href);
              } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
              }
          });
           
        }else if(state === 'pending'){
            setIsPending(true)
            setShow(true)
          }else if(state === 'declined'){
            setIsDeclined(true)
            setShow(true)
          }else if(state === 'accepted'){
              //
          }
    }

    const modalAction = () => {
        if(isPending){
          FloatService.floatRequestReminder(businessId)
          .then(response=> response.data)
          .then(res => {
              if(res.status === 'success'){
                  toast.success('Reminder sent!')
                  setTimeout(() => {
                      window.location = '/float/status'
                  }, 1500);
                 
              } else {
                  toast.error(res.message)
              }
          })
          .catch((err) => {
              if(err.response){
                  logToBin(err.response.data, window.location.href);
              } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
              }
          });
        }else if(isDeclined){
            window.location = '/float'
        }
    }

    const checkBalance = (amountType) => {
        if (wallet.balance>9999){
            initiateRequest(amountType)
        } else {
            setShowLowBalance(true)
        }
    }

    return (
        <div>
            <Row>
                <Col lg={4} md={6} >
                    <div className="float_overview-box">
                        <p className="text1">Total Request Sent</p>
                        <h4 className="font-medium text-[1.3em] my-2">{stats?.totalRequest}</h4>
                        {/* <button onClick={()=>setShowRequestOptions(!showRequestOptions)}>Request New Float</button> */}
                        <button onClick={()=>checkBalance("naira")} className="bg-cc-blue-6">Request New Float</button>
                        {
                            // showRequestOptions &&
                            false &&
                            <div className="float_overview-box-dropdown"> 
                                <button className="mr-2 cursor-pointer" onClick={()=>checkBalance("naira")}>Naira </button>
                                <button onClick={()=>checkBalance("usd")}> USD </button>
                            </div>
                        }
                    </div>
                </Col>
                <Col lg={4} md={6} >
                    <div className="float_overview-box">
                        <p className="text1">Total Approved</p>
                        <h4 className="font-medium text-[1.3em] my-2">{stats?.approvedRequest}</h4>
                    </div>
                </Col>
                <Col lg={4} md={6} >
                    <div className="float_overview-box">
                        <p className="text1">Total Declined</p>
                        <h4 className="font-medium text-[1.3em] my-2">{stats?.rejectRequest}</h4>
                    </div>
                </Col>
            </Row>
            <div>
                <div className="table_top">
                    <div className="home_main-topText-toggles">
                        <div>
                            <h4>Float Request History</h4>
                        </div>
                    </div>
                </div>
                {
                    loading ? <InsightLoader />
                    :
                    <Table 
                        data={data} 
                        doAction = { (id, state) => viewRequest(id, state)}
                    />
                }
            </div>
            <Modal show={show} onHide={()=>setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal text-center">
                    <img src={isPending ? floatDisbursed : isDeclined ? floatDeclined : null} alt='icon' />
                    <h5>{isPending?'Under Review':isDeclined?'Float Request Declined':null}</h5>
                    <p style={{color:"#667085"}}>
                        {
                            isPending ?
                            "Your application is currently being reviewed. You will receive an offer within 24hrs"
                            : isDeclined ?
                            "Your application has been declined. Please try again later"
                            : null
                        }
                    </p>
                    {/* <Link to="/float"> */}
                        <button className="float_modal-action" onClick={modalAction} >
                            {
                                isPending?
                                'Send Reminder'
                                : isDeclined?
                                'Try Again'
                                : null
                            }
                        </button>
                    {/* </Link> */}
                </div>
            </Modal>
            <ConfirmAction
                show={showLowBalance}
                onCancel={()=>setShowLowBalance(false)}
                onAction={()=>{
                    setDisableAction(true);
                    navigate("/home/wallet");
                }}
                loading={disableAction}
                title={`Float Request`}
                content={
                    <>
                        <p>{`You don't have enough balance to proceed. You need to have a balance of at least ₦10000 to complete a new float request process.`}</p>
                        <p className="font-semibold">Current Balance: {currencyFormatter(wallet?.balance)}<span className="font-normal"></span></p>
                    </>
                }
            />
        </div>
    )
}

export default FloatStatus;