import goback from "images/svg/back.svg";

function back ({to}) {
    return(
        <div onClick={()=>to()} className="flex cursor-pointer item-center gap-x-1.5 mb-10 md:hidden">
            <img src={goback} alt="back" />
            <p className="text-[1.1em] font-bold">Back</p>
        </div>
    )
}

export default back;