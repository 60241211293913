import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

const timeout = 15000;

export const AuthService = {
    SignupAction: (payload) => {
        return APIKit(timeout).post('auth/register', payload)
    },

    LoginAction: (payload, ipDeets, userAgent) => {
        return APIKit(timeout).post("/auth/login", payload, {
            headers: {
                'ipcountry': ipDeets.country_name,
                'ipaddress': ipDeets.IPv4,
                'useragent': userAgent,
                'city': ipDeets.city
            }
        })
    },
        
    ContinueLogin: (payload) => {
        return APIKit(timeout).post("auth/login/continue", payload)
    },
        
    ForgotPasswordAction: (payload) => {
        return APIKit(timeout).post("auth/forgot-password", payload)
    },

    ResetPasswordAction: (reset_token, payload) => {
        return APIKit(timeout).post(`auth/reset-password?token=${reset_token}`, payload)
    },
    
    verifyAction: (payload) => {
        return APIKit(timeout).post(`auth/verify-email`, payload)
    },
    
    verifyLoginAction: (payload, ipDeets, userAgent) => {
        return APIKit(timeout).post(`auth/verify-login`, payload, {
            headers: {
                'ipcountry': ipDeets.country_name,
                'ipaddress': ipDeets.IPv4,
                'useragent': userAgent,
                'city': ipDeets.city
            }
        })
    },

    ResendVerificationAction: (payload) => {
        return APIKit(timeout).post(`auth/resend-verification-email`, payload)
    },

    authLogs: (payload) => { // get user log
        return APIKit(timeout).post(`auth/user-log`, payload)
    },

    logoutAction: (payload) => {
        return APIKit(timeout).post(`auth/logout`, payload)
    },

    changePassword: (id, payload) => {
        return APIKit(timeout).put(`auth/user/change-password/${id}`, payload)
    },

    getBusinessById: (payload) => {
        return APIKit(timeout).post(`auth/business/get-info/id`, payload)
    },

    approvalReminder: (payload) => {
        return APIKit(timeout).post(`auth/approval-reminder`, payload)
    },

    fetchStates: (id) => {
        return APIKit(timeout).get(`auth/states/${id}`)
    },

    createApp: (payload) => {
        return APIKit(timeout).post(`auth/app/create`, payload)
    },
    
    businessApps: (payload) => {
        return APIKit(timeout).post(`auth/business/apps`, payload)
    },

    getAppById: (id) => {
        return APIKit(timeout).get(`auth/app/${id}`)
    },

    toggleStatus: (payload) => {
        return APIKit(timeout).post(`/auth/app/toggle-status`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    resetTestKeys: (payload) => {
        return APIKit(timeout).post(`auth/reset/test-keys`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    resetLiveKeys: (payload) => {
        return APIKit(timeout).post(`/auth/reset/app-keys`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    loginNotifications: (userId) => {
        return APIKit(timeout).post(`auth/user/toggle-notification/${userId}`)
    },

    createRole: (payload) => {
        return APIKit(timeout).post(`auth/business/role-create`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getModules: () => {
        return APIKit(timeout).get(`auth/modules`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getAllRoles: (payload) => {
        return APIKit(timeout).post(`auth/business/role-fetch`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getRolesById: (id) => {
        return APIKit(timeout).get(`auth/business/role/${id}/get`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    editRole: (id, payload) => {
        return APIKit(timeout).put(`auth/business/role-edit/${id}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteRole: (id) => {
        return APIKit(timeout).delete(`auth/business/role-delete/${id}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    inviteUser: (payload) => {
        return APIKit(timeout).post(`auth/business/team-invite`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getTeam: (id) => {
        return APIKit(timeout).get(`auth/business/team-member/${id}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    changeMemberRole: (payload) => {
        return APIKit(timeout).put(`auth/team-member/role/edit`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    resendInvite: (id) => {
        return APIKit(timeout).put(`auth/business/resend-invite/${id}`)
    },
    
    getInvitedUser: (id) => {
        return APIKit(timeout).get(`auth/business/team-invite/${id}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    completeInvite: (payload, id) => {
        return APIKit(timeout).put(`/auth/business/invite-onboard/${id}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    editUserProfile: (payload, id) => {
        return APIKit(timeout).put(`/auth/user/edit-profile/${id}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteInvite: (bussId, userId) => {
        return APIKit(timeout).delete(`/auth/business/${bussId}/delete-invite/${userId}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    getWidget: (appId) => {
        return APIKit(timeout).get(`/auth/widget/${appId}/appId`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    editWidget: (businessId, payload) => {
        return APIKit(timeout).put(`/auth/widget/${businessId}/edit`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    toggleWidgetStatus: (businessId) => {
        return APIKit(timeout).put(`/auth/widget/${businessId}/toggle-status`, {},
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    changeWidgetApp: (businessId, payload) => {
        return APIKit(timeout).put(`/auth/widget/${businessId}/change-app`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    unlinkWidget: (widgetId) => {
        return APIKit(timeout).delete(`/auth/widget/${widgetId}/unlink`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getAppLinkedToWidget: (businessId) => {
        return APIKit(timeout).get(`/auth/widget/linked-app/${businessId}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteSingleApp: (appID) => {
        return APIKit(timeout).delete(`auth/app//${appID}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },


    // WEBHOOKS

    sendWebhookURL: (businessId, payload) => {
        return APIKit(timeout).post(`auth/business/set-webhook/${businessId}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    createWebhook: (payload) => {
        return APIKit(timeout).post(`auth/webhook/create`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    getWebhooks: () => {
        return APIKit(timeout).get(`auth/webhook`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    updateWebhook: (payload) => {
        return APIKit(timeout).put(`auth/webhook`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    toggleWebhookStatus: (payload) => {
        return APIKit(timeout).put(`auth/webhook/toggle-status`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    deleteWebhook: () => {
        return APIKit(timeout).delete(`auth/webhook`, 
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    getWebhookLogs: () => {
        return APIKit(timeout).get(`auth/webhook/logs`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    changeBusinessOwnerEmail: (payload) => {
        return APIKit(timeout).post(`auth/business/user/update-email`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    verifyBusinessOwnerEmail: (payload) => {
        return APIKit(timeout).post(`auth/business/user/verify-email`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
}