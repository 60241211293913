// import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { useState } from "react";
import {Modal} from "react-bootstrap";

import calendar from "../../../images/svg/income/calendar.svg";
import calendarWhite from "../../../images/svg/income/calendarWhite.svg";


export default function MonthRange ({data, setSelectionRange}) {
    const [positions, setPositions] = useState({
        first: 0, //Jan is 1, dec is 12
        last: 0
    })
    const [showCustomOptions, setShowCustomOptions] = useState(false)

    const update = (value) => {
        console.log(value)
        if(positions.first===0 || positions.first>value){
            setPositions({
                ...positions,
                first: value
            })
        } else if (positions.first<value && positions.last>value) {
            setPositions({
                ...positions,
                first: value,
                last: 0
            })
        } else {
            setPositions({
                ...positions,
                last: value
            })
        }
    }

    const cancelModal = () => {
        setPositions({
            first: 0,
            last: 0
        });
        setShowCustomOptions(false)
    }

    const applyModal = () => {
        if(positions.first!==0 && positions.last!==0){
            setSelectionRange(positions.first, positions.last)
        }
        setShowCustomOptions(false)
    }

    return(
        <div className={(data.customStart===0&&data.customEnd===0)||(positions.first===0&&positions.last===0)?"home_main-topText-filter":"home_main-topText-filter income-filterActive"} >
            <p className={(data.customStart===0&&data.customEnd===0)||(positions.first===0&&positions.last===0)?'flex items-center':'income-filterActive-text flex items-center'} style={{width:'100px !important'}} onClick={()=>setShowCustomOptions(true)}>
                <img src={(data.customStart===0&&data.customEnd===0)||(positions.first===0&&positions.last===0)?calendar:calendarWhite} alt="" />
                <span>{(data.customStart===0&&data.customEnd===0)||(positions.first===0&&positions.last===0)?'Custom':`${data?.months[positions.first-1]?.substring(0,3)}-${data?.months[positions.last-1]?.substring(0,3)}`}</span>
            </p>                     
            <Modal show={showCustomOptions} size="sm" onHide={()=>setShowCustomOptions(false)} aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="income_spending-box-filter-monthRange">
                    <h5 className="text-center text-lg font-medium">Custom</h5>
                    {/* <div>
                        <p>Sep, 2022</p>
                        <p>-</p>
                        <p>Sep, 2022</p>
                    </div> */}
                    {/* <h6 className="text-center">{new Date().getFullYear()}</h6> */}
                    <div className="income_spending-box-filter-months">
                        {
                            data?.months?.map((item, idx)=>(
                                <p key={idx} className={positions?.first===(idx+1)?"first":positions?.first<(idx+1)&&positions?.last>(idx+1)?"middle":positions?.last===(idx+1)?"last":''} onClick={()=>update(idx+1)}>{item?.substring(0,3)} {item?.split(" ")[1]}</p>
                            ))
                        }
                    </div>
                </div>
                <hr className="mb-3"/>
                <div className="myModal_main-widget-action" style={{margin:'0 20px 20px'}}>
                    <button className="cancel" onClick={cancelModal}>Cancel</button>
                    <button className="publish" onClick={applyModal}>Apply</button>
                </div>
            </Modal>
        </div>
    )
}