import sharelink from 'images/svg/shareLink.svg'
import {Modal} from "react-bootstrap";
import { useState } from 'react';

function ShareYouData ({data, show, handleAction}) {
    const [option, setOption] = useState("upload-pdf")

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    return(
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <div className="myModal_main-widget-shareLeft" >
                    <img src={sharelink} alt="icon" />
                    <h4>Share your Bank <br className='sm:hidden'/>Transactional Data</h4>
                    <p>
                        To boost your chance of approving your credit, kindly share your bank account transaction statement with {data.businessName} securely?
                    </p>
                    <div className='radio mt-2.5 mb-4'>
                        <p onClick={()=>setOption("upload-pdf")} className='mb-2.5'>
                            <input type="radio" name="radio-group" checked={option==="upload-pdf"} />
                            <label>Upload PDF bank statement</label>
                        </p>
                        <p onClick={()=>setOption("connect-edoc")}>
                            <input type="radio" name="radio-group" checked={option==="connect-edoc"} />
                            <label>Connect with OpenBanking</label>
                        </p>
                    </div>
                </div>
                <div className="myModal_main-widget-share-action">
                    <button className="decline" onClick={handleClose}>Decline</button>
                    <button className="share" style={{marginLeft:"auto"}} onClick={()=>handleAction(option)}>Proceed</button>
                </div>
            </div>
        </Modal>
    )
}

export default ShareYouData;