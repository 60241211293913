import {useState, useEffect} from "react";
import tableIcon from "../../../../images/svg/loan-performance.svg";
import Table from "components/Table";
import { currencyFormatter, dateFormatter } from "utils/helpers";
import Status from "components/shared/Status";
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from "clsx";
import dropdown from "images/svg/table_dropdown.svg";


export default function LoanHistory ({
    currentPath, // "CRC", "FIRST_CENTRAL", "CREDIT_REGISTRY"
    loanHistory
}) {
    const [displayData, setDisplayData] = useState([]);
    const [viewMore, setViewMore] = useState(null);


    useEffect(()=>{
        setDisplayData(loanHistory);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath]);

    const handleChange = (e) => {
        const filteredData = loanHistory?.filter((item) => item?.loanProvider?.toLowerCase().includes(e.target.value.toLowerCase()) );
        setDisplayData(filteredData);
    }

    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Loan History"}
                data={displayData}
                hideSearch={false}
                trClassName={"relative"}
                searchPlaceHolder={"Search loan provider"}
                onSearchChange={handleChange}
                tableClassName={
                    !!viewMore ? 
                        currentPath==="CREDIT_REGISTRY" ? "mb-[390px] xl:min-w-[1000px]": currentPath==="CRC" ? "mb-[320px] xl:min-w-[1000px]" : "mb-[250px] xl:min-w-[1000px]"
                        :
                        "xl:min-w-[1000px]"
                }
                rows={[
                    {
                        header:'Loan Provider',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.loanProvider || "Not Available"}
                            </p>
                        ),
                    },
                    {
                        header:'Loan Amount',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="FIRST_CENTRAL"?`₦${item?.loanAmount||0}`:currencyFormatter(item?.loanAmount||0)}
                            </p>
                        ),
                    },
                    {
                        header:'Outstanding Balance',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="FIRST_CENTRAL"?`₦${item.outstandingBalance||0}`:currencyFormatter(item.outstandingBalance||0)}
                            </p>
                        ),
                    },
                    {
                        header:'Overdue Amount',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="FIRST_CENTRAL"?`₦${item.overdueAmount||0}`:currencyFormatter(item.overdueAmount||0)}
                            </p>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {
                                    currentPath==="CRC" ?
                                    item?.loanStatus
                                    :
                                    (
                                        !!item?.accountStatus || !!item?.status ?
                                        <Status 
                                            type={
                                                currentPath==="FIRST_CENTRAL" ? 
                                                    (
                                                        item?.accountStatus==="Closed"||
                                                        item?.accountStatus==="CLOSED" ||
                                                        item?.status==="Closed" ||
                                                        item?.status==="CLOSED" ||
                                                        item?.status==="Paid up"
                                                    )?"success":"fail"
                                                : item?.status==="Closed"?"success":"fail"
                                            } 
                                            text={
                                                currentPath==="FIRST_CENTRAL" ? (item?.accountStatus||item?.status)
                                                : item?.status
                                            } 
                                        />
                                        :
                                        <span>Not available</span>
                                    )
                                }
                            </p>
                        ),
                    },
                    {
                        header:'Date Reported',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {currentPath==="CREDIT_REGISTRY"?dateFormatter(item.dateReported, 'dd-M-yyyy'):(item.dateReported||"Not available")}
                            </p>
                        ),
                    },
                    {
                        header:'',
                        view: (item) => (
                            <div>
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setViewMore(null)
                                    }}
                                >
                                    <img 
                                        src={dropdown} 
                                        alt="dropdown" 
                                        className={clsx(
                                            "w-[30px] cursor-pointer mx-2 transition-all transform",
                                            viewMore===item && "rotate-180"
                                        )} 
                                        onClick={()=>{
                                            viewMore===item?setViewMore(null):setViewMore(item);
                                        }}
                                    />
                                    {
                                        viewMore===item &&
                                        <div className="shadow-lg grid grid-cols-4 absolute left-[10px] right-[10px] top-[55px] rounded-[4px] z-10 border border-solid border-emerald-600 bg-white px-2.5 pt-2.5">
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Provider</p>
                                                <p className="text-cc-black-3">{item?.loanProvider||"Not available"}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Account Number</p>
                                                <p className="text-cc-black-3">{item?.accountNumber||"Not available"}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Account Type</p>
                                                <p className="text-cc-black-3">{item?.type || "Not Available"}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Amount</p>
                                                <p className="text-cc-black-3">{currentPath==="FIRST_CENTRAL"?`₦${item?.loanAmount||0}`:currencyFormatter(item.loanAmount||0)}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Installment Amount</p>
                                                <p className="text-cc-black-3">{currentPath==="FIRST_CENTRAL"?`₦${item?.installmentAmount||0}`:currencyFormatter(item.installmentAmount||0)}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Outstanding Balance</p>
                                                <p className="text-cc-black-3">{currentPath==="FIRST_CENTRAL"?`₦${item.outstandingBalance}`:currencyFormatter(item.outstandingBalance||0)}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Overdue Amount</p>
                                                <p className="text-cc-black-3">{currentPath==="FIRST_CENTRAL"?`₦${item.overdueAmount}`:currencyFormatter(item.overdueAmount||0)}</p>
                                            </div>
                                            {
                                                currentPath==="CRC" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Amount Written Off</p>
                                                    <p className="text-cc-black-3">{item?.amountWrittenOff||"None"}</p>
                                                </div>
                                            }
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Currency</p>
                                                <p className="text-cc-black-3">NGN</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Date Disbursed</p>
                                                <p className="text-cc-black-3">{currentPath==="CREDIT_REGISTRY"?dateFormatter(item.disbursedDate, 'dd-M-yyyy'):(item?.dateDisbursed||item?.disbursedDate||"Not available")}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Last Payment Date</p>
                                                <p className="text-cc-black-3">{currentPath==="CREDIT_REGISTRY"?dateFormatter(item.lastPaymentDate, 'dd-M-yyyy'):(item.lastPaymentDate||"Not available")}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Maturity Date</p>
                                                <p className="text-cc-black-3">{currentPath==="CREDIT_REGISTRY"?dateFormatter(item.maturityDate, 'dd-M-yyyy'):(item.maturityDate||item.expiryDate||"Not available")}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Duration</p>
                                                <p className="text-cc-black-3">{item?.loanDuration||"Not available"}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Date Reported</p>
                                                <p className="text-cc-black-3">{currentPath==="CREDIT_REGISTRY"?dateFormatter(item.dateReported, 'dd-M-yyyy'):(item.dateReported||"Not available")}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Account Status</p>
                                                {
                                                    currentPath==="CRC" ?
                                                    <p className="text-cc-black-3">{item?.loanStatus}</p>
                                                    :
                                                    (
                                                        !!item?.accountStatus || !!item?.status ?
                                                        <Status 
                                                            type={
                                                                currentPath==="FIRST_CENTRAL" ? 
                                                                    (
                                                                        item?.accountStatus==="Closed"||
                                                                        item?.accountStatus==="CLOSED" ||
                                                                        item?.status==="Closed" ||
                                                                        item?.status==="CLOSED" ||
                                                                        item?.status==="Paid up"
                                                                    )?"success":"fail"
                                                                : item?.status==="Closed"?"success":"fail"
                                                            } 
                                                            text={
                                                                currentPath==="FIRST_CENTRAL" ? (item?.accountStatus||item?.status)
                                                                : item?.status
                                                            } 
                                                        />
                                                        :
                                                        <p className="text-cc-black-3">Not available</p>
                                                    )
                                                }
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Performance Status</p>
                                                {
                                                    item.performanceStatus==="" ? 
                                                    "Not available"
                                                    :
                                                    <Status 
                                                        type={
                                                            item?.performanceStatus==="Performing" || item?.performanceStatus?.includes("Closed") ?
                                                            "success":"fail"
                                                        } 
                                                        text={item?.performanceStatus}
                                                    />
                                                }
                                            </div>
                                            {
                                                currentPath==="CRC" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">No of Overdue Days</p>
                                                    <p className="text-cc-black-3">{item?.numberOfOverdueDays||0}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Guarantor</p>
                                                    <p className="text-cc-black-3">{item.guarantor||"None"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Loan Provider Address</p>
                                                    <p className="text-cc-black-3">{item.loanProviderAddress}</p>
                                                </div>
                                            }
                                            {
                                                currentPath!=="FIRST_CENTRAL" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Collateral</p>
                                                    <p className="text-cc-black-3">{item?.collateral||"None"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Collateral Value</p>
                                                    <p className="text-cc-black-3">{currencyFormatter(item?.collateralValue||0)}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Purpose</p>
                                                    <p className="text-cc-black-3">{item?.purpose||"Not available"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="FIRST_CENTRAL" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Repayment Frequency</p>
                                                    <p className="text-cc-black-3">{item?.repaymentFrequency||"Not available"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Last Updated On</p>
                                                    <p className="text-cc-black-3">{dateFormatter(item?.lastUpdatedAt, 'dd-M-yyyy')}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CRC" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Rankings</p>
                                                    <p className="text-cc-black-3">{item?.rankings||"Not available"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CRC" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Reason</p>
                                                    <p className="text-cc-black-3">{item?.reason||"Not available"}</p>
                                                </div>
                                            }
                                        </div>
                                    }
                                </OutsideClickHandler>
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}