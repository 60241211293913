import { useState } from "react";
import { toast} from "react-toastify";
import { Modal } from "react-bootstrap";
import { createSlackChannel, inviteUser } from "../../../../lib/slack";
import { logToBin } from "../../../../lib/logs";
import { AuthService } from "services/auth.service";
import { WalletService } from "services/wallet.service";
import { useSelector, useDispatch } from "react-redux";
import { setLoginNotification } from "redux/login";
import { setWalletAlert } from "redux/wallet";
import {currencyFormatter} from "utils/helpers";

import slack from "../../../../images/svg/settings/slack.svg";
import product from "../../../../images/svg/settings/product.svg";
import login from "../../../../images/svg/settings/login.svg";
import metrics from "../../../../images/svg/settings/metrics.svg";
import wallet_ from "../../../../images/svg/settings/wallet.svg";
import thresholdIcon from "../../../../images/svg/settings/thresholdUpdate.svg";
import { Mixpanel } from "../../../../lib/mixpanel";
import SetWalletThreshold from "components/Modal/SetWalletThreshold";


function Notifications ({permissionValues}) {
    const [showThresholdModal, setShowThresholdmodal] = useState(false);
    const [slackInviteModal, setSlackInviteModal] = useState(false);
    const [inviteDetails, setInviteDetails] = useState({
        fullName: '',
        email: ''
    })
    const b2b_data = useSelector(state => state?.loginData?.data)
    const dispatch = useDispatch()
    const wallet = useSelector(state => state?.walletData?.wallet)

    let channelId = "";
    
    try{
        let slackData = sessionStorage.getItem('channelId');
        channelId = JSON.parse(slackData);
    } catch {}

    const handleInviteChange = (e) => {
        setInviteDetails({
            ...inviteDetails,
            [e.target.name]: e.target.value
        })
    }

    const changeLoginNotif = () => {
        Mixpanel.track(`Login notification toggle button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        })
        dispatch(setLoginNotification(!b2b_data?.user?.loginNotification))
        AuthService.loginNotifications(b2b_data?.user?.id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message);
                    dispatch(setLoginNotification(res.data.loginNotification))
                } else {
                    toast.error(res.message)
                    dispatch(setLoginNotification(!b2b_data?.user?.loginNotification))
                }
            })
            .catch((err) => {
                dispatch(setLoginNotification(!b2b_data?.user?.loginNotification))
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    
    const changeWalletNotif = () => {
        Mixpanel.track(`Wallet threshold toggle button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        })
        dispatch(setWalletAlert(!wallet?.alert))
        WalletService.walletNotifications()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message);
                    dispatch(setWalletAlert(res.data.alert))
                } else {
                    toast.error(res.message)
                    dispatch(setWalletAlert(!wallet?.alert))
                }
            })
            .catch((err) => {
                dispatch(setWalletAlert(!wallet?.alert))
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doSlackJoin = async () => {
        if(false){
        if(channelId==='' || channelId===null){
            let channelName = b2b_data?.businessName.replace(/\s/g, '_').toLowerCase()
            createSlackChannel(channelName)
                .then((response) => response.data )
                .then((resp) => {
                    if(resp.ok===true){
                        inviteFunc(resp.channel.id, b2b_data?.user?.email, b2b_data?.businessName)
                    } else {
                        toast.error('Could not join at this time, please try again later!')
                    }
                })
                .catch((error) => {
                });
        } else {
            setSlackInviteModal(true);
        }
        }
    }

    const doSlackInvite = () => {
        if(inviteDetails.fullName==='' || inviteDetails.email===''){
            toast.error('Please fill all fields!!!')
        } else {
            inviteFunc(channelId, inviteDetails.email, inviteDetails.fullName)
        }
    }

    const inviteFunc = (channel_id, email, fullName) => {
        inviteUser(channel_id, email, fullName)
            .then((response) => response.data )
            .then((resp) => {
                if(resp.ok===true){
                    toast.success('Check your email to see details to join slack!')
                }
            })
            .catch((error) => {
            })
        
    }


    return(
        <div className="settings_members-table settings_notifs">
            <div className="settings_members-table-data">
                <div className="settings_members-table-top">
                    <h3 className="text-[1.2em]">Notifications</h3>
                    {/* <div className="settings_roles-notifs" style={{marginRight:"30px", display:"flex"}}>
                        <h5 style={{margin:"3px 10px 0 0"}}>Enable all</h5>
                        <div className="button r button-2" >
                            <input type="checkbox" className="checkbox" />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                        </div>
                    </div> */}
                </div>
                <hr className="my-[15px]" />
                <div className="settings_roles">
                    <p className="settings_roles-default">Default roles</p>
                    <div>
                        <div className="settings_roles-item">
                            <div>
                                <img src={slack} alt="logo"  className="settings_roles-item-icon" />
                            </div>
                            <div className="settings_roles-item-text">
                                <p className="title">Partner's Slack</p>
                                <p className="desc">Keep up with CreditChek and get fast on-time Support on slack</p>
                            </div>
                            <button onClick={doSlackJoin}>Join Slack</button>
                        </div>
                        <hr className="my-[15px]"/>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-icon" style={{backgroundColor:"#E01E5A"}}>
                                <img src={wallet_} alt="logo"/>
                            </div>
                            <div className="settings_roles-item-text">
                                <p className="title">Wallet Threshold</p>
                                <p className="desc">
                                    Current threshold:{' '}
                                        {currencyFormatter(wallet.alert_limit)}<br/>
                                            <span 
                                                className={!permissionValues?.includes("edit")?"":'spanInactive'}
                                                onClick={()=> 
                                                    permissionValues?.includes("edit") ? setShowThresholdmodal(true) : toast.error("You are not authorized to perform this action!!!")
                                                }
                                            >
                                                Set Wallet Threshold
                                            </span>
                                </p>
                            </div>
                            <div className="settings_roles-notifs">
                                <div className="button r button-2">
                                    <input type="checkbox" className="checkbox" checked={wallet.alert} 
                                        onClick={()=> permissionValues?.includes("edit") ? changeWalletNotif() :
                                        toast.error("You are not authorized to perform this action!!!")
                                    } />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-[15px]"/>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-icon" style={{backgroundColor:"#2EB67D"}}>
                                <img src={login} alt="logo"  />
                            </div>
                            <div className="settings_roles-item-text">
                                <p className="title">Login Notifications</p>
                                <p className="desc">Receive Security alerts everytime your account is accessed</p>
                            </div>
                            <div className="settings_roles-notifs">
                                <div className="button r button-2">
                                    <input type="checkbox" className="checkbox" checked={b2b_data?.user?.loginNotification} 
                                    onClick={()=> permissionValues?.includes("edit") ? changeLoginNotif() :
                                        toast.error("You are not authorized to perform this action!!!")} />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-[15px]"/>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-icon" style={{backgroundColor:"#36C5F0"}}>
                                <img src={product} alt="logo"  />
                            </div>
                            <div className="settings_roles-item-text">
                                <p className="title">Product Notifications</p>
                                <p className="desc">Be the first to know about CreditChek products updates</p>
                            </div>
                            <div className="settings_roles-notifs">
                                <div className="button r button-2" onClick={()=> Mixpanel.track(`Product notification toggle button was clicked`, {
                                                                    name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                                    bussinessName: b2b_data?.businessName
                                                                }) }>
                                    <input type="checkbox" className="checkbox" checked={true} />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </div>
                            </div>
                        </div>
                        <hr className="my-[15px]"/>
                        <div className="settings_roles-item">
                            <div className="settings_roles-item-icon" style={{backgroundColor:"#0046E6"}}>
                                <img src={metrics} alt="logo"  />
                            </div>
                            <div className="settings_roles-item-text">
                                <p className="title">Key Metrics Report</p>
                                <p className="desc">Receive monthly performance metrics in your email</p>
                            </div>
                            <div className="settings_roles-notifs">
                                <div className="button r button-2" onClick={() => Mixpanel.track(`Key metrics report toggle button was clicked`, {
                                                                    name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                                    bussinessName: b2b_data?.businessName
                                                                })}>
                                    <input type="checkbox" className="checkbox" checked={true} />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* To create channel using channel name */}
            <Modal show={slackInviteModal} onHide={()=>setSlackInviteModal(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="settings_modals-threshold">
                    <img src={thresholdIcon} alt="" />
                    <h5>Invite To Slack</h5>
                    <p>Send an invite email to any of your team members to join your slack channel</p>
                    <div>
                        <label>Full Name</label><br/>
                        <input placeholder="Enter full name" type="text" name="fullName" onChange={handleInviteChange} />
                    </div>
                    <div>
                        <label>Email address</label><br/>
                        <input placeholder="Enter email address" type="email" name="email" onChange={handleInviteChange} />
                    </div>
                    <div>
                        <button className="btn1" onClick={()=>setSlackInviteModal(false)}>Cancel</button>
                        <button className="btn2" onClick={doSlackInvite}>Invite</button>
                    </div>
                </div>
            </Modal>
            <SetWalletThreshold
                show={showThresholdModal}
                onClose={()=>setShowThresholdmodal(false)}
                value={wallet?.alert_limit}
            />
        </div>
    )
}

export default Notifications;