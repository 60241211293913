import { useEffect, useState } from "react";
import InviteTeam from "./InviteTeam";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import SelectInput from "components/Form/SelectInput";
import WarningModal from "components/Modal/WarningModal";
import { AuthService } from "services/auth.service";
import { useSelector } from "react-redux";

import bin from "../../../../images/svg/settings/bin.svg";
import edit from "../../../../images/svg/settings/edit.svg";
import search from "../../../../images/svg/settings/search.svg";
import resend from "../../../../images/svg/settings/resend.svg";
import { logToBin } from "../../../../lib/logs";
import { Mixpanel } from "../../../../lib/mixpanel";
import { dateFormatter, timeFormatter } from "utils/helpers";
import ChangeEmailModal from "./components/ChangeEmailModal";

function Member ({permissionValues}) {
    const [show, setShow] = useState(false);
    const [allTeam, setAllTeam] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [oneUser, setOneUser] = useState({});
    const [newRole, setNewRole] = useState("")
    const [showWarning, setShowWarning] =useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
    const [showEmailChange, setShowEmailChange] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        doGetTeam()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const doGetTeam = () => {
        AuthService.getTeam(b2b_data?.user?.businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setAllTeam(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doEdit = () => {
        if (newRole===""){
            toast.error("Please select a role to change")
        } else {
            let payload = JSON.stringify({
                userId : oneUser.id,
                role : newRole==="Business Owner" ? "businessOwner"
                        : newRole==="Engineering"?'engineering'
                        : newRole==="Operations"?""
                        : newRole==="View only"?"viewOnly"
                        : newRole==="Product Manager"?"productManager"
                        : newRole
            })
            AuthService.changeMemberRole(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message)
                        setShowEdit(false)
                        doGetTeam()
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }
    }

    const doResend = (item) => {
        AuthService.resendInvite(item.id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doDelete = () => {
        AuthService.deleteInvite(b2b_data?.user?.id, oneUser.id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success("Account deleted successfully!!!")
                    setShowWarning(false);
                    doGetTeam()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div className="settings_members">
            <div className="settings_members-table">
                <div className="settings_members-table-data">
                    <div className="settings_members-table-top">
                        <h3 className="text-[1.2em]">Team</h3>
                        <div className="settings_members-table-top-btn">
                            <div className="settings_members-table-top-btn-form">
                                <input type="text" placeholder="Find a member" />
                                <img src={search} alt="search" />
                            </div>
                            <button 
                                className={permissionValues?.includes("edit")?"":"settings_members-table-top-btn-btnInactive"}
                                onClick={()=>{ 
                                    Mixpanel.track(`Invite a member button was clicked`, {
                                        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                        bussinessName: b2b_data?.businessName
                                    });
                                    permissionValues?.includes("edit") ? setShow(true) : toast.error("You are not authorized to perform this action!!!")
                                }}
                            >Invite a member</button>
                        </div>
                    </div>
                    <hr className="my-[15px]" />
                    <div  className="settings_members-table-main">
                        <table>
                            <thead>
                                <tr>
                                    <th>MEMBER</th>
                                    <th>ROLE</th>
                                    <th>LAST LOG IN</th>
                                    <th>STATUS</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allTeam.map((item, idx)=>(
                                        <tr key={idx}>
                                            <td className="settings_members-table-main-name">
                                                <p>
                                                    {
                                                        idx===0 ? b2b_data?.businessName
                                                        :`${item.firstName===undefined?b2b_data?.businessName:item.firstName} ${item.lastName===undefined?"":item.lastName}`
                                                    }
                                                    {b2b_data?.user?.email===item.email && <span className="me">You</span>}<br/>
                                                    <span className="mail">{item.email}</span>
                                                </p>
                                            </td>
                                            <td style={{textTransform:"capitalize"}}>{item.role}</td>
                                            <td>{item.lastLoggedIn?`${dateFormatter(item.lastLoggedIn, 'dd/mm/yyyy')}, ${timeFormatter(item?.lastLoggedIn)}`:"Not yet logged in"}</td>
                                            <td className={item.isActive?"settings_members-table-main-status":"settings_members-table-main-pending"}>{item.isActive?"Active":"Pending"}</td>
                                            <td className="settings_members-table-main-action" style={{width:"330px"}}>
                                                <div className="flex">
                                                    {
                                                        (b2b_data?.user?.role==="businessOwner" && item.id===b2b_data?.user?.id) &&
                                                        <button className="flex items-center" onClick={()=>{setOneUser(item);setShowEmailChange(true);}}>
                                                            <img src={edit} alt="edit" />
                                                            <span>Change Email</span>
                                                        </button>
                                                    }
                                                    {
                                                        (b2b_data?.user?.email!==item.email && idx!==0) &&
                                                        <>
                                                            <button className="flex items-center" onClick={()=>{setOneUser(item);setShowEdit(true);}}>
                                                                <img src={edit} alt="edit" />
                                                                <span>Change Roles</span>
                                                            </button>
                                                            {
                                                                ((b2b_data?.user?.role==="businessOwner"||b2b_data?.user?.role==="productManager") && item.role!=="businessOwner") &&
                                                                <button onClick={()=>{setOneUser(item);setShowWarning(true);}}>
                                                                    <img src={bin} alt="delete" />
                                                                </button>
                                                            }
                                                            <button className="flex items-center" onClick={()=>doResend(item)}>
                                                                <img src={resend} alt="edit" />
                                                                <span>Resend Invite</span>
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            
                        </table>
                    </div>
                </div>
            </div>
            <WarningModal show={showWarning} type={'delete-member'} handleClose={()=>setShowWarning(false)} acceptAction={()=>doDelete()} />
            <InviteTeam show={show} handleClose={()=>{handleClose();doGetTeam();}} handleShow={handleShow}/>
            <Modal show={showEdit} onHide={()=>setShowEdit(false)}>
                <Modal.Body>
                    <Modal.Title>Change User Role</Modal.Title>
                    <div className="settings-invite-mail">
                        <label>Choose Role</label>
                        <SelectInput
                            placeholder={oneUser.role}
                            options={["Business Owner", "Engineering", "Operations", "View only", "Product Manager"]}
                            selectChange={(item)=>setNewRole(item)}
                        />
                    </div>
                    <div className="settings-invite-action">
                        {/* <button className="cancel" onClick={()=>doDelete()}>Delete</button> */}
                        <button className="publish" onClick={()=>doEdit()}>Change Role</button>
                    </div>
                </Modal.Body>
            </Modal>
            <ChangeEmailModal show={showEmailChange} handleClose={()=>{doGetTeam(); setShowEmailChange(false)}} user={oneUser} />
        </div>
    )
}

export default Member;