import {Routes, Route} from "react-router-dom";
import NewRequest from "./NewRequest";
import FloatMain from "./FloatMain";
import RepaymentSchedule from "./Details/RepaymentSchedule";
import OfferDetails from "./Details/OfferDetails";
import Patch from "./Patch";
import StatusTable from "./Patch/StatusTable";


function Float () {
    return(
        <div>
            <Routes>
                <Route path="/*" element={<FloatMain/>} />
                <Route path="/new-request/*" element={<NewRequest/>} />
                <Route path="/repayment-schedule" element={<RepaymentSchedule />} />
                <Route path="/offer-details" element={<OfferDetails />} />
                <Route path="/:floatId/patch" element={<Patch />} />
                <Route path="/patch-status/:floatId" element={<StatusTable />} />
            </Routes>
        </div>
    )
}

export default Float;