import { useEffect, useState } from "react";
import {Row, Col, Modal} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Back from '../../../components/shared/back.js';
import clsx from "clsx";
import { currencyFormatter, getInitials, dateFormatter, timeFormatter } from "utils/helpers.js";
import { spectrumPDF } from "lib/pdfGenerate/spectrum.js";

import info from "../../../images/svg/identity/info.svg";
import importIcon from "../../../images/svg/spectrum/import.svg";
import down from "../../../images/svg/spectrum/chevron-down.svg";
import view from "../../../images/svg/spectrum/view.svg";
import version from "../../../images/svg/spectrum/version.svg";
import performingAcc from "../../../images/svg/spectrum/performing_account.svg";
import delinquentAcc from "../../../images/svg/spectrum/delinquent_account.svg";
import closedAcc from "../../../images/svg/spectrum/closed_account.svg";
import nosummary from "../../../images/svg/spectrum/no-summary.svg";


export default function Details () {
    let params = useParams();
    let navigate = useNavigate();
    const [showVersion, setShowVersion] = useState("current");
    const [versionData, setVersionData] = useState("current")
    const [showCreditOption, setShowCreditOption] = useState(false);
    const [showModal, setShowModal] = useState(false);


    let data = {};

    try{
        data = JSON.parse(sessionStorage.getItem('spectrumDetails'));
    }
    catch(err){
        // err
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        setVersionData(data?.loans?.[data?.loans?.length - 1])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => setShowModal(false);

    const creditEnqs = data?.creditEnquiries[data?.creditEnquiries?.length - 1]?.data?.creditEnquiries;
    
    return(
        <div>
            <Back to={()=>navigate('/spectrum')} />
            <div className="identity_result">
                <div className="identity_result-top">
                    <div className="bg-white w-[130px] h-[130px] mr-[20px] shrink-0 overflow-hidden rounded-full flex items-center justify-center md:w-[60px] md:h-[60px]">
                        <h1 className="text-[3.5em] md:text-[2em]">{getInitials(data?.name)}</h1>
                    </div>
                    <div className="spectrum_detail-top">
                        <div className="spectrum_detail-top-title">
                            <div>
                                <h5>{data?.name}</h5>
                                <p>{params.type==="individual"?"BVN#":"RC/BN#"} : {params.type==="individual"? data?.BVN : data?.regNo}</p>
                            </div>
                            <div className="spectrum_detail-top-title-action">
                                <button className="flex items-center"  onClick={()=>spectrumPDF(data, params.type)}><img src={importIcon} alt=""/><span>Export PDF</span></button>
                                <img src={info} onClick={()=>setShowCreditOption(!showCreditOption)} alt="" className="identity_result-top_button" />
                            </div>
                            {
                                showCreditOption &&
                                <div className="spectrum_detail-top-title-option">
                                    <p onClick={()=>setShowModal(true)} className="flex items-center"><img src={view} alt="" /><span>Credit Inquiry History</span></p>
                                </div>
                            }
                        </div>
                        <Row>
                            <Col lg={8}>
                                <p>Created by : {data?.loans[0]?.data?.lastUpdatedBy} @ {dateFormatter(data?.loans[0]?.metadata?.txTime)}, {timeFormatter(data?.loans[0]?.metadata?.txTime)}</p>
                                <p>Last Update : {data?.loans[data.loans.length-1]?.data?.lastUpdatedBy} @ {dateFormatter(data?.loans[data.loans.length-1]?.metadata?.txTime)}, {timeFormatter(data?.loans[data.loans.length-1]?.metadata?.txTime)}</p>
                            </Col>
                            <Col lg={4}>
                                <p>Number of Credit Providers : {data?.creditors}</p>
                                {/* <p>Number of Loans: {merged()?.length}</p> */}
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="spectrum_detail-data">
                    <Row>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">{params.type==="individual"?"NIN":"RC/BN#"}</p>
                                <p className="text2">{params.type==="individual"? data?.NIN===""?'Not available':data?.NIN : data?.regNo}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">{params.type==="individual"?"Date of Birth":"Registered on"}</p>
                                <p className="text2">{params.type==="individual"? data?.dateOfBirth===""?'Not available':data?.dateOfBirth : data?.dateOfRegistration===""?'Not available':data?.dateOfRegistration}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">{params.type==="individual"?"Gender":"Industry"}</p>
                                <p className="text2">{params.type==="individual"? data?.gender===""?'Not available':data?.gender : data?.industry===""?'Not available':data?.industry}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">{params.type==="business" ? 'Corporate Address':'Residential Address'}</p>
                                <p className="text2">{data?.address===""?'Not available':data?.address}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">Ledger ID</p>
                                <p className="text-cc-green-7">{data?.ledgerId||'Not available'}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4} xs={6}>
                            <div className="identity_result-box1">
                                <p className="text1">Phone Number</p>
                                <p className="text2">{data?.phoneNumber?.length===0?'Not available':data?.phoneNumber[0]}</p>
                            </div>
                        </Col>
                        <Col lg={3} md={4}>
                            <div className="identity_result-box1">
                                <p className="text1">Email</p>
                                <p className="text-cc-blue-8 lowercase">{data?.email?.length===0?'Not available':data?.email[0]}</p>
                            </div>
                        </Col>
                        {
                            params.type==="business" &&
                            <>
                                <Col lg={3} md={4} xs={6}>
                                    <div className="identity_result-box1">
                                        <p className="text1">Business Type</p>
                                        <p className="text2">{data?.businessType || 'Not available'}</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} xs={6}>
                                    <div className="identity_result-box1">
                                        <p className="text1">Industry</p>
                                        <p className="text2">{data?.industry||'Not Available'}</p>
                                    </div>
                                </Col>
                                <Col lg={3} md={4} xs={6}>
                                    <div className="identity_result-box1">
                                        <p className="text1">Website</p>
                                        <p className="text2">{data?.website||'Not Available'}</p>
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </div>
                {/* <hr className="spectrum_detail-line" />
                <div className="spectrum_detail-mix" >
                    <div className="spectrum_detail-mix-title flex items-center">
                        <img src={invertedArrows} alt="" />
                        <p className="text-[14px] font-medium">CreditMix...so far 😍</p>
                    </div>
                    <div className="spectrum_detail-mix-items">
                        {
                            creditMix?.map((item, idx)=>(
                                <button className='norm' key={idx} >
                                    <div></div>{item}
                                </button>
                            ))
                        }
                    </div>
                </div> */}
                <hr className="spectrum_detail-line" />
                <div>
                    <div className="spectrum_detail-info mb-4">
                        <div>
                            <h4 className="text-[18px] font-semibold m-0 text-[24px]">Credit History Information</h4>
                            <p style={{color:'#475467'}}>{params.type==="individual"?'Individual':'Business'} debt profile breakdown</p>
                        </div>
                    </div>
                    <div className="spectrum_detail-history gap-x-6">
                        <div className="w-[200px]">
                            <button type="button" className="flex items-center text-white rounded-tl-[12px] rounded-br-[12px] py-1 px-2.5 h-10 font-semibold bg-cc-primary"><img src={version} alt=""/><span>Version History</span></button>
                            <div>
                                <p className={clsx(
                                        showVersion==='current'?'text-cc-grey-4 bg-cc-grey-14':'text-cc-black-4',
                                        "m-0 py-[7px] px-2.5 cursor-pointer"
                                    )} 
                                    onClick={()=>{setShowVersion('current'); ; setVersionData(data?.loans?.[data?.loans?.length-1]);}}
                                >Current</p>
                                {
                                    data.loans.length>1 &&
                                    <>
                                        <p className="text-cc-black-4 m-0 py-[7px] px-2.5 cursor-pointer">Previous <img src={down} alt="" className="-mt-0.5 float-right" /></p>
                                        <div>
                                            {
                                                data?.loans?.map((item, index)=>(
                                                    (index+1)!==data?.loans?.length && 
                                                    <p className={clsx(
                                                            showVersion===item?.metadata?.version?'text-cc-grey-4 bg-cc-grey-14':'text-cc-black-4',
                                                            "m-0 py-[7px] px-2.5 cursor-pointer text-sm"
                                                        )} 
                                                        onClick={()=>{setShowVersion(item?.metadata?.version); setVersionData(data?.loans?.[item?.metadata?.version]);}} 
                                                        key={index}
                                                    >
                                                        {dateFormatter(item?.metadata?.txTime, 'MMM dd')}, {timeFormatter(item?.metadata?.txTime)} <br/>
                                                        <span className="text-cc-grey-15 text-[12.5px] -pt-1" >By {item.data.lastUpdatedBy}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="w-full">
                            <div>
                                <div className="flex items-center">
                                    <img src={performingAcc} alt="performing account" className="w-[30px] rotate-180" />
                                    <h3 className="text-[18px] font-bold ml-1">Performing Accounts</h3>
                                </div>
                                <div>
                                    {
                                        versionData?.data?.performingAccounts?.last7days!==undefined ?
                                        <LoanAccount data={versionData?.data?.performingAccounts} />
                                        :
                                        <EmptyAccount type="Performing" />
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <img src={delinquentAcc} alt="delinquent account" className="w-[30px]" />
                                    <h3 className="text-[18px] font-bold ml-1">Delinquent Accounts</h3>
                                </div>
                                <div>
                                    {
                                        versionData?.data?.delinquentAccounts?.last7days!==undefined ?
                                        <LoanAccount data={versionData?.data?.delinquentAccounts} />
                                        :
                                        <EmptyAccount type="Delinquent"/>
                                    }
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <img src={closedAcc} alt="closed account" className="w-[30px]" />
                                    <h3 className="text-[18px] font-bold ml-1">Closed Accounts</h3>
                                </div>
                                <div>
                                    {
                                        versionData?.data?.closedAccounts?.last7days!==undefined ?
                                        <LoanAccount data={versionData?.data?.closedAccounts} />
                                        :
                                        <EmptyAccount type="Closed"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} size="md" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
                <div className="spectrum_detail-modal">
                    <h4 className="text-center font-semibold mb-3">Enquiries in the Last 12 Months</h4>
                    <hr/>
                    { 
                        data?.creditEnquiries?.length>0 ?
                        <div className="grid grid-cols-2 gap-x-5 gap-y-5 p-[20px]">
                            <div>
                                <p className="text-cc-grey-4 text-sm">All Time</p>
                                <p className="text-cc-black-4 text-base">{creditEnqs?.all_time||0}</p>
                            </div>
                            <div>
                                <p className="text-cc-grey-4 text-sm">Last 7 days</p>
                                <p className="text-cc-black-4 text-base">{creditEnqs?.last7days||0}</p>
                            </div>
                            <div>
                                <p className="text-cc-grey-4 text-sm">Last 30 days</p>
                                <p className="text-cc-black-4 text-base">{creditEnqs?.last30days||0}</p>
                            </div>
                            <div>
                                <p className="text-cc-grey-4 text-sm">Last 90 days</p>
                                <p className="text-cc-black-4 text-base">{creditEnqs?.last90days||0}</p>
                            </div>
                            <div>
                                <p className="text-cc-grey-4 text-sm">First Enquiry Date</p>
                                <p className="text-cc-black-4 text-base">{dateFormatter(creditEnqs?.firstEnquiryDate)}, {timeFormatter(creditEnqs?.firstEnquiryDate)}</p>
                            </div>
                            <div>
                                <p className="text-cc-grey-4 text-sm">Last Enquiry Date</p>
                                <p className="text-cc-black-4 text-base">{dateFormatter(creditEnqs?.lastEnquiryDate)}, {timeFormatter(creditEnqs?.lastEnquiryDate)}</p>
                            </div>
                        </div>
                        :
                        <p className="my-3 text-center">No Credit Enquiries made</p>
                    }
                </div>
            </Modal>
        </div>
    )
}


const EmptyAccount = ({type}) => {
    let today = new Date();
    return(
        <div className="py-[30px] px-[20px] mt-[10px] mb-[40px] shadow-xl flex items-center">
            <img src={nosummary} alt="warning" className="w-[28px] mr-1.5" />
            <div>
                <p className="text-cc-grey-4 opacity-60 text-sm">{type} Account Summary is empty</p>
                <p className="text-cc-black-4 text-base font-medium">{dateFormatter(today, 'dd-mm-yyyy')}, {timeFormatter(today)}</p>
            </div>
        </div>
    )
}

const LoanAccount = ({data}) => {
    return(
        <div className="pt-[30px] mt-[10px] mb-[40px] shadow-xl">
            <div className="px-[20px] grid grid-cols-4 gap-x-5 gap-y-5">
                <div>
                    <p className="text-cc-grey-4 text-sm">Last 7 days</p>
                    <p className="text-cc-black-4 text-base">{data?.last7days||0}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">All Time</p>
                    <p className="text-cc-black-4 text-base">{data?.all_time||0}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Total Outstanding Balance</p>
                    <p className="text-cc-black-4 text-base">{currencyFormatter(data?.totalOutstandingBalance||0)}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Last Updated At</p>
                    <p className="text-cc-black-4 text-base">{dateFormatter(data?.lastUpdatedAt)}, {timeFormatter(data?.lastUpdatedAt)}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Last 30 days</p>
                    <p className="text-cc-black-4 text-base">{data?.last30days||0}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Date First Reported</p>
                    <p className="text-cc-black-4 text-base">{dateFormatter(data?.dateFirstReported)}, {timeFormatter(data?.dateFirstReported)}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Total Loan Amount</p>
                    <p className="text-cc-black-4 text-base">{currencyFormatter(data?.totalLoanAmount||0)}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Currency</p>
                    <p className="text-cc-black-4 text-base">{data?.currency||"NGN"}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Last 90 days</p>
                    <p className="text-cc-black-4 text-base">{data?.last90days||0}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Guarantor</p>
                    <p className="text-cc-black-4 text-base">{data?.guarantors||"None"}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Total Amount Arrears</p>
                    <p className="text-cc-black-4 text-base">{currencyFormatter(data?.totalAmountInArrears||0)}</p>
                </div>
                <div>
                    <p className="text-cc-grey-4 text-sm">Performance Status</p>
                    <p className="text-cc-black-4 text-base">{data.performanceStatus}</p>
                </div>
            </div>
            <div className="bg-cc-grey-16 mt-[10px] px-[20px] pt-[10px] pb-[30px]">
                <h4 className="text-cc-blue-8 text-base font-bold mb-2.5">Repayment History</h4>
                <div className="grid grid-cols-4 gap-x-5 gap-y-5">
                    <div>
                        <p className="text-cc-grey-4 text-sm">Last 7 days</p>
                        <p className="text-cc-black-4 text-base">{data?.repaymentHistory?.last7days||0}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">Last 30 days</p>
                        <p className="text-cc-black-4 text-base">{data?.repaymentHistory?.last30days||0}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">Last 90 days</p>
                        <p className="text-cc-black-4 text-base">{data?.repaymentHistory?.last90days||0}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">All Time</p>
                        <p className="text-cc-black-4 text-base">{data?.repaymentHistory?.all_time||0}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">Total Paid</p>
                        <p className="text-cc-black-4 text-base">{currencyFormatter(data?.repaymentHistory?.total_repaid||0)}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">First Repayment Date</p>
                        <p className="text-cc-black-4 text-base">{dateFormatter(data?.repaymentHistory?.firstRepaymentDate)}, {timeFormatter(data?.repaymentHistory?.firstRepaymentDate)}</p>
                    </div>
                    <div>
                        <p className="text-cc-grey-4 text-sm">Last Repayment Date</p>
                        <p className="text-cc-black-4 text-base">{data?.repaymentHistory?.lastRepaymentDate!=="1970-01-01T00:00:00.000Z" ? `${dateFormatter(data?.repaymentHistory?.lastRepaymentDate)}, ${timeFormatter(data?.repaymentHistory?.lastRepaymentDate)}`:"---"}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}