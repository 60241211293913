import secure from "images/svg/secure.svg";
import privateX from "images/svg/private.svg";
import protection from "images/svg/protection.svg";
import logo from "images/logos/white-logo.svg";
import Button from "components/shared/Button";
import { useEffect, useState } from "react";
import { handleRequestErrors } from "utils/helpers";
import { AuthService } from "services/auth.service";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import logoPlaceholder from "images/logos/logo-notext.svg";
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";


export default function DirectDebitHome () {
    let params = useParams();
    const [data, setData] = useState(null)
    let history = useNavigate()
    const [searchParams] = useSearchParams();
    const [mandate, setMandate] = useState(null)

    useEffect(()=>{
        AuthService.getBusinessById(JSON.stringify({businessId: params.businessId}))
            .then((response) => response.data)
            .then((res) => {
                if (res.success) {
                    setData(res.data);
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            })
        getMandate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getMandate = () => {
        RecovaService.getMandateById(params.mandateId)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if(res.status===true){
                    setMandate(res?.data?.mandate)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            })
    }

    return (
        <div className="bg-cc-primary h-full text-white py-10 px-4 bg-[url('./images/svg/sidebar/bg-image.svg')]">
            <div className="flex gap-x-6 items-center">
                <h4 className="text-[30px] font-bold">Setup a Direct Debit mandate with {data?.businessName}</h4>
                <div className="w-[80px] h-[80px] shrink-0 overflow-hidden rounded-full bg-slate-100 flex items-center justify-center">
                    <img src={data?.logo || logoPlaceholder} alt="logo" className="w-full"  />
                </div>
            </div>
            <div className="flex items-start gap-x-2.5 mt-8">
                <img src={secure} alt="secure" className="w-[20px]" />
                <div>
                    <h5 className="font-bold text-[20px]">Secure</h5>
                    <p className="font-medium text-[17px]">Your information is encrypted using bank grade security</p>
                </div>
            </div>
            <div className="flex items-start gap-x-2.5 mt-6">
                <img src={privateX} alt="privateX" className="w-[20px]" />
                <div>
                    <h5 className="font-bold text-[20px]">Private</h5>
                    <p className="font-medium text-[17px]">Your credentials will never be made accessable to {data?.businessName}</p>
                </div>
            </div>
            <div className="flex items-start gap-x-2.5 mt-6">
                <img src={protection} alt="protection" className="w-[20px]" />
                <div>
                    <h5 className="font-bold text-[20px]">Protection</h5>
                    <p className="font-medium text-[17px]">Neither {data?.businessName} nor Creditchek have access to move your funds without your consent</p>
                </div>
            </div>
            <div>
                <p className="text-xs text-center mt-[60px] mb-4">By clicking on "Proceed" you agree to creditchek privacy policy</p>
                <Button
                    isValid={true}
                    type="button"
                    text="Proceed"
                    onClick={()=>{
                        if(mandate?.status==="deactivated"){
                            toast.info(`You can't proceed because this mandate has been deactivated. Please contact ${data?.businessName} for more information.`)
                        } else {
                            history(`/customer/direct-debit/repayment/${params.mandateId}/${params.businessId}?appId=${searchParams.get("appId")}&account=${searchParams?.get("account")||"primary"}`)
                        }
                    }}
                    className="bg-white !text-cc-primary font-semibold"
                />
                {
                    mandate?.status==="deactivated" &&
                    <p className="text-red-600 text-xs mt-2.5">Please take note taht this mandate has been deactivated and you cannot proceed, contact {data?.businessName} for more information.</p>
                }
                <div className="flex items-center justify-center mt-10 gap-x-1.5">
                    <p className="text-xs font-bold">Powered by</p>
                    <img src={logo} alt="logo" className="w-[90px]" />
                </div>
            </div>
        </div>
    )
}