import {useState, useEffect} from "react";
import tableIcon from "../../../../images/svg/loan-performance.svg";
import Table from "components/Table";


export default function EmploymentHistory ({data}) {
    const [displayData, setDisplayData] = useState([]);


    useEffect(()=>{ 
        if(data?.length > 0){
            setDisplayData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Employment History"}
                data={displayData}
                hideSearch={true}
                tableClassName="min-w-[800px]"
                rows={[
                    {
                        header:'Employer Name',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.employerName || "Not available"}
                            </p>
                        ),
                    },
                    {
                        header:'Position',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.position || "Not available"}
                            </p>
                        ),
                    },
                    {
                        header:'Address',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{item?.address || "Not available"}</p>
                        ),
                    },
                    {
                        header:'Date Reported',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">{item?.dateReported || "Not available"}</p>
                        ),
                    },
                ]}
            />
        </div>
    )
}