import { useState, useEffect } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


export default function DriversLicense ({permissionValues}) {
  let history = useNavigate();
  const [data, setData] = useState({
    drivers_license: '',
    dob: '',
    first_name: '',
    last_name: ''
  })
  const [idPrice, setIdPrice] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [disableAction, setDisableAction] = useState(false);
  const b2b_data = useSelector(state => state?.loginData?.data)

  const handleSubmit = () => {
    if(permissionValues?.includes("edit")){
      Mixpanel.track(`Identity Insight (Verify Drivers License) button was clicked`, {
      name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
      bussinessName: b2b_data?.businessName
    });
      if(data.drivers_license==="" || data.dob==="" || data.first_name==="" || data.last_name===""){
        toast.error("No empty fields allowed!")
      } else {
        setShowModal(true);
      }
    }else{
      toast.error("You are not authorized to perform this action!!!")
    }
  }

  useEffect(() => {
    let payload = {
      action: "drivers id verification"
    }
    BillingService.getBillingPricing(payload)
    .then(response=> response.data)
    .then(res => {
        if(res?.success === true){
          setIdPrice(res?.data?.price)
        } else {
            toast.error(res.message)
        }
    })
    .catch((err) => {
        if(err.response){
          logToBin(err.response.data, window.location.href);
            // toast.error(err.response.data.error.message);
        } else{
          if(err.message === "timeout of 15000ms exceeded"){
              toast.error('Request timed out, please try again!')
          } else if (err.message==="Network Error"){
              toast.error('Network error, please make sure you are connected to the internet!')
          } else{
              toast.error("Your request can't be processed at this time, please try again later!");
          }
        }
    });
  }, [])

  const doSubmit = () => {
      Mixpanel.track(`Identity Insight (Driver's linsene verification) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
        bussinessName: b2b_data?.businessName
      });
      IdentityService.doLicenseVerification(data)
          .then((response) => response.data)
          .then((res) => {
              setShowModal(false)
              if (res.status === true) {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                toast.success("Request Successful!!!")
                history(`/identity/license-verification`)
              } else {
                  toast.error(res.message)
                  setDisableAction(false)
              }
          })
          .catch((err) => {
              setShowModal(false)
              setDisableAction(false)
              if(err.response){
                logToBin(err.response.data, window.location.href);
                  toast.error(err.response.data.message);
              } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
              }
          });
  }

  const handleLicenseChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
    console.log(data)
  }

  return(
    <div className="box-border">
      <div className="settings_profile-form w-[400px] md:w-full md_min:mb-0 mb-[30px]">
        <label>First Name</label><br/>
        <input className="apps_create-input" type="text" placeholder="First Name" name="first_name" onChange={handleLicenseChange}/><br/>
        <label>Last Name</label><br/>
        <input className="apps_create-input" type="text" placeholder="Last Name" name="last_name" onChange={handleLicenseChange}/><br/>
        <label>License Number</label><br/>
        <input className="apps_create-input" type="text" placeholder="License Number" name="drivers_license" onChange={handleLicenseChange}/><br/>
        <label>Date of Birth</label><br/>
        <input className="apps_create-input" type="date" placeholder="YYYY-MM-DD" name="dob" onChange={handleLicenseChange}/><br/>
        <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify License</button>
      </div>
      <ConfirmAction
        show={showModal}
        onCancel={()=>setShowModal(false)}
        onAction={()=>{
          doSubmit()
          setDisableAction(true);
        }}
        loading={disableAction}
        title={`Driver's License Verification`}
        content={`Using this service would attract a charge of ₦${idPrice}.`}
      />
    </div>
  )
}