import React from 'react'
import arrow from "images/svg/recova/arrow.svg"

const SetupBox = ({title, linkText, icon, onClick, content }) => {
  return (
    <div onClick={onClick} className='max-w-[400px]'>
      <h1 className='text-xl font-bold'>{title}</h1>
      <div className='flex'>
          <p 
            className="text-xs font-bold mr-1.5 cursor-pointer text-cc-primary"
          >
            {linkText}
          </p>
          <img src={arrow} alt="arrow icon" />
      </div>
      <div className='relative'>
        <div className='p-[20px] shadow-md mx-[20px] bg-white rounded-[10px]'>
          <img src={icon} alt="icon" className="w-10 h-10" />
          <div>{content}</div>
        </div>
        <div className='w-full absolute -z-10 -bottom-10 h-[200px] bg-[#E0E9FF] rounded-[10px] shadow-lg'/>
      </div>
    </div>
  )
}

export default SetupBox