import clsx from "clsx";
import Button from "components/shared/Button";
import { dateFormatter } from "utils/helpers";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { AuthService } from "services/auth.service";
import {useNavigate} from "react-router-dom";
import { useState } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { Tooltip } from 'react-tooltip';

import webhookIcon from "images/svg/apps/webhooks.svg";


export default function WidgetSettings ({data, fetchApp}) {
    const navigate = useNavigate();
    const [showUnlink, setShowUnlink] = useState(false)

    const changeSDKStatus = (widgetId) => {
        AuthService.toggleWidgetStatus(widgetId)
            .then((response) => response.data)
            .then((res) => {
                if (res.success===true) {
                    toast.success(res.message);
                    fetchApp();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const changeWebhookStatus = (url) => {
        const payload = JSON.stringify({
            url,
        })
        AuthService.toggleWebhookStatus(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success('Event status edited!');
                    fetchApp();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const unlinkSDK = () => {
        AuthService.unlinkWidget(data?.widget?._id)
            .then((response) => response.data)
            .then((res) => {
                setShowUnlink(false)
                if (res.success===true) {
                    toast.success(res.message);
                    fetchApp();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return (
        <div>
            <div className="shadow-[rgba(0,0,0,0.24)_0px_3px_8px] box-border rounded-[10px] py-10 px-[20px] mb-[30px]">
                <div className="grid grid-cols-3 gap-4 xl:grid-cols-2 sm:!grid-cols-1">
                    <div>
                        <p className="font-bold text-sm">
                            SDK Linked:
                            <span
                                className={clsx(
                                    "ml-2",
                                    data?.widget?.createdAt ? "text-cc-green-8":"text-cc-red-11",
                                )}
                            >{data?.widget?.createdAt ? 'YES':'NO'}
                            </span>
                        </p>
                        <div className="flex items-center gap-x-2 mt-2">
                            <p className="text-cc-black-4 font-medium text-xs">{data?.widget?.createdAt && `Linked on ${dateFormatter(data?.widget?.createdAt, 'MMM dd, yyyy')}`}</p>
                            <Button
                                isValid={true}
                                type="button"
                                className="!w-[70px] !m-0 !rounded-[20px] bg-cc-primary py-1 px-4 text-xs"
                                text={data?.widget?.createdAt ? 'Unlink':'Setup'}
                                onClick={()=>data?.widget?.createdAt ? setShowUnlink(true):navigate("/home")}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="font-bold text-sm">
                            SDK Status:
                            <span
                                className={clsx(
                                    "ml-2",
                                    data?.widget?.status ? "text-cc-green-8":"text-cc-red-11",
                                )}
                            >{data?.widget?.status ? 'ENABLED':'DISABLED'}
                            </span>
                        </p>
                        <div className="flex items-center gap-x-2 mt-2">
                            <p className="text-cc-black-4 font-medium text-xs">{data?.widget?.updatedAt && `Updated on ${dateFormatter(data?.widget?.updatedAt, 'MMM dd, yyyy')}`}</p>
                            <Button
                                isValid={true}
                                type="button"
                                className="!w-[70px] !m-0 !rounded-[20px] bg-cc-primary py-1 px-4 text-xs"
                                text={data?.widget?.status ? 'Disable':'Enable'}
                                onClick={()=>{
                                    data?.widget?.createdAt ? changeSDKStatus(data?.widget?._id):toast.error("No widget created for this app yet!")
                                }}
                            />
                        </div>
                    </div>
                    <div className="xl:col-span-2 sm:!col-span-1">
                        <p className="font-bold text-sm">
                            Webhook Status:
                            <span
                                className={clsx(
                                    "ml-2",
                                    data?.webhook?.status ? "text-cc-green-8":"text-cc-red-11",
                                )}
                            >{data?.webhook?.status ? 'ACTIVE':'INACTIVE'}
                            </span>
                        </p>
                        <div className="flex items-center gap-x-2 mt-2">
                            <p className="text-cc-black-4 font-medium text-xs">{data?.webhook?.updatedAt && `Updated on ${dateFormatter(data?.webhook?.updatedAt, 'MMM dd, yyyy')}`}</p>
                            <Button
                                isValid={true}
                                type="button"
                                className="!w-[130px] !m-0 !rounded-[20px] bg-cc-primary py-1 px-4 text-xs whitespace-nowrap"
                                text="Update Status"
                                onClick={()=>{
                                    data?.webhook?.url ? 
                                    changeWebhookStatus(data?.webhook?.url) 
                                    : 
                                    toast.error("No webhook event created for this app yet!")
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-2.5 mt-10">
                    <p className="font-bold">Webhook Events Enabled:</p>
                    <div className="flex items-center gap-2.5 flex-wrap mt-3.5">
                        <div data-tooltip-id={"Identity"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="Identity"
                                className={clsx(
                                    "py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]",
                                    false ? "!text-white !bg-cc-primary":"!bg-white !text-cc-primary",
                                )}
                            />
                            <MyToolTip id={"Identity"} status="disabled"/>
                        </div>
                        <div data-tooltip-id={"Credit Insight"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="Credit Insight"
                                className={clsx(
                                    "py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]",
                                    (data?.webhook?.credit_advanced || data?.webhook?.credit_premium) ? "!text-white !bg-cc-primary":"!bg-white !text-cc-primary",
                                )}
                            />
                            <MyToolTip id={"Credit Insight"} status={(data?.webhook?.credit_advanced || data?.webhook?.credit_premium) ? "enabled":"disabled"}/>
                        </div>
                        <div data-tooltip-id={"Income Insight"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="Income Insight"
                                className={clsx(
                                    "py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]",
                                    (data?.webhook?.income_insight || data?.webhook?.income_transaction) ? "!text-white !bg-cc-primary":"!bg-white !text-cc-primary",
                                )}
                            />
                            <MyToolTip id={"Income Insight"} status={(data?.webhook?.income_insight || data?.webhook?.income_transaction) ? "enabled":"disabled"}/>
                        </div>
                        <div data-tooltip-id={"Spectrum"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="Spectrum"
                                className="!bg-[#D0D5DD] py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]"
                            />
                            <MyToolTip id={"Spectrum"} status={"coming soon..."}/>
                        </div>
                        <div data-tooltip-id={"Recova"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="Recova"
                                className="!bg-[#D0D5DD] py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]"
                            />
                            <MyToolTip id={"Recova"} status={"coming soon..."}/>
                        </div>
                        <div data-tooltip-id={"ERM"} data-tooltip-place="bottom">
                            <Button
                                isValid={true}
                                type="button"
                                text="ERM"
                                className="!bg-[#D0D5DD] py-1 px-3 text-sm shadow-[rgba(0,0,0,0.24)_0px_3px_8px]"
                            />
                            <MyToolTip id={"ERM"} status={"coming soon..."}/>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmAction
                show={showUnlink}
                onCancel={()=>setShowUnlink(false)}
                onAction={()=>{
                    unlinkSDK()
                }}
                loading={false}
                title="Unlink SDK from App"
                content="Are you sure you want to permanemtly unlink this app from the widget settings?"
            />
        </div>
    )
}

const MyToolTip = ({id, status}) => {
    return(
        <Tooltip 
            id={id}
            render={()=>(
                <div className="text-xs">
                    <div className='flex items-end gap-x-2'>
                        <div className="w-[58px] h-10 -ml-3 mb-1.5 relative">
                        <img src={webhookIcon} alt="webhook" className="w-[58px] absolute bottom-0"/>
                        </div>
                        <p className="w-[130px] normal-case justify-content">
                            {id} service webhook setting is currently {status}
                        </p>
                    </div>
                </div>
            )}
        />
    )
}