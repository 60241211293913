import Tab from "components/shared/Tab";
import RecovaHeaderLayout from "../layout/RecovaHeaderLayout";
import ToggleButton from "components/shared/ToggleButton";
import { useEffect, useState } from "react";
import { RecovaService } from "services/recova.service";
import { handleRequestErrors } from "utils/helpers";
import { useSearchParams } from "react-router-dom";

export default function Preference () {
    const [preferences, setPreferences] = useState({});
    const [searchParams] = useSearchParams()
    const tab = searchParams.get("tab")

    useEffect(()=>{
        RecovaService.getPreferences()
            .then(response=> response.data)
            .then(res => {
                if(res.status===true){
                    setPreferences(res?.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }, [])

    const handleChange = (key, value) => {
        setPreferences({
            ...preferences,
            [`${key}`]: value,
        })
        const payload = JSON.stringify({
            [`${key}`]: value
        })
        RecovaService.editPreferences(payload)
            .then(response=> response.data)
            .then(res => {
                if (res.status===true) {
                } else {
                    setPreferences({
                        ...preferences,
                        [`${key}`]: !value,
                    })
                }
            })
            .catch((err) => {
                setPreferences({
                    ...preferences,
                    [`${key}`]: !value,
                })
                handleRequestErrors(err, true)
            });
    }

    return (
        <RecovaHeaderLayout>
            <h1 className="text-2xl font-bold">Preference</h1>
            <div className="flex items-center gap-x-10 my-[20px]">
                <p className="font-bold text-[18px] border-b-[3px] border-b-cc-primary">Notifications</p>
                <p className="font-bold text-[18px]">Other Settings</p>
            </div>
            <Tab 
                variant="filled"
                tabs={[
                    { label: "Business ", key: "business" },
                    { label: "Payer/Customer", key: "customer" },
                ]}
            />
            <div className="rounded-lg border-[1px] border-[#EAECF0] mt-6">
                {
                    [
                        {
                            title: "Mandate Created",
                            businessKey: "businessMandateCreated",
                            customerKey: "customerMandateCreated",
                        },
                        {
                            title: "Mandate Approved",
                            businessKey: "businessMandateApproved",
                            customerKey: "customerMandateApproved",
                        },
                        {
                            title: "Mandate Canceled",
                            description: "Applies when the e-Mandate isn't approved after 6hrs",
                            businessKey: "businessMandatedCanceled",
                            customerKey: "customerMandatedCanceled",
                        },
                        {
                            title: "Collection Advanced Notice",
                            description: "Notice to the Payer at least 24 hours before initiating the direct debit",
                            businessKey: "businessCollectionAdvanceNotice",
                            customerKey: "customerCollectionAdvanceNotice",
                        },
                        {
                            title: "Collection Successful Receipt Notice",
                            description: "When collection from Payer's account is successful",
                            businessKey: "businessCollectionSuccessfulNotice",
                            customerKey: "customerCollectionSuccessfulNotice",
                        },
                        {
                            title: "Collection Failure Receipt Notice ",
                            description: "When collection from Payer's account fails",
                            businessKey: "businessCollectionFailureNotice",
                            customerKey: "customerCollectionFailureNotice",
                        },
                        // {
                        //     title: "Payout Successful Notice ",
                        //     description: "When payout is successfully approved",
                        //     businessKey: "businessPayoutSuccuess",
                        //     customerKey: "customerPayoutSuccuess",
                        // },
                        // {
                        //     title: "Payout Cancelled Notice ",
                        //     description: "When payout is rejected",
                        //     businessKey: "businessPayoutCanceled",
                        //     customerKey: "customerPayoutCanceled",
                        // },
                    ].map(item=>(
                        <div className="border-b-[1px] border-[#EAECF0]">
                            <div className="flex max-w-[600px] justify-between gap-x-6 p-[20px]">
                                <div>
                                    <p className="text-base">{item.title}</p>
                                    <p className="text-sm sm:text-base">{item.description}</p>
                                </div>
                                <ToggleButton
                                    checked={preferences?.[tab==="business" ? `${item.businessKey}`:`${item.customerKey}`]} 
                                    onChange={()=>{
                                        handleChange(
                                            tab==="business" ? item.businessKey:item.customerKey,
                                            !preferences?.[tab==="business" ? `${item.businessKey}`:`${item.customerKey}`],
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </RecovaHeaderLayout>
    )
}