import {Container} from "react-bootstrap";
import {Routes, Route, Navigate, useLocation, Link} from "react-router-dom";
import { useState, useEffect } from "react";
import Team from "./Team";
import Notifications from "./Notifications";
import Profile from "./Profile";
import Compliance from "../../../pages/Dashboard/Compliance";
import Webhook from "./Webhook";


function Settings ({setDetailName, rolePermissions}) {
    const location = useLocation().pathname;
    const [path, setPath] = useState("team");
    const [permissionValues, setPermissionValues] = useState([]);
   
    
    useEffect(()=>{
        setDetailName("")
        setPath(location);
    }, [path, location, setDetailName]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "settings"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    return (
        <div className="settings">
            <div className="settings_navMain">
                <Link to="/settings/team" className="link-decor"><p className={path.includes("team")?"active":""} onClick={()=>setPath("team")}>Team</p></Link>
                <Link to="/settings/notifications" className="link-decor"><p className={path.includes("notifications")?"active":""} onClick={()=>setPath("notifications")}>Notifications</p></Link>
                <Link to="/settings/profile" className="link-decor"><p className={path.includes("settings/profile")?"active":""} onClick={()=>setPath("profile")}>Profile</p></Link>
                <Link to="/settings/compliance" className="link-decor"><p className={path.includes("compliance")?"active":""} onClick={()=>setPath("compliance")}>Compliance</p></Link>
                <Link to="/settings/webhook" className="link-decor"><p className={path.includes("webhook")?"active":""} onClick={()=>setPath("webhook")}>Webhook</p></Link>
            </div>
            <Container>
                <Routes>
                    <Route path="/" element={<Navigate to="/settings/team" />}/>
                    <Route path="/team/*" element={<Team permissionValues={permissionValues} />} />
                    <Route path="/notifications" element={<Notifications permissionValues={permissionValues} />} />
                    <Route path="/profile" element={<Profile permissionValues={permissionValues} />} />
                    <Route path="/compliance/*" element={<Compliance from={"settings"} permissionValues={permissionValues} />} />
                    <Route path="/webhook" element={<Webhook permissionValues={permissionValues} />} />
                </Routes>
            </Container>
        </div>
    )
}

export default Settings;