export const logToBin = async (message, url) => {
    try{
        const response = await fetch("https://api.jsonbin.io/v3/b", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Master-Key": '$2b$10$4tFjOjPNDJ9e9hQIbz9g1OseGu2Q/whT6r5XS.fMOuju4XCSGYusm',
                "X-Bin-Private": true,
                "X-Bin-Name": "FrontendB2B",
                "X-Collection-Id": process.env.REACT_APP_X_COLLECTION_ID,
            },
            body: JSON.stringify({
                statusCode: message.stack===undefined ? 401 : message?.code ? message.code : message.statusCode,
                url: url,
                message: message.stack===undefined ? message.message : message.stack,
            }),
        });
        const data = await response.json();
        console.log("jsonToBin", data)
    } catch (e) {
        console.log(e)
    }
};