import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import customer from "../../../images/svg/credit/customer.svg";
import business from "../../../images/svg/credit/business.svg";


function Selection () {
    return (
        <div className="box-border mx-10 md:mx-[20px] -mt-[20px]">
            <p className="xl_min:w-[350px] mb-[60px] xl:mt-10 xl:mb-[20px]">Generate business and individual credit history from all credit bureau in a single search</p>
            <Row>
                <Col md={6} lg={5} xl={4}>
                    <Link to="/credit-insight/customer?tab=first-central">
                        <div className="shadow-md rounded-[12px] p-[20px] cursor-pointer text-black">
                            <img src={customer} alt="" className="mb-2.5"/>
                            <h5 className="font-semibold text-lg">Credit Insight for Individuals</h5>
                            <p className='mb-[20px]'>Generate customer Credit Insight using credit insight</p>
                            <button className="bg-cc-primary border-none text-white rounded-[4px] py-[7px] px-[25px]">Get Started</button>
                        </div>
                    </Link>
                </Col>
                <Col md={6} lg={5} xl={4}>
                    <Link to="/credit-insight/business">
                        <div className="shadow-md rounded-[12px] p-[20px] cursor-pointer text-black">
                            <img src={business} alt="" className="mb-2.5"/>
                            <h5 className="font-semibold text-lg">Credit Insight for businesses</h5>
                            <p className='mb-[20px]'>Generate business Credit Insight using credit insight</p>
                            <button className="bg-cc-primary border-none text-white rounded-[4px] py-[7px] px-[25px]">Get Started</button>
                        </div>
                    </Link>
                </Col>
            </Row>
        </div>
    )
}

export default Selection;