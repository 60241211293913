import ShareYourData from "./components/ShareYourData";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate } from "react-router-dom"; 
import axios from 'axios';
import logo from "images/svg/white-logo.svg";
import creditcheklogo from "images/svg/home/creditchek.svg";
import { logToBin } from "lib/logs";
import { TextField } from "components/Form/TextField";
import { Formik, Form } from 'formik';
import Button from "components/shared/Button";
import { SelectField } from "components/Form/SelectField"; 
import { customerOnboardingSchema } from "utils/validationSchemas";
import { DateField } from "components/Form/DateField";
import { dateFormatter } from "utils/helpers";

export default function CustomerKYC () {
    const [searchParams,setSearchParams] = useSearchParams();
    let history = useNavigate();
    const [fetched, setFetched] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)

    let isDev = process.env.REACT_APP_NODE_ENV === 'development'
    let baseURL = isDev ? 
        process.env.REACT_APP_Dev_Cluster
        :
        process.env.REACT_APP_Production_Cluster

    useEffect(()=>{
        // get business Data
        axios.get(`${baseURL}identity/widget/show-form-data?appLink=${searchParams.get("appLink")}`)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === true) {
                    setFetched(res.data);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const submitForm = (values) => {
        setLoading(true)
        let payload = {}
        if (searchParams.get("type")==="long") {
            payload = ({
                fullName: `${values.firstName} ${values.lastName}`,
                email: values.email,
                phoneNumber: values.phoneNumber,
                bvn: `${values.bvn}`,
                dateOfBirth: dateFormatter(values.dateOfBirth, 'dd/mm/yyyy'),
                gender:values.gender,
                residentialAddress: values.address,
                residenceType: values.residentType,
                appId: searchParams.get("app_id"),
                maritalStatus: values.maritalStatus,
                occupation: values.occupation,
                guarantorName: values.guarantorName,
                guarantorEmail: values.guarantorEmail,
                guarantorAddress: values.guarantorAddress,
                guarantorPhoneNumber: values.guarantorPhoneNumber,
                employerName: values.employerName,
                employerAddress: values.employerAddress,
                loanAmount: values.loanAmount,
                loanTenure: values.loanTenure
            })
        } else {
            payload = ({
                fullName: `${values.firstName} ${values.lastName}`,
                email: values.email,
                phoneNumber: values.phoneNumber,
                bvn: `${values.bvn}`,
                dateOfBirth: dateFormatter(values.dateOfBirth, 'dd/mm/yyyy'),
                gender:values.gender,
                residentialAddress: values.address,
                residenceType: values.residentType,
                appId: searchParams.get("app_id"),
                maritalStatus: values.maritalStatus
            })
        }
       
        axios.post(`${baseURL}identity/widget/submit`, payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading(false)
                if (res.status === true) {
                    setShowModal(true)
                    sendConsent(res.data._id)
                    searchParams.set('borrowerId', res.data._id);
                    setSearchParams(searchParams);
                    sessionStorage.setItem( "customerId", JSON.stringify({ data: res.data._id })); 
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    if(err.response.status===409){
                        toast.error("You are already registered with the business, please proceed to share your data!");
                        history(`/customer/share-data/upload-pdf?${searchParams.toString()}&borrowerId=${err.response.data.data.borrowerId}`)
                    } else {
                        toast.error(err.response.data.message);
                    }
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const sendConsent = (userId) => {
        axios.post(`${baseURL}identity/borrower/userconsent`, {
            appId: searchParams.get("app_id"),
            borrowerId: userId,
            action: "onboarding"
        })
            .then((response) => response.data)
            .then((res) => {
                // given consent
            })
            .catch((err) => {
            })
    }

    const initialValues ={
        firstName:"",
        lastName:"",
        phoneNumber:"",
        email:"",
        dateOfBirth: '',
        gender:"",
        bvn:"",
        address:"",
        residentType:"",
        maritalStatus:"",
        occupation: "",
        guarantorName: "",
        guarantorEmail: "",
        guarantorAddress: "",
        guarantorPhoneNumber: "",
        employerName: "",
        employerAddress: "",
        loanAmount: "",
        loanTenure: ""
    }

    return(
        <div>
            <div className="max-w-[500px] block mx-auto bg-cc-primary py-10 px-7 rounded-[10px]">
                <div className="myModal_main-widget box-border">
                    <div className="myModal_main-widget-header mb-7">
                        <p className="text-[1em] font-bold text-white">Fill in your details for {fetched.businessName}</p>
                        <div className="w-[70px] h-[70px] overflow-hidden border-[0.3px] border-[blue] border-solid rounded-[50%] block my-2.5 mx-auto">
                            <img src={fetched?.logo?.length?fetched.logo:creditcheklogo} alt="upload" width="100%" />
                        </div>
                    </div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={customerOnboardingSchema(searchParams.get("type")==="long")}
                        onSubmit={(values) => {
                            submitForm(values)
                        }}
                    >  
                        {(props) => (
                            <Form>
                                <div className="-mb-2">
                                    <TextField
                                        name='firstName'
                                        type='text'
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <TextField
                                        name='lastName'
                                        type='text'
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <TextField
                                        name='phoneNumber'
                                        type='text'
                                        placeholder="Phone Number"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <TextField
                                        name='email'
                                        type='email'
                                        placeholder="Email Address"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <DateField
                                        name='dateOfBirth'
                                        placeholder="Date of Birth"
                                        onChange={(value)=>{
                                            props.setFieldValue("dateOfBirth", value)
                                        }}
                                        value={props?.values?.dateOfBirth}
                                    />
                                </div>
                                <div className="-mb-2">
                                    <SelectField
                                        name='gender'
                                        options={[
                                            { value: 'Male', label: 'Male' },
                                            { value: 'Female', label: 'Female' },
                                        ]}
                                        placeholder="Gender"
                                        onChange={(selected)=>{
                                            props.setFieldValue( "gender", selected.value )
                                        }}
                                        value={props?.values?.gender}
                                    />
                                </div>
                                <div className="-mb-2">
                                    <TextField
                                        name='bvn'
                                        type='text'
                                        placeholder="BVN"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <TextField
                                        name='address'
                                        type='text'
                                        placeholder="Full Resident Address"
                                    />
                                </div>
                                <div className="-mb-2">
                                    <SelectField
                                        name='residentType'
                                        options={[
                                            { value: 'Temporary Residence', label: 'Temporary Residence' },
                                            { value: 'Family owned', label: 'Family owned' },
                                            { value: 'Rented', label: 'Rented' },
                                            { value: 'Personally owned', label: 'Personally owned' },
                                            { value: 'Employer Provided', label: 'Employer Provided' },
                                        ]}
                                        placeholder="Resident Type"
                                        onChange={(selected)=>{
                                            props.setFieldValue( "residentType", selected.value )
                                        }}
                                        value={props?.values?.residentType}
                                    />
                                </div>
                                <div className="-mb-2">
                                    <SelectField
                                        name='maritalStatus'
                                        options={[
                                            { value: 'Single', label: 'Single' },
                                            { value: 'Married', label: 'Married' },
                                            { value: 'Divorced', label: 'Divorced' },
                                        ]}
                                        placeholder="Marital Status"
                                        onChange={(selected)=>{
                                            props.setFieldValue( "maritalStatus", selected.value )
                                        }}
                                        value={props?.values?.maritalStatus}
                                    />
                                </div>
                                {
                                    searchParams.get("type")==="long" &&
                                    <div>
                                        <div className="-mb-2">
                                            <SelectField
                                                name='occupation'
                                                options={[
                                                    { value: 'Business', label: 'Business' },
                                                    { value: 'Employed', label: 'Employed' },
                                                    { value: 'Artisan', label: 'Artisan' },
                                                ]}
                                                placeholder="Occupation"
                                                onChange={(selected)=>{
                                                    props.setFieldValue( "occupation", selected.value )
                                                }}
                                                value={props?.values?.occupation}
                                            />
                                        </div>
                                        <p className="myModal_main-widget-form-text1">Guarantor's Information</p>
                                        <div className="-mb-2">
                                            <TextField
                                                name='guarantorName'
                                                type='text'
                                                placeholder="Full Name"
                                            />
                                        </div>
                                        <div className="-mb-2">
                                            <TextField
                                                name='guarantorEmail'
                                                type='email'
                                                placeholder="Email Address"
                                            />
                                        </div>
                                        <div className="-mb-2">
                                            <TextField
                                                name='guarantorPhoneNumber'
                                                type='text'
                                                placeholder="Mobile Number"
                                            />
                                        </div>
                                        <div className="-mb-2">
                                            <TextField
                                                name='guarantorAddress'
                                                type='text'
                                                placeholder="Address"
                                            />
                                        </div>
                                        <p className="myModal_main-widget-form-text1">Employment Information</p>
                                        <div className="-mb-2">
                                            <TextField
                                                name='employerName'
                                                type='text'
                                                placeholder="Employer's Name"
                                            />
                                        </div>
                                        <div className="-mb-2">
                                            <TextField
                                                name='employerAddress'
                                                type='text'
                                                placeholder="Employer's Address"
                                            />
                                        </div>
                                        <div className="-mb-2">
                                            <TextField
                                                name='guarantorPhoneNumber'
                                                type='text'
                                                placeholder="Mobile Number"
                                            />
                                        </div>
                                        <p className="myModal_main-widget-form-text1">Loan Information</p>
                                        <div className="-mb-2">
                                            <SelectField
                                                name='loanTenure'
                                                options={[
                                                    { value: '1 month', label: '1 month' },
                                                    { value: '3 months', label: '3 months' },
                                                    { value: '6 months', label: '6 months' },
                                                    { value: '9 months', label: '9 months' },
                                                    { value: '12 months', label: '12 months' },
                                                ]}
                                                placeholder="Loan Tenure"
                                                onChange={(selected)=>{
                                                    props.setFieldValue( "loanTenure", selected.value )
                                                }}
                                                value={props?.values?.loanTenure}
                                            />
                                        </div>
                                    </div>
                                }
                                <Button
                                    isValid={props.isValid}
                                    isLoading={loading}
                                    type="submit"
                                    // text="Next Steps"
                                    text="Continue to BVN Verification"
                                    className="mb-[30px] mt-2.5 !bg-[#CCDCFF] !text-cc-primary"
                                />
                            </Form>
                        )}
                    </Formik>
                    <div className="my-[20px] flex justify-center">
                        <p className="text-[1em] text-center text-white font-bold mr-2">Powered by </p><img width="90px" src={logo} alt="logo"/>
                    </div>
                    <ShareYourData
                        data={fetched} 
                        show={showModal} 
                        handleAction={(option)=>{
                            option==="upload-pdf" ?
                                history(`/customer/share-data/upload-pdf?${searchParams.toString()}`)
                                : 
                                history(`/customer/share-data/open-banking?${searchParams.toString()}`)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}