import clsx from "clsx"

export default function ToggleButton ({
    checked, onChange, text, 
    textClass='',
}) {
    return (
        <div className="flex">
            <div className="toggle_button mt-[7px] mb-[15px]">
                <div className="button r button-2 relative w-[60px] h-7 overflow-hidden rounded-[100px]">
                    <input 
                        type="checkbox" 
                        className="checkbox relative w-[100%] h-[100%] p-0 m-0 opacity-0 cursor-pointer z-[3]" 
                        checked={checked} 
                        onChange={onChange} 
                    />
                    <div className="knobs absolute top-0 bottom-0 right-0 left-0 z-[2]"></div>
                    <div className="layer absolute top-0 bottom-0 right-0 left-0 rounded-[100px] w-[100%] z-[1]"></div>
                </div>
            </div>
            <p 
                className={clsx(
                    "mt-[9px] ml-[7px]",
                    textClass
                )}
            >{text}</p>
        </div>
    )
}