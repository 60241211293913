import { useEffect, useState } from "react";
import { Col, Row, Modal, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import WarningModal from "components/Modal/WarningModal";
import Terms from "pages/Dashboard/Float/components/Terms";
import Back from 'components/shared/back'
import floatDisbursed from "../../../../images/svg/float/float-disbursed.svg";
import { toast } from "react-toastify";
import { logToBin } from "../../../../lib/logs";
import { FloatService } from "services/float.service";
import { useSelector } from "react-redux";


function OfferDetails () {
    const [warningType, setWarningType] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showDisbursed, setShowDisbursed] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const b2b_data = useSelector(state => state?.loginData?.data)
   
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    
 
    let navigate = useNavigate()
    let data = JSON.parse(sessionStorage.getItem('floatOffer'));
    const offerData = data?.approvedFloat;

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
    });

    const handleAcceptModal = () => {
        if(acceptTerms){
            setWarningType('accept-float');
            setShowWarningModal(true);
        }else{
            toast.error('Please ensure you read and accept terms to continue')
        }
    }

    const acceptAction = () => {
        FloatService.floatAgreement(data?._id)
        .then((response) => response.data)
            .then((res) => {
                // setIsSubmitting(false);
                if (res.status === 'success') {
                    acceptFloatOfferAction()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const acceptFloatOfferAction = () => {
        let payload = {
            userId: b2b_data?.user?.id,
            businessId: b2b_data?.user?.businessId,
            approvedLoanAmount: data?.approvedFloat?.approvedAmount,
            approvedLoanDuration: data?.approvedFloat?.loanTenure,
            interestRate: data?.approvedFloat?.monthlyIntrestRate,
            interestType: data?.approvedFloat?.monthlyIntrestType,
            amountType: data?.amountType
        }
        FloatService.acceptFloatOffer(data?._id, payload)
        .then((response) => response.data)
            .then((res) => {
                // setIsSubmitting(false);
                if (res.status === 'success') {
                    setShowDisbursed(true)  
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const declineAction = () => {
        FloatService.declineFloatOffer(data?._id)
        .then((response) => response.data)
            .then((res) => {
                // setIsSubmitting(false);
                if (res.status === 'success') {
                    toast.success('Float Offer Declined')
                    navigate('/float/status')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    useEffect(() => {
        if( acceptTerms ){
           setIsDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptTerms])

    return (
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <Container>
             <Back to={()=> navigate("/float/status")} />
                <div className="float_request">
                    <h3 className="text-[1.3em] font-medium">{b2b_data?.businessName}</h3>
                    <p>We have successfully reviewed the information. Please go over the details before accepting</p>
                    <div>
                        <Row>
                            <Col sm={8} lg={5} xl={4}>
                                <div className="float_request-box">
                                    <h4 className="text-center text-[1.2em] font-medium" style={{marginBottom: '20px'}}>Approved Float Request</h4>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Request ID</p>
                                        <p className="text2 !lowercase">{data?.requestCode||"---"}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Approved Float Amount</p>
                                        <p className="text2">{formatMoney.format(offerData?.approvedAmount)}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Monthly Interest rate</p>
                                        <p className="text2">{offerData?.monthlyIntrestRate}%</p>
                                    </div>
                                    {/* <div className="float_request-item mb-2.5">
                                        <p className="text1">Monthly Interest amount</p>
                                        <p className="text2">N100,000</p>
                                    </div> */}
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Loan Tenure</p>
                                        <p className="text2">{offerData?.loanTenure} months</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Interest Type</p>
                                        <p className="text2">{offerData?.monthlyIntrestType}</p>
                                    </div>
                                    <div className="float_request-item mb-2.5">
                                        <p className="text1">Total Repayment</p>
                                        <p className="text2">{formatMoney.format(offerData?.totalRepayment)}</p>
                                    </div>
                                </div>
                                <div className='float_request-action'>
                                    <Row>
                                        <Col md={12} lg={12} xl={12}>
                                            <div className='float_request-checks'>
                                                <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                                <p >Terms and Conditions</p>
                                            </div>
                                        </Col>
                                        <Col md={12} lg={12} xl={12}>
                                            <div className='float_request-checks'>
                                                <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                                <p >Recovery Mandate</p>
                                            </div>
                                        </Col>
                                        <Col md={12} lg={12} xl={12}>
                                            <div className='float_request-checks'>
                                                <input type="checkbox" checked={acceptTerms} onClick={()=>setShowTerms(true)}/>
                                                <p >Penalties and Fees</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        <button className="decline" style={{marginRight:'20px'}} onClick={()=>{setWarningType('decline-float'); setShowWarningModal(true);}}>Decline</button>
                                        <button className="accept" onClick={handleAcceptModal} disabled={isDisabled}>Accept</button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} lg={7} xl={7}>
                                <div className="float_request-box">
                                    <h5 style={{marginBottom: '20px'}} className="text-[1.2em] font-medium">Repayment Breakdown</h5>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Principal</th>
                                                <th>Interest</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                offerData?.repaymentBreakdown?.map((item, idx) => (
                                                    <tr key={idx}>
                                                        <td>{formatMoney.format(item?.principal)}</td>
                                                        <td>{formatMoney.format(item?.interest)}</td>
                                                        <td>{formatMoney.format(item?.totalRepayment)}</td>
                                                    </tr>
                                                )) 
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            <WarningModal show={showWarningModal} type={warningType} declineAction={declineAction} acceptAction={acceptAction} handleClose={()=>setShowWarningModal(false)} />
            <Modal show={showDisbursed} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <img src={floatDisbursed} alt='' />
                    <h5 className="text-[1.3em]">Float Disbursed</h5>
                    <p style={{color:"#667085"}} className="my-2.5">Congratulations, your Float request will be disbursed shortly to your account</p>
                    <Link to="/float/status"><button className="float_modal-action">Go Back</button></Link>
                </div>
            </Modal>
            <Terms 
                show={showTerms} 
                handleClose={()=>setShowTerms(false)} 
                accept={()=>{setAcceptTerms(true);setShowTerms(false);}} 
                decline={()=>{setAcceptTerms(false);setShowTerms(false);}} 
                data={data} 
            />
        </div>
    )
}

export default OfferDetails;