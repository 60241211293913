import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { persistor } from "redux/store";

function Idle({ show, cancel }) {
  let history = useNavigate()
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);


  useEffect(()=>{
    setSeconds(30);
  }, [show])

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }

      if(show && minutes === 0 && seconds===0){
        persistor.purge()
        history('/auth');
        sessionStorage.clear();
      }

    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Modal show={show} size="md" onHide={cancel} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
      <div className="m-[20px] text-center">
        <h4>Idle Session</h4>
        <form>
          <div className="myModal_main-linkBank">
            <div>
              <p>
                You have been inactive for 10mins and are about to be logged out,
                do you still want to remain logged in?
              </p>
              <h3 style={{ color: "#0046E6" }}>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
              </h3>
            </div>
          </div>
        </form>
        <div className="myModal_main-widget-action">
          {/* <button className="cancel">Cancel</button> */}
          <button className="publish" onClick={cancel}>
            Stay Logged In
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default Idle;
