import { Col, Modal, Row} from "react-bootstrap";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { useState } from "react";
import {toast} from "react-toastify";
import { useEffect } from "react";
import { AuthService } from "services/auth.service";

import loader from "../../../../images/gif/white-loader.gif";


function EditRole ({show, handleClose, roleData, fetchAllRoles, modules}) {
    const [oneRole, setOneRole] = useState({});
    const [checkedList, setCheckedList] = useState([]);
    let permissions = [];
    const [disabled, setDisabled] = useState(false);

    useEffect(()=>{
        setOneRole(roleData);
        let dbPermissions = [];
        roleData?.permission?.forEach(function(item){
            Object.keys(item).map((k) => (
                item[k].map((x)=>  dbPermissions.push(`${Object.keys(item)[0]==='audit logs'?'audit':Object.keys(item)[0]==='api pricing'?'api':Object.keys(item)[0]} ${x}`))
            ))
        })
        setCheckedList(dbPermissions)
    }, [roleData])

    const formatPermissions = () => {
        // convert array to object array first
        const newCheckedList = []
        checkedList.forEach(function(item) {
            let words =  item.split(' ')
            newCheckedList.push({
                name: words[0],
                value: words[1]
            })
        })
        
        // merge array objects with same name and join the values as arrays
        var output = [];

        newCheckedList.forEach(function(item) {
            var existing = output.filter(function(v, i) {
                return v.name === item.name;
            });
            if (existing.length) {
                var existingIndex = output.indexOf(existing[0]);
                output[existingIndex].value = output[existingIndex].value.concat(item.value);
            } else {
                if (typeof item.value == 'string')
                item.value = [item.value];
                output.push(item);
            }
        });

        // convert to desired array of object to send to backend
        let newFormat = []
        output.forEach(function(item){
            newFormat.push({
                [item.name==='audit'?'audit logs':item.name==='api'?'api pricing':item.name]: item.value
            })
        })

        permissions = newFormat;
    }

    const addPermission = (checked, service, allow) => {
        if(checked && !checkedList.includes(`${service} ${allow}`)){
            setCheckedList(oldArray => [...oldArray, `${service} ${allow}`]);
        } else {
            setCheckedList(checkedList.filter(function(item) {
                return item !== `${service} ${allow}`
            }))
        }
    }

    const doEdit = () => {
        setDisabled(true);
        formatPermissions();
        let payload = JSON.stringify({
            name: oneRole.name,
            description: oneRole.description,
            permission: permissions
        })
        AuthService.editRole(oneRole._id, payload)
            .then((response) => response.data)
            .then((res) => {
                setDisabled(false);
                if (res.success === true) {
                    toast.success(res.message);
                    handleClose();
                    fetchAllRoles()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisabled(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <Modal.Title>Edit Role</Modal.Title>
                <div className="settings-invite-mail">
                    <label>Role name</label>
                    <div>
                        <input type="text" className="mail" name="name" defaultValue={oneRole?.name} onChange={(e)=>setOneRole({...oneRole, name:e.target.value})} style={{textTransform:"capitalize"}} />
                    </div>
                    <label style={{marginTop:'30px'}}>Role description</label>
                    <div>
                        <input type="text" className="mail" name="description" defaultValue={oneRole?.description} onChange={(e)=>setOneRole({...oneRole, name:e.target.value})} style={{textTransform:"capitalize"}} />
                    </div>
                </div>
                <div className="settings-invite-role">
                    <div className="settings-invite-role-contain">
                        <Row>
                            {
                                modules.map((item)=>(
                                    item.status &&
                                    <Col md={6} key={item._id}>
                                        <div className="settings-invite-role-contain-views">
                                            <p style={{textTransform:'capitalize'}}><strong>{item.name==='dash'?'Dashboard':item.name}</strong></p>
                                            <div>
                                                <Checkbox
                                                    icon={<Icon.FiCheck color="black" size={14} />}
                                                    name="my-input"
                                                    checked={checkedList?.includes(`${item.name==='audit logs'?'audit':item.name==='api pricing'?'api':item.name} view`)}
                                                    onChange={(value) => {
                                                        addPermission(value, `${item.name==='audit logs'?'audit':item.name==='api pricing'?'api':item.name}`, 'view');
                                                    }}
                                                    borderColor="black"
                                                    style={{ cursor: "pointer" }}
                                                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                                    label={`Can view ${item.name==='dash'?'dashboard':item.name}`}
                                                />
                                                <Checkbox
                                                    icon={<Icon.FiCheck color="black" size={14} />}
                                                    name="my-input"
                                                    checked={checkedList?.includes(`${item.name==='audit logs'?'audit':item.name==='api pricing'?'api':item.name} edit`)}
                                                    onChange={(value) => {
                                                        addPermission(value, `${item.name==='audit logs'?'audit':item.name==='api pricing'?'api':item.name}`, 'edit');
                                                    }}
                                                    borderColor="black"
                                                    style={{ cursor: "pointer" }}
                                                    labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                                    label={`Can edit ${item.name==='dash'?'dashboard':item.name}`}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </div>
                <div className="settings-invite-action">
                    {/* <button className="cancel" onClick={()=>doDelete()}>Delete</button> */}
                        <button className="publish" onClick={()=>doEdit()} disable={disabled} style={{width:'90px'}}>{disabled ? <img src={loader} alt="" width="20px" className="block m-auto" /> : 'Edit Role'}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EditRole;