import {Modal} from "react-bootstrap";
import { useEffect, useState } from 'react';
import Select from "../Form/SelectInput";
import {toast} from "react-toastify";
import { logToBin } from "../../lib/logs";
import { IncomeService } from "services/income.service";
import { useSelector } from "react-redux";

import and from "../../images/svg/income/and.svg";
import or from "../../images/svg/income/or.svg";
import filterDelete from "../../images/svg/income/filter-delete.svg";
import filterCancel from "../../images/svg/income/filter-cancel.svg";

function IncomeFilter ({show, handleClose, runFilterData, setLoading, formerFilter}) {
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const [criteriaList, setCriteriaList] = useState([
        "Debt Burden Ratio (0% to 100%)", 
        "Average Monthly Income (NGN 0 to any Amount)",
        "Average Monthly Balance (NGN 0 to any Amount)"
    ]);
    const [filters, setFilters] = useState([
        {
            criteria: "Select Criteria",
            range: "equal",
            from: 1,
            to: 100,
            operator: "and" // and/or
        },
    ])

    useEffect(()=>{
        // this condition fills the modal incase filter has been set before
        if(formerFilter.length > 0){
            let listArray = [];
            formerFilter.forEach(function(item) {
                let name = "";
                let start = 1;
                let end = 100;
                let range = 'equal';
                let condition = "and";
                if(Object.keys(item).map(oneKey => oneKey)?.[0] === "averageMonthlyEdti"){
                    name = 'Debt Burden Ratio (0% to 100%)';
                    start = item?.averageMonthlyEdti?.start;
                    end = item?.averageMonthlyEdti?.end;
                    range = item?.averageMonthlyEdti?.queryOperator;
                    condition = item?.averageMonthlyEdti?.queryCondition;
                } else if(Object.keys(item).map(oneKey => oneKey)?.[0] === "averageMonthlyIncome") {
                    name = 'Average Monthly Income (NGN 0 to any Amount)';
                    start = item?.averageMonthlyIncome?.start;
                    end = item?.averageMonthlyIncome?.end;
                    range = item?.averageMonthlyIncome?.queryOperator;
                    condition = item?.averageMonthlyIncome?.queryCondition;
                } else {
                    name = 'Average Monthly Balance (NGN 0 to any Amount)';
                    start = item?.averageMonthlyBalance?.start;
                    end = item?.averageMonthlyBalance?.end;
                    range = item?.averageMonthlyBalance?.queryOperator;
                    condition = item?.averageMonthlyBalance?.queryCondition;
                }
                const data1 = {
                    criteria: name,
                    range: range,
                    from: start,
                    to: end,
                    operator: condition
                }
                listArray.push(data1)
            })

            setFilters(listArray)
        }
    }, [formerFilter])

    useEffect(()=>{
        let list = [
            "Debt Burden Ratio (0% to 100%)", 
            "Average Monthly Income (NGN 0 to any Amount)",
            "Average Monthly Balance (NGN 0 to any Amount)"
        ]
        let newList = [] 

        // check if filter array has criteria value that is in the list array
        list.forEach(function(item) {
            const checkCriteria = obj => obj.criteria === item;

            // if it has, add the items already chosen to new list array (i.e item is in any index of filter.criteria)
            if(filters.some(checkCriteria)){
                newList.push(item)
            }
        });

        // remove items (in newList array) that are already in list array and display this to user via "criteriaList"
        list = list.filter( function( el ) {
            return !newList.includes( el );
        } );
        setCriteriaList(list)
    }, [filters])

    const handleFilterAdd = () => {
        setFilters([
            ...filters,
            {
                criteria: "Select Criteria",
                range: "equal",
                from: 1,
                to: 100,
                operator: "and"
            }
        ])
    }

    const handleFilterRemove = (index) => {
        let list = [...filters];
        list.splice(index, 1);
        setFilters(list)
    }

    const handleFilterChange = (name, value, index) => {
        const list = [...filters];
        list[index][name] = value;
        setFilters(list)
    }

    const doAction = (type) => {
        const data = []
        filters.forEach(function(item) {
            if(item.range==="between"){
                data.push({
                    [`${item.criteria==="Debt Burden Ratio (0% to 100%)"?"averageMonthlyEdti":item.criteria==="Average Monthly Income (NGN 0 to any Amount)"?"averageMonthlyIncome":item.criteria==="Average Monthly Balance (NGN 0 to any Amount)"?"averageMonthlyBalance":item.criteria}`] : {
                        start: parseInt(item.from),
                        queryCondition: item.operator,
                        queryOperator: item.range,
                        end: parseInt(item.to)
                    }
                })
            } else {
                data.push({
                    [`${item.criteria==="Debt Burden Ratio (0% to 100%)"?"averageMonthlyEdti":item.criteria==="Average Monthly Income (NGN 0 to any Amount)"?"averageMonthlyIncome":item.criteria==="Average Monthly Balance (NGN 0 to any Amount)"?"averageMonthlyBalance":item.criteria}`] : {
                        start: parseInt(item.from),
                        queryCondition: item.operator,
                        queryOperator: item.range
                    }
                })
            }
        });
        let payload = JSON.stringify(data)
        if(type==="run"){
            handleRunFilter(payload)
        } else {
            handleSave(payload)
        }

    }

    const handleSave = (data) => {
        IncomeService.createFilter(data, businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    toast.success("Change saved successfully!!!");
                    handleClose();
                } else {
                    toast.error("There is a downtime, please try again in 5 minutes!")
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const handleRunFilter = (data) => {
        setLoading()
        IncomeService.doFilterSearch(data, businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    runFilterData(res.data)
                } else {
                    toast.error("There is a downtime, please try again in 5 minutes!")
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const clearData = () => {
        setFilters([
            {
                criteria: "Select Criteria",
                range: "equal",
                from: 1,
                to: 100,
                operator: "and" // and/or
            },
        ]);
    }

    const formatCurrency = () => {
        var currencyInput = document.querySelector('input[type="currency"]')
        // format inital value
        onBlur({target:currencyInput})

        // bind event listeners
        currencyInput.addEventListener('focus', onFocus)
        currencyInput.addEventListener('blur', onBlur)


        function localStringToNumber( s ){
        return Number(String(s).replace(/[^0-9.-]+/g,""))
        }

        function onFocus(e){
            var value = e.target.value;
            e.target.value = value ? localStringToNumber(value).substring(4) : ''
        }

        function onBlur(e){
            var value = e.target.value

            var options = {
                maximumFractionDigits : 0,
                currency              : "NGN",
                style                 : "currency",
                currencyDisplay       : "symbol"
            }
            
            e.target.value = (value || value === 0) 
                ? localStringToNumber(value).toLocaleString(undefined, options).substring(4)
                : ''
        }
    }

    return(
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose} >
            <div className="myModal_income">
                <div>
                    <h5 className="font-medium text-2xl">Income Data Criteria Filter</h5>
                    <img src={filterCancel} alt="" className="filter-cancel" onClick={handleClose} />
                </div>
                <p className="text1">FILTER LIST</p>
                {
                    filters?.map((item, idx)=>(
                        <div key={idx} className="myModal_income-box1">
                            <div className="myModal_income-box1-main">
                                <div className="myModal_income-box1-main-form">
                                    <label>Select Criteria</label>
                                    <Select 
                                        placeholder={item?.criteria==="Select Criteria"?'Select Criteria':item?.criteria}
                                        options={criteriaList}
                                        selectChange={(item)=>handleFilterChange("criteria", item, idx)}
                                        arrayType={'incomeFilter'}
                                    />
                                    <div>
                                        <label className="myModal_income-box1-main-form-rangelabel">Select Range</label>
                                        <div className="myModal_income-box1-main-form-range">
                                            <div className={item?.range==="equal"?"active div1":"div1"} onClick={()=>handleFilterChange("range","equal",idx)}>
                                                <p>Equals</p>
                                            </div>
                                            <div className={item?.range==="less"?"active":""} onClick={()=>handleFilterChange("range","less",idx)}>
                                                <p>Less than</p>
                                            </div>
                                            <div className={item?.range==="more"?"active":""} onClick={()=>handleFilterChange("range","more",idx)}>
                                                <p>More than</p>
                                            </div>
                                            <div className={item?.range==="between"?"active div2":"div2"} onClick={()=>handleFilterChange("range","between",idx)}>
                                                <p>Between</p>
                                            </div>
                                        </div>
                                        <div className="myModal_income-box1-main-form-amount">
                                            <input type='currency' placeholder={item?.criteria==="Debt Burden Ratio (0% to 100%)"?"%":"NGN"} value={item?.from} name="from" onChange={(e)=>handleFilterChange(e.target.name, e.target.value, idx)} onKeyUp={formatCurrency} />
                                            {
                                                item?.range==="between" && <p>-</p>
                                            }
                                            {
                                                item?.range==="between" && <input type='currency' placeholder={item?.criteria==="Debt Burden Ratio (0% to 100%)"?"%":"NGN"} value={item?.to} name="to" onChange={(e)=>handleFilterChange(e.target.name, e.target.value, idx)} onKeyUp={formatCurrency} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    filters.length > 1 && (
                                        <img src={filterDelete} alt="" className="myModal_income-box1-main-delete" onClick={()=>handleFilterRemove(idx)} />
                                    )
                                }
                            </div>
                            <div className="myModal_income-box1-btns flex">
                                <button className="selected">{item.operator}</button>
                                {
                                    filters.length - 1 === idx && filters.length<6 && criteriaList.length>0 ? 
                                        <>
                                            <button className="flex items-center and justify-center" onClick={()=>{ handleFilterChange("operator", "and", idx);handleFilterAdd();}} ><img src={and} alt="" className="mr-[5px]"/> <p>AND</p></button>
                                            <button className="flex items-center or justify-center" onClick={()=>{ handleFilterChange("operator", "or", idx); handleFilterAdd();}} ><img src={or} alt="" className="mr-[5px]"/> <p>OR</p></button>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="myModal_income-save">
                <div className="myModal_main-widget-action">
                    {/* <button className="cancel">Cancel</button> */}
                    <button className="cancel" onClick={()=>clearData()}>Clear</button>
                    <button className="publish" onClick={()=>doAction("save")}>Save Changes</button>
                    <button className="publish" onClick={()=>doAction("run")}>Run Filter</button>
                </div>
            </div>
        </Modal>
    )
}

export default IncomeFilter;