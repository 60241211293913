import { Modal } from "react-bootstrap";
import { MdCancel } from "react-icons/md";

export default function ModalLayout ({children, show, handleClose}) {
    return(
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="px-[20px] relative">
                <div className="absolute w-full h-10 bg-cc-primary rounded-[6px] -z-10 -top-3 left-0" />
                <MdCancel className="scale-[1.6] cursor-pointer absolute top-2.5 right-2.5" onClick={()=>handleClose()} />
                <div>
                    {children}
                </div>
            </div>
        </Modal>
    )
}