import clsx from "clsx";
import Button from "components/shared/Button";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";


export default function FinishDirectDebit () {
    const [searchParams] = useSearchParams();
    let history = useNavigate()

    return(
        <div className="bg-cc-primary min-h-[80vh] text-white flex items-center justify-center flex-col bg-[url('./images/svg/sidebar/bg-image.svg')]">
            <div className="mb-[130px] mt-[50px] text-white px-6 box-border flex items-center gap-x-1.5">
                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.625 11.25C20.625 16.4276 16.4276 20.625 11.25 20.625C6.07233 20.625 1.875 16.4276 1.875 11.25C1.875 6.07233 6.07233 1.875 11.25 1.875C16.4276 1.875 20.625 6.07233 20.625 11.25ZM22.5 11.25C22.5 17.4632 17.4632 22.5 11.25 22.5C5.0368 22.5 0 17.4632 0 11.25C0 5.0368 5.0368 0 11.25 0C17.4632 0 22.5 5.0368 22.5 11.25ZM10.3125 13.125V6.5625H12.1875V13.125H10.3125ZM10.3125 15.9375V14.0625H12.1875V15.9375H10.3125Z" fill="white"/>
                </svg>
                <p className="text-sm font-bold">Don't reload this page or click the back button.</p>
            </div>
            <MdCheckCircle className="scale-[4] mb-7"/>
            <div className="max-w-[350px] text-center">
                <h5 className="font-bold text-[30px]">
                    {
                        searchParams.get("account")==="primary" ?
                        "Primary Account setup is completed!"
                        :
                        "Your direct debit setup is completed!"
                    }
                </h5>
                <p className="text-base font-semibold">
                    {
                        searchParams.get("account")==="primary" ?
                        // "You can now proceed to add your backup account"
                        ""
                        :
                        "The first debit on your account will be made after an 8 hours waiting period elapses"
                    }
                </p>
            </div>
            <div 
                className={clsx(
                    "w-full !px-6 box-border",
                    // searchParams.get("account")==="backup" && 
                    "mt-[150px] mb-[20px] grid grid-cols-2 gap-x-[16px] md:grid-cols-1"
                )}
            >
                <Button
                    text="Close"
                    type="button"
                    isValid={true}
                    onClick={()=>{
                        window.open('about:blank', '_self');
                        window.close()
                    }}
                    className={clsx(
                        "!bg-transparent font-semibold !text-white !border-[1px] !border-white mt-3",
                        // searchParams.get("account")==="primary" && "hidden"
                    )}
                />
                <Button
                    text={
                        searchParams.get("account")==="primary" ?
                        "Add Secondary Account":"Link more accounts"
                    }
                    type="button"
                    isValid={true}
                    onClick={()=>{
                        history(`/customer/direct-debit/select-bank?account=backup`)
                    }}
                    className="!bg-white font-semibold !text-cc-primary mt-3"
                />
            </div>
        </div>
    )
}