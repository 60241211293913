import Button from "components/shared/Button";
import done from "images/svg/recova/done.svg";

export default function SuccessModal ({title, message, onClose}) {
    return(
        <div>
            <img src={done} alt="" className="block mx-auto my-[20px]" />
            <div className="max-w-[350px] bloxk mx-auto text-center">
                <h5 className="text-xl font-semibold">{title}</h5>
                <p>{message}</p>
                <div className="mt-[20px] mb-10">
                    <Button
                        isValid={true}
                        type="button"
                        text="Finish"
                        onClick={()=>onClose()}
                    />
                </div>
            </div>
        </div>
    )
}