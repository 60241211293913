
//Library methods
import React, { useState } from 'react'
import { useSteps } from 'react-step-builder'

//Components
import Header from './Header'
import { useRecovaSetup, useUser } from 'hooks/redux.hook'
import Button from 'components/shared/Button'
import { useSelector } from 'react-redux'
import { object, string } from 'yup'
import { TextField } from 'components/Form/TextField'
import { Form, Formik } from 'formik'
import { RecovaService } from 'services/recova.service'
import { toast } from 'react-toastify'
import { handleRequestErrors } from 'utils/helpers'


const Terms = () => {
    const { b2b_data } = useUser();
    const { jump } = useSteps();
    const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
    const wallet = useSelector(state => state?.walletData?.wallet);
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = (values) => {
        setIsLoading(true)
        const payload ={
            terms: true,
            name: values.name
        }
        RecovaService.acceptTerms(payload)
            .then(response=> response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status){
                    updateSetupDispatch({
                        currentStep: 23,
                        termsAccepted: true,
                        termsSignedBy: values.name
                    })
                    jump(23)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setIsLoading(false)
            });
    }

    const months=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let date = Date.now()

    return (
        <div className="float_modal-terms !mx-2.5 sm:!mx-0">
            <div className='!ml-8'>
                <Header 
                    progress={60} 
                    back={()=> {
                        if(recovaSetupData.enabledCreditInsurance) {
                            updateSetupDispatch({
                                currentStep: 19
                            })
                            jump(19)
                        } else {
                            updateSetupDispatch({
                                currentStep: 13
                            })
                            jump(13)
                        }
                    }} 
                /> 
            </div>

            <p className="float_modal-terms-subTitle text-center text-cc-black-1">
                Terms of Use & Disputes Agreement <br />
                (Indemnity for Direct Debit - RECOVA)
            </p>
            <div className="float_modal-terms-scroll pt-4">
                <div className='mb-2'>
                    <p className='text-sm'>
                        Date: {new Date(date).getDate()} {months[new Date(date).getMonth()]} {new Date(date).getFullYear()}
                    </p>
                    {/* <p className='text-sm'>
                        Creditor’s RC Number/Service Code Identifier: <br />
                        <span><strong> RC 1862757/ [Insert from NIBSSS] </strong> </span>
                    </p> */}
                </div>
                
                <p className='text-sm'>From: {b2b_data.businessName} </p>
                <p className='text-sm mb-2'>{b2b_data.address}</p>   

                <p className='text-sm'>TO: Providus Bank</p>                                                	
                <p className='text-sm mb-2'>{wallet?.account_name || ""}</p>   

                <p className='text-sm'>C/O (Creditor Aggregator - Originator): Creditchek Technologies Nigeria Ltd</p>                                                	
                <p className='text-sm mb-2'>Plot 24 Adebisi Ogunniyi Crescent, Lekki Phase 1, Lagos, Nigeria.</p>   

                <p><strong>Rights & Obligations of all participants:</strong></p>
                <p className='text-sm mt-2'> <strong>Originator ({b2b_data?.businessName} via CreditChek Technology platform):</strong></p>
                <p className='text-sm'>It is pertinent that the Originator must first obtain Authority from a Payer before initiating a Transfer from the Payer’s bank account. An Originator must be prepared to accept any Indemnity claim from a Payer, arising from an amendment and or a limitation clause inserted by the Payer before lodgement.</p>
                
                <p className='text-sm mt-2'> An Originator must: - </p>
                <p className='text-sm'>a. Initiate a Transfer strictly within the terms of the Authority (Mandate).</p>
                <p className='text-sm'>b. Initiate a Transfer within the terms of any Advance Notice given to a Payer.</p>
                <p className='text-sm'>The data on a Transfer must conform to that advised by the Authority(mandate).</p>
                <p className='text-sm'>An Originator’s identity quoted on a Transfer, if any, must be the same as that quoted in the Authority(mandate).</p>
                
                <p className='text-sm mt-2'> Once the Originator is notified and accepts that an Authority is cancelled or amended, the Originator must alter his records immediately, to ensure that: -</p>
                <p className='text-sm'>a. a Transfer is not initiated after receipt of such notice of cancellation.</p>
                <p className='text-sm'>b. all Transfers initiated in the future incorporate the amendment so notified, in the case of amendment.</p>

                <p className='text-sm mt-2'>In all cases, an Advance Notice, or the publicity referred to here-before, must allow sufficient time for a Payer to raise a query, countermand a single payment or cancel the Transfer. Where the amount or due date is certain, an Originator shall issue an Advance Notice not less than 24 hours from the due date. In the absence of any specific agreement between an Originator and a Payer, this period shall be the minimum requirement. The Advance Notice could be in writing or electronic as agreed between the Originator and Payer. CreditChek’s advanced notice system for Automated Collection is triggered on three (3) days before the due date and default advanced notice for Manual Collections. </p>
                
                <p className='text-sm mt-2'><strong>Payer (Debtor):</strong> </p>
                <p className='text-sm'> The initiation of a Transfer must be strictly within the terms of the Mandate and any Advance Notice to which a Payer is entitled.</p>
                
                <p className='text-sm mt-2'>A Payer is at liberty to cancel a Mandate at any time by advising the Payer’s bank in writing and with a copy to the Originator. The Originator will duly advise his bank on receipt of the notice of cancellation. It is not the Originator Bank’s responsibility for accepting a cancellation of an Authority. Therefore, the Payer must raise a claim against the Originator through his bank per adventure a payment gets through after cancellation, and the Originator must pay promptly.</p>
                <p className='text-sm'>It is possible that a notice of cancellation is received by the Payer’s bank and Originator, but not be able to act on it before the next due date of payment. Adequate notice must be given by the payer to avoid such situations. For recurrent items where the collection is made in advance of the service provided, this risk is well mitigated.</p>
            
                <p className='text-sm mt-2'><strong>Payer’s (Debtor) Bank:</strong> </p>
                <p className='text-sm'>A Payer’s Bank shall accept Transfers originated under an Authority on the understanding that it is not responsible for verifying or checking that: -</p>
                <p className='text-sm'>a. an Authority exists.</p>
                <p className='text-sm'>b. payments conform to the terms of the Authority.</p>
                <p className='text-sm'>c. any purpose or condition of payment expressed in that Authority is fulfilled.</p>
                <p className='text-sm mt-2'>Depending on the nature of the account, which could be a deposit account or any other type of account that might be subject to notice of withdrawal, a Payer’s Bank may decline to accept instructions to charge Transfers to such accounts.</p>
                <br />


                <p><strong>Indemnity (Dispute Resolution)</strong></p>
                <p className='text-sm mt-2'> Dear Sir/Madam,</p>
                <p className='text-sm'>1. IN CONSIDERATION of you severally accepting instructions from time to time from <span className='font-bold'>CreditChek Inc.</span> (hereinafter called the “Creditor”) or from an agent of the Creditor to debit the account of the Payer with the amounts specified and agreed upon as instruments drawn in paper form or in automated input electronic form written in accordance with the Direct Debit Agreement dated {new Date(date).getDate()} {months[new Date(date).getMonth()]} {new Date(date).getFullYear()} between <span className='font-bold'>{b2b_data?.businessName}</span>, we hereby warrant that the Payer on whose account debit is drawn will have signed a Direct Debit Mandate, and we shall keep you indemnified upon your first demand against all actions,
                    losses, damages, claims, demands costs and expenses (including legal costs, fees, and expenses on a full indemnity basis) howsoever arising, which you may incur or sustain directly or indirectly from such debiting or failure to debit and without our requiring proof of our agreement to the validity of such demand, we shall forthwith pay the amount.</p>

                <p className='text-sm'> 2. We authorise you to admit compromise or reject any claims made upon you without reference to or authority from the Creditor. Furthermore, with respect to any claims or demand for the refund of any money received by you on our behalf pursuant to any debit and transfer made on our behalf in accordance with the [Direct Debit Agreement] [direct debit arrangements between us and the Payer], you are hereby authorised and are at liberty to comply with such demands and claims without any further reference or authorisation from us, you may debit our account and transfer such funds to the account of the Payer.</p>
                <p className='text-sm'> 3. You are not required to verify or check that instructions given to you have been approved and remain in force in respect of any debit and transfer made at the request of the Creditor.</p>

                <p className='text-sm mt-2'><strong>Types of Direct Debit Mandate</strong></p>
                <p className='text-sm'>There are two distinct types of Authorities: -</p>
                <p className='text-sm'>a. Variable Direct Debit Mandate </p>
                <p className='text-sm'>i. suitable for all payments, particularly if amounts are liable to change, and must be used when it is intended to merge payments. </p>
                <p className='text-sm'>ii. the Originator must give an Advance Notice to the Payer of amounts and dates of debiting. </p>
                <p className='text-sm'>b. Fixed Amount Direct Debit Mandate</p>
                <p className='text-sm'>This is suitable when amounts remain constant for the lifetime of the Mandate. </p>

                <p className='text-sm'> 4. You are not required to verify or check that any purpose of payment stated in the Direct Debit Mandate signed by the Payer is fulfilled or is observed.</p>
                <p className='text-sm'> 5. This Direct Debit Indemnity is to be in addition to and is not to prejudice or be prejudiced by any other Direct Debit Indemnity which has been or may now or hereafter be executed by us in connection with the Direct Debit Agreement and shall be binding on us as continuing security notwithstanding any payments from time to time made to you or any settlement of account or disability, incapacity, insolvency that may affect us or any other thing whatsoever.</p>
                <p className='text-sm'> 6. You are to be at liberty without thereby affecting your rights hereunder at any time and from time to time at your absolute discretion to release, discharge, compound with or otherwise vary or agree to the liability under this Direct Debit Indemnity or make any other arrangements with us.</p>
                <p className='text-sm'> 7. This Direct Debit Indemnity shall be enforceable notwithstanding any change in your name or any change in the constitution of the bank, its successors or assigns or by its amalgamation with any other bank or banks.</p>
                <p className='text-sm'>8. This Direct Debit Indemnity shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.</p>
                <br />


                <p><strong>Claims under the indemnity.</strong></p>
                <p className='text-sm mt-2'>• {b2b_data?.businessName} shall undertake to indemnify banks against any loss arising from Transfers.</p>
                <p className='text-sm'>• The liability under this Indemnity shall be limited to a period of one year and shall be reviewed directly by the Central Bank after 12 months upon signing.</p>
                <p className='text-sm'>• There is no limit on the amount of an Indemnity liability. This is because of consequential loss as well as errors of commission and omission.</p>
                <p className='text-sm'>• {b2b_data?.businessName}  must honour an Indemnity claim immediately and in any case within 14 days.</p>
                <p className='text-sm'>• {b2b_data?.businessName}  wishing to make a counterclaim on a Payer’s Bank should seek guidance from the Originator’s Bank. The Originator’s Bank should assist an Originator in lodging the counterclaim with the Payer’s Bank concerned.</p>
                <p className='text-sm'>• A Payer’s Bank shall not claim an Indemnity to recover funds paid in error. The Payer’s
                    Bank may request an Originator for a full or partial refund of funds, in writing. This should not
                    include a request for consequential loss. Any recovery shall be at the discretion of {b2b_data?.businessName}. 
                </p>

                <p className='text-sm mt-2'> If {b2b_data?.businessName} doubts the validity of a claim should seek the advice of the
                    CreditChek Technologies Sales/Business team. </p>
                <p className='text-sm mt-2'> Note: The essential principle of the Scheme is the right of a Payer to seek and obtain an immediate refund of payments made in error from and by {b2b_data?.businessName} </p>
                <p className='text-sm mt-2'> On establishing that {b2b_data?.businessName} was at fault, a Payer’s Bank shall assist a Payer in lodging an Indemnity claim with the CreditChek. </p>

                <br />
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    validationSchema={object({
                        name: string().required().label("Name"),
                    })}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {(props) => (
                        <Form>
                            <div className='flex items-end gap-x-4 -mt-2'>
                                <p className='text-sm mb-[24px]'>Signed By:</p>
                                <div className='flex-1'>
                                    <TextField
                                        name='name'
                                        type='text'
                                        extraClassName="w-full border-t-0 border-x-0 !p-0 !text-sm !rounded-[0px]"
                                    />
                                </div>
                            </div>
                            <p className='text-sm mb-4 -mt-2.5'>For and on behalf of: {b2b_data?.businessName} pursuant to a resolution of the Board of Directors of the Creditor a certified copy of which is annexed hereto.</p>
                            <Button
                                text="Accept & Continue"
                                type="submit"
                                isValid={props.isValid}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Terms