import {Modal} from "react-bootstrap";
import { useEffect, useState } from 'react';
import {toast} from "react-toastify";
import { logToBin } from "../../lib/logs";
import { IncomeService } from "services/income.service";
import { useSelector } from "react-redux";

function InsightKeywords ({show, handleClose}) {
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const [option, setOption] = useState('')
    const [keywordVal, setKeywordVal] = useState('');
    const [fetchedKeywords, setFetchedKeywords] = useState([]);

    useEffect(()=>{
        allKeywords()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const NewKeyword = (e) => {
        e.preventDefault()
        let keywordForm = document.getElementById("keywordForm");
        if(keywordVal===''){
            toast.error('Keyword cannot be empty!')
        } else {
            let payload = JSON.stringify({
                businessId: businessId,
                keywords: [keywordVal]
            })
            IncomeService.addKeyword(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === "success") {
                        toast.success("Keyword added")
                        keywordForm.reset();
                        setKeywordVal('')
                        allKeywords()
                    }
                })
                .catch((err) => {
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        // toast.error(err.response.data.error.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }
    }

    const allKeywords = () => {
        IncomeService.getKeywords(businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    setFetchedKeywords(res.data.keywords)
                    setOption(res.data.option)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doChangeOption = (name) => {
        setOption(name);
        let payload = JSON.stringify({
            option: name
        })
        IncomeService.changeKeywordOption(payload, businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    toast.success("Keyword option changed")
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const removeKeywords = (type, item) => {
        let payload = JSON.stringify({
            keywords: type==='all'?item:[item]
        })
        IncomeService.deleteKeywords(payload, businessId)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === "success") {
                    toast.success("Keyword removed!");
                    allKeywords();
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose} >
            <div className="p-[20px]">
                <form onSubmit={NewKeyword} className="box-border mt-[20px]" id='keywordForm'>
                    <input placeholder="Type and press enter to create keyword" className="w-full h-10 rounded-[5px] border-[1px] border-solid border-[#D0D5DD] text-[#667085] px-2.5" onChange={(e)=>setKeywordVal(e.target.value)} />
                </form>
                <div className="border-[1px] border-solid border-[#F2F4F7] rounded-[8px] font-medium p-2.5 mt-[20px] shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)]">
                    <div className="flex">
                        <p className="text-[#A5A5A5]">Recently added keywords</p>
                        <div className="text-[#B42318] ml-auto cursor-pointer flex items-center" onClick={()=>removeKeywords('all', fetchedKeywords)}><p>clear</p>
                            <svg style={{marginLeft: "4px", cursor:"pointer"}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 3L3.5 9M3.5 3L9.5 9" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2.5 mt-2.5">
                        {
                            fetchedKeywords.length<1?
                            <h6 style={{margin:"10px auto 20px"}}>No recently added keywords</h6>
                            :
                            fetchedKeywords.map((item, idx)=>(
                                <p key={idx} className="flex items-center bg-[#EEF4FF] rounded-[16px] px-2.5 py-[5px] text-[#3538CD]"><span>{item}</span>
                                    <svg onClick={()=>removeKeywords('single', item)} style={{marginLeft: "4px", cursor:"pointer"}}  width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 3L3.5 9M3.5 3L9.5 9" stroke="#3538CD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </p>
                            ))
                        }
                    </div>
                </div>
                <div className="border-[1px] border-solid border-[#F2F4F7] rounded-[8px] font-medium p-2.5 mt-[20px] shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)]">
                    <p className="text-[#A5A5A5]">Keyword Usage Options</p>
                    <div className="myModal_main-widget-shareLeft">
                        <div className='radio'>
                            <p onClick={()=>doChangeOption("default")} className="mt-[5px]">
                                <input type="radio" name="radio-group" checked={option==="default"} />
                                <label>Default</label>
                            </p>
                            <p onClick={()=>doChangeOption("custom")} className="mt-[8px]">
                                <input type="radio" name="radio-group" checked={option==="custom"} />
                                <label>Customized Keywords</label>
                            </p>
                            <p onClick={()=>doChangeOption("both")} className="mt-[8px]">
                                <input type="radio" name="radio-group" checked={option==="both"} />
                                <label>Default & Customized Combined</label>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default InsightKeywords;