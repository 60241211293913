import React from "react";
import {Modal} from "react-bootstrap";


export default function BasicVerification ({show, onCancel, data, banksList }) {
    const bankLogo = banksList?.find(item => item?.code === data?.bank_code)?.logo?.url;

    return(
        <Modal show={show} size="md" onHide={onCancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <h5 className="font-medium text-[1.3em]">Verification Result</h5>
                <hr className="my-[20px]"/>
                <h5 className="font-medium text-[1.3em]">Bank Account Verification</h5>
                <div className="flex">
                    <div style={{width:'60%'}}>
                        <p className="font-semibold text-cc-grey-4 mt-2.5">Account Name</p>
                        <p className="text-cc-black-4 uppercase">{data.account_name}</p>
                    </div>
                    <div>
                        <p className="font-semibold text-cc-grey-4 mt-2.5">Account Number</p>
                        <p className="text-cc-black-4 uppercase">{data.account_number}</p>
                    </div>
                </div>
                <div className="flex">
                    <div style={{width:'50%'}}>
                        <p className="font-semibold text-cc-grey-4 mt-2.5">BVN</p>
                        <p className="text-cc-black-4 uppercase">{data.bvn}</p>
                    </div>
                </div>
                <hr className="my-[20px]"/>
                <div>
                    <p className="font-semibold text-cc-grey-4 mt-2.5">Bank</p>
                    <img src={bankLogo} alt="bank logo" className="w-10"/>
                </div>
            </div>
        </Modal>
    )
}