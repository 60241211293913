import {Routes, Route, Navigate, useLocation} from "react-router-dom";
// import {Container} from "react-bootstrap";
import { useEffect, useState } from "react";
import Login from "./Login";
import SignUp from "./Signup";
import Onboarding from "./Onboarding";
import Otp from "./Otp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ChooseProfile from "./ChooseProfile";
import clsx from 'clsx';

import logo from "images/svg/logo.svg";
import pattern from "images/svg/pattern.svg";
import signupImg from "images/signup.png"
import loginImg from "images/login.png"
import otpImg from "images/otp.png"

function Auth () {
    const location = useLocation().pathname;
    const [path, setPath] = useState("");
    
    useEffect(()=>{
        if(location==="/auth/login"){
            setPath("login")
        } else if (location==="/auth/signup"){
            setPath("signup")
        } else if (location==="/auth/verification"){
            setPath("verification")
        } else if (location==="/auth/forgot-password"){
            setPath("forgot-password")
        } else if (location==="/auth/reset-password"){
            setPath("reset-password")
        } else if (location==="/auth/onboarding"){
            setPath("onboarding")
        } else if (location==="/auth/choose-profile"){
            setPath("profile")
        } else {
            // do nothing
        }
    }, [location])

    console.log(window.screen.height)
    return(
        <div className="w-full box-border">
            <div className="box-border lg_min:flex lg_min:relative lg_min:w-full">
                <div className="w-[50vw] mb-[50px] lg:w-full lg:h-[100vh] sm:px-[20px]">
                    <div className="max-w-[450px] m-auto block">
                        { 
                            path!=="profile" && 
                            <div>
                                <img src={logo} alt="logo" className="w-[200px] mt-[70px] mb-[30px] sm:my-[30px]" />
                                <h4 className="font-bold text-cc-grey-21 text-2xl">
                                    Let's get you started
                                </h4>
                            </div>
                        }
                        <Routes>
                            <Route path="/" element={<Navigate to="/auth/login" />}/>
                            <Route path="/invite/:token" element={<Onboarding/>}/>
                            <Route path="/signup" element={<SignUp/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/choose-profile" element={<ChooseProfile/>}/>
                            <Route path="/forgot-password" element={<ForgotPassword/>}/>
                            <Route path="/reset-password" element={<ResetPassword/>}/>
                            <Route path="/verification/:type/:email/:bussName/:id/:userId" element={<Otp/>}/>
                        </Routes>
                    </div>
                </div>
                <div 
                    className={clsx(
                        "bg-cc-blue-6 w-[40vw] rounded-[15px] lg:hidden",
                        window.screen.height>720 ? "h-[90vh] fixed right-[100px] top-[30px] bottom-[30px]":"h-[650px] my-10 relative"
                    )}
                >
                    <img src={pattern} alt="pattern" className="w-[70%] absolute bottom-0 left-0" />
                    <div className="bg-[rgba(183,233,246,0.25)] backdrop-blur-lg rounded-[15px] h-[calc(100%-40px)] m-[20px] relative">
                        <h3 className="py-10 px-[20px] text-white font-bold text-[2em] xl:text-[1.7em]">The easiest <br/>way to assess and <br/>verify creditworthiness</h3>
                        <img 
                            src={
                                path==="login" || path==="forgot-password" || path==="onboarding" ?loginImg
                                : path==="signup" || path==="reset-password"?signupImg
                                : otpImg
                            }
                            alt="welcome" 
                            className="absolute w-[86%] left-[7%] bottom-0"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;