import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";


const timeout = 15000;

export const SpectrumService = {
    getContributions: (type) => {
        return APIKit(timeout).get(`/dln/ui/get-contributions?type=${type}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getDebtProfileSearched: (type) => {
        return APIKit(timeout).get(`/dln/ui/search-history?type=${type}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getSingleDebtProfile: (type, identityNo) => {
        return APIKit(timeout).get(`/dln/ui/search?type=${type}&identityNo=${identityNo}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getCSVHistory: () => {
        return APIKit(timeout).get(`/dln/ui/csv-history?type=business`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    agreeTandC: () => {
        return APIKit(timeout).post(`/dln/ui/agree-terms`, {}, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    requestDevAccess: () => {
        return APIKit(timeout).post(`/dln/ui/request-dev-access`, {}, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    requestProdAccess: () => {
        return APIKit(timeout).post(`/dln/ui/request-prod-access`, {}, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getSpectrumRequests: () => {
        return APIKit(timeout).get(`/dln/ui/get-spectrum-requests`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    }
}