import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { useEffect } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";


export default function Pagination ({
    data, 
    setDisplayData,
    showDownload=false,
    onDownloadClick,
    pageCountOffset,
}) {
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0)
    const itemsPerPage = 10;

    useEffect(()=>{
        const endOffset = itemOffset + itemsPerPage;
        setDisplayData(data?.slice(itemOffset, endOffset) || []);
        setPageCount(Math.ceil(data?.length / itemsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemOffset, itemsPerPage, data])
    
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data?.length;
        setItemOffset(newOffset);
        pageCountOffset(newOffset);
    };
  
    return (
        <div className='flex items-center gap-x-4'>
            {
                showDownload &&
                <button 
                    onClick={onDownloadClick}
                    className='flex gap-x-1 font-semibold items-center border border-[#D0D5DD] bg-[#D0D5DD] text-cc-black-4 px-2 py-1 font-normal whitespace-nowrap rounded-[6px]'
                >
                    Download CSV
                </button>
            }
            <ReactPaginate
                breakLabel="..."
                nextLabel={
                    <button className='flex gap-x-1 items-center border border-cc-black-4 text-cc-black-4 px-2 py-1.5 rounded-[6px]'>
                        <p className='font-semibold'>Next</p>
                        <AiOutlineArrowRight className='w-6 font-semibold'/>
                    </button>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                    <button className='flex gap-x-1 items-center border border-cc-black-4 text-cc-black-4 px-2 py-1.5 rounded-[6px]'>
                        <AiOutlineArrowLeft className='w-6 font-semibold'/>
                        <p className='font-semibold'>Previous</p>
                    </button>
                }
                renderOnZeroPageCount={null}
                containerClassName='flex items-center text-sm gap-1.6 list-none w-full'
                pageLinkClassName='p-2 font-normal cursor-pointer text-cc-grey-5 hover:text-cc-grey-5'
                pageClassName="w-8 rounded-[4px] flex justify-center hover:bg-cc-blue-18 sm:hidden"
                previousClassName='mr-auto'
                nextClassName="ml-auto"
                nextLinkClassName='mt-2.5'
                breakLinkClassName="p-2 sm:hidden"
                activeClassName='bg-cc-blue-18'
                activeLinkClassName='text-cc-primary'
            />
        </div>
    );
}