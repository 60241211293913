import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { IdentityService } from "services/identity.service";
import loader from "../../images/gif/blue-loader.gif";
import { logToBin } from "../../lib/logs";


function BorrowerConsent () {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState("Thank you! You have successfully accepted the terms and granted this business consent for assessment.")

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    useEffect(()=>{
        let payload = JSON.stringify({
            businessId: searchParams.get("businessId"),
            borrowerId: searchParams.get("borrowerId")
        })
        IdentityService.grantBorrowerConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status === true){
                    setMessage("Thank you! You have successfully accepted the terms and granted this business consent for assessment.")
                } else {
                    toast.error(res.message)
                    setMessage(res.message)
                }
            })
            .catch((err)=>{
                setIsLoading(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                    setMessage(err.response.data.message)
                } else{
                    setMessage("An error occured, please try again later!")
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div style={{
            background: "rgba(183, 233, 246, 0.25)",
            backdropFilter: "blur(16px)",
            padding:"100px 0 50px",
            minHeight:"100vh",
            marginBottom:"-20px"
        }}>
            <div style={{
                maxWidth:"400px", 
                display:"block", 
                margin:"auto",
                border: "1px solid #0046E6",
                background: "white",
                borderRadius: "10px",
            }}>
                <div className="m-[20px]">
                    <div className="myModal_main-widget-header">
                        {
                            isLoading ?
                            <div>
                                <img src={loader} alt="" className="w-[70px] block mx-auto mb-[20px]" />
                                <p className="text-[1em] text-center font-bold w-[100%]">Please Wait!</p>
                            </div>
                            :
                            <p className="text-[1em] text-center font-bold w-[100%]">{message}</p>
                        }
                    </div>
                    <div className="myModal_main-widget-action">
                        <button className="publish w-[100%] mt-[30px]" onClick={handleClose}>Close Page</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BorrowerConsent;