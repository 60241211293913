
//Library methods
import React from 'react'
import { useSteps } from 'react-step-builder'

//Components
import Header from './Header'

//Utilities
import editIcon from 'images/svg/recova/edit.svg'
import { useRecovaSetup } from 'hooks/redux.hook'
import { getBankLogo } from 'utils/helpers'
import Button from 'components/shared/Button'
import { useSelector } from 'react-redux'

const Review = () => {
    //hooks
    const { jump } = useSteps();
    const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
    const ngBanks = useSelector((state) => state.banksData.banks);

    const switchPage = (num) => {
        updateSetupDispatch({
            currentStep: num
        })
        jump(num)
    }
    
  return (
    <div className="rounded-3xl px-2.5 py-4 sm:px-0">
        <Header progress={80} /> 
        <h2 className='text-xl font-bold leading-5'>
            Review and finish up
        </h2>
        <p className='mt-2'>
            You are almost ready to start using recova. Take a 
            moment to review and confirm our information
        </p>

        <div
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
          className='px-3 py-3 rounded-2xl my-4'
        >
            <p className='text-sm mt-3'> How do you want to use Recova </p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg my-2 cursor-pointer' 
                style={{width: "95%"}}
            >
                <p className='capitalize'>Lending</p>
                <img src={editIcon} alt="icon" onClick={()=> switchPage(1)} />
            </div>

            <p className='text-sm mt-3'> Your preferred payout mode</p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg my-2 cursor-pointer' 
                style={{width: "95%"}}
            >
                <p className='capitalize'>{recovaSetupData?.paymentOption}</p>
                <img src={editIcon} alt="icon" onClick={()=>switchPage(5)} />
            </div>

            <p className='text-sm mt-3'> Payout Details</p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg my-2 cursor-pointer' 
                style={{width: "95%"}}
            >
                {
                    recovaSetupData?.paymentOption === "self-service" ?
                    <>
                        <div>
                            <p className='text-sm'>e-Wallet</p>
                            <p className='text-sm'>{recovaSetupData?.ewalletNumber}</p>
                        </div> 
                    </>
                    :
                    <>
                        <div>
                            <p className='text-sm'>{recovaSetupData?.linkedAccount?.accountName||"---"}</p>
                            <div className='flex items-center gap-2'>
                                {getBankLogo(ngBanks, recovaSetupData?.linkedAccount?.bankCode, "50px")}
                                <div>
                                    <p className='text-sm'>{recovaSetupData?.linkedAccount?.bankName||"---"}</p>
                                    <p className='text-sm'>{recovaSetupData?.linkedAccount?.accountNumber||"---"}</p>
                                </div>
                            </div>
                        </div> 
                    </>
                }
                <img 
                    src={editIcon} 
                    alt="icon"
                    onClick={()=> {
                        if(recovaSetupData?.paymentOption === "self-service"){
                            switchPage(7)
                        }else{
                            switchPage(9)
                        }
                    }} 
                />
            </div>
            
            <p className='text-sm mt-3'>Credit Insurance - {recovaSetupData?.enabledCreditInsurance ? "Enabled":"Not Enabled"}</p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg my-2 cursor-pointer' 
                style={{width: "95%"}}
                // onClick={()=> jump(1)}
            >
                <p className='capitalize'>{recovaSetupData?.creditUseCase?.join(", ") || "None"}</p>
                <img src={editIcon} alt="icon" onClick={()=>switchPage(13)} />
            </div>
            
            <p className='text-sm mt-3'>Loan Product Definition</p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg my-2 cursor-pointer' 
                style={{width: "95%"}}
            >
                <p className='capitalize'>
                    {
                        recovaSetupData?.loanProdDefs?.length < 1 ?
                        "None"
                        :
                        recovaSetupData?.loanProdDefs?.map((item, idx)=>(
                           <span key={idx}>{item.name}{recovaSetupData?.loanProdDefs?.length!==idx+1 && ","} </span>
                        ))
                    }
                </p>
                <img src={editIcon} alt="icon" onClick={()=>switchPage(17)} />
            </div>

            <p className='text-sm mt-3'> Dispute Indemnity Agreement </p>
            <div 
                className='w-full bg-cc-grey-19 flex justify-between p-2.5 rounded-lg mt-2 mb-4 ' 
                style={{width: "95%"}}
            >
                <p className='capitalize'>{recovaSetupData?.termsAccepted && "Accepted"}</p>
            </div>
            <Button
                text="Agree & Submit"
                onClick={()=> switchPage(25)}
                isValid={true}
                className="bg-cc-primary"
            />
          
        </div>
    </div>
  )
}

export default Review