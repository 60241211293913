import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import { getGeolocation } from "../../lib/externalApis";
import { resendVerificationCode } from "../../lib/doVerification";
import { deviceDetect } from 'react-device-detect';
import { AuthService } from "services/auth.service";
import { TextField } from "components/Form/TextField";
import { Formik, Form } from 'formik';
import { logToBin } from "../../lib/logs";
import { Mixpanel } from '../../lib/mixpanel';
import { loginSchema } from "utils/validationSchemas";
import Button from "components/shared/Button";
import { useDispatch } from "react-redux";
import { initial, multiTenant } from "redux/login";
import { setActiveApp, setApps } from "redux/apps";


function Login () {
    let history = useNavigate();
    const dispatch = useDispatch();
    const [ipDeets, setIpDeets] = useState({
        country_name: "Nigeria",
        IPv4: "102.89.45.161",
        city: 'Lagos'
    });
    const [userAgent, setUserAgent] = useState('')
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect( () => {
        if(deviceDetect().ua===undefined){
            setUserAgent(deviceDetect().userAgent)
        } else {
            setUserAgent(deviceDetect().ua)
        }
        getGeolocation()
        .then(response => response.data)
        .then((res) => {
            setIpDeets(res)
        })
        .catch(()=>{})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const doLogin = (values) => {
        setLoginLoading(true)
        let payload = JSON.stringify({
            email: values?.email?.toLowerCase(),
            password: values?.password,
        });
        AuthService.LoginAction(payload, ipDeets, userAgent)
            .then((response) => response.data)
            .then((res) => {
                setLoginLoading(false)
                if (res.status === true) {
                    if(res?.data?.multipleAccount) {
                        history('/auth/choose-profile');
                        dispatch(multiTenant({
                            email: values?.email,
                            password: values?.password,
                            accounts: res.data.accounts,
                        }))
                    } else {
                        toast.success(`Login successful! Redirecting to dashboard...`);
                        sessionStorage.setItem(
                            "b2b_details",
                            JSON.stringify({
                                bussinessName: res.data.businessName,
                                bussinessId: res.data.businessId
                            })
                        );
                        dispatch(initial(res.data));
                        dispatch(setApps(res.data.apps));
                        dispatch(setActiveApp(res.data.apps[0]))
                        sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.isActive }));
                        sessionStorage.setItem( "b2bCompliance", JSON.stringify({ isComplied: res.data.isComplied }));
                        setTimeout( function (){
                            res.data.isComplied ? history('/'):history('/compliance');
                        }, 1500);
                    }
                } else if (res.status === "success") {
                    //mixpanel
                    Mixpanel.identify(res.data.user.id,);
                    Mixpanel.track('Login button was clicked', {
                        bussinessName: res.data.businessName,
                        email: res.data.user.email,
                        role: res.data.user.role,
                    });
                    Mixpanel.people.set({
                      $first_name: res.data.user.firstName,
                      $last_name: res.data.user.lastName,
                      $email: res.data.user.email
                    });
                    routeToDashboard(res)
                } else {
                    toast.error(res.message);
                }
            })
            .catch((err) => {
                setLoginLoading(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    if(err.response.data.message === 'please verify your email'){
                        setTimeout( function (){
                            console.log(err.response.data)
                            resendVerificationCode(values.email, err.response.data.data.businessId, err.response.data.data.businessName, err.response.data.data.userId)
                        }, 1500);
                        toast.info('Please verify your email')
                    } else if(err.response.data.message === "Verify this login"){
                        toast.info('Please verify your login attempt')
                        sessionStorage.setItem('password', values.password)
                        history(`/auth/verification/login-verify/${values.email}/${err.response.data.data.businessName}/${err.response.data.data.businessId}/${err.response.data.data.userId}`)
                    } else {
                        toast.error(err.response.data.message);
                    }
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const routeToDashboard = (res) => {
        toast.success(`Login successful!`);
        dispatch(initial(res.data))
        res?.data?.user?.roleId && sessionStorage.setItem("roleId", JSON.stringify(res?.data?.user?.roleId))
        
        sessionStorage.setItem( "webhook", res.data.webhookUrl);

        sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.user.isActive })); 
        sessionStorage.setItem( "b2bCompliance", JSON.stringify({ isComplied: res.data.isComplied }));

        dispatch(setApps(res.data.apps));
        dispatch(setActiveApp(res.data.apps[0]))
        
        history('/');
    }

    const initialValues ={
        email: '',
        password: '',
    }

    return(
        <div className="mt-[80px]">
            <Formik
                initialValues={initialValues}
                validationSchema={loginSchema}
                onSubmit={(values) => {
                    doLogin(values)
                }}
            >  
                {(props) => (
                    <Form>
                        <TextField
                            name='email'
                            type='text'
                            placeholder="Email Address"
                        />
                        <TextField
                            name='password'
                            type='password'
                            placeholder="Password"
                        />
                        <Button
                            isValid={props.isValid}
                            isLoading={loginLoading}
                            type="submit"
                            text="Login"
                            className="mb-[30px] mt-2.5"
                        />
                    </Form>
                )}
            </Formik>
            <p className="text-cc-black-5 mb-1">Don't have an account? <Link to="/auth/signup"><span className="text-cc-primary">Create an account</span></Link></p>
            <p className="text-cc-black-5">Forgot your password? <Link to="/auth/forgot-password"><span className="text-cc-primary">Reset Password</span></Link></p>
        </div>
    )
}

export default Login;