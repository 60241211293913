import {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Back from "components/shared/back";
import {Row, Col} from "react-bootstrap";
import NumberModal from "components/Modal/RadarNumberModal";
import { IncomeService } from "services/income.service";
import { RadarService } from "services/radar.services";
import { IdentityService } from "services/identity.service";
import { BillingService } from "services/billing.service";
import MultipleValueTextInput from 'react-multivalue-text-input';
import { IoMdAddCircle } from 'react-icons/io';
import BankAccounts from "components/shared/BankAccounts";
import { logToBin } from "../../../../lib/logs";
import ConfirmAction from "components/Modal/ConfirmAction";
import { useSelector } from "react-redux";

// import radar from "../../../../images/svg/sidebar/radar.svg";
import info from "../../../../images/svg/identity/info.svg";
import file from "../../../../images/svg/identity/file.svg";
import download from "../../../../images/svg/identity/download.svg";


function Details ({setDetailName}) {
    let params = useParams();
    
    const navigate = useNavigate()
    const [data, setData] = useState({})
    const [radarData, setRadarData] = useState('')
    const [radarPrice, setRadarPrice] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [addNumber, setAddNumber] = useState([])
    const [addEmail, setAddEmail] = useState([])
    const [showNumberInput, setShowNumberInput] = useState(false)
    const [showEmailInput, setShowEmailInput] = useState(false)
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const [identityBanks, setIdentityBanks] = useState([])
    const [showRadarModal, setShowRadarModal] = useState('')
    const [mobileNumber, setMobileNumber] = useState('');
    const [disableAction, setDisbleAction] = useState(false);
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)

    let storedIdentity = {};
    try {
        storedIdentity = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    }   

    useEffect(() => {
        let payload = {
            action: "get borrower connected banks"
        }
        BillingService.getBillingPricing(payload)
        .then(response=> response.data)
        .then(res => {
            if(res.success === true){
                setRadarPrice(res.data.price)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            if(err.response){
                logToBin(err.response.data, window.location.href);
                // toast.error(err.response.data.error.message);
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    }, [])

    useEffect(()=>{
        getCustomerBankData();
        fetchIdentity();
        IncomeService.encryptText(JSON.stringify({text: businessId})).then(resp1 => {
            IncomeService.encryptText(JSON.stringify({text: params.id})).then(resp2 => {
                let business_id = resp1
                let borrowerId = resp2
                let payload = {business_id, borrowerId  }
                IncomeService.getUserAcccounts(payload)
                    .then((response) => response.data)
                    .then((res) => {
                        if (res.status === "success" ) {
                            setIdentityBanks(res.data)
                        } else {
                            // toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        // err
                    });
            })
        })
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchIdentity = () => {
        IdentityService.checkIdentity(params.bvn)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === true) {
                    setDetailName(params?.name)
                    setData(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            });
    }

    const getCustomerBankData = () => {
        RadarService.getExistingBankRecords(params?.id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setRadarData(res.data)
                } else if(res.success === false){
                    toast.error(res.data?.message)
                }else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const radarSearch = (type, mobileNumber) => {
        setDisbleAction(true)
        let payload = {
            type,
            mobile_number: mobileNumber
        }
        RadarService.connectedBanks(payload)
        .then((res) => {
            setDisbleAction(false)
            if (res.data.success === true) {
                toast.success("Radar search successful!!")
                sessionStorage.setItem( "radarDetails", JSON.stringify({ radarData: res.data.data }));
                window.location=`/identity/radar/${res.data.data.mobile_number}`;
                setTimeout(() => {
                    setShowModal(false)
                }, 2000);
                
            }else if (res.status === false) {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            setDisbleAction(false)
            if(err.response){
                logToBin(err.response.data, window.location.href);
                // toast.error(err.response.data.message);
                setTimeout(() => {
                    setShowModal(false)
                }, 2000);
                
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    }

    var getInitials = function (string) {
        var names = string?.split(' '),
            initials = names?.[0]?.substring(0, 1).toUpperCase();
        
        if (names?.length > 1) {
            initials += names?.[names?.length - 1]?.substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const handleInputNumber = () => {
        setShowNumberInput(!showNumberInput)
    }

    const handleInputEmail = () => {
        setShowEmailInput(!showEmailInput)
    }

    const handleNumberSubmit = () => {
        let payload = {
            phoneNumbers: addNumber,
        }
        IdentityService.updateIdentityData(params.id, payload)
            .then((response) => response.data)
            .then((res) => {
            if(res.status === true){
                toast.success(res.message);
                setAddNumber([]);
                setShowNumberInput(false)
                fetchIdentity();
            }else{
                toast.error(res.message)
            }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                    setTimeout(() => {
                        setShowEmailInput(false)
                    }, 1500);
                    
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    const handleEmailSubmit = () => {
        let payload = {
            emails: addEmail 
        }
        IdentityService.updateIdentityData(params.id, payload)
            .then((response) => response.data)
            .then((res) => {
            if(res.status === true){
                toast.success(res.message)
                setAddEmail([]);
                setShowEmailInput(false);
                fetchIdentity();
            }else{
                toast.error(res.message)
            }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                    setTimeout(() => {
                        setShowModal(false)
                    }, 1500);
                    
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doAction =() => {
        setShowRadarModal(true)
        setShowModal(false)
    }

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top mb-6">
                    <div className="bg-white w-[130px] h-[130px] mr-[20px] shrink-0 overflow-hidden rounded-full flex items-center justify-center md:w-[60px] md:h-[60px]">
                        <h1 className="text-[3.5em] md:text-[2em]">{getInitials(params?.name)}</h1>
                    </div>
                    <div>
                        <h5>{params?.name}</h5>
                        <p>ID: {params?.id}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr/>
                <Row>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">BVN</p>
                            <p className="text2">{params?.bvn}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{data?.dateOfBirth===""||data?.dateOfBirth===null?"Not Available":data?.dateOfBirth}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{data?.gender===""||data?.gender===null?"Not Available":data?.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Marital Status</p>
                            <p className="text2">{data?.maritalStatus===""||data?.maritalStatus===null?"Not Available":data?.maritalStatus}</p>
                        </div>
                    </Col>
                    {
                        storedIdentity?.occupation &&
                        <Col lg={3} md={4}>
                            <div className="identity_result-box1">
                                <p className="text1">Occupation</p>
                                <p className="text2">{storedIdentity?.occupation}</p>
                            </div>
                        </Col>
                    }
                    {
                        storedIdentity?.occupation &&
                        <Col lg={3} md={4}>
                            <div className="identity_result-box1">
                                <p className="text1">Business Terms</p>
                                <p className="text2">{storedIdentity?.businessTermsAgreement?.isAccepted?"Accepted":"Not Accepted"}</p>
                            </div>
                        </Col>
                    }
                    {storedIdentity?.businessTermsAgreement?.isAccepted &&
                        <Col lg={3} md={4}>
                            <div className="identity_result-box1">
                                <p className="text1">Date Accepted</p>
                                <p className="text2">{storedIdentity?.businessTermsAgreement?.dateAccepted?.substring(0, 10)} {storedIdentity?.businessTermsAgreement?.dateAccepted?.substring(11, 16)}</p>
                            </div>
                        </Col>
                    }
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Number</p>
                            {data?.mobileNumber?.length===0 || data?.mobileNumber?.[0]===null ?
                                "Not Available" 
                                :
                                data?.mobileNumber?.map((item,idx)=>(
                                    <p key={idx} className="text2" style={{margin:'0'}}>
                                        {item===""?
                                            ''
                                            :<span key={idx}>{item}</span>
                                        }<br/>
                                    </p>
                                ))
                            }
                            <div className="phoneNumber">
                                { !showNumberInput && <button onClick={ handleInputNumber} > Add Number <IoMdAddCircle color="#0046e6" fontSize={'18px'} /> </button>}
                                {
                                    showNumberInput &&
                                    <div>
                                        <MultipleValueTextInput
                                            onItemAdded={(item, allItems) => setAddNumber(allItems)}
                                            onItemDeleted={(item, allItems) =>setAddNumber(allItems)}
                                            name="item-input"
                                            className="inputBox"
                                            placeholder="Type and press enter"
                                        />
                                        {addNumber?.length>0 && <button className="submit" onClick={ handleNumberSubmit} > Done  </button>}
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            {data?.email?.length===0 ?
                                "Not Available" 
                                :
                                data?.email?.map((item,idx)=>(
                                    <p key={idx} className="text2" style={{margin:'0'}}>
                                        {item===""?
                                            ''
                                            :<span key={idx}>{item}</span>
                                        }<br/>
                                    </p>
                                ))
                            }
                            
                            <div className="phoneNumber">
                                {
                                    !showEmailInput &&
                                    <button onClick={ handleInputEmail}> Add Email <IoMdAddCircle color="#0046e6" fontSize={'18px'} /> </button>
                                }
                                {
                                    showEmailInput &&
                                    <div>
                                        <MultipleValueTextInput
                                            onItemAdded={(item, allItems) => setAddEmail(allItems)}
                                            onItemDeleted={(item, allItems) => setAddEmail(allItems)}
                                            name="item-input"
                                            className="inputBox"
                                            placeholder="Type and press enter"
                                        />
                                        {addEmail?.length>0 && <button className="submit" onClick={ handleEmailSubmit} >Done</button>}
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={8}>
                        <div className="identity_result-box1">
                            <p className="text1">Address</p>
                            <p className="text2">{data?.currentAddress===""||data?.currentAddress===null?"Not Available":data?.currentAddress}</p>
                        </div>
                    </Col>
                </Row>
                <hr/>
                {
                    storedIdentity?.occupation &&
                    <>
                        <Row>
                            <h5 style={{marginLeft:'10px'}}>Guarantor Details</h5>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Full Name</p>
                                    <p className="text2">{storedIdentity?.guarantorInfo?.fullname}</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Email Address</p>
                                    <p className="text2">{storedIdentity?.guarantorInfo?.email}</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Phone Number</p>
                                    <p className="text2">{storedIdentity?.guarantorInfo?.mobileNumber}</p>
                                </div>
                            </Col>
                            {storedIdentity?.guarantorInfo?.consentStatus==="approved" &&
                                <Col lg={3} md={4}>
                                    <div className="identity_result-box1">
                                        <p className="text1">BVN</p>
                                        <p className="text2">{storedIdentity?.guarantorInfo?.bvn}</p>
                                    </div>
                                </Col>
                            }
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Consent</p>
                                    <p className="text2">{storedIdentity?.guarantorInfo?.consentStatus==="approved"?"Approved":"Not yet approved"}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <h5 style={{marginLeft:'10px'}}>Employer Details</h5>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Full Name</p>
                                    <p className="text2">{storedIdentity?.employmentInfo?.employerName}</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Address</p>
                                    <p className="text2">{storedIdentity?.employmentInfo?.employerAddress}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <h5 style={{marginLeft:'10px'}}>Loan Details</h5>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Loan Amount</p>
                                    <p className="text2">₦{storedIdentity?.loanInfo?.loanAmount}</p>
                                </div>
                            </Col>
                            <Col lg={3} md={4}>
                                <div className="identity_result-box1">
                                    <p className="text1">Loan Tenure</p>
                                    <p className="text2">{storedIdentity?.loanInfo?.loanTenure}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr/>
                    </>
                }
                <Row>
                    <Col md={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Employment History</p>
                            <p className="text2">Not Available</p>
                            {data?.employmentHistory?.length===0 ?
                                "Not Available" 
                                :
                                data?.employmentHistory?.map((item,idx)=>(
                                    <p className="text2" key={idx}>{item}</p>
                                ))
                            }
                        </div>
                    </Col>
                    <Col lg={6} md={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Residential History</p>
                            {data?.residenceHistory?.length===0 ?
                                "Not Available" 
                                :
                                data?.residenceHistory?.map((item,idx)=>(
                                    <p className="text2" key={idx}>{item}</p>
                                ))
                            }
                        </div>
                    </Col>
                    <Col md={6} lg={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Linked Accounts to this Business</p>
                            {identityBanks?.length===0 ?
                                "Not Available" 
                                :
                                identityBanks?.map((item,idx)=>(
                                    <BankAccounts 
                                        bankImage={ngBanks?.find(o => o.code === item?.institution?.bankCode)?.logo?.url }
                                        bankName={item?.institution?.name?.split(' ').slice(0,2).join(' ')} 
                                        bankAcc={item?.accountNumber}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                </Row>
                {
                    data?.bankStatement?.length>=1 &&
                    <>
                        <hr/>
                                <div className="identity_result-box1" style={{boxSizing:'border-box'}}>
                                    <p className="text1">Uploaded Bank Statements</p>
                                    <Row>
                                        {
                                            data?.bankStatement?.map(item=>(
                                                <Col md={6} lg={4}>
                                                    <div className="identity_result-box1-uploadBox">
                                                        <img src={file} alt="" />
                                                        <div className="identity_result-box1-uploadBox-txt">
                                                            <h6>{item.accountName}.pdf</h6>
                                                            <p>{item.bankName}</p>
                                                        </div>
                                                        <div className="identity_result-box1-uploadBox-dwnld">
                                                            <a href={item.url} download target="_blank" rel="noreferrer">
                                                                <img src={download} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                        <hr/>
                    </>
                }
                {/* <div className="identity_share" style={{marginTop: '50px'}}>
                    <p className="mr-4">See other NUBAN bank accounts used by {params?.name} to boost this application</p>
                    <div onClick={() => setShowModal(true)} style={{flexShrink: '0'}}>
                        <img src={radar} alt="radar" className="mr-4" />
                            Radar
                    </div>
                </div> */}
                <div style={{maxWidth:"350px", marginTop:"20px"}}>
                        {
                            radarData && radarData?.banks?.map((bank, index) =>
                             <BankAccounts 
                                key={index}
                                bankImage={ngBanks.find(o => o.code === bank?.bankCode)?.logo?.url }
                                bankName={bank?.bankName.split(' ').slice(0,2).join(' ')} 
                                bankAcc={bank?.accountDescription}
                            />
                         )
                        }
                </div> 
                <NumberModal show={showModal} doAction={doAction} cancel={()=>setShowModal(false)} setMobileNumber={setMobileNumber} />
                <ConfirmAction
                    show={showRadarModal}
                    onCancel={()=>setShowRadarModal(false)}
                    onAction={()=>{
                        radarSearch('mobile_number', mobileNumber)
                        setDisbleAction(true);
                    }}
                    loading={disableAction}
                    title={`Use Radar?`}
                    content={`Using the radar service would attract a charge of ₦${radarPrice}.`}
                />
            </div>
        </div>
    )
}

export default Details;