import { Modal } from "react-bootstrap";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import floatSuccess from "../../../../images/svg/float/float-success.svg";
import { Link, useNavigate } from "react-router-dom";
import Back from "components/shared/back";
import clsx from "clsx";
import { FloatService } from "services/float.service";
import { dateFormatter, currencyFormatter, handleRequestErrors } from "utils/helpers";
import ConfirmAction from "components/Modal/ConfirmAction";


function RepaymentSchedule () {
    const [show, setShow] = useState(false)
    const [checkedValue, setCheckedValue] = useState('next')
    const [showSuccess, setShowSuccess] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [data, setData] = useState([]);
    let navigate = useNavigate();
    let repaymentData = JSON.parse(sessionStorage.getItem('floatRepayment'));
    const repaymentSchedule = repaymentData?.repaymentschedule;
    const [repaymentLoading, setRepaymentLoading] = useState(false)
    
    useEffect(() => {
        setData(repaymentSchedule)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = (e) => {
        setRepaymentLoading(true)
        let payload = {
            floatId: repaymentData?.floatId,
            type: checkedValue
        }
        FloatService.floatBilling(payload)
            .then(response=> response.data)
            .then(res => {
                setRepaymentLoading(false)
                if(res.success === true){
                    setShowSuccess(true)
                    setShowWarningModal(false)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setRepaymentLoading(false)
                handleRequestErrors(err)
            });  
    }

    return(
        <div className="py-[30px] px-10 md:pr-[20px]">
            <div className="float_request" style={{marginTop: '50px'}}>
                <Back to={()=> navigate("/float")} />
                <div className={clsx(
                        "grid gap-6 mb-[30px] xl:grid-cols-2 sm:!grid-cols-1",
                        window.screen.width>1400 ? "grid-cols-4":"grid-cols-3"
                    )}
                >
                    <div className="border-[1px] border-[#EAECF0] shadow-md rounded-lg p-[20px] min-h-[150px] relative">
                        <p className="text-[#808080]">Loan Amount</p>
                        <h4 className="font-medium text-[1.3em] my-2">{currencyFormatter(repaymentData?.loanAmount || 0)}</h4>
                    </div>
                    <div className="border-[1px] border-[#EAECF0] shadow-md rounded-lg p-[20px] min-h-[150px] relative">
                        <p className="text-[#808080]">Loan Duration</p>
                        <h4 className="font-medium text-[1.3em] my-2">{repaymentData?.loanDuration} months</h4>
                    </div>
                    <div className="border-[1px] border-[#EAECF0] shadow-md rounded-lg p-[20px] min-h-[150px] relative">
                        <p className="text-[#808080]">Next Repayment Date</p>
                        <h4 className="font-medium text-[1.3em] my-2">{repaymentData?.nextRepaymentDate?repaymentData?.nextRepaymentDate?.slice(0,10):'YYYY-MM-DD'}</h4>
                    </div>
                    <div className="border-[1px] border-[#EAECF0] shadow-md rounded-lg p-[20px] min-h-[150px] relative">
                        <p className="text-[#808080]">Next Repayment Amount</p>
                        <h4 className="font-medium text-[1.3em] my-2">{currencyFormatter(repaymentData?.nextRepaymentAmount || 0)}</h4>
                        <button 
                            onClick={()=> repaymentData?.nextRepaymentAmount ? setShow(true) : toast.error('No active loan repayment')} 
                            className="bg-cc-blue-6 border-none text-white rounded py-[5px] px-4"
                        >
                            Make Payment Now
                        </button>
                    </div>
                </div>
                <Table
                    noTitleIcon={true}
                    tableTitle={"Repayment Schedule"}
                    data={data}
                    hideSearch={true}
                    rows={[
                        {
                            header:'TransactionID',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px]">{item._id?.slice(-8)}</span>
                            ),
                        },
                        {
                            header:'Amount',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px]">{currencyFormatter(item?.totalAmount)}</span>
                            ),
                        },
                        {
                            header:'Date',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px]">{dateFormatter(item?.dueDate)}</span>
                            ),
                        },
                        {
                            header:'Status',
                            view: (item) => (
                                <div className="py-[15px] pl-[10px] flex">
                                    <div 
                                        className={clsx(
                                            "flex items-center px-2 rounded-2xl cursor-pointer",
                                            item.repaymentStatus==="scheduled" ? "bg-cc-brown-5"
                                            : item.repaymentStatus==="overdue" ? "bg-cc-red-12"
                                            : item.repaymentStatus==="paid" ? "bg-cc-green-6"
                                            : "bg-cc-yellow-4"
                                        )}
                                    >
                                        <div
                                            className={clsx(
                                                "w-1.5 h-1.5 rounded-full mr-1",
                                                item.repaymentStatus==="scheduled" ? "bg-cc-brown-4"
                                                : item.repaymentStatus==="overdue" ? "bg-cc-red-10"
                                                : item.repaymentStatus==="paid" ? "bg-cc-green-5"
                                                : "bg-cc-yellow-3"
                                            )}
                                        ></div>
                                        <span 
                                            className={clsx(
                                                "text-sm whitespace-nowrap capitalize",
                                                item.repaymentStatus==="scheduled" ? "text-cc-brown-4"
                                                : item.repaymentStatus==="overdue" ? "text-cc-red-10"
                                                : item.repaymentStatus==="paid" ? "text-cc-green-5"
                                                : "text-cc-yellow-3"
                                            )}
                                        >
                                            {item.repaymentStatus}
                                        </span>
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
            <ConfirmAction
                show={showWarningModal} 
                onCancel={()=>setShowWarningModal(false)} 
                type={'make-repayment'} 
                onAction={handleSubmit}
                title="Make Repayment"
                loading={repaymentLoading}
                // hideHourGlassLoader={true}
                content={`Your wallet will be charged ${currencyFormatter(checkedValue === 'next'? repaymentData?.nextRepaymentAmount : repaymentData?.liquidationAmount)}. This action is unreversible`}
            />
            <Modal show={show} onHide={()=>setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <h5>Float Repayment</h5>
                    <div className="float_modal-options">
                        <div className='radio'>
                        <p onClick={()=> setCheckedValue('next')}>
                            <input type="radio" value='next' checked={checkedValue === 'next'} />
                                <label>Next Repayment</label>
                            </p>
                            <h5>{currencyFormatter(repaymentData?.nextRepaymentAmount || 0)}</h5>
                            <p onClick={()=> setCheckedValue('all')}>
                                <input type="radio" value='all' checked={checkedValue === 'all'} />
                                <label>Liquidate Loan</label>
                            </p>
                            <p className="text-[#DE213C] -mt-3 mb-3.5 !leading-[12px] font-bold !text-[10px]">Early liquidation (less than 80% payment) attracts a 5% liquidation fee on the principal in addition to full interest for the current month will be charged</p>
                            <h5>{currencyFormatter(repaymentData?.liquidationAmount || 0)}</h5>
                        </div>
                    </div>
                    <button className="float_modal-action" onClick={()=>{setShowWarningModal(true); setShow(false)}}>Make Repayment</button>
                </div>
            </Modal>

            <Modal show={showSuccess} aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="float_modal">
                    <img src={floatSuccess} alt='icon' />
                    <h5>Payment Succesful</h5>
                    <p style={{color:"#667085"}}>Your Float repayment is succesful</p>
                    <Link to="/float"><button className="float_modal-action">Go Back</button></Link>
                </div>
            </Modal>
        </div>
    )
}

export default RepaymentSchedule;