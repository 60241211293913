import Button from "components/shared/Button";
import { useRecovaSetup } from "hooks/redux.hook";
import mark from "images/svg/recova/exclamation-mark.svg";
import { useState } from "react";
import { useSteps } from "react-step-builder";
import { toast } from "react-toastify";
import { RecovaService } from "services/recova.service";
import { handleRequestErrors } from "utils/helpers";

export default function SwitchService ({from, closeModal}) {
    const {next, jump} = useSteps()
    const [isLoading, setIsLoading] = useState(false)
    const { updateSetupDispatch } = useRecovaSetup()

    const doSwitch = () => {
        setIsLoading(true);
        let val = from==="self-service" ? "auto-repayment":"self-service"
        const payload = JSON.stringify({
            paymentOption: val
        })
        RecovaService.payoutSwitchService(payload)
            .then(response=> response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status){
                    updateSetupDispatch({
                        paymentOption: val
                    })
                    if(val==="self-service"){
                        jump(3)
                    } else {
                        next()
                    }
                } else {
                    toast.error("something went wrong, please try again")
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
                setIsLoading(false)
            });
    }

    return(
        <div className="flex flex-col items-center">
            <img src={mark} alt="" className="block w-[50px] my-4 mx-auto" />
            <p className="text-center w-[300px]">Are you sure you want to switch to {from==="self-service" ? "Automated settlement":"Self service"}  mode?</p>
            <div className="flex my-[20px] justify-center">
                <div className="w-[100px] mr-5">
                    <Button
                        isValid={true}
                        type="button"
                        text="No"
                        onClick={()=>closeModal()}
                    />
                </div>
                <div className="w-[100px]">
                    <Button
                        isValid={true}
                        type="button"
                        text="Yes"
                        isLoading={isLoading}
                        onClick={()=>doSwitch()}
                    />
                </div>
            </div>
        </div>
    )
}