import { useSelector, useDispatch } from "react-redux";
import { 
  updateSetupState
} from "redux/recova/setup";

export const useRecovaSetup = () => {
  const recovaSetupData = useSelector((state) => state.recovaSetupData);
  const dispatch = useDispatch();
  return {
    recovaSetupData,
    updateSetupDispatch: (data) => dispatch(updateSetupState(data)),
  };
};

export const useUser = () => {
  const b2b_data = useSelector(state => state?.loginData?.data)
  const user = useSelector(state => state?.loginData?.data?.user)
  const activeApp = b2b_data?.apps?.filter(item => item?.isLive === true)
  return {
    user,
    b2b_data,
    activeApp
  };
};