import clsx from "clsx";
import Table from "components/Table";
import { dateFormatter } from "utils/helpers";
import { useState, useEffect } from "react";
import { FloatService } from "services/float.service";
import { logToBin } from "lib/logs";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import Status from "components/shared/Status";
import { Container } from "react-bootstrap";


export default function StatusTable () {
    const [selectedTab, setSelectedTab] = useState('all')
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const [data, setData] = useState([]);

    useEffect(()=>{
        getPatches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab])
    
    const getPatches = () => {
        FloatService.getFloatPatchStatuses(businessId)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.status === 'success') {
                    if (selectedTab==="all") {
                        setData(res.data.reverse())
                    } else {
                        const filteredData = res.data?.reverse().filter((item) => item?.status===selectedTab);
                        setData(filteredData)
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    return(
        <Container>
            <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
                <div className="flex mt-10 -mb-[30px]">
                    <p 
                        className={clsx(
                            "py-2.5 px-[20px] w-[80px] text-center text-cc-grey-4 font-semibold cursor-pointer",
                            selectedTab==="all"&&"text-cc-blue-17 bg-cc-blue-18 border-b-[3px] border-b-cc-blue-17"
                        )} 
                        onClick={()=>setSelectedTab('all')}
                    >
                        All
                    </p>
                    <p 
                        className={clsx(
                            "py-2.5 px-[20px] w-[80px] text-center text-cc-grey-4 font-semibold cursor-pointer",
                            selectedTab==="closed"&&"text-cc-blue-17 bg-cc-blue-18 border-b-[3px] border-b-cc-blue-17"
                        )} 
                        onClick={()=>setSelectedTab('closed')}
                    >
                        Closed
                    </p>
                    <p 
                        className={clsx(
                            "py-2.5 px-[20px] w-[100px] text-center text-cc-grey-4 font-semibold cursor-pointer",
                            selectedTab==="inprogress"&&"text-cc-blue-17 bg-cc-blue-18 border-b-[3px] border-b-cc-blue-17"
                        )} 
                        onClick={()=>setSelectedTab('inprogress')}
                    >
                        In Progress
                    </p>
                    <p 
                        className={clsx(
                            "py-2.5 px-[20px] w-[80px] text-center text-cc-grey-4 font-semibold cursor-pointer",
                            selectedTab==="rejected"&&"text-cc-blue-17 bg-cc-blue-18 border-b-[3px] border-b-cc-blue-17"
                        )} 
                        onClick={()=>setSelectedTab('rejected')}
                    >
                        Rejected
                    </p>
                </div>
                <Table
                    noTitleIcon={true}
                    tableTitle={"Float Patches"}
                    data={data}
                    searchPlaceHolder={"Search by Date"}
                    hideSearch={true}
                    loading={false}
                    rows={[
                        {
                            header:'Patch Option',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {
                                        item.category==="changeBalance"?"Change Balance"
                                        : item.category==="freezeCollection"?"Freeze Collections"
                                        : "Skip Collections"
                                    }
                                </span>
                            ),
                        },
                        {
                            header:'Date of Request',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.createdAt, 'dd/mm/yyyy')}</span>
                            ),
                        },
                        {
                            header:'Date of Approval',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {
                                        item.status==="closed" ?  dateFormatter(item.updatedAt, 'dd/mm/yyyy') : "---"
                                    }
                                </span>
                            ),
                        },
                        {
                            header:'Date of Rejection',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {
                                        item.status==="rejected" ?  dateFormatter(item.updatedAt, 'dd/mm/yyyy') : "---"
                                    }
                                </span>
                            ),
                        },
                        {
                            header:'Status',
                            view: (item) => (
                                <div className="py-[15px] pl-[10px] flex">
                                    <Status
                                        type={
                                            item.status==="inprogress"?"pending"
                                            : item.status==="rejected"?"fail"
                                            : item.status==="pending"?"pending"
                                            : "success"
                                        }
                                        text={
                                            item.status==="inprogress"?"In Progress"
                                            : item.status==="rejected"?"Rejected"
                                            : item.status==="pending"?"Pending"
                                            : "Closed"
                                        }
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
            </div>
        </Container>
    )
}