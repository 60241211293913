import { createSlice } from '@reduxjs/toolkit'



export const appSlice = createSlice({
  name: 'apps',
  initialState: {
    active: '', // this is the app active for the dashboard operations
    apps: []
  },
  reducers: {
    setActiveApp: (state, action) => {
      return { ...state, active: action.payload}
    },
    setApps: (state, action) => {
      return { ...state, apps: action.payload}
    },
    setActiveAppStatus: (state, action) => {
      return { 
        ...state, 
        active: {
          ...state.active,
          isLive: action.payload
        }
      }
    },
  },
})

export const { setActiveApp, setApps, setActiveAppStatus } = appSlice.actions

export default appSlice.reducer