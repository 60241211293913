import logo from "images/logos/logo-notext.svg";
import directDebit from "images/direct-debit.jpeg";
import Button from "components/shared/Button";
import { useState, useEffect } from "react";
import { dateFormatter, timeFormatter } from "utils/helpers";
import { months } from "constants/months";
import { AuthService } from "services/auth.service";
import { useNavigate } from "react-router-dom";


export default function Review () {
    const [userBankDeets, setUserBankDeets] = useState(null)
    const [mandateInfo, setMandateInfo] = useState(null)
    const [business, setBusiness] = useState(null)
    let history = useNavigate()

    useEffect(()=>{
        try {
            const primary = JSON.parse(sessionStorage.getItem("cc_dd_selectedPrimaryBank"))
            const backup = JSON.parse(sessionStorage.getItem("cc_dd_selectedBackupBank"))
            setUserBankDeets({
                primary,
                backup
            })

            const mandate = JSON.parse(sessionStorage.getItem("mandateReview"))?.mandate
            setMandateInfo(mandate)
            AuthService.getBusinessById(JSON.stringify({businessId: mandate.businessId}))
                .then((response) => response.data)
                .then((res) => {
                    if (res.success) {
                        setBusiness(res.data);
                    }
                })
                .catch((err) => {
                })
        } catch {}
    }, [])

    return (
        <div className="py-10 px-3 bg-white">
            <p className="text-[#D92D20] text-xs mt-2.5">Please note: You will need to get this electronic form approved by your bank or financial institution before using it. We suggest working closely with financial institutions to ensure that your Direct Debit mandate agreement complies with Central Bank Direct Debit scheme requirements.</p>
            <div className="grid grid-cols-2 gap-x-4 mt-[20px]">
                <div>
                    <p className="text-base mt-[26px]">CreditChek Inc. <br/>Lagos Nigeria</p>
                    <div>
                        <p className="text-xs mb-1 mt-3">Name of Account Holder (“The Payer”)</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">{userBankDeets?.primary?.accountName}</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs mb-1 mt-3">Bank Account Number</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">{userBankDeets?.primary?.accountNumber}</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs mb-1 mt-3">Payment method on file (Primary[100%]/Backup)</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">Primary</p>
                        </div>
                    </div>
                    {/* <div>
                        <p className="text-xs mb-1 mt-3">Name and full  postal address of your Bank/Financial Institution</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">Fidelity Bank 114, Fijabi street, Lekki</p>
                        </div>
                    </div> */}
                    <div>
                        <p className="text-xs mb-1 mt-3">Direct Debit Instruction type (FIXED/Variable)</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">Variable</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs mb-1 mt-3">Payment frequency <br/>(daily/weekly/monthly/quarterly/semi-annual)</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm capitalize">{mandateInfo?.frequency}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center">
                        <img src={logo} alt="logo" className="w-10" />
                        <img src={directDebit} alt="logo" className="w-[120px]" />
                    </div>
                    <p className="text-xs">Instruction to your bank or financial institutions to pay by Direct Debit</p>
                    {/* <div>
                        <p className="text-xs mb-1 mt-3">RC # /Service Code Identifier</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">123456</p>
                        </div>
                    </div> */}
                    <div>
                        <p className="text-xs mb-1 mt-3">Mandate Reference</p>
                        <div className="border-[1px] rounded-md border-black bg-white p-2">
                            <p className="text-sm">{mandateInfo?.reference}</p>
                        </div>
                    </div>
                    <p className="mt-3 text-xs">
                        This AGREEMENT thus explicitly states that CreditChek Technologies Nigeria Ltd - on behalf of “the Creditor” has been officially appointed by {business?.businessName} to manage and recover all monies involved in {mandateInfo?.productName} from {userBankDeets?.primary?.accountName} “the Payer” on this day dated {dateFormatter(new Date(), 'dd/mm/yyyy')} and {timeFormatter(new Date())}.  
                        <br/>
                        Please pay CreditChek Nigeria Technologies Direct Debits from the account detailed in  this Instruction subject to the safeguards assured by the Direct Debit Guarantee. I understand  that  this instruction may remain with CreditChek Nigeria Technologies and, if so, details will  be passed electronically to my bank/financial institution.
                    </p>
                    <div>
                        <p className="text-xs mb-1 mt-3">Date & Signature <br/> <span className="text-[10px]">(must match bank record)</span></p>
                        <div className="border-[1px] rounded-md border-black flex items-center gap-x-2 bg-white p-2">
                            <p className="text-sm">{dateFormatter(new Date(), 'dd/mm/yyyy')}</p>
                            {/* <img src={directDebit} alt="logo" className="w-[60px] h-10" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-sm">
                <p className="font-semibold text-base mt-4">The Direct Debit Guarantee</p>
                <ul className="my-2.5">
                    <li>This Guarantee is offered by all  banks and financial institutions that accept  instructions to pay by Direct Debit. </li>
                    <li>
                        If there are any changes to the amount, date, or frequency of  your Direct Debit, {business?.businessName} will notify you 10  working days in  advance of  your account being debited or as otherwise agreed. If you request {business?.businessName} to collect a payment, confirmation of  the amount and date will be given to you at the time of  the request.                                                                                                                                                                                                                                   
                        <br/>-The amounts are variable and may be debited on various dates. The payer accepts that the Creditor may change the amount and dates only after giving issuing a prior notice.  
                    </li> 
                    <li>If an error is made in the payment of  your Direct Debit, by {business?.businessName} or your bank or financial institution, you are entitled to a full  and immediate refund of  the amount paid from your bank– if you receive a refund, you are not entitled to, you must pay it  back when {business?.businessName} asks you to.</li> 
                    <li>You can cancel a Direct Debit at any time by simply contacting  your bank or financial institution. A written confirmation document will be required. Please also notify {business?.businessName}</li> 
                </ul>
                <p className="font-semibold"> I have also accepted that if there’s an unavailability of funds in my chosen primary bank account at the scheduled collection date  after  due  advanced  notification,  the  below  will  serve  as  a  backup  bank  account(s)  with  respective  percentage contributions to enable paying off this loan and its associated agreed interests.</p>
                <div className="mt-3">
                    <p className="font-semibold text-sm mb-1">Backup Bank Account(s)</p>
                    <table class="table-fixed text-[13px]">
                        <thead>
                            <tr>
                                {
                                    ["Bank", "Account Number", "Payment Method on File"].map(item=>(
                                        <th key={item} className="border-[1px] p-2 font-normal border-cc-grey-2">{item}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">{userBankDeets?.primary?.bankName || userBankDeets?.primary?.BankName}</td>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">{userBankDeets?.primary?.accountNumber}</td>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">Primary</td>
                            </tr>
                            <tr>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">{userBankDeets?.backup?.bankName || userBankDeets?.backup?.BankName}</td>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">{userBankDeets?.backup?.accountNumber}</td>
                                <td className="border-[1px] p-2 font-normal border-cc-grey-2">Backup</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="font-semibold my-2.5">
                    I agree to pay any bank charges relating to this Mandate.
                    <br/>
                    This Mandate may be canceled by me by giving both {business?.businessName} and the Creditor “CreditChek Technology Nigeria Ltd” twenty (20) Business Days' notice in writing, sent by prepaid registered post, or delivered to the addresses stated above, but I understand that I shall not be entitled to any refund of amounts which may have already been withdrawn while this Mandate was in force if such amounts were legally owing to the Creditor.  I understand that if any Direct Debit Instruction is paid that breaches the terms of this Mandate, you shall not be liable to us in any way or manner whatsoever, whether under contract, tort, or negligence and that our recourse shall be limited to the Creditor...  
                </p>
                <p className="font-semibold">
                    Signed at __________ on {new Date().getDate()} {months[new Date().getMonth()]}, {new Date().getFullYear()} {" "}
                    ________________________________________ [SIGNATURE AS PER ACCOUNT MANDATE- must match bank account signature]
                </p>
            </div>
            <div className="grid grid-cols-2 gap-x-6 my-4">
                <div className="w-full">
                    <Button
                        text="Go Back"
                        type="button"
                        isValid={true}
                        className="bg-white border-[1px] border-cc-primary py-2 !text-cc-primary"
                    />
                </div>
                <div className="w-full">
                    <Button
                        text="Accept"
                        type="button"
                        isValid={true}
                        onClick={()=>{
                            history('/customer/direct-debit/completed?account=backup')
                        }}
                        className="bg-cc-primary font-semibold py-2"
                    />
                </div>
            </div>
        </div>
    )
}