import { useState } from "react";
import { GrDocumentText } from "react-icons/gr";

import tableIcon from "images/svg/table-icon.svg";
import search from "images/svg/income/search.svg";
import clsx from "clsx";
import Pagination from "./Pagination";


export default function Table ({
    noTitleIcon=false,
    titleIcon=tableIcon,
    tableTitle, 
    searchPlaceHolder,
    onSearchChange,
    data,
    rows,
    hideSearch=false,
    trClassName,
    loading=false,
    slot,
    slotBeforeSearch,
    clickRowAction,
    showDownload,
    onDownloadClick,
    theadClassName="",
    tableClassName="",
    tableEmpty=<TableEmpty/>,
    searchContainerClass="w-[230px]",
    searchInputClass="",
    searchIconPosition="right", // right or left
    searchIconFirst=false,
}) {
    const [displayData, setDisplayData] = useState([]);
    const [offset, setOffset] = useState(0);

    return(
        <div>
            <div className="border border-solid border-cc-grey-1 py-2.5 px-2.5 mt-[30px] relative rounded-t-lg">
                <div className="flex items-center md:block">
                    <div 
                        className={clsx(
                            "flex items-center",
                            hideSearch && "my-2.5",
                        )}
                    >
                        <img 
                            src={titleIcon} 
                            alt="table" 
                            className={clsx(
                                "mr-1.5 w-[25px] -mt-[3px]",
                                noTitleIcon && "hidden"
                            )} 
                        />
                        <h4 className="mt[3px] text-[18px] font-medium">{tableTitle}</h4>
                    </div>
                    <div className="ml-auto flex gap-x-4 items-center md:mt-[15px] md:w-[100%]">
                        {slotBeforeSearch}
                        {
                            !hideSearch &&
                            <div 
                                className={clsx(
                                    "ml-auto rounded-[10px] flex h-10 items-center px-[10px] py-[3px] bg-white border border-solid border-cc-grey-2 border-box md:ml-auto",
                                    searchContainerClass
                                )}
                            >
                                <input 
                                    placeholder={searchPlaceHolder} 
                                    onChange={onSearchChange} 
                                    className={clsx(
                                        "leading-4 opacity-50 flex-1 bg-transparent outline-none border-0 pl-[8px] font-normal text-xs w-full text-cc-black-1",
                                        searchInputClass,
                                        searchIconPosition==="left" && "order-last"
                                    )}
                                />
                                <img 
                                    src={search} 
                                    alt="search" 
                                    className={clsx(
                                        searchIconFirst && "order-first"
                                    )}
                                />
                            </div>
                        }
                        {slot}
                    </div>
                </div>
            </div>
            <div className="border-box border-[1px] pb-2.5 relative border-solid border-cc-grey-1 rounded-b-lg capitalize overflow-hidden">
                {
                    loading &&
                    <div className=" h-1 bg-white absolute w-full overflow-hidden">
                        <div className="h-1 bg-cc-primary loader-loop"></div>
                    </div>
                }
                {
                    data?.length>0 ?
                    <div className="box-border">
                        <div className="overflow-x-auto">
                            <table 
                                className={clsx(
                                    "w-[100%]",
                                    tableClassName,
                                )}
                            >
                                <thead 
                                    className={clsx(
                                        "border-b border-solid border-cc-grey-1",
                                        theadClassName
                                    )}
                                >
                                    <tr className="text-cc-grey-4 text-sm font-medium text-base">
                                        {
                                            rows.map((item,idx)=>(
                                                <th className="py-3 pl-[10px] pr-[5px] text-cc-grey-8 font-normal capitalize" key={idx}>{item.header}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        displayData?.map((item, itemIndex) =>(
                                            <tr 
                                                key={itemIndex} 
                                                className={clsx(
                                                    "border-b border-solid border-cc-grey-1 text-cc-black-2 text-base",
                                                    trClassName,
                                                    !!clickRowAction && "cursor-pointer",
                                                )}
                                                onClick={()=>{!!clickRowAction && clickRowAction(item)}}
                                            >
                                                {
                                                    rows.map((row)=>{
                                                        const view = row.view(item, itemIndex, offset);
                                                        return <td>{view}</td>
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3 mx-[20px] mb-1.5 overflow-hidden">
                            <Pagination
                                setDisplayData={(items)=>setDisplayData(items)}
                                data={data}
                                pageCountOffset={(val)=>setOffset(val)}
                                showDownload={showDownload}
                                onDownloadClick={onDownloadClick}
                            /> 
                        </div>
                    </div>
                    :
                    <div>
                        {tableEmpty}
                    </div>
                }
            </div>
        </div>
    )
}

const TableEmpty = () => {
    return (
        <div className="my-[100px] mx-[20px] text-center">
            <GrDocumentText className="mx-auto mb-2.5 block" size="50px" />
            <h1 className="text-xl font-bold">Empty</h1>
            <p>No data available</p>
        </div>
    )
}