import { useEffect, useState } from "react";
import {Accordion, Col, Modal, Row} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import selectArrow from "../../../../images/svg/select-arrow.svg";
import plus from "../../../../images/svg/settings/plus.svg";
import { AuthService } from "services/auth.service";
import { useSelector } from "react-redux";
import Button from "components/shared/Button";
import clsx from "clsx";
import { handleRequestErrors } from "utils/helpers";


function InviteTeam ({show, handleClose, handleShow}) {
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const userId = useSelector(state => state?.loginData?.data?.user?.id)
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [roleId, setRoleId] = useState("");
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const doInvite = (e) => {
        e.preventDefault()
        setIsLoading(true)
        let payload = JSON.stringify({
            businessId: businessId,
            email: email,
            role: role,
            userId: userId,
            roleId: roleId
        })
        AuthService.inviteUser(payload)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                handleClose()
                if (res.success === true) {
                    toast.success(res.message);
                    handleClose()
                    setEmail("")
                    setRole("")
                    setRoleId("")
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                handleRequestErrors(err)
            });
    }

     useEffect(()=>{
        let payload = JSON.stringify({
            businessId: businessId
        })
        AuthService.getAllRoles(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message)
                    setRoles(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const returnObjectKeys = (val) => {
       let obj = Object.keys(val)
       return obj?.[0]
    }

    const returnObjectValues = (val) => {
       let obj = Object.values(val)
       return obj?.[0]
    }


    return(
        <Modal show={show} onHide={handleClose}>
            <ToastContainer />
            <Modal.Body>
                <Modal.Title>Invite Team Member</Modal.Title>
                <form onSubmit={doInvite}>
                    <div className="settings-invite-mail">
                        <label>Email</label>
                        <input type="email" required className="mail" placeholder="Enter customers email" onChange={(e)=>setEmail(e.target.value)}/>
                    </div>

                    <div>
                        <p className="font-bold">Role</p>
                        {
                            roles && roles.map((item, index)=> (
                                <Accordion key={index}>
                                    <div className="settings-invite-role !border-0">
                                        <Accordion.Item eventKey={item}>
                                            <Accordion.Header>
                                                <div className="settings-invite-role-opts">
                                                    <p>
                                                        <input type="radio" id={`test1`} name="radio-group" value={item?.name} onChange={(e)=>{setRole(e.target.value); setRoleId(item?._id)}} style={{marginRight:"10px"}}/>
                                                        <label for={`test1`} style={{textTransform:"capitalize"}} >{item?.name}</label>
                                                    </p>
                                                    <img src={selectArrow} alt="" />
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="settings-invite-role-permissions">
                                                    <Row>
                                                        {
                                                            item?.permission.map((val, index) => (
                                                                <Col md={6} key={index}>
                                                                    <div className="settings-invite-role-permissions-views">
                                                                        <p><strong> {returnObjectKeys(val)} </strong></p>
                                                                        <div>
                                                                        {
                                                                            returnObjectValues(val)?.map((value, idx)=> (
                                                                                <p key={idx}>Can {value}</p>
                                                                            ))
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))   
                                                        }
                                                    </Row>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                </Accordion>
                            ))
                        }
                    </div>

                    <div className="settings-invite-action gap-x-4">
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="button"
                            text="Cancel"
                            className="!bg-white !text-[#7D7D7D] border-[1px] !border-[#7D7D7D]"
                            onClick={handleClose}
                        />
                        <Button
                            isValid={email!==""&&role!==""}
                            isLoading={isLoading}
                            type="submit"
                            text="Invite Team Member"
                            className={clsx(
                                "border-[1px]",
                                email!==""&&role!==""?"bg-cc-primary":"bg-cc-grey-1"
                            )}
                            iconPosition="left"
                            icon={<img src={plus} alt="" className="w-[20px]" />}
                        />
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default InviteTeam;