import {Container} from "react-bootstrap";
import {Routes, Route} from "react-router-dom";
import Overview from "./Overview";
import Pricing from "./Pricing";
import Details from "./Details";
import ImportCSV from "./ImportCSV";


function Spectrum ( ) {
    return (
        <div className="spectrum">
            <p className="spectrum-topText text-base">CreditChek’s Decentralised Ledger - A private secured network for lenders in Africa </p>
            <Container>
                <Routes>
                    <Route path="/" element={<Overview />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/details/:type" element={<Details />} />
                    <Route path="/import" element={<ImportCSV />} />
                </Routes>
            </Container>
        </div>
    )
}

export default Spectrum;