import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";


export const BillingService = {
    billingLogs: () => {
        return APIKit(15000).get(`/billing/history`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    getBillingLog: (query) => {
        return APIKit(15000).get(`/billing/count?type=${query}`,
            {
                headers: {
                    Authorization: `Bearer ${getPublicKey()}`
                }
            }
        )
    },
    
    getBillingPricing: (payload) => {
        return APIKit(15000).post(`/billing/get-pricing`, payload,
            {
                headers: {
                    Authorization: `Bearer ${getPublicKey()}`
                }
            }
        )
    },

    getChartStat: (type) => {
        return APIKit(15000).get(`/billing/monthly-logs?type=${type}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    }
}