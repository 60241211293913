import clsx from "clsx";
import Button from "components/shared/Button";
import dayjs from "dayjs";
import React, { useState } from "react";
import {Modal} from "react-bootstrap";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; 
import { MdOutlineCancel } from "react-icons/md";

export default function DownloadModal ({show, onClose, onDownloadClick, isDownloading}) {
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
          color: "#0046E6",
        }
    ]);
    const [lastSelected, setLastSelected] = useState("")
    const [
        // email, 
        setEmail] = useState("");
    const [sendLoading, setSendLoading] = useState(false)

    const handleSendEmail = (e) => {
        e.preventDefault()
        setSendLoading(true)
    }
    
    return(
        <Modal show={show} size="md" onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="download-filter-daterange relative pt-7">
                <MdOutlineCancel onClick={onClose} className="scale-[1.4] text-cc-primary absolute top-3.5 right-[20px] cursor-pointer" />
                <DateRange
                    className="w-full rounded-[12px] !text-center"
                    months={2}
                    ranges={state}
                    onChange={item => setState([item.selection])}
                    direction="horizontal"
                    editableDateInputs={false}
                    showMonthAndYearPickers={false}
                />
                <div className="flex flex-wrap justify-center gap-[20px]">
                    {
                        [
                            {
                                title: "Last 7 days",
                                date: () => ({
                                    start: dayjs().subtract(7, "day").startOf("day").toDate(),
                                    end: dayjs().endOf("day").toDate(),
                                }),
                            },
                            {
                                title: "Last 30 days",
                                date: () => ({
                                    start: dayjs().subtract(30, "day").startOf("day").toDate(),
                                    end: dayjs().endOf("day").toDate(),
                                }),
                            },
                            {
                                title: "Last 90 days",
                                date: () => ({
                                    start: dayjs().subtract(90, "day").startOf("day").toDate(),
                                    end: dayjs().endOf("day").toDate(),
                                }),
                            },
                            {
                                title: "12 months",
                                date: () => ({
                                    start: dayjs().subtract(1, "year").startOf("day").toDate(),
                                    end: dayjs().endOf("day").toDate(),
                                }),
                            },
                        ].map((item)=>(
                            <div 
                                key={item.title}
                                className={clsx(
                                    "border rounded-lg px-2.5 py-1.5 text-xs cursor-pointer",
                                    lastSelected===item.title ? "!font-medium !border-black":" border-[#7E7E7E]",
                                )}
                                onClick={()=>{
                                    setLastSelected(item.title);
                                    console.log(item.date())
                                    setState([
                                        {
                                            startDate: item.date().start,
                                            endDate: item.date().end,
                                            key: 'selection',
                                            color: "#0046E6",
                                        }
                                    ])
                                }}
                            >
                                <p>{item.title}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="flex justify-end gap-x-3 p-[20px]">
                    <Button
                        isValid={true}
                        isLoading={isDownloading}
                        type="button"
                        text="Download"
                        className="bg-cc-primary py-1 px-[20px]"
                        fullwidth={false}
                        onClick={()=>onDownloadClick(state[0].startDate, state[0].endDate)} 
                    />
                    <Button
                        isValid={true}
                        isLoading={false}
                        type="button"
                        text="Send to Email"
                        className="!bg-[#F2F4F7] !text-cc-primary py-1 px-[20px]"
                        fullwidth={false}
                        // onClick={()=>onAction()} 
                    />
                </div>
                <div className="mb-10 mt-2.5 mx-[20px]">
                    <p className="text-[15px] font-medium">Enter Email</p>
                    <form className="mt-2 flex" onSubmit={handleSendEmail}>
                        <input type="email" required onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email address" className="w-full rounded-l-[12px] bg-cc-blue-18 border-[1px] border-cc-blue-18 py-2 px-[20px] text-sm text-cc-grey-21" />
                        <div className="w-[150px] bg-cc-primary rounded-r-[12px] overflow-hidden">
                            <Button
                                type="submit"
                                isValid={true}
                                text="Send"
                                isLoading={sendLoading}
                                className="bg-cc-primary !pt-1 !pb-0 h-[30px]"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}