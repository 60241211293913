import {Modal} from "react-bootstrap";
import clsx from 'clsx';
import Button from "components/shared/Button";


export default function ConfirmAction ({
    show, onCancel, onAction, title, content,
    loading=false,
    hideActionBtn=false,
    cancelBtnClass="",
    actionBtnClass="",
    actionText="Proceed",
    type="primary", // delete or primary
}) {
    return(
        <Modal show={show} size="md" onHide={onCancel} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <div>
                    <h4 className="font-semibold text-[1.3em]">{title}</h4>
                    <p className="mt-2 mb-3">{content}</p>
                    <div className="flex justify-between gap-x-[20px]">
                        <button 
                            className={clsx(
                                "py-1.5 px-[20px] rounded-[7px] text-white bg-cc-grey-11",
                                hideActionBtn && "w-full",
                                cancelBtnClass,
                                )} 
                                onClick={onCancel}
                        >Cancel</button>
                        {
                            !hideActionBtn &&
                            <Button
                                isValid={true}
                                isLoading={loading}
                                type="submit"
                                text={actionText}
                                className={clsx(
                                    "py-1.5 px-[20px]",
                                    type==='delete' ? 'bg-cc-red-3' : 'bg-cc-primary',
                                    actionBtnClass,
                                )}
                                fullwidth={false}
                                onClick={()=>onAction()} 
                            />
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
}