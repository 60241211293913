// Library Methods
import React from 'react'
import ProgressBar from "@ramonak/react-progress-bar";

//Utilities
import { color } from 'utils/theme/colors';
import backArrow from 'images/svg/recova/backArrow.svg'

const Header = ({progress, back}) => {
  return (
    <div>
        <div className='flex pb-2 gap-x-2.5'>
          {
            back && 
            <div 
              className="cursor-pointer flex items-center justify-center -ml-6 sm:ml-0"
              onClick={back}
            >
              <img src={backArrow} alt="icon" />
            </div>
          }
          <p className='text-sm'>Activate Recova</p>
          <div className='flex justify-center items-center bg-cc-blue-11 w-24'>
              <p className='text-cc-blue-8 text-xs font-normal'>In progress</p>
          </div> 
        </div>
        <div className='pb-4'>
          <ProgressBar 
            completed={progress ?? 0} 
            bgColor={color?.primary} 
            height={"5px"} 
            width={"44%"} 
            isLabelVisible={false}
          />
        </div>
        
    </div>
  )
}

export default Header