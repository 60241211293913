import { useState, useEffect } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Row, Col} from 'react-bootstrap';


export default function RCInterface ({permissionValues}) {
    let history = useNavigate();
    const [rcBasic, setRCBasic] = useState("");
    const [rcAdvanced, setRCAdvanced] = useState("");
    const [verificationType, setVerificationType] = useState("");
    const [rcPrice, setRCPrice] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [disableAction, setDisableAction] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data)
  
    const handleSubmit = () => {
      if(permissionValues?.includes("edit")){
        Mixpanel.track(`Identity Insight (Verify RC Number) button was clicked`, {
          name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
          bussinessName: b2b_data?.businessName
        });
        if((rcBasic!=="" && verificationType==="basic") || (rcAdvanced!=="" && verificationType==="advanced")){
          setShowModal(true);
        } else {
          toast.error("No empty fields allowed!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
  
    useEffect(() => {
      let payload = {
        action: verificationType==="basic"?"cac basic verification":"identity cac verification"
      }
      BillingService.getBillingPricing(payload)
      .then(response=> response.data)
      .then(res => {
          if(res?.success === true){
            setRCPrice(res?.data?.price)
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
            logToBin(err.response.data, window.location.href);
              // toast.error(err.response.data.error.message);
          } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
          }
      });
    }, [verificationType])
  
    const doSubmit = () => {
      if(verificationType==="advanced"){
        IdentityService.doCACVerification(rcAdvanced)
          .then((response) => response.data)
          .then((res) => {
              setShowModal(false)
              if (res.status === true) {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                  toast.success("Request Successful!!!")
                  setTimeout( function (){
                      history(`/identity/cac-verification/advanced`)
                  }, 100);
              } else {
                  toast.error(res.message)
                  setDisableAction(false)
              }
          })
          .catch((err) => {
              setShowModal(false)
              setDisableAction(false);
              if(err.response){
                logToBin(err.response.data, window.location.href);
                  toast.error(err.response.data.message);
              } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
              }
          });
      } else {
        let payload = JSON.stringify({
          RCNumber: rcBasic
        })
        IdentityService.doBasicCAC(payload)
          .then((response) => response.data)
          .then((res) => {
              setShowModal(false)
              if (res.status === true) {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                  toast.success("Request Successful!!!")
                  setTimeout( function (){
                      history(`/identity/cac-verification/basic`)
                  }, 100);
              } else {
                  toast.error(res.message)
                  setDisableAction(false)
              }
          })
          .catch((err) => {
              setShowModal(false)
              setDisableAction(false);
              if(err.response){
                logToBin(err.response.data, window.location.href);
                  toast.error(err.response.data.message);
              } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
              }
          });
      }
    }
  
    return(
      <div className="box-border">
        <Row>
          <Col md={6}>
            <div className="settings_profile-form w-[400px] md:w-full md_min:mb-0 mb-[30px]" style={{border:'1px solid #E6E6E6', borderRadius:'10px', padding:'15px'}}>
              <h5 className="font-medium text-[1.3em]">Basic Verification</h5>
              <label>Enter RC/BN Value</label><br/>
              <input className="apps_create-input" type="text" placeholder="Only input the value. No RC or BN prefix" onChange={(e)=>{setRCBasic(e.target.value); setVerificationType("basic")}}/><br/>
              <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify RC/BN Value</button>
            </div>
          </Col>
          <Col md={6}>
            <div className="settings_profile-form w-[400px] md:w-full md_min:mb-0 mb-[30px]" style={{border:'1px solid #E6E6E6', borderRadius:'10px', padding:'15px'}}>
              <h5 className="font-medium text-[1.3em]">Advanced Verification</h5>
              <label>Enter RC/BN Value</label><br/>
              <input className="apps_create-input" type="text" placeholder="Only input the value. No RC or BN prefix" onChange={(e)=>{setRCAdvanced(e.target.value);  setVerificationType("advanced")}}/><br/>
              <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify RC/BN Value</button>
            </div>
          </Col>
        </Row>
        <ConfirmAction
          show={showModal}
          onCancel={()=>setShowModal(false)}
          onAction={()=>{
            doSubmit()
            setDisableAction(true);
          }}
          loading={disableAction}
          title={`RC Number Verification`}
          content={`Using the RC Number service would attract a charge of ₦${rcPrice}.`}
        />
      </div>
    )
}