import { useEffect, useState } from "react";
import Table from "../../../components/Table";
import { toast } from "react-toastify";
import cancel from "../../../images/svg/apis/cancel.svg";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { logToBin } from "../../../lib/logs";
import { dateFormatter, getInitials, timeFormatter } from "utils/helpers";
import clsx from "clsx";
import dropdown from "images/svg/table_dropdown.svg";
import Status from "components/shared/Status";
import { AuthService } from "services/auth.service";
import { Tooltip } from "react-tooltip";


export default function Webhook () {
    const [data, setData] = useState({
        tableFor: "apilogs",
        headings: ["S/N", "SERVICE ENDPOINT", "APP", "TOTAL COST", "WHEN", "STATUS"],
        items: [],
        isLoading: true,
    })
    const [showDeets, setShowDeets] = useState(false)
    const [viewData, setViewData] = useState({});

    useEffect(()=>{
        AuthService.getWebhookLogs()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setData({
                        ...data,
                        isLoading: false,
                        items: res.data || []
                    })
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setData({
                    ...data,
                    isLoading: false,
                })
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div className="relative">
                <Table
                    noTitleIcon={true}
                    tableTitle={"Webhook Events"}
                    data={data.items}
                    hideSearch={true}
                    loading={data.isLoading}
                    tableClassName="min-w-[1300px]"
                    rows={[
                        {
                            header:'S/N',
                            view: (item, idx, offset) => (
                                <div className="py-[10px] pl-[10px] flex">
                                    {idx+1+offset}
                                </div>
                            ),
                        },
                        {
                            header:'Event Type',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px] capitalize">{item?.eventType}</span>
                            ),
                        },
                        {
                            header:'App',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.appId?.appName}</span>
                            ),
                        },
                        {
                            header:'Event ID',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?._id}</span>
                            ),
                        },
                        {
                            header:'URL',
                            view: (item, idx) => (
                                <div className="max-w-[250px] text-ellipsis overflow-hidden">
                                    <span 
                                        data-tooltip-id={idx}
                                        data-tooltip-content={item?.url}
                                        className="text-cc-primary py-[10px] cursor-pointer pl-[10px] pr-[5px] lowercase truncate whitespace-nowrap"
                                    >
                                        {item?.url}
                                    </span>
                                    <Tooltip id={idx} place="bottom" />
                                </div>
                            ),
                        },
                        {
                            header:'Time',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item?.updatedAt, "dd/mm/yyyy")}, {timeFormatter(item?.updatedAt)}</span>
                            ),
                        },
                        {
                            header:'Action',
                            view: (item) => (
                                <div className="lowercase py-[10px] pl-[10px] pr-[5px] flex gap-x-2 items-center">
                                    <button 
                                        className={clsx(
                                            "px-[20px] py-1.5 rounded-[4px] text-white text-sm",
                                            item?.status==="success"?" bg-cc-green-4":"bg-cc-red-3",
                                        )}
                                    >
                                        {item?.status}
                                    </button>
                                    <img 
                                        src={dropdown} 
                                        alt="dropdown" 
                                        className="w-[35px] cursor-pointer" 
                                        onClick={()=>{setShowDeets(true); setViewData(item)}} 
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
                {
                    showDeets &&
                    <div className="w-[450px] border-[1px] border-cc-grey-23 p-[15px] font-semibold absolute top-0 right-0 bg-white h-[calc(100%-30px)] overflow-y-scroll rounded-[7px]">
                        <div className="flex items-center mb-3">
                            <div className="w-[45px] h-[45px] rounded-[50%] bg-cc-purple-1 relative">
                                <p className="text-white text-[1.7em] font-bold text-center mt-[3px] uppercase">{getInitials(viewData.eventType)}</p>
                            </div>
                            <h3 className="text-cc-grey-24 text-[1.5em] capitalize ml-2">{viewData.eventType}</h3>
                        </div>
                            <img src={cancel} alt="" className="absolute top-[15px] right-[15px] cursor-pointer" onClick={()=>setShowDeets(false)} />
                        <hr />
                        <div style={{height:'40px'}}></div>
                        <p className="text-cc-grey-24 mb-1.5">Event Type <span className="text-cc-black-6 float-right capitalize">{viewData.eventType}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">App <span className="text-cc-black-6 float-right">{viewData.appId.appName}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Event ID <span className="text-cc-black-6 float-right">{viewData._id}</span></p>
                        <p className="text-cc-grey-24 mb-1.5">Status 
                            <span className="float-right">
                                <Status type={viewData.status==="success"?"success":"fail"} text={viewData.status} />
                            </span>
                        </p>
                        <div>
                            <h5 className="my-[20px] text-[1.1em] text-[#0F123F]">Event Response</h5>
                            <SyntaxHighlighter 
                                language="json" 
                                style={github} 
                                showLineNumbers={true}
                                lineNumberStyle={{textAlign:'left', minWidth: '25px', padding:"0 10px ", marginRight:'15px'}}
                            >
                                {JSON.stringify(viewData.responseMeta||"No response available", null, 2)}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}