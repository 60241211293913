import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Back from "components/shared/back";
import FirstCentral from "./components/FirstCentral";
import CRC from "./components/CRC";
import Premium from "./components/Premium";


function Main ({regNo, doRegNo, setDetailName, path, setPath, rolePermissions}) {
    let history = useNavigate();
    const [permissionValues, setPermissionValues] = useState([]);

    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])
    
    return (
        <div className="credit">
            <p className="xl_min:w-[350px] md_min:ml-2.5 mb-[60px] -mt-7 text-cc-grey-4 xl:mt-10 xl:mb-[20px]">Generate Customer(Business) Credit Insight.</p>
            <div style={{margin:"20px 10px -30px"}}>
                <Back to={()=> history("/credit-insight")} />
            </div>
            <div className="settings_navMain !px-0">
                <p className={path==="first-central"?"active mx-0":"mx-0"} 
                    onClick={()=> { 
                        setPath("first-central")
                    }}
                >First Central</p>
                <p className={path==="CRC"?"active mx-0":"mx-0"} 
                    onClick={()=> {
                        setPath("CRC")
                    }}
                >CRC</p>
                <p className={path==="premium"?"active mx-0":"mx-0"} 
                    onClick={()=> { 
                        setPath("premium")
                    }}
                    style={{position:"relative"}}
                >Premium</p>
            </div>
            {
                path==="first-central" ?
                <FirstCentral 
                    permissionValues={permissionValues}
                />
                :
                path==="CRC" ?
                <CRC 
                    regNo={regNo} 
                    doRegNo={doRegNo} 
                    path={path} 
                    setPath={setPath} 
                    rolePermissions={rolePermissions}
                />
                :
                <Premium 
                    regNo={regNo} 
                    doRegNo={doRegNo} 
                    path={path} 
                    setPath={setPath} 
                    rolePermissions={rolePermissions}
                />
            }
        </div>
    )
}

export default Main;