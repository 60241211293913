import Button from "components/shared/Button";
import cloud from 'images/svg/cloud.svg';
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate, useSearchParams } from "react-router-dom";
import { RecovaService } from "services/recova.service";
import { handleRequestErrors } from "utils/helpers";
import clsx from "clsx";


export default function ESignature () {
    const drop = useRef(null);
    const sigPad = useRef(null);
    const [fileName, setFileName] = useState("");
    const [uploaded, setUploaded] = useState(null);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [acceptPrimary, setAcceptPrimary] = useState(false)
    const [uploadBase64, setUploadBase64] = useState("");
    const [bankLogo, setBankLogo] = useState("")

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
      
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('dragover', handleDragOver);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    });

    useEffect(()=>{
        try{
            const logo = JSON.parse(
                sessionStorage.getItem(
                        searchParams.get("account")==="primary" ? "cc_dd_selectedPrimaryBank":"cc_dd_selectedBackupBank"
                    )
                ).logo;
            setBankLogo(logo||"")
        } catch {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;
      
        if (files && files.length===1) {
            if(
                files[0].type==="image/png" ||
                files[0].type==="image/jpeg" ||
                files[0].type==="image/jpg"
            ){
                setFileName(files[0].name)
                setUploaded(files[0])
                convertImagetoBase64()
                sigPad?.current?.clear()
            } else {
                toast.error("Only image files are allowed!")
            }
        } else {
            toast.error("You can't upload more than one file!")
        }
    };

    const clearSignature = () => {
        sigPad?.current?.clear()
    }
    const downloadSignature = () => {
        if(!sigPad?.current?.isEmpty()){
            var dataUrl = sigPad?.current?.getTrimmedCanvas().toDataURL('image/png')
            var downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = 'signature_image.png';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }

    const convertImagetoBase64 = () => {
        var reader = new FileReader();
        reader.onloadend = function() {
            setUploadBase64(reader.result)
        }
        reader.readAsDataURL(uploaded);
    } 

    const handleSubmit = async () => {
        // const review = {
        //     status: true,
        //     data: {
        //         hasResult: true,
        //         result: "Mandate is being Processed",
        //         resultType: 1,
        //         message: "Successful",
        //         validationMessages: null,
        //         successful: true,
        //         exceptionThrown: false,
        //         error: null,
        //         responseCode: "00"
        //     },
        //     message: "Mandate created successfully",
        //     error: false
        // }

        const stored = JSON.parse(localStorage.getItem("cc_dd_data"))
        const mandateInfo = JSON.parse(sessionStorage.getItem("mandateInfo"))
        const bvnInfo = JSON.parse(sessionStorage.getItem("cc_dd_userBVNDetails"))
        const selectedBank = JSON.parse(
            sessionStorage.getItem(
                    searchParams.get("account")==="primary" ? "cc_dd_selectedPrimaryBank":"cc_dd_selectedBackupBank"
                )
            );

        const fileType = acceptPrimary ? JSON.parse(sessionStorage.getItem("dd_signatureData")).fileType
            : sigPad?.current?.isEmpty() ? uploaded?.type?.split("/")?.[1]
            : "png"

        const fileBase64 = acceptPrimary ? JSON.parse(sessionStorage.getItem("dd_signatureData")).fileBase64
            : sigPad?.current?.isEmpty() ? uploadBase64
            : sigPad?.current?.getTrimmedCanvas().toDataURL('image/png')

        if(searchParams.get("account")==="primary"){
            sessionStorage.setItem(
                "dd_signatureData", 
                JSON.stringify({
                    fileType: fileType,
                    fileBase64: fileBase64,
                })
            )
        }
        
        const payload = {
            appId: stored.appId,
            businessId: stored.businessId,
            mandateId: stored.mandateId,
            accountNumber: selectedBank.accountNumber,
            accountName: selectedBank.accountName,
            bankCode: selectedBank.bankCode,
            name: selectedBank.accountName,
            email: bvnInfo?.email||null,
            phone: bvnInfo?.phoneNo||bvnInfo?.phoneNo||"",
            address: bvnInfo?.residentialAddress||"Nigeria",
            fileExtention: fileType,
            file: fileBase64,
        }

        setLoading(true);
        RecovaService.eSignatureMandate(payload)
            .then((response) => response.data)
            .then((res) => {
                if(res.status===true){
                    setLoading(false);
                    sessionStorage.setItem("mandateReview", JSON.stringify(mandateInfo))
                    if(searchParams.get("account")==="primary"){
                        navigate(`/customer/direct-debit/completed?account=primary`)
                    } else {
                        navigate(`/customer/direct-debit/review`)
                    }
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setLoading(false);
            })
    }

    return (
        <div className="bg-white py-10 min-h-[80vh]">
            <div className="px-4">
                <img src={bankLogo} alt="bank" className="block mx-auto w-[60px] h-[60px] transition ease-in-out !duration-[5000ms] hover:w-[100px]" />
                <p className="text-base text-center mt-1 mb-3">Authorize via e-Signature<br/>({searchParams.get("account")==="primary"?"Primary":"Backup"} Account)</p>
            </div>
            <div className="bg-[#EAECF0] px-4 box-border py-8">
                {
                    searchParams.get("account")!=="primary" &&
                    <div>
                        <p className="text-center mb-2 text-lg">Accept signature from Primary account</p>
                        <div className="mx-auto block w-[150px]">
                            <Button
                                text={acceptPrimary? "Change":"Accept"}
                                type="button"
                                isValid={true}
                                onClick={()=>setAcceptPrimary(!acceptPrimary)}
                                className={clsx(
                                    "bg-cc-primary font-semibold",
                                    (searchParams.get("account")==="backup"&&acceptPrimary) && "mb-10"
                                )}
                            />
                        </div>
                        <div 
                            className={clsx(
                                "flex items-center gap-x-3 my-6",
                                (searchParams.get("account")==="backup"&&acceptPrimary) && "hidden"
                            )}
                        >
                            <div className="border-[#101828] border-t-[3px] w-full" />
                            <p>or</p>
                            <div className="border-[#101828] border-t-[3px] w-full" />
                        </div>
                    </div>
                }
                {
                    !acceptPrimary &&
                    <div>
                        <div>
                            <p className="text-center mb-2 text-lg">Draw your e-Signature here</p>
                            <div className="w-full h-[150px] overflow-hidden rounded-lg bg-white flex justify-center">
                                <SignatureCanvas 
                                    penColor='black'
                                    canvasProps={{
                                        width: 500, 
                                        height: 150, 
                                        className: 'sigCanvas'
                                    }} 
                                    ref={sigPad}
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-x-6 mt-4">
                                <div className="w-full overflow-hidden rounded-full border-[1px] border-cc-primary">
                                    <Button
                                        text="Clear and draw again"
                                        type="button"
                                        isValid={true}
                                        onClick={()=>{
                                            clearSignature()
                                        }}
                                        className="bg-white border-0 py-1 !text-cc-primary"
                                    />
                                </div>
                                <div className="w-full overflow-hidden rounded-full border-[1px] border-cc-primary">
                                    <Button
                                        text="Download e-Signature"
                                        type="button"
                                        isValid={true}
                                        className="bg-cc-primary font-semibold py-1"
                                        onClick={()=>{
                                            downloadSignature()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-x-3 my-6">
                            <div className="border-[#101828] border-t-[3px] w-full" />
                            <p>or</p>
                            <div className="border-[#101828] border-t-[3px] w-full" />
                        </div>
                        <div>
                            <input 
                                type="file" 
                                accept="image/png, image/jpeg, image/jpg" 
                                id='input_file' 
                                onChange={(e)=>{
                                    setUploaded(e.target.files[0]);
                                    setFileName(e.target.files[0].name);
                                    convertImagetoBase64()
                                    sigPad?.current?.clear();
                                }} 
                                hidden 
                            />
                            <div className="myModal_main-widget-form-dragUpload" ref={drop} onClick={()=>document.getElementById('input_file').click()}>
                                <img src={cloud} alt="" />
                                <p><span className="text1">Click to upload</span> or drag and drop e-signature</p>
                                <p className="text2">&#40;max. 10MB&#41;</p>
                            </div>
                            <p className="text-center mb-[30px]">{fileName}</p>
                        </div>
                    </div>
                }
                <Button
                    text="Submit"
                    type="button"
                    isValid={acceptPrimary || !sigPad?.current?.isEmpty() || !!uploaded}
                    isLoading={loading}
                    onClick={handleSubmit}
                    className="bg-cc-primary font-semibold mt-1"
                />
                <Button
                    text="Cancel"
                    type="button"
                    isValid={true}
                    onClick={()=>navigate(-1)}
                    className="bg-white border-[1px] border-cc-primary font-semibold !text-cc-primary mt-3"
                />
            </div>
        </div>
    )
}