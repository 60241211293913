import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import ShareImg from "images/svg/share.svg";
import NewShare from "components/Modal/NewShare";
import Table from "components/Table";
import { handleRequestErrors } from "utils/helpers";
import person from "images/svg/income/person-circle.svg";
import view from "images/svg/income/view.svg";
import { Link } from "react-router-dom";
import folder from "images/svg/identity/folder.svg";


export default function CustomerOnboarding ({permissionValues}) {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [isLoading, setIsLoading] = useState(false)
  
    const getAllBorrowers = useCallback(() => {
      setIsLoading(true)
      IdentityService.getBorrowers()
        .then((response) => response.data)
        .then((res) => {
          setIsLoading(false)
          if (res.status === true) {
            const filteredData = res?.data?.filter((item) => item?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()));
            setData(filteredData.reverse());
  
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setIsLoading(false)
          handleRequestErrors(err)
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);
  
    useEffect(() => {
      getAllBorrowers();
    }, [getAllBorrowers]);
  
    const handleChange = (e) => {
      setSearchTerm(e.target.value)
    }
  
    const shareClicked = () => {
      if(permissionValues?.includes("edit")){
        setShowModal(true)
        Mixpanel.track(`Identity Insight (Share link) button was clicked`, {
          name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
          bussinessName: b2b_data?.businessName
        })
      } else {
        toast.error("You are not authorized to perform this action!!!"); 
      }
    }
  
    return(
      <div>
          <div className="identity_share">
            <p className="mr-4">Onboard new customers</p>
            <div onClick={() => shareClicked()} className="flex items-center">
              <img src={ShareImg} alt="share" className="mr-4" />
              <p>Share Link</p>
            </div>
          </div>
          <Table
              tableTitle={"Identity"}
              searchPlaceHolder={"Search by customers name"}
              onSearchChange={handleChange}
              data={data}
              hideSearch={false}
              loading={isLoading}
              tableClassName="2xl:min-w-[1200px]"
              rows={[
                  {
                      header:'CUSTOMER NAME',
                      view: (item) => (
                          <span className="py-[10px] pl-[10px] relative flex items-center pr-[5px]">
                              <img src={person} alt='user' className="mr-[5px]" />
                              <span>{item?.fullname}</span>
                              {/* <span className="absolute w-2.5 top-[10px] left-[25px] h-2.5 rounded-full bg-cc-primary"></span> */}
                          </span>
                      ),
                  },
                  {
                      header:'MOBILE',
                      view: (item) => (
                          <span className="py-[10px] pl-[10px] pr-[5px]">{item.phones?.[0]===null||item.phones.length===0?'n/a':item.phones?.[0]}</span>
                      ),
                  },
                  {
                      header:'EMAIL',
                      view: (item) => (
                          <span className="lowercase py-[10px] pl-[10px] pr-[5px]">{item.emails?.[0]===""||item.emails.length===0?'not available':item.emails?.[0]}</span>
                      ),
                  },
                  {
                      header:'BVN',
                      view: (item) => (
                          <span className="py-[5px] rounded-[6px] px-[5px] border border-solid border-cc-primary">{item.bvn}</span>
                      ),
                  },
                  {
                      header:'MARITAL STATUS',
                      view: (item) => (
                          <span className="py-[10px] pl-[10px] pr-[5px]">{item.maritalStatus===""||!item.maritalStatus?'n/a':item.maritalStatus}</span>
                      ),
                  },
                  {
                      header:'',
                      view: (item) => (
                        <Link to={`/identity/details/${item.bvn}/${item.fullname}/${item._id}`}>
                            <button 
                                className={`${permissionValues?.includes("edit")?"bg-[#47D800]":"bg-cc-grey-3"} text-[0.8em] m-2.5 py-[3px] px-2.5 text-white rounded-[4px] flex items-center`}
                                onClick={()=>{
                                  sessionStorage.setItem("identity", JSON.stringify(item));
                                  Mixpanel.track(`View identity button was clicked`, {
                                      name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                      bussinessName: b2b_data?.businessName
                                  });
                                }}
                            >
                                <img src={view} alt="view" className="w-[15px] mr-[5px]" />
                                <p>View</p>
                            </button>
                        </Link>
                      ),
                  },
              ]}
              tableEmpty={
                <div className="flex flex-col items-center my-[70px] text-center max-w-[450px] mx-auto">
                  <img src={folder} alt="empty" className="m-auto w-[100px]" />
                  <h4 className="text-xl font-bold mt-2.5 mb-1">No data here</h4>
                  <p className="text-sm mb-2.5">Use your customized Web Widget & URL to send to your customers email address.This should assist you to assess permissioned data to get insight using our <span className="text-cc-primary">Credit</span> and <span className="text-cc-primary">Income</span> products</p>
                  <button 
                    className="bg-cc-primary text-sm font-semibold m-2.5 py-2 px-7 text-white rounded-lg"
                    onClick={() => {
                        setShowModal(true)
                    }}
                  >
                    Share Link
                  </button>
              </div>
              }
          />
          <NewShare
            show={showModal}
            type={"share-whatsapp"}
            cancel={() => setShowModal(false)}
            doAction={() => {
              setShowModal(false);
            }}
          />
        </div>
    )
}