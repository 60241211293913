import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { AuthService } from "services/auth.service";

import notvisible from "../../images/svg/not-visible.svg";
import visible from "../../images/svg/visible.svg";
import { logToBin } from "../../lib/logs";


function ForgotPassword () {
    const urlParams = new URLSearchParams(window.location.search);
    const reset_token = urlParams.get('token');
    const [formValid, setFormValid] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [data, setData] = useState({
        password:"",
        confirmPassword:""
    })

    useEffect(() =>{
        if(data.password.length && data.confirmPassword.length){
            setFormValid(true)
        } 
    }, [data.password, data.confirmPassword])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const doReset = (e) => {
        e.preventDefault();
        var btn = document.getElementById("actionBtn");

        let payload = JSON.stringify({
            password: data.password
        });

        if(data.confirmPassword !== data.password){
            toast.error("Passwords must match!!!")
        } else {
            btn.innerHTML=`Please Wait...`;
            AuthService.ResetPasswordAction(reset_token, payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === "success") {
                        toast.success(`Password reset successful, redirecting to login...`);
                        btn.innerHTML=`Reset Password`;
                        setTimeout( function (){
                            window.location = '/auth/login';
                        }, 2500);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    btn.innerHTML=`Reset Password`;
                    if(err.response.data.message){
                        logToBin(err.response.data, window.location.href);
                        btn.innerHTML=`Reset Password`;
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }
    }

    return(
        <div>
            <form className="auth_signup" onSubmit={doReset} >
                <div className="auth_signup-password">
                    <input type={showPassword?"text":"password"} required placeholder="Password" name="password" onChange={handleChange}  />
                    <img src={showPassword?visible:notvisible} alt="visible" className={showPassword?"visible":"notvisible"} onClick={()=>setShowPassword(!showPassword)} />
                </div>
                <div className="auth_signup-password">
                    <input type={showConPassword?"text":"password"} required placeholder="Confirm Password" name="confirmPassword" onChange={handleChange}  />
                    <img src={showConPassword?visible:notvisible} alt="visible" className={showConPassword?"visible":"notvisible"} onClick={()=>setShowConPassword(!showConPassword)} />
                </div>
                <button 
                    className= {formValid ? "auth_signup-active" : "auth_signup-submit"}
                    id="actionBtn"
                    disabled={!formValid}
                >
                    Reset Password
                </button>
                <p className="text-cc-black-5"><Link to="/auth/login"><span className="cursor-pointer text-cc-primary font-semibold no-underline">Back to Login</span></Link></p>
            </form>
        </div>
    )
}

export default ForgotPassword;