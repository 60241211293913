import { getInitials } from "utils/helpers";

function Name ({data}) {

    return(
        <div className="flex items-center gap-x-2.5">
            <div className="w-10 h-10 rounded-full bg-[#CE06E0] flex items-center justify-center">
                <p className="text-white text-[1.3em] font-bold uppercase">{getInitials(data || "")}</p>
            </div>
            <h3 className="text-xl font-medium capitalize">{data||""}</h3>
        </div>
    )
}

export default Name;