import { Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Chart from "./Chart";
import { BillingService } from "services/billing.service"; 
import { WalletService } from "services/wallet.service";
import { IncomeService } from "services/income.service";
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "redux/wallet";

import arrowUp from "../../../images/svg/overview/arrow-up.svg";
import arrowDown from "../../../images/svg/overview/arrow-down.svg";
import chevronDown from "../../../images/svg/overview/chevron-down.svg";
import chartUp from "../../../images/svg/overview/chart_up.svg";
import chartDown from "../../../images/svg/overview/chart_down.svg";
import check from "../../../images/svg/overview/check.svg";
import clsx from "clsx";
import WalletBox from "../Home/components/WalletBox";


function Overview () {
    const [countQuery, setCountQuery] = useState("30days");
    const [whichApi, setWhichApi] = useState("All APIs");
    const [showOptions, setShowOptions] = useState(false);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [statData, setStatData] = useState({})
    const [statType, setStatType] = useState("all")
    const [chartData, setChartData] = useState([]);
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const dispatch = useDispatch();

    useEffect(()=>{
        WalletService.getWallet()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true && res.data !== null ){
                    dispatch(setWallet(res.data))
                }
            })
            .catch((err) => {
                // err
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        IncomeService.getDashboardStats(businessId, countQuery)
            .then((response) => response.data)
            .then(resp=>{
                if(resp.status==="success"){
                    setStatData(resp.data)
                } else{
                    // toast.error("")
                }
            })
            .catch(err => {
                // toast.error("")
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[countQuery])

    useEffect(()=>{
        BillingService.getChartStat(statType)
            .then((response) => response.data)
            .then(resp=>{
                if(resp.success===true){
                    setChartData(resp.data)
                } else{
                    // toast.error("")
                }
            })
            .catch(err => {
                // toast.error("")
            })

    },[statType])

    const changeApi = (name) => {
        setShowOptions(false);
        setWhichApi(name);
    }

    const queryMonths = [
        {
            label: '12 months',
            value: '12months'
        },
        {
            label: '30 days',
            value: '30days'
        },
        {
            label: '7 days',
            value: '7days'
        },
        {
            label: '24 hours',
            value: '24hours'
        },
    ]

    const apiDataOptions = [
        { label: "All APIs", value: "all", color: "#0046E6" },
        { label: "Identity", value: "identity", color: "#2E90FA" },
        { label: "Radar", value: "radar", color: "#F79009" },
        { label: "Income", value: "income", color: "#12B76A" },
        { label: "Credit", value: "credit", color: "#EE46BC" },
    ]

    return(
        <div className="py-[30px] px-10 md:px-[20px]">
            <div className="flex items-start justify-between gap-6 mb-10 xl:flex-col">
                <div>
                    <h4 className="text-[1.4em] text-[#101828]">Business Overview</h4>
                    <p className="text-[1em] text=[#817E7E]">Your current business activity and summary</p>
                </div>
                <div className="sm:w-full">
                    <WalletBox/>
                </div>
            </div>
            <div className="flex">
                <div className="overview_box1-top-left">
                    <div className="flex cursor-pointer items-center justify-between font-bold text-sm min-w-[80px] gap-x-1.5" onClick={()=>setShowFilterOptions(!showFilterOptions)}>
                        <p>{countQuery}</p>
                        <img src={chevronDown} alt="" />
                    </div>
                    {
                        showFilterOptions &&
                        <div className="absolute bg-white right-0 border-[1px] w-[160px] px-2.5 border-[#EAECF0] shadow-[0px_1px_3px_rgba[16,24,40,0.6] rounded-[8px] top-[50px] z-[3]">
                            {
                                queryMonths.map(item => (
                                    <div className="flex items-center cursor-pointer my-2 gap-x-1.5" onClick={()=>{setCountQuery(item.value);setShowFilterOptions(false)}}>
                                        <div 
                                            className={clsx(
                                                "w-2.5 h-2.5 rounded-full",
                                                `bg-[${item.color}]`
                                            )}
                                        ></div>
                                        <p>{item.label}</p>
                                        {countQuery===item.value && <img src={check} alt="" className="ml-auto"/>}
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="box-border">
                <Row>
                    <StatsBox
                        title="Total Registered Customers"
                        value={statData?.borrowerCount}
                        growthValue={statData?.businessPercentageStats?.borrowerStats}
                        chart={chartUp}
                    />
                    <StatsBox
                        title="Total Accounts Linked"
                        value={statData?.linkedAccountCount}
                        growthValue={statData?.businessPercentageStats?.linkedAccountStats}
                        chart={chartDown}
                    />
                    <StatsBox
                        title="Total Assessments"
                        value={statData?.assesmentCount}
                        growthValue={statData?.businessPercentageStats?.assesmentStats}
                        chart={chartUp}
                    />
                </Row>
                <div className="border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_2px_rgba(16,24,40,0.06)] rounded-[8px] p-2.5 mt-[30px]">
                    <div className="flex">
                        <h4 className="text-[1.3em]">API Calls</h4>
                        <div className="overview_box1-top-left">
                            <div className="flex cursor-pointer items-center gap-x-1.5" onClick={()=>setShowOptions(!showOptions)}>
                                <div 
                                    className={clsx(
                                        "w-2.5 h-2.5 rounded-full",
                                        whichApi==="All APIs"?"bg-[#0046E6]"
                                            :whichApi==="Identity"?"bg-[#2E90FA]"
                                            :whichApi==="Radar"?"bg-[#F79009]"
                                            :whichApi==="Income"?"bg-[#12B76A]"
                                            :"bg-[#EE46BC]"
                                    )}
                                ></div>
                                <p>{whichApi}</p>
                                <img src={chevronDown} alt="" />
                            </div>
                            {
                                showOptions &&
                                <div className="absolute bg-white right-0 border-[1px] w-[160px] px-2.5 border-[#EAECF0] shadow-[0px_1px_3px_rgba[16,24,40,0.6] rounded-[8px] top-[50px] z-[3]">
                                    {
                                        apiDataOptions.map(item => (
                                            <div className="flex items-center cursor-pointer my-2 gap-x-1.5" onClick={()=>{setStatType(item.value);changeApi(item.label)}}>
                                                <div 
                                                    className={clsx(
                                                        "w-2.5 h-2.5 rounded-full",
                                                        `bg-[${item.color}]`
                                                    )}
                                                ></div>
                                                <p>{item.label}</p>
                                                {whichApi===item.label && <img src={check} alt="" className="ml-auto"/>}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full h-[230px] z-[1]">
                        <Chart data={chartData} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const StatsBox = ({title, value, growthValue, chart}) => {
    return (
        <Col md={4}>
            <div className="border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_2px_rgba(16,24,40,0.06)] rounded-[8px] p-2.5 mt-[30px]">
                <p className="font-semibold">{title}</p>
                <div className="flex">
                    <div>
                        <h4 className="text-[24px] my-[10px]">{value}</h4>
                        <div className="flex">
                            <img src={growthValue>0?arrowUp:arrowDown} alt="" />
                            <p className="text-[#667085] font-semibold">
                                <span 
                                    className={clsx(
                                        growthValue>0?"text-[#027A48]":"text-[#B42318]"
                                    )}
                                >
                                    {Math.abs(growthValue || 0)}%
                                </span> vs last month
                            </p>
                        </div>
                    </div>
                    <img src={chart} alt="chart" className="ml-auto" />
                </div>
            </div>
        </Col>
    )
} 

export default Overview;