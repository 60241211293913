import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { logToBin } from "lib/logs";
import ShareYourData from "../components/ShareYourData";

import identity from "images/svg/identity/identity.svg";
import credit from "images/svg/identity/credit.svg";
import linked from "images/svg/identity/linked.svg";
import logo from "images/svg/logo.svg";
import creditcheklogo from "images/svg/home/creditchek.svg";
// import { IncomeService } from "services/income.service";


export default function LinkAccIndex () {
    let history = useNavigate()
    const [searchParams] = useSearchParams();
    const [fetched, setFetched] = useState({});
    const [showModal, setShowModal] = useState(false);

    let isDev = process.env.REACT_APP_NODE_ENV === 'development'
    let baseURL = isDev ? 
        process.env.REACT_APP_Dev_Cluster
        :
        process.env.REACT_APP_Production_Cluster

    useEffect(()=>{
        axios.get(`${baseURL}identity/widget/show-form-data?appLink=${searchParams.get("appLink")}`)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === true) {
                    setFetched(res.data);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleAction = () => {
        axios.post(`${baseURL}identity/borrower/userconsent`, {
            borrowerId: searchParams.get("borrowerId"),
            appId: searchParams.get("app_id"),
            action: "bank account linking"
        })
            .then((response) => response.data)
            .then(() => {
                setShowModal(true);
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            })
    }

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    // const generateConsent = () => {
    //     const payload = JSON.stringify({
    //         appId: searchParams.get("app_id"),
    //         borrowerId: searchParams.get("borrowerId"),
    //     })

    //     IncomeService.initializeConsent(payload)
    //         .then(response => response.data)
    //         .then(resp => {
    //             console.log(resp)
    //             if(resp.status==="success") {
    //                 var handler = window.EDoc.setup({
    //                     key: process.env.REACT_APP_EDOC_LIVE_KEY,
    //                     consent_id: resp.data.consentId,
    //                     onSuccess: function(response) {
    //                         //this happens after the payment is completed successfully
    //                         console.log('EDoc Response',response);
    //                         if (window.crypto && window.crypto.subtle) {
    //                             const reqBody = {
    //                                 consentId: resp.data.consentId,
    //                                 data: response
    //                             }
    //                             hashString(reqBody).then(hashedString => {
    //                                 console.log(`Hashed: ${hashedString}`);
    //                                 consentSuccess(resp.data.consentId, hashedString, response)
    //                             });
    //                         } else {
    //                             console.error('Web Crypto API not available');
    //                         }
    //                     },
    //                     onError: function(error) {
    //                         console.log('EDoc Error',error);
    //                     },
    //                     onClose: function() {
    //                         console.log('Transaction was not completed, window closed.');
    //                     },
    //                 });
    //                 handler.openWindow();
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }

    // async function hashString(reqBody) {
    //     const encoder = new TextEncoder();
    //     const data = encoder.encode(JSON.stringify(reqBody));
    //     const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    //     // Convert the hash buffer to a hex string
    //     const hashArray = Array.from(new Uint8Array(hashBuffer));
    //     const hashedString = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    //     return hashedString;
    // }

    // const consentSuccess = (consentId, hashedString, EDocResp) => {
    //     const payload = JSON.stringify({
    //         consentId: consentId,
    //         data: EDocResp,
    //     })

    //     IncomeService.successConsent(payload, hashedString)
    //         .then(response => response.data)
    //         .then(resp => {
    //             console.log(resp)
    //             if(resp.status==="success"){
    //                 history(`/customer/edoc-success?${searchParams.toString()}`)
    //             }
    //         })
    //         .catch(err => {
    //             console.log(err)
    //         })
    // }

    return(
        <div className="max-w-[500px] block mx-auto bg-white border border-cc-primary rounded-[10px]">
            <div className="m-[20px]">
                <div className="flex items-center justify-between">
                    <p className="text-[30px] leading-[35px] mr-[20px] font-bold">Link your bank account data for {fetched.businessName}</p>
                    <div className="w-[70px] h-[70px] shrink-0 overflow-hidden border-[0.3px] border-blue-400 rounded-full block my-2.5 mx-auto">
                        <img src={fetched?.logo?.length?fetched.logo:creditcheklogo} alt="upload" width="100%" />
                    </div>
                </div>
                <div style={{margin:"20px 0"}}>
                    <p className="my-3">I consent to provide {fetched.businessName} with information about my identity, bank account transaction, credit history and telecommunication for the purpose of performing credit worthiness assessments within the CreditChek infrastructure, and improve my chances to access better financial services.</p>
                    <div className="flex items-center mb-2"><img src={identity} alt="" style={{marginRight:"7px"}}/><p>Identity Information</p></div>
                    <div className="flex items-center mb-2"><img src={credit} alt="" style={{marginRight:"7px"}}/><p>Credit Bureau History</p></div>
                    <div className="flex items-center mb-2"><img src={linked} alt="" style={{marginRight:"7px"}}/><p>Linked Bank Accounts</p></div>
                    <p>By clicking the button below you consent to Creditchek’s platform Use agreement</p>
                </div>
                <div className="myModal_main-widget-action">
                    <button className="cancel" onClick={handleClose}>No, I do not consent</button>
                    <button className="publish" onClick={()=>handleAction()}>Yes, I consent</button>
                </div>
                <div className="my-[20px] flex justify-center">
                    <p className="text-[1em] text-center font-bold mr-2">Powered by </p><img width="90px" src={logo} alt="logo"/>
                </div>
            </div>

            <ShareYourData
                data={fetched} 
                show={showModal} 
                handleAction={(option)=>{
                    option==="upload-pdf" ?
                        history(`/customer/share-data/upload-pdf?${searchParams.toString()}`)
                        : 
                        history(`/customer/share-data/open-banking?${searchParams.toString()}`)
                }}
            />

        </div>
    )
}