import { useRef, useState } from "react";
import { toast } from "react-toastify";
import importLogo from "../../../images/svg/home/import.svg";
import { useNavigate } from "react-router-dom";
import { logToBin } from "../../../lib/logs";
import { AuthService } from "services/auth.service";
import { useSelector } from "react-redux";
import { SelectField } from "components/Form/SelectField";
import { Formik, Form } from 'formik';
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { object, string, array } from "yup";


function Create ({info, setDetailName, permissionValues}) {
    const [photoUpload, setPhotoUpload] = useState('');
    const [fileName, setFileName] = useState('Upload Logo');
    const fileInput = useRef(null);
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId)
    const [submitLoading, setSubmitLoading] = useState(false);
    let history = useNavigate();

    const handleClick = () => {
        fileInput.current.click()
    }

    const onFileChange = (e) => {
        setPhotoUpload(e.target.files[0])
        setFileName(e.target.files[0].name)
    }

    const submitApp = (values) => {
        setSubmitLoading(true);

        let formData = new FormData();
        formData.append('businessId', businessId);
        formData.append('details', values.details);
        formData.append('appName', values.appName);
        formData.append('displayName', values.displayName);
        formData.append('chooseProduct', values.product);
        formData.append('accountType', values.accountType);
        formData.append('industry', values.industry);
        formData.append('appLogo', photoUpload);

        if(permissionValues?.includes("edit")){

        AuthService.createApp(formData)
            .then((response) => response.data)
            .then((res) => {
                setSubmitLoading(false);
                if (res.success === true) {
                    toast.success("App created successfully!")
                    history('/apps')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setSubmitLoading(false);
                if(err.response){
                    toast.error(err.response.data.message);
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
        }else{
            toast.error("You are not authorized to perform this action!!!")
        }
    }

    const initialValues = {
        details: '',
        appName: '',
        displayName: '',
        product: '',
        accountType: '',
        industry: '',
    }

    return(
        <div>
            <div className="px-[20px] pt-6 border-[1px] border-cc-primary box-border rounded-[15px] max-w-[400px]">
                <h4 className="font-semibold text-2xl">Create an App</h4>
                <Formik
                    initialValues={initialValues}
                    validationSchema={object({
                        details: string().required(),
                        appName: string().required(),
                        displayName: string().required(),
                        product: array().of(string()).required(),
                        accountType: string().required(),
                        industry: string().required(),
                    })}
                    onSubmit={(values) => {
                        submitApp(values)
                    }}
                >
                    {(props) => (
                        <Form>
                            <input type="file"  onChange={onFileChange} ref={fileInput} style={{display:"none"}} />
                            <div className="flex justify-between box-border py-1.5 pr-[20px] pl-2.5 w-full mt-[30px] mb-[25px] rounded-[4px] border-[1px] border-cc-grey-12" onClick={() => handleClick()} style={{cursor:"pointer"}}>
                                <p className="mt-[3px] ml-2.5">{fileName}</p>
                                <img src={importLogo} alt="upload" className="w-[30px]" />
                            </div>
                            <TextField
                                name='appName'
                                type='text'
                                placeholder="App Name"
                            />
                            <TextField
                                name='displayName'
                                type='text'
                                placeholder="Display Name"
                            />
                            <TextField
                                name='details'
                                type='text'
                                placeholder="App Details"
                            />
                            <SelectField
                                name='product'
                                selectMultiple={true}
                                options={[
                                    { value: 'Income Insight', label: 'Income Insight' },
                                    { value: 'Credit Insight', label: 'Credit Insight' },
                                    { value: 'MFI', label: 'MFI' },
                                    { value: 'Identity', label: 'Identity' },
                                    { value: 'Telco', label: 'Telco' },
                                    { value: 'MMO', label: 'MMO' },
                                    { value: 'BNPL', label: 'BNPL' },
                                ]}
                                placeholder="Choose Product"
                                onChange={(selected)=>{
                                    console.log(selected, props)
                                    props.setFieldValue( "product", selected?.map(x => x?.value))
                                }}
                                value={props?.values?.product}
                            />
                            <SelectField
                                name='accountType'
                                options={[
                                    { value: 'Business', label: 'Business' },
                                    { value: 'Consumer', label: 'Consumer' },
                                ]}
                                placeholder="Account Type"
                                onChange={(selected)=>{
                                    props.setFieldValue( "accountType", selected.label )
                                }}
                                value={props?.values?.accountType}
                            />
                            <SelectField
                                name='industry'
                                searchable={true}
                                options={[
                                    { value: 'Finance', label: 'Finance' },
                                    { value: 'Retail & Distrubution', label: 'Retail & Distrubution' },
                                    { value: 'Agriculture', label: 'Agriculture' },
                                    { value: 'Chemical (manufacturing)', label: 'Chemical (manufacturing)' },
                                    { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
                                    { value: 'Computing (software)', label: 'Computing (software)' },
                                    { value: 'Computing (hardware)', label: 'Computing (hardware)' },
                                    { value: 'Construction', label: 'Construction' },
                                    { value: 'Education', label: 'Education' },
                                    { value: 'Energy', label: 'Energy' },
                                    { value: 'Entertainment', label: 'Entertainment' },
                                    { value: 'Food', label: 'Food' },
                                    { value: 'Health Care', label: 'Health Care' },
                                    { value: 'Hospitality', label: 'Hospitality' },
                                    { value: 'Information', label: 'Information' },
                                    { value: 'Manufacturing', label: 'Manufacturing' },
                                    { value: 'Telecommunication', label: 'Telecommunication' },
                                    { value: 'Transport', label: 'Transport' },
                                    { value: 'Sports Management & Event Promotion', label: 'Sports Management & Event Promotion' },
                                ]}
                                placeholder="Industry"
                                onChange={(selected)=>{
                                    props.setFieldValue( "industry", selected.label )
                                }}
                                value={props?.values?.industry}
                            />
                            <Button
                                isValid={props.isValid && permissionValues?.includes("edit")}
                                isLoading={submitLoading}
                                type={permissionValues?.includes("edit") ? "submit":"button"}
                                text="Create App"
                                className="mb-[30px] mt-2.5"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default Create;