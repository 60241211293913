import { Row, Col, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import {logToBin} from "../../../lib/logs";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import { formatArrayPaging } from "utils/helpers";
import { SpectrumService } from "services/spectrum.service";

import arrow from "../../../images/svg/spectrum/arrow.svg";
import importCSV from "../../../images/svg/spectrum/import.svg";
import search from "../../../images/svg/search.svg";
import empty from "../../../images/svg/spectrum/empty.svg";
import plus from "../../../images/svg/spectrum/plus.svg";
import view from "../../../images/svg/spectrum/view.svg";
import play from "../../../images/svg/spectrum/play.svg";
import loading from "../../../images/gif/insight-loading.gif";
import btnloader from "../../../images/gif/white-loader.gif";
import viewLoader from "../../../images/svg/spectrum/view-loader.svg";
import loader from 'images/gif/blue-loader.gif';
import access from 'images/svg/spectrum/access.svg';


export default function Overview () {
    const [viewOption, setViewOption] = useState('t&c');
    const [selectedTab, setSelectedTab] = useState('individual');
    const [tableData, setTableData] = useState({
        data: [],
        loading: true
    });
    const [tableFor, setTableFor] = useState('debt-profile');
    const [identityNo, setIdentityNo] = useState('');
    const [loadView, setLoadView] = useState('');
    const [loaders, setLoaders] = useState({
        searchDebt: false
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [unpagedData, setUnpagedData] = useState([]);
    let history = useNavigate();
    const [showTC, setShowTC] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [requestData, setRequestData] = useState({})

    useEffect(()=>{
        loadRequestStats()
        setTableData({
            ...tableData,
            loading: true
        })
        setIdentityNo('');
        tableFor==="contributions"? allContributions() : allDebtProfileHistory() ;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tableFor, selectedTab])

    const loadRequestStats = () => {
        SpectrumService.getSpectrumRequests()
            .then(response=> response.data)
            .then(res => {
                if(res.status){
                    setRequestData(res.data)
                }
            })
            .catch((err) => {})
    }

    const allContributions = () => {
        SpectrumService.getContributions(selectedTab)
            .then(response=> response.data)
            .then(res => {
                if(res.status === true){
                    setUnpagedData(res.data.reverse())
                    setTableData({
                        ...tableData,
                        data: formatArrayPaging(res.data.reverse(), 10),
                        loading: false
                    })
                } else {
                    toast.error(res.message)
                    setTableData({
                        ...tableData,
                        loading: false
                    })
                }
            })
            .catch((err) => {
                setTableData({
                    ...tableData,
                    loading: false
                })
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const allDebtProfileHistory = () => {
        SpectrumService.getDebtProfileSearched(selectedTab)
            .then(response=> response.data)
            .then(res => {
                if(res.status === true){
                    setUnpagedData(res.data.reverse())
                    setTableData({
                        ...tableData,
                        data: formatArrayPaging(res.data.reverse(), 10),
                        loading: false
                    })
                } else {
                    toast.error(res.message)
                    setTableData({
                        ...tableData,
                        loading: false
                    })
                }
            })
            .catch((err) => {
                setTableData({
                    ...tableData,
                    loading: false
                })
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doDebtProfileSearch = () => {
        if((selectedTab==="individual" && identityNo.length===11) || (selectedTab==="business" && (identityNo.toLowerCase().includes("rc") || identityNo.toLowerCase().includes("bn")))){
            debtProfileAction(identityNo);
            setLoaders({
                ...loaders,
                searchDebt: true
            })
        } else {
            toast.error(`${selectedTab==="business"?"Please include RC/BN prefix in your identity number":"BVN must be 11 digits!"}`)
        }
    }

    const debtProfileAction = (idNumber) => {
        SpectrumService.getSingleDebtProfile(selectedTab, idNumber)
            .then(response=> response.data)
            .then(res => {
                setLoaders({
                    ...loaders,
                    searchDebt: false
                })
                setLoadView('')
                if(res.status === true){
                    if(res?.data?.name){
                        sessionStorage.setItem("spectrumDetails", JSON.stringify(res.data))
                        history(`/spectrum/details/${selectedTab}`)
                    } else {
                        toast.error(res.message)
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoaders({
                    ...loaders,
                    searchDebt: false
                })
                setLoadView('')
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doContributionSearch = (val) => {
        console.log(val)
        if(val===""){
            setTableData({
                ...tableData,
                data: formatArrayPaging(unpagedData, 10),
                loading: false
            })
        } else {
            const filteredData = unpagedData?.filter((item) => item?.bvn?.includes(val) || item?.regNo?.toLowerCase().includes(val.toLowerCase()));
            setTableData({
                ...tableData,
                data: formatArrayPaging(filteredData, 10),
                loading: false
            })
        }
    }

    const goToBtn = () => {
        viewOption==="t&c"? setShowTC(true)
        : setShowAccessModal(true)
    }

    return(
        <div className="spectrum_overview">
            <div className="spectrum_overview-top">
                <h5 className="text-lg font-medium">Get Started with Spectrum</h5>
                {/* <Link to="/spectrum/pricing">
                    <button>View Pricing</button>
                </Link> */}
            </div>
            <TermsAndConditions
                showTC={showTC}
                setShowTC={()=>setShowTC(false)}
                reload={()=>loadRequestStats()}
            />
            <RequestAccess
                type={viewOption}
                showAccessModal={showAccessModal}
                setShowAccessModal={()=>setShowAccessModal(false)}
                reload={()=>loadRequestStats()}
            />
            <Row>
                <Col xl={7}>
                    <div className="spectrum_overview-shareBox">
                        <div className="spectrum_overview-shareBox-select">
                            <p className={viewOption==="t&c"?'active-text mb-2.5':'mb-2.5 inactive-text'} onClick={()=>setViewOption('t&c')}> 
                                
                                {
                                    viewOption==="t&c" ?
                                    <img src={arrow} alt="arrow" />
                                    :
                                    <input type="checkbox" checked={requestData.acceptedTerms} />
                                }
                                <span>Read and Accept T&C</span>
                            </p>
                            <p className={viewOption==="dev access"?'active-text mb-2.5':'inactive-text mb-2.5'} onClick={()=>setViewOption('dev access')}>
                                {
                                    viewOption==="dev access" ?
                                    <img src={arrow} alt="arrow" />
                                    :
                                    <input type="checkbox" checked={requestData.devAccess} />
                                }
                                <span>Request Dev Access</span>
                            </p>
                            <p className={viewOption==="prod access"?'active-text mb-2.5':'inactive-text mb-2.5'} onClick={()=>setViewOption('prod access')}>
                                {
                                    viewOption==="prod access" ?
                                    <img src={arrow} alt="arrow" />
                                    :
                                    <input type="checkbox" checked={requestData.prodAccessRequest} />
                                }
                                <span>Request "Go-Live" to network</span>
                            </p>
                        </div>
                        <div className="spectrum_overview-shareBox-content">
                            <h5 className="text-lg font-medium">
                                {
                                    viewOption==="t&c"?'Terms and Conditions'
                                    : viewOption==='dev access'?'Request Developer Access'
                                    : 'Request Prod Access'
                                }
                            </h5>
                            <p className="my-2.5 text-base">
                                {
                                    viewOption==="t&c"?'CreditChek has provided an Information Symmetry infrastructure to enable seamless real-time sharing of credit data amongst lenders securely to prevent loan stacking and detect regular defaulters.'
                                    : viewOption==="dev access"?"The access to the Spectrum platform only provides you API access to the Staging environment and not the production ledger. Please contact sales@creditchek.africa if you have completed the Developer API testing, and integration, and now need to go Live."
                                    : `Request Access to the Production environment to start using spectrum`
                                }
                            </p>
                            <div>
                                {
                                    (viewOption==="t&c" && requestData.acceptedTerms) || (viewOption==="prod access" && requestData.prodAccessRequest) || (viewOption==="dev access" && requestData.devAccess) ? 
                                    null 
                                    :
                                    <button onClick={goToBtn}>
                                        {
                                            viewOption==="t&c"?'Read T&C'
                                            : 'Request'
                                        }
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xl={5}>
                    <div className="spectrum_overview-video">
                        <img src={play} alt="play"/>
                        <h5>Quick Tour</h5>
                        <p>Learn how the Data network works</p>
                    </div>
                </Col>
            </Row>
            <div className="spectrum_overview-tableNav">
                <p className={selectedTab==="individual"?"selectedTab":''} onClick={()=>setSelectedTab('individual')}>For Individuals</p>
                <p className={selectedTab==="business"?"selectedTab":''} onClick={()=>setSelectedTab('business')}>For Businesses</p>
            </div>
            <div className="spectrum_overview-dataTable">
                <div className="spectrum_overview-dataTable-top">
                    <div>
                        <h5 className="text-lg font-medium">Lenders Data Network</h5>
                        <p>A distributed ledger infrastructure for African lenders to share real-time commercial lending information & insights securely.</p>
                    </div>
                    <Link to="/spectrum/import">
                        <button className="flex items-center">
                            <img src={importCSV} alt="" />
                            <span>Import CSV</span>
                        </button>
                    </Link>
                </div>
                <div className="spectrum_overview-dataTable-top">
                    <div className="spectrum_overview-dataTable-top-selection">
                        <p className={`search-debt ${tableFor==='debt-profile' && 'active'}`} onClick={()=>setTableFor('debt-profile')}>Search Debt Profile</p>
                        <p className={`contribution ${tableFor==='contributions' && 'active'}`} onClick={()=>setTableFor('contributions')}>My contribution to Spectrum</p>
                    </div>
                    {   
                        tableFor==="contributions" &&
                        // search for my contributions
                        <div className="spectrum_overview-dataTable-top-input">
                            <input placeholder={`Search by ${selectedTab==="business"?"RC/BN":"BVN"}`} onChange={(e)=>doContributionSearch(e.target.value)} />
                            <img src={search} alt="search" />
                        </div>
                    }
                </div>

                {/* The form below shows only when search debt profile tile is clicked */}
                {   tableFor==="debt-profile" &&
                    <div className="spectrum_overview-dataTable-searchForm">
                        <div className="spectrum_overview-dataTable-top-input input-search">
                            <input placeholder={`Search by ${selectedTab==="business"?"RC/BN":"BVN"}`} onChange={(e)=>setIdentityNo(e.target.value)} value={identityNo} />
                            <img src={search} alt="search" />
                        </div>
                        <button onClick={doDebtProfileSearch} disabled={loaders.searchDebt}>
                            {
                                loaders.searchDebt ? 
                                <img src={btnloader} alt="" width="20px"/>
                                :
                                "Search"
                            }
                        </button>
                    </div>
                }
                {
                    !tableData.loading && tableData.data.length===0  ?
                    <div className="spectrum_overview-dataTable-empty">
                        <img src={empty} alt="import" className="spectrum_overview-dataTable-empty-mainIcon"/>
                        {
                            tableFor==="debt-profile" ?
                            // when search is done and there's no data
                            <div>
                                <h5 className="text-md font-medium">No Search History</h5>
                                <p className="my-2">Sorry, you have not yet performed any debt profile search. Get started by entering the {selectedTab==="business"?"RC or BN number" : "BVN"} of your customer in the form above.</p>
                            </div>
                            :
                            // when there has not been any contribution
                            <div>
                                <h5 className="text-md font-medium">No credit history data contributed yet</h5>
                                <p className="my-2">Start by accepting the terms and conditions and importing using the formatted CSV file</p>
                            </div>
                        }
                        <div className="flex justify-center">
                            <button className="read">Read T&C</button>
                            <Link to="/spectrum/import">
                                <button className="import flex items-center">
                                    <img src={plus} alt="import"/>
                                    <span>Import Files</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                    :
                    !tableData.loading && tableData.data.length>0  ?
                    <div className="spectrum_overview-dataTable-main">
                        <div className="spectrum_overview-dataTable-main-scroll">
                            <table>
                                    <tr>
                                        <th>Name</th>
                                        <th>{selectedTab==="individual"?"BVN":'RC/BN Number'}</th>
                                        <th>Created By</th>
                                        <th>Last Updated By</th>
                                        <th>Action</th>
                                    </tr>
                                    {
                                        tableData?.data[currentPage-1]?.map((item)=>(
                                            <tr key={item._id}>
                                                <td>{item.name}</td>
                                                <td>{selectedTab==="individual"?item.bvn:item.regNo}</td>
                                                <td>{item.createdBy.businessName}</td>
                                                <td>{item.lastUpdatedBy.businessName}</td>
                                                <td>
                                                    {
                                                        loadView!==item._id ?
                                                        <button className="view flex items-center" onClick={()=>{setLoadView(item._id);debtProfileAction(selectedTab==="individual"?item.bvn:item.regNo)}}>
                                                            <img src={view} alt="" />
                                                            <span>View</span>
                                                        </button>
                                                        :
                                                        <button className="view" >
                                                            <img src={viewLoader} alt=""  className="load" />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                            </table>
                        </div>
                        {
                            tableData.data.length>1 &&
                            <Pagination total={tableData.data.length} current={currentPage} setCurrent={(item)=>setCurrentPage(item)} />
                        }
                        <div className="spectrum_overview-dataTable-empty">
                            <div className="flex justify-center">
                                <button className="read">Read T&C</button>
                                <Link to="/spectrum/import">
                                    <button className="import flex items-center">
                                        <img src={plus} alt="import"/>
                                        <span>Import Files</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="spectrum_overview-dataTable-loading">
                        <img src={loading} alt="loading"/>
                        <h5>Data loading...</h5>
                    </div>
                }
            </div>
        </div>
    )
}


function TermsAndConditions ({showTC, setShowTC, reload}) {
    const agreeTerms = () => {
        SpectrumService.agreeTandC()
            .then(response=> response.data)
            .then(res => {
                console.log(res)
                if(res.status === true){
                    setShowTC(false)
                    toast.success("Terms and Conditions accepted.")
                    reload();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowTC(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={showTC} aria-labelledby="contained-modal-title-vcenter" size='md' centered >
            <div className='bg-cc-primary rounded-t-[15px] -mt-2.5 w-full '>
                <div className="bg-white mt-3 rounded-t-[8px] p-4">
                    <div>
                        <h2 className="text-[18px] font-semibold mt-2 mb-3 text-cc-primary underline text-center">Terms and Conditions – Spectrum: Alpha release </h2>
                        <p className="font-bold text-[16px] mb-2">-as of March 11th, 2023</p>
                        <p className="font-semibold text-base mb-2">This page is a summary of the most important aspects of the terms and conditions of CreditChek’s Alpha release Spectrum service, but it does not replace the Full version.</p>
                        <p className="font-semibold text-base mb-5">Introduction: Welcome to CreditChek’s Spectrum Terms of Use agreement. For purposes of this agreement, “SPECTRUM” refers to the Company’s distributed ledger network, which can be accessed at https://app.creditchek.africa or through our Public API Service. “SPECTRUM Service” refers to the Company’s Distributed Ledger services accessed via the API or Web Portal, where businesses can share lending information securely and in near real-time. The terms “we,” “us,” and “our” refer to the Company. “You” refers to the business, as a user of the SPECTRUM Service.</p>
                        <p className="font-bold text-[16px] mb-1">PRIVACY POLICY</p>
                        <p className="font-semibold text-base mb-5">The Company respects the privacy of its Service users. Please refer to the Company’s Privacy Policy (found here: https://www.creditchek.africa/privacy-policy ) which explains how we collect, use, and disclose information that pertains to your privacy. When you access or use the Service, you signify your agreement to the Privacy Policy as well as these Terms of Use.</p>
                        <p className="font-bold text-[16px] mb-1 text-cc-primary">The Service</p>
                        <p className="font-semibold text-base mb-2"><span className="text-[16px] font-bold">About Spectrum:</span> We are pleased to announce the introduction of a new feature: a private distributed ledger system for Lenders in Africa. This new system will provide lenders with a secure and efficient way to manage their financial records. It is built on a blockchain platform, which is an immutable and secure technology that ensures all data is protected from unauthorised access.</p>
                        <p className="font-semibold text-base mb-0">This service is designed to enhance the security and accuracy of data while providing a streamlined process for tracking and managing loan information. We believe this will be an invaluable tool for lenders in the region, and we look forward to helping them make more informed decisions with confidence.</p>
                         <p className="font-semibold text-base mb-5"><span className="text-[16px] font-bold">Use of Distributed Ledger Technology:</span> The ledger system will enable lenders to track approved loans, disbursed credits, and credit repayment transactions, manage customer credit accounts, and monitor compliance with regulations. Additionally, it will provide enhanced transparency and audibility by allowing users to view their entire transaction history. We believe this new system will drastically improve Africa's lending process's efficiency and security.</p>
                        <p className="font-bold text-[16px] mb-1">ABOUT THE SERVICE</p>
                        <p className="font-semibold text-base mb-3">The Service allows your lending business to securely share lending information with other peers in the network, with an aim to reduce information asymmetry and loan stacking within the credit ecosystem.</p>
                    </div>
                    <div className="myModal_main-widget-action mt-[20px]">
                        <button className="cancel w-[40%]" onClick={()=>setShowTC(false)}>Decline</button>
                        <button className="publish w-[40%]" onClick={()=>agreeTerms()}>Accept</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


function RequestAccess ({type, showAccessModal, setShowAccessModal, reload}) {
    const makeRequestDev = () => {
        SpectrumService.requestDevAccess()
            .then(response=> response.data)
            .then(res => {
                if(res.status === true){
                    setShowAccessModal(false)
                    toast.success("Access requested.")
                    reload();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowAccessModal(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const makeRequestProd = () => {
        SpectrumService.requestProdAccess()
            .then(response=> response.data)
            .then(res => {
                if(res.status === true){
                    setShowAccessModal(false)
                    toast.success("Access requested.");
                    reload();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setShowAccessModal(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={showAccessModal} aria-labelledby="contained-modal-title-vcenter" centered >
            {
                false ? 
                <div className="text-center my-[70px]">
                    <img src={loader} alt="loader" style={{margin:"auto", display:"block", width:"70px"}} />
                    <h4 className="text-[18px] text-black-3 font-medium">Please wait...</h4>
                </div>
                :
                <div className="my-4 mx-3">
                    <div className="flex items-start">
                        <img src={access} alt="" className="w-[40px] mr-2" />
                        <div>
                            <h4 className="text-[18px] text-black-3 font-medium">Spectrum {type==="dev access"? "Developer Access":"'Go-Live'"}?</h4>
                            <p className="text-grey-5">Request access to {type==="dev access"? "test Alpha release":"access the Beta (production) ledger"}.</p>
                        </div>
                    </div>
                    <div className="myModal_main-widget-action mt-[20px]">
                        <button className="cancel w-[40%]" onClick={()=>setShowAccessModal(false)}>Cancel</button>
                        <button className="publish w-[40%]" onClick={()=>type==='dev access'?makeRequestDev():makeRequestProd()}>Request</button>
                    </div>
                </div>
            }
        </Modal>
    )
}