import Button from "components/shared/Button"
import Header from "../Header"
import optIn from "images/svg/recova/opt-in.svg";
import { useSteps } from "react-step-builder";
import { useRecovaSetup } from "hooks/redux.hook";
import { useState } from "react";
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";
import { handleRequestErrors } from "utils/helpers";

export default function CreditInsuranceMain () {
    const  { jump } = useSteps()
    const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
    const [isLoading, setIsLoading] = useState({
        yesBtn: false,
        noBtn: false,
    })

    const handleSelection = (val) => {
        const payload = JSON.stringify({
            creditInsurance: val
        })
        RecovaService.enableCreditInsurance(payload)
            .then(response=> response.data)
            .then(res => {
                console.log(res)
                setIsLoading({
                        yesBtn: false,
                        noBtn: false
                })
                if(res.status){
                    if (val) {
                    updateSetupDispatch({
                        currentStep: 15,
                        enabledCreditInsurance: val,
                    })
                    jump(15)
                    } else {
                    updateSetupDispatch({
                        currentStep: 21,
                        enabledCreditInsurance: val,
                    })
                    jump(21)
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setIsLoading({
                        yesBtn: false,
                        noBtn: false
                })
            });
    }

    return (
        <div className="rounded-3xl px-2.5 py-4 sm:px-0">
            <Header 
                progress={50} 
                back={()=>{
                    if(recovaSetupData.paymentOption==="self-service"){
                        updateSetupDispatch({
                          currentStep: 7
                        })
                        jump(7)
                    } else {
                        updateSetupDispatch({
                          currentStep: 9
                        })
                        jump(9)
                    }
                }} 
            />
            <h2 className='text-xl font-bold leading-5'>Credit Insurance</h2>
            <p className='mt-2'>To further protect your credit business, you can opt-in and enable our embedded risk management features on your credit product within your platform.</p>
            <div
              style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
              className='px-3 py-8 rounded-2xl my-10 box-border'
            >
                <img src={optIn} alt="opt-in" className="w-[60px] block mx-auto mb-6" />
                <p className="text-center">Opt-in for Credit Insurance</p>
                <div className="flex gap-x-[60px] mt-6 justify-center">
                    <div className="w-[100px]">
                        <Button
                            text={"Yes"}
                            isLoading={isLoading.yesBtn}
                            onClick={()=>{
                                handleSelection(true)
                                setIsLoading({
                                    ...isLoading,
                                    yesBtn: true,
                                })
                            }}
                            isValid={true}
                            className="bg-cc-primary"
                        />
                    </div>
                    <div className="w-[100px]">
                        <Button
                            isLoading={isLoading.noBtn}
                            text={"No"}
                            onClick={()=>{
                                handleSelection(false)
                                setIsLoading({
                                    ...isLoading,
                                    noBtn: true,
                                })
                            }}
                            isValid={true}
                            className="!border-cc-primary border-[1px] !text-cc-primary bg-white"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}