import React from "react";
import { Steps, StepsProvider } from "react-step-builder";
import AddPayoutMethod from "./AddPayoutMethod";
import AutomatedActivation from "./AutomatedActivation";
import LinkedSuccessCard from "./LinkedSuccessCard";
import NotifyMe from "./NotifyMe";
import Review from "./Review";
import SelfServiceActivation from "./SelfServiceActivation";
import SuccessPage from "./SuccessPage";
import Terms from "./Terms";
import UseCase from "./UseCase";
import CreditInsuranceMain from "./creditInsurance";
import EnableCreditInsurance from "./creditInsurance/EnableCreditInsurance";
import LoanProductDef from "./creditInsurance/LoanProductDefinition";
import LoanProductSuccess from "./creditInsurance/LoanProductSuccess";


export default function RecovaSetup ({ step, closeModal }) {
    return (
        <StepsProvider>
            <Steps startsFrom={step}>
                {/* for some reason console.logging total steps from the useSteps hook returns 18 intead of 9 */}
                <UseCase /> {/**step 1 */}
                <NotifyMe /> {/**step 3 */}
                <AddPayoutMethod /> {/**step 5 */}
                <SelfServiceActivation /> {/**step 7 */}
                <AutomatedActivation /> {/**step 9 */}
                <LinkedSuccessCard /> {/**step 11 */}
                <CreditInsuranceMain/> {/**step 13 */}
                <EnableCreditInsurance/> {/**step 15 */}
                <LoanProductDef/> {/**step 17 */}
                <LoanProductSuccess /> {/**step 19 */}
                <Terms /> {/**step 21 */}
                <Review /> {/**step 23 */}
                <SuccessPage closeModal={closeModal} /> {/*step 25*/}
            </Steps>
        </StepsProvider>
    );
};
