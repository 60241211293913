import {BrowserRouter, Routes, Route} from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import Customers from "./pages/Customers";
import { ToastContainer } from "react-toastify";
import DirectorEmailConsent from "components/Modal/DirectorEmailConsent";
import Guarantor from "./pages/Customers/GuarantorForm";
import BorrowerConsent from "./pages/Customers/BorrowerConsent";
import BVNIgree from "./pages/Customers/BVNIgree";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from 'redux-persist/integration/react';

import "./scss/main.css";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';


function App() {

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer autoClose={10000} />
          <BrowserRouter>
            <Routes>
              <Route path="auth/*" element={<Auth/>}/>
              <Route path="/customer/*" element={<Customers />} />
              <Route path="/email-consent/:floatId/:type/:email" element={<DirectorEmailConsent  />} />
              <Route path="/guarantor" element={<Guarantor />} />
              <Route path="/borrower-consent" element={<BorrowerConsent />} />
              <Route path="/bvn-verification/:appLink" element={<BVNIgree/>} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/*" element={<Dashboard  />}/>
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
