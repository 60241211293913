import {Routes, Route} from "react-router-dom";
import { useEffect, useState } from "react";
import Main from "./Main";
import Payouts from "./Payouts";
import Collections from "./Collections";
import Mandates from "./Mandates/MandateLayout";
import Disputes from "./Disputes";
import Products from "./Products";
import Preference from "./Preference";


function Recova ({setDetailName, rolePermissions}) {
    const [path, setPath] = useState("main");
    const [permissionValues, setPermissionValues] = useState([]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    return(
        <div className="py-[20px] px-10 md:px-[20px]">
            <Routes>
                <Route path="/" element={<Main path={path} setPath={(name)=>setPath(name)} permissionValues={permissionValues} />}/>
                <Route path="/mandates" element={<Mandates />} />
                <Route path="/payouts" element={<Payouts />} />
                <Route path="/collections" element={<Collections />} />
                <Route path="/disputes/*" element={<Disputes />} />
                <Route path="/products" element={<Products />} />
                <Route path="/preference" element={<Preference />} />
            </Routes>
        </div>
    )
}

export default Recova;
