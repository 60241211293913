import axios from 'axios';

export const createSlackChannel = (channelName) => {
    let url = `https://slack.com/api/conversations.create?name=${channelName}&is_private=false&pretty=1`;
    return axios({
            method: 'post',
            url: url,
            data: `token=xoxp-3048730683156-3070210657440-4035684369731-5b2a8b7c13a3516154d7c2564a36da83`,
        })
}

export const inviteUser = (channelId, email, fullName) => {
    let url = `https://slack.com/api/admin.users.invite?channel_ids=${channelId}&email=${email}&team_id=T031EMGL34L&pretty=1`;
    return axios({
      
          method: 'post',
      
          url: url,
      
          data: `token=xoxp-3048730683156-3070210657440-4035684369731-5b2a8b7c13a3516154d7c2564a36da83`,
      
        })
}