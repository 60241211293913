import Header from "../Header"
import successTick from "images/svg/recova/successTick.svg"
import { useState } from "react";
import mark from "images/svg/recova/mark.svg"
import { useSteps } from "react-step-builder";
import Button from "components/shared/Button";
import { useRecovaSetup } from "hooks/redux.hook";
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";
import { handleRequestErrors } from "utils/helpers";


export default function EnableCreditInsurance () {
    const { recovaSetupData, updateSetupDispatch} = useRecovaSetup()
    const [selected, setSelected] = useState(recovaSetupData.creditUseCase || [])
    const { jump } = useSteps();
    const [isLoading, setIsLoading] = useState(false)

    const options= ["Loan", "BNPL", "RNPL", "Asset Financing", "Other"]

    const handleSubmit = () => {
        setIsLoading(true)
        RecovaService.creditInsuranceUsecase(JSON.stringify({creditUseCase: selected}))
        .then(response=> response.data)
        .then(res => {
            setIsLoading(false)
            if(res.status){
                updateSetupDispatch({
                    currentStep: 17,
                    creditUseCase: selected
                })
                jump(17)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err, true)
            setIsLoading(false)
        });
    }

    const handleSelection = (item) => {
        if(selected.includes(item)){
            // remove
            const newArray = selected.filter(e => e !== item);
            setSelected(newArray)
        } else {
            setSelected([...selected, item])
        }
    }

    return (
        <div className="rounded-3xl px-2.5 py-4 sm:px-0">
            <Header progress={60} back={()=>{
                    jump(13)
                    updateSetupDispatch({
                        currentStep: 13
                    })
                }} 
            />
            <div className="text-center">
                <img src={successTick} alt="success" className="w-[60px] block mx-auto mb-2" />
                <h2 className='text-xl font-bold leading-5'>Credit Insurance Enabled</h2>
                <p className='mt-2'>You have successfully opt-in for Credit insurance</p>
            </div>
            <div
              style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
              className='px-3 py-8 rounded-2xl my-10 box-border'
            >
                <h2 className='text-lg font-bold leading-5'>Define / Choose use case</h2>
                <p>Select from the list the one that defines your business </p>
                <div className="flex flex-col gap-y-6 mt-7">
                    {
                        options.map(item=>(
                            <div className="p-2.5 flex justify-between border-[2px] border-[#CCDCFF] rounded-[8px]" onClick={()=>handleSelection(item)}>
                                <p className="text-cc-grey-4 text-base">{item}</p>
                                {
                                    selected.includes(item) ? 
                                    <img src={mark} alt="mark" className="w-6"/>
                                    :
                                    <div className="w-6 h-6 rounded-full border-[1px] border-[#667085]" />
                                }
                            </div>
                        ))
                    }
                </div>
                <Button
                    text={"Submit"}
                    onClick={handleSubmit}
                    isValid={true}
                    isLoading={isLoading}
                    className="bg-cc-primary mt-7"
                />
            </div>
        </div>
    )
}