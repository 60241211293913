// import Modal from "../../../Modal"
import { useEffect, useState } from "react";
import {Row, Col, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import { AuthService } from "services/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { logToBin } from "../../../../lib/logs";
import { Mixpanel } from "../../../../lib/mixpanel";
import { editFirstAndLastName } from "redux/login";

import notvisible from "../../../../images/svg/not-visible.svg";
import visible from "../../../../images/svg/visible.svg";
import padlock from "../../../../images/svg/settings/padlock.svg";


function Profile ({permissionValues}) {
    const [show, setShow] = useState(false)
    const [form, setForm] = useState({
        oldPassword:"",
        newPassword:"",
        confirmPassword:""
    });
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [editProfile, setEditProfile]=useState({
        firstName: b2b_data?.user?.firstName,
        lastName: b2b_data?.user?.lastName
    })
    const [passwordValid, setPasswordValid] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const dispatch = useDispatch();

    useEffect(()=>{
        if(form.newPassword===form.confirmPassword && form.oldPassword!=="" && form.newPassword!==""){
            setPasswordValid(true)
        } else {
            setPasswordValid(false)
        }
    },[form])

    const handleProfileChange = (e) => {
        setEditProfile({
            ...editProfile,
            [e.target.name]: e.target.value
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        const passwordWarning = document.getElementById("password-warning");

        if(e.target.name==="newPassword" || e.target.name==="confirmPassword"){
            var hasUpperCase = /[A-Z]/.test(e.target.value);
            var hasLowerCase = /[a-z]/.test(e.target.value);
            var hasNumbers = /\d/.test(e.target.value);
            var hasNonalphas = /\W/.test(e.target.value);
            if (hasUpperCase && hasLowerCase && hasNonalphas && hasNumbers) {
                passwordWarning.innerHTML = "";
            } else {
                setPasswordValid(false)
                passwordWarning.innerHTML = "<p>Password must be a minimum 8 characters and contain at least one uppercase letter, one lower case letter, one special character and a number</p>"
            }
        }
    }

    const doChangePassword = () => {
        var btn = document.getElementById("actionBtnPassword");
        btn.innerHTML=`Please Wait...`;
        let payload = JSON.stringify({
            oldPassword: form.oldPassword,
            newPassword: form.newPassword
        })
        if(form.newPassword===form.confirmPassword){
            AuthService.changePassword(b2b_data?.user?.id, payload)
                .then((response) => response.data)
                .then((res) => {
                    btn.innerHTML=`Confirm`;
                    if (res.success === true) {
                        toast.success(res.message);
                        setShow(false)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    btn.innerHTML=`Confirm`;
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        } else {
            toast.error("Passwords do not match!");
        }
    }

    const doSubmit = () => {
        Mixpanel.track(`Edit Profile button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        })
        if(permissionValues?.includes("edit")){
            var btn = document.getElementById("actionBtn");
            btn.innerHTML=`Please Wait...`;
            let payload = JSON.stringify({
                firstName: editProfile.firstName===""? b2b_data?.user?.firstName:editProfile.firstName,
                lastName: editProfile.lastName===""? b2b_data?.user?.lastName:editProfile.lastName,
            })
            AuthService.editUserProfile(payload, b2b_data?.user?.id)
                .then((response) => response.data)
                .then((res) => {
                    btn.innerHTML=`Save Changes`;
                    if (res.success === true) {
                        toast.success(res.message);
                        dispatch(editFirstAndLastName(editProfile))
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    btn.innerHTML=`Save Changes`;
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }else{
            toast.error("You are not authorized to perform this action!!!")
        }
    }
    
    return(
        <div className="apps_create settings_notifs">
            <h4 className="text-[1.3em] font-medium">Profile</h4>
            <hr className="my-[15px]"/>
            <div className="settings_profile-form">
                <label>Email Address</label><br/>
                <input className="apps_create-input" type="text" value={b2b_data?.user?.email} readOnly /><br/>
                <Row>
                    <Col md={6}>
                        <label>First Name</label><br/>
                        <input className="apps_create-input" type="text" defaultValue={b2b_data?.user?.firstName} placeholder="First Name" name="firstName" onChange={handleProfileChange} /><br/>
                    </Col>
                    <Col md={6}>
                        <label>Last Name</label><br/>
                        <input className="apps_create-input" type="text" defaultValue={b2b_data?.user?.lastName} placeholder="Last Name" name="lastName" onChange={handleProfileChange} /><br/>
                    </Col>
                </Row>
                <label>Role assigned</label><br/>
                <input className="apps_create-input" type="text" value={b2b_data?.user?.role==="businessOwner"?"Business Owner":b2b_data?.user?.role.toUpperCase()} readOnly /><br/>
                <label>Password</label><br/>
                <button 
                    className={permissionValues?.includes("edit")?"apps_create-button settings_profile-form-btn1" : "apps_create-button settings_profile-form-btn2"}
                    onClick={()=> 
                        permissionValues?.includes("edit") ? setShow(true) : toast.error("You are not authorized to perform this action!!!")
                    }
                >
                        Change Password
                </button>
                <button 
                    className={
                        permissionValues?.includes("edit") ? 
                        "apps_create-button" 
                        :
                        "apps_create-btnInactive"
                    }
                    onClick={()=>doSubmit()} id="actionBtn"
                >
                    Save Changes
                </button>
            </div>
            {/* <Modal /> */}
            <Modal show={show} onHide={()=>setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div>
                    <div className="settings_modals-threshold">
                        <img src={padlock} alt="" style={{display:"block", margin:"auto"}} />
                        <h5 style={{textAlign:"center"}}>Change Password</h5>
                        <p style={{textAlign:"center"}}>Create a new password</p>
                        <div>
                            <label>Old Password</label>
                            <div className="auth_signup-password">
                                <input type={showOldPassword?"text":"password"} required placeholder="Old Password" name="oldPassword" onChange={handleChange}  />
                                <img style={{top:showOldPassword?"10px":"17px"}} src={showOldPassword?visible:notvisible} alt="visible" className={showOldPassword?"visible":"notvisible"} onClick={()=>setShowOldPassword(!showOldPassword)} />
                            </div>
                        </div>
                        <div>
                            <label>New Password</label>
                            <div className="auth_signup-password">
                                <input type={showNewPassword?"text":"password"} required placeholder="New Password" name="newPassword" onChange={handleChange}  />
                                <img style={{top:showNewPassword?"10px":"17px"}} src={showNewPassword?visible:notvisible} alt="visible" className={showNewPassword?"visible":"notvisible"} onClick={()=>setShowNewPassword(!showNewPassword)} />
                            </div>
                        </div>
                        <div>
                            <label>Confirm Password</label>
                            <div className="auth_signup-password">
                                <input type={showConPassword?"text":"password"} required placeholder="Confirm Password" name="confirmPassword" onChange={handleChange}  />
                                <img style={{top:showConPassword?"10px":"17px"}} src={showConPassword?visible:notvisible} alt="visible" className={showConPassword?"visible":"notvisible"} onClick={()=>setShowConPassword(!showConPassword)} />
                            </div>
                        </div>
                    </div>
                    <div id="password-warning"style={{fontSize:"0.8em", color:"red", margin:"-25px 20px -40px"}}>
                        <p></p>
                    </div>
                    <div className="settings_modals-threshold">
                        <div>
                            <button className="btn1" onClick={()=>setShow(false)}>Cancel</button>
                            <button className={passwordValid?"btn2":"btn3"} onClick={doChangePassword} disabled={!passwordValid} id="actionBtnPassword">Confirm</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Profile;