import { useState } from "react";
import { Modal } from "react-bootstrap";
import { CreditService } from "services/credit.service";
import { toast } from "react-toastify";

import loader from "../../images/gif/white-loader.gif";


function Share ({show, cancel, bvn}) {
    const [data, setData] = useState({
        name: "",
        email: ""
    });
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleImage = (e) => {
        if (e.target.files[0]?.size > 2097152) {
          alert("File too large! A maximum of 2MB is allowed.");
        } else {
          setFile(e.target.files[0]);
        }
    };

    const uploadTrigger = (e) => {
        e.preventDefault();
        document.getElementById("inputFile").click();
    };

    
    const shareReport = () => {
        setIsLoading(true)
        let formData = new FormData();
        formData.append('name', data.name);
        formData.append('bvn', bvn);
        formData.append('email', data.email);
        formData.append('file', file);
        CreditService.sendReport(formData)
            .then((response) => response.data)
            .then((res) => {
                setIsLoading(false)
                if(res.status===true){
                    toast.success(res.message)
                    cancel();
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                if(err.response){
                    toast.error(err.response.data.message)
                } else{
                    if(err.message === "timeout of 60000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <Modal show={show} onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <h4>Share Report</h4>
                <div>
                    <div className="myModal_main-shareReport">
                        <p>Download report first then select file to share.</p>
                        <input type="text" required  placeholder="Name of recipient" name="name" onChange={handleChange} />
                        <input type="email" required placeholder="Email of recipient" name="email" onChange={handleChange} />
                        <input type="file" required style={{ display: "none" }} id="inputFile" onChange={handleImage} />
                        <input type="text" value={file?.name} readOnly placeholder="Upload downloaded report file" onClick={uploadTrigger} />
                    </div>
                    <div className="myModal_main-widget-action">
                        <button className="cancel" onClick={cancel}>Cancel</button>
                        <button className="publish" style={{width:'60px'}} onClick={shareReport} disabled={isLoading}>{isLoading ? <img style={{width:'25px'}} src={loader} alt="" className="block mx-auto" /> : 'Share'}</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Share;