import { APIKit } from "services";

const timeout = 15000;

export const BankService = {
    getAllBankLogo: () => {
        return APIKit(timeout).get(`/digitalassets/bank/get-all`, {
            baseURL: "https://api.creditchek.africa/v1"
        })
    },
}