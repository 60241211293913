import { createSlice } from '@reduxjs/toolkit'


export const radarSlice = createSlice({
    name: 'radar',
    initialState: {
        details: {} // radar search response
    },
    reducers: {
        radarDetails: (state, action) => {
            return { 
                ...state, 
                details: action.payload
            }
        },
    },
})

export const { radarDetails } = radarSlice.actions

export default radarSlice.reducer