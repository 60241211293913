import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { AuthService } from "services/auth.service";
import { capitalizeFirstLetter, handleRequestErrors } from "utils/helpers";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { inviteSchema } from "utils/validationSchemas";
import passwordGood from "images/svg/passwordGood.svg";
import passwordBad from "images/svg/passwordBad.svg";


function Onboarding () {
    let {token} = useParams();
    const [loading, setLoading] = useState(false)

    const form = useFormik({
        initialValues: {
            userId: '',
            first_name: "",
            last_name: "",
            email:"",
            role:"",
            password: ""
        },
        validationSchema: inviteSchema, 
        onSubmit: (values) => {
            let payload = JSON.stringify({
                firstName: values.first_name,
                lastName: values.last_name,
                password: values.password
            })
            const readTerms = document.getElementById('readTerms');
    
            if (readTerms.checked) {
                setLoading(true)
                AuthService.completeInvite(payload, values.userId)
                    .then((response) => response.data)
                    .then((res) => {
                        setLoading(false)
                        if (res.success === true) {
                            toast.success("Onboarding completed successfully!!!");
                            setTimeout( function (){
                                window.location = '/';
                            }, 1500);
                        } 
                        else {
                            toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        setLoading(false)
                        handleRequestErrors(err)
                    });
            } else {
                toast.info("Please make sure that you have read our terms and conditions first!")
            }
        }
    })

    useEffect(()=>{
        AuthService.getInvitedUser(token)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message)
                    form.setFieldValue("email", res?.data?.email?.toLowerCase());
                    form.setFieldValue("role", capitalizeFirstLetter(res?.data?.role));
                    form.setFieldValue("userId", res.data.id);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div className="mt-[50px]">
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit}>
                    <TextField
                        name='first_name'
                        type='text'
                        placeholder="First name"
                    />
                    <TextField
                        name='last_name'
                        type='text'
                        placeholder="Last name"
                    />
                    <TextField
                        name='email'
                        type='text'
                        placeholder="Email Address"
                        readOnly
                    />
                    <TextField
                        name='role'
                        type='text'
                        placeholder="User Role"
                        readOnly
                    />
                    <TextField
                        name='password'
                        type='password'
                        placeholder="Password"
                    />
                    {
                        form?.values?.password!=="" &&
                        <ul className="auth_signup-passwordCheck -mt-[20px] mb-6">
                            <li> 
                                <img src={form?.values?.password?.length>= 8 ? passwordGood : passwordBad} alt="icon" />
                                <span>Must be at least 8 characters</span>
                            </li>
                            <li>
                                <img src={/[a-z]/.test(form?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                <span>Must contain at least 1 lower case letters(s)</span>
                            </li>
                            <li> 
                                <img src={/[A-Z]/.test(form?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                <span>Must contain at least 1 upper case letters(s)</span>
                            </li>
                            <li> 
                                <img src={/\d/.test(form?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                <span>Must contain at least 1 numbers</span>
                            </li>
                            <li> 
                                <img src={/\W/.test(form?.values?.password) ? passwordGood : passwordBad} alt="icon" />
                                <span>Must contain at least 1 special character</span>
                            </li>
                        </ul>
                    }
                    <div style={{display:"flex", alignItems:"flex-start"}}>
                        <input type="checkbox" className="w-10 mr-1.5" id="readTerms" />
                        <p className="-mt-1.5">I have read, understood and i agree to Creditchek's <a style={{color:'#0046E6', cursor:'pointer', textDecoration:"none"}} href="https://creditchek.africa/terms-of-use" target="_blank" rel="noreferrer"> Terms and Conditions </a> and that my information will only be used according to CreditChek’s <a style={{color:'#0046E6', cursor:'pointer', textDecoration:"none"}} href="https://creditchek.africa/privacy-policy" target="_blank" rel="noreferrer"> Privacy Policy </a></p>
                    </div>
                    <Button
                        isValid={form.isValid}
                        isLoading={loading}
                        type="submit"
                        text="Get Onboarded"
                        className="mb-[30px] mt-2.5"
                    />
                </form>
            </FormikProvider>
            <p className="text-cc-black-5 -mt-3.5 mb-1.5">Already onboarded? <Link to="/auth/login"><span className="cursor-pointer text-cc-primary font-semibold no-underline">Login</span></Link></p>
            <p style={{color:"#8A8A8A", marginBottom:"50px !important"}} >&copy; CreditChek  Technologies</p>
        </div>
    )
}

export default Onboarding;