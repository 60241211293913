import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";


export default function NINResult () {
    const navigate = useNavigate();
    
    let ninRes = {};
    try {
        ninRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 
    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!ninRes.photo ? `url(${ninRes.photo})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{ninRes.firstname} {ninRes.surname}</h5>
                        <p>NIN: {ninRes?.nin}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">NIN</p>
                            <p className="text2">{ninRes?.nin}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{ninRes?.birthdate ?? "Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{ninRes.gender==="M"?"Male":"Female"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Marital Status</p>
                            <p className="text2">{ninRes?.maritalstatus||"Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Phone Number</p>
                            <p className="text2">{ninRes?.telephoneno ?? "Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Nationality</p>
                            <p className="text2">{ninRes?.birthcountry||"Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Email</p>
                            <p className="text3">{ninRes?.email??"Not available"}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Education Level</p>
                            <p className="text2">{ninRes.educationallevel===""?"Not available":ninRes.educationallevel}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Profession</p>
                            <p className="text2">{ninRes.profession??"Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Original Location</p>
                            <p className="text2">{ninRes.self_origin_lga} {ninRes.self_origin_state??"Not available"}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Birth Location</p>
                            <p className="text2">{ninRes.birthlga} {ninRes.birthstate===""?"Not available":ninRes.birthstate}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Employment Status</p>
                            <p className="text2">{ninRes.employmentstatus===""?"Not available":ninRes.employmentstatus}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Native Language</p>
                            <p className="text2">{ninRes.ospokenlang===""?"Not available":ninRes.ospokenlang}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Next of Kin Name</p>
                            <p className="text2">{ninRes.nok_firstname===""?"Not available":ninRes.nok_firstname} {ninRes.nok_surname}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Religion</p>
                            <p className="text2">{ninRes.religion===""?"Not available":ninRes.religion}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Tracking ID</p>
                            <p className="text2">{ninRes.centralID===""||ninRes.centralID===" "?"Not available":ninRes.centralID}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Residential Address</p>
                            <p className="text2">{ninRes.residence_lga} {ninRes.residence_state===""?"Not available":ninRes.residence_state}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Next of Kin Address</p>
                            <p className="text2">{ninRes.nok_town} {ninRes.nok_lga===""?"Not available":ninRes.nok_lga}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}