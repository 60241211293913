import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AuthService } from "services/auth.service";
import Button from "components/shared/Button";
import Table from "components/Table";
import clsx from "clsx";
import CreateWebhook from "../components/CreateWebhook";

import hook from "images/svg/settings/hook-vector.svg";
import info from "images/svg/income/info.svg";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import { RiEditLine } from "react-icons/ri";
import { useSelector } from "react-redux";


function Webhook () {
    const [showCreate, setShowCreate] = useState(false);
    // const [index, setIndex] = useState(null);
    const [webhooks, setWebhooks] = useState({
        isLoading: true,
        data: []
    });
    const [edit, setEdit] = useState(null)
    const apps = useSelector(state => state?.app?.apps)

    useEffect(()=>{
        getAllWebhooks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllWebhooks = () => {
        AuthService.getWebhooks()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setWebhooks({
                        isLoading: false,
                        data: res.data||[]
                    })
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setWebhooks({
                    isLoading: false,
                    data: []
                })
                handleRequestErrors(err)
            });
    }

    return(
        <div className="mx-8">
            {
                webhooks?.data?.length===0 ?
                <div className="border-[1px] my-5  border-cc-primary rounded-xl p-6">
                    <h2 className="text-[23px] font-bold">Webhook Settings</h2>
                    <div className="text-base">
                        <p className="mt-7 mb-[20px]">Notify a URL of your choice with information about events that occur as CreditChek Platform processes your API and SDK requests. <a className="text-black underline" href="https://docs.creditchek.africa/webhook" target="_blank" rel="noreferrer">Learn more.</a></p>
                        <p className="text-cc-grey-18 mb-10">Allows notifications for events, such as failures, errors, success with al relevant information to be POSTed to your specified URL per associated App.</p>
                        <p className="font-bold">No event webhook</p>
                        <img src={hook} alt="hook" className="w-[100px] block mx-auto mt-4"/>
                        <div className="block mx-auto max-w-[800px]">
                            <p className="font-bold my-[30px]">There are no event webhooks setting configured for this account or App. Your account enables you to create one webhook setting associated with an active App. Create your first webhook to get started!</p>
                            <Button
                                isValid={true}
                                text="Create New Webhook"
                                className="!w-[300px]"
                                onClick={()=>{
                                    webhooks?.data?.length>0 ?
                                    toast.info("You can only craete one webhook for the currently active app!")
                                    :
                                    setShowCreate(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="flex items-center mt-6">
                        <img src={info} alt='' className="mr-[7px]" />
                        <p>Notify a URL of your choice with information about events that occur as CreditChek Platform processes your API and SDK requests. <a className="text-black underline" href="https://docs.creditchek.africa/webhook" target="_blank" rel="noreferrer">Learn more.</a></p>
                    </div>
                    <p className="my-7 max-w-[800px]">Allows notifications for events, such as failures, errors, success with all relevant information to be POSTed to your specified URL per associated App.</p>
                    <div className="relative">
                        <Button
                            isValid={true}
                            text="Create new Webhook"
                            className="!w-[200px] absolute top-[10px] left-[13px] z-[99]"
                            onClick={()=>{
                                webhooks?.data?.length>0 ?
                                toast.info("You can only create one Webhook setting per active app!")
                                :
                                setShowCreate(true)
                            }}
                        />
                        <Table
                            tableTitle={""}
                            data={[webhooks.data]}
                            hideSearch={true}
                            loading={webhooks.isLoading}
                            tableClassName={clsx(
                                "min-w-[900px]",
                                // !!index && "mb-[70px]"
                            )}
                            rows={[
                                {
                                    header:'Status',
                                    view: (item) => (
                                        <div className="py-[10px] pl-[10px] flex">
                                            <div 
                                                className={clsx(
                                                    "flex items-center px-2 rounded-2xl cursor-pointer",
                                                    item.status ? "bg-[#47D8004F]" : "bg-cc-red-6"
                                                )}
                                            >
                                                <div
                                                    className={clsx(
                                                        "w-1.5 h-1.5 rounded-full mr-1",
                                                        item.status ? "bg-cc-green-11" : "bg-cc-red-11"
                                                    )}
                                                ></div>
                                                <span 
                                                    className={clsx(
                                                        "text-sm whitespace-nowrap font-bold",
                                                        item.status ? "text-cc-green-11" : "text-cc-red-11"
                                                    )}
                                                >
                                                    {item.status ? "Enabled":"Disabled"}
                                                </span>
                                            </div>
                                        </div>
                                    ),
                                },
                                {
                                    header:'Created At',
                                    view: (item) => (
                                        <span className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.createdAt, 'dd-mm-yyyy')}</span>
                                    ),
                                },
                                {
                                    header:'Last Updated',
                                    view: (item) => (
                                        <span className="py-[10px] pl-[10px] pr-[5px]">{dateFormatter(item.updatedAt, 'dd-mm-yyyy')}</span>
                                    ),
                                },
                                {
                                    header:'Webhook ID',
                                    view: (item) => (
                                        <span className="py-[10px] pl-[10px] pr-[5px]">{item._id}</span>
                                    ),
                                },
                                {
                                    header:'App',
                                    view: (item) => (
                                        <span className="py-[10px] pl-[10px] pr-[5px]">{apps?.find((v) => v?._id === item.appId).displayName||"CreditChek"}</span>
                                    ),
                                },
                                {
                                    header:'Endpoint URL',
                                    view: (item) => (
                                        <span className="lowercase py-[10px] pl-[10px] pr-[5px]">{item.url}</span>
                                    ),
                                },
                                {
                                    header:'',
                                    view: (item) => (
                                        <div className="relative">
                                            <RiEditLine 
                                                className="w-6 my-3 scale-[1.5] text-cc-primary font-bold" 
                                                // onClick={()=>index===null?setIndex(item._id):setIndex(null)} 
                                                onClick={()=>{setShowCreate(true);setEdit(item)}}
                                            />
                                            {/* {
                                                item._id===index &&
                                                <div className="absolute top-[28px] z-[999] right-[5px] bg-white rounded-[5px] shadow-md w-[70px] z-[2] p-2.5">
                                                    <p className="cursor-pointer hover:text-cc-primary" onClick={()=>{setShowCreate(true);setEdit(item)}}>Edit</p>
                                                    <p className="cursor-pointer hover:text-cc-red-1" onClick={()=>deleteItem()}>Delete</p>
                                                </div>
                                            } */}
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            }
            <CreateWebhook
                show={showCreate}
                edit={edit}
                handleClose={()=>{
                    setShowCreate(false); 
                    setEdit(null);
                    getAllWebhooks();
                }}
            />
        </div>
    )
}

export default Webhook;