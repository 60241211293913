//Library Methods
import { useSteps } from 'react-step-builder'

//Components
import Header from './Header'

//Utilities
import blueTick from "images/svg/recova/blueTick.svg"
import Button from "components/shared/Button";
import { useState } from 'react';
import { RecovaService } from 'services/recova.service';
import { handleRequestErrors } from 'utils/helpers';
import { toast } from 'react-toastify';
import { useRecovaSetup } from 'hooks/redux.hook';


const UseCase = () => {
  const { jump, total } = useSteps()
  const [isLoading, setIsLoading] = useState({
    checkoutBtn: false,
    creditBtn: false,
  })
  const { updateSetupDispatch } = useRecovaSetup()

  const handleSelection = (value) => {
    let payload = JSON.stringify({
      intention: value
    })
    RecovaService.recovaIntent(payload)
      .then(response=> response.data)
      .then(res => {
        console.log(res)
        if(res.status){
          // set intent to value in redux
          if(value==="credit"){
            // set current step to 5 in redux
            updateSetupDispatch({
              currentStep: 5,
              useCase: value,
            })
            jump(5) 
          } else {
            // set current step to 3 in redux
            updateSetupDispatch({
              currentStep: 3,
              useCase: value,
            })
            jump(3);
          }
        } else {
          toast.error(res.message)
        }
        setIsLoading({
          checkoutBtn: false,
          creditBtn: false
        })
      })
      .catch((err) => {
          handleRequestErrors(err)
          setIsLoading({
            checkoutBtn: false,
            creditBtn: false
          })
      });
  }

  console.log(total)
  return (
    <div className="rounded-3xl px-2.5 py-4 sm:px-0">
        <Header progress={10} />

        <h2 className='text-xl font-medium leading-5'>
            Please tell us how your business intends to use Recova.
        </h2>

        <div 
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}
          className='flex justify-center items-center gap-[20px] h-48 p-[15px] rounded-2xl mt-16 sm:flex-col'
        >
          <Button
            isValid={true}
            isLoading={isLoading.checkoutBtn}
            className="bg-cc-primary"
            text={"At Secured Checkout"}
            onClick={()=> {
              handleSelection("secure-widget")
              setIsLoading({
                ...isLoading,
                checkoutBtn: true
              })
            }}
          />
          <Button
            isValid={true}
            isLoading={isLoading.creditBtn}
            className="bg-cc-primary"
            text={"For Credit"}
            onClick={()=> {
              setIsLoading({
                ...isLoading,
                creditBtn: true
              })
              handleSelection("credit")
            }}
          />
        </div>

        <div
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}
          className='px-2.5 py-3 rounded-2xl mt-8 mb-4'
        >
          <h2 className='text-xl font-bold'>Need Help?</h2>

          <div>
            <div className='flex mt-3'>
              <img src={blueTick} alt="blue-tick icon" />
              <p className='ml-2 font-bold'>Secured Checkout</p>
            </div>
            <p className='text-sm'>
              Your business involves selling products & services with the need 
              to improve on convenience or customer experience to receive 
              payments directly from the Payer’s bank account; in fixed 
              instalments, and possibly on a recurring basis; this also applies 
              to subscription services.
            </p>
          </div>

          <div>
            <div className='flex mt-3'>
              <img src={blueTick} alt="blue-tick icon" />
              <p className='ml-2 font-bold'>Lending</p>
            </div>
            <p className='text-sm'>
              Your business involves providing traditional or alternative credit 
              to consumers and business with the need to automate recovery of funds 
              directly from such debtors bank account within the defined period agreed 
              upon as “loan duration.
            </p>
          </div>

        </div>
    </div>
  )
}

export default UseCase