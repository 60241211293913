
import { useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { TextField } from 'components/Form/TextField';
import Button from 'components/shared/Button';
import clsx from 'clsx';
import { SelectField } from 'components/Form/SelectField';
import { useEffect } from 'react';

import greenCheck from "../../../../images/svg/float/green-check.svg";
import failed from "../../../../images/svg/float/verificationFailed.svg";
import success from "../../../../images/svg/float/verificationSuccess.svg";
import process from "../../../../images/svg/float/verificationProcess.gif";


function Director2 ({setPath, verification, data, setData, ngBanks, triggerPriceModal, handleDirectorConsent, submitApplication, amountType}) {
    let navigate = useNavigate();
    
    useEffect(() => {
        form.setFieldValue("director2Fullname", data.director2Fullname||"")
        form.setFieldValue("director2BVN", data.director2BVN||"")
        form.setFieldValue("director2BankName", data.director2BankName||"")
        form.setFieldValue("director2BankAccountNumber", data.director2BankAccountNumber||"")
        form.setFieldValue("director2Position", data.director2Position||"")
        form.setFieldValue("director2Phone", data.director2Phone||"")
        form.setFieldValue("director2Email", data.director2Email||"")
        form.setFieldValue("director2Address", data.director2Address||"")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    
    const positionOptions = [
        "Guarantor",
        "Proprietor",
        "Shareholder",
        "Managing Director",
        "Executive Director",
        "Alternate Director",
        "Trustee"
    ];

    const form = useFormik({
        initialValues: {
            director2Fullname: "",
            director2BVN: "",
            director2BankName: "",
            director2BankAccountNumber: "",
            director2Position: "",
            director2Phone: '',
            director2Email: "",
            director2Address: "",
        },
        onSubmit: (values) => {
            saveValues()
            submitApplication()
        }
    })

    const saveValues = () => {
        setData({
            ...data,
            director2Fullname: form.values.director2Fullname,
            director2BVN: form.values.director2BVN,
            director2BankName: form.values.director2BankName,
            director2BankAccountNumber: form.values.director2BankAccountNumber,
            director2Position: form.values.director2Position,
            director2Phone: form.values.director2Phone,
            director2Email: form.values.director2Email,
            director2Address: form.values.director2Address,
        })
    }

    return (
        <div className='border-[1px] border-solid border-[#EAECF0] shadow-[0px_1px_3px_rgba(16,24,40,0.1),0px_1px_2px_rgba(16,24,40,0.06)] rounded-[10px] mt-2.5 box-border'>
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit}>
                    <div className='p-[20px]'>
                        <div className="relative">
                            <TextField
                                label="Full Name"
                                name='director2Fullname'
                                type='text'
                                placeholder="Enter full name"
                            />
                            <p className='flex items-center right-0 absolute bottom-0 text-xs text-cc-grey-8'>
                                <img src={greenCheck} alt="" className="w-[10px] mr-1" />
                                <span>Name must match with <span className='font-bold text-black'>BVN</span> registered name.</span>
                            </p>
                        </div>
                        <div className='flex items-center relative gap-x-2.5'>
                            <div className='w-[calc(100%-110px)]'>
                            <TextField
                                label="Bank Verification Number"
                                name='director2BVN'
                                type='text'
                                placeholder="Enter BVN"
                                extraClassName={clsx(
                                    verification.bvn2==='processing'?'!border-cc-primary'
                                        : verification.bvn2==='success'?'!border-[#12B76A]'
                                        : verification.bvn2==='failed'?'!border-[#FDA29B]'
                                        : ''
                                )}
                                readOnly={verification?.bvn2==='processing'}
                            />
                            </div>
                            <div className='w-[100px] mt-0.5'>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Verify"
                                    className="h-10 !rounded-[8px]"
                                    onClick={()=>{
                                        saveValues()
                                        triggerPriceModal('identity bvn verification')
                                    }}
                                    isLoading={verification?.bvn2==='processing'}
                                />
                            </div>
                            {
                                verification.bvn2 !== "none" &&
                                <p
                                    className={clsx(
                                        "absolute bottom-0 text-[0.85em]",
                                        verification.bvn2==='processing' ? "text-[#7C7C7C]" 
                                            : verification.bvn2==='success' ? "text-[#12B76A]"
                                            : "text-[#F04438]"
                                    )}
                                >
                                    {
                                        verification.bvn2==='processing'?'Processing...'
                                        :verification.bvn2==='success'?'Verification Successful'
                                        :'BVN is incorrect'
                                    }
                                </p>
                            }
                            {
                                verification.bvn2!=="none" &&
                                <img 
                                    src={verification.bvn2==='processing'?process:verification.bvn2==='success'?success:failed} 
                                    alt=""
                                    className='absolute w-4 right-[120px] bottom-[36px]'
                                />
                            }
                        </div>
                        <SelectField
                            searchable
                            name='director2BankName'
                            options={ngBanks?.map(item=>({
                                label: item.name,
                                value: item.name
                            }))}
                            label="Select Bank"
                            placeholder={form.values.director2BankName || "Select Bank"}
                            onChange={(selected)=>{
                                form.setFieldValue( "director2BankName", selected.value )
                            }}
                            value={form?.values.director2BankName}
                        />
                        <div className='flex items-center relative gap-x-2.5'>
                            <div className='w-[calc(100%-110px)]'>
                            <TextField
                                label="Director's Bank Account Number"
                                name='director2BankAccountNumber'
                                type='text'
                                placeholder="Enter Bank Account Number"
                                extraClassName={clsx(
                                    verification.acc2==='processing'?'!border-cc-primary'
                                        : verification.acc2==='success'?'!border-[#12B76A]'
                                        : verification.acc2==='failed'?'!border-[#FDA29B]'
                                        : ''
                                )}
                                readOnly={verification?.acc2==='processing'}
                            />
                            </div>
                            <div className='w-[100px] mt-0.5'>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Verify"
                                    className="h-10 !rounded-[8px]"
                                    onClick={()=>{
                                        triggerPriceModal('account number basic verification')
                                        saveValues()
                                    }}
                                    isLoading={verification?.acc2==='processing'}
                                />
                            </div>
                            {
                                verification.acc2 !== "none" &&
                                <p
                                    className={clsx(
                                        "absolute bottom-0 text-[0.85em]",
                                        verification.acc2==='processing' ? "text-[#7C7C7C]" 
                                            : verification.acc2==='success' ? "text-[#12B76A]"
                                            : "text-[#F04438]"
                                    )}
                                >
                                    {
                                        verification.acc2==='processing'?'Processing...'
                                        :verification.acc2==='success'?'Verification Successful'
                                        :'No account match'
                                    }
                                </p>
                            }
                            {
                                verification.acc2!=="none" &&
                                <img 
                                    src={verification.acc2==='processing'?process:verification.acc2==='success'?success:failed} 
                                    alt=""
                                    className='absolute w-4 right-[120px] bottom-[36px]'
                                />
                            }
                        </div>
                        <SelectField
                            name='director2Position'
                            options={positionOptions.map(item=>({
                                label: item,
                                value: item
                            }))}
                            label="Position/Role"
                            placeholder={form.values.director2Position || "Select Role"}
                            onChange={(selected)=>{
                                form.setFieldValue( "director2Position", selected.value )
                            }}
                            value={form?.values.director2Position}
                        />
                        <TextField
                            label="Phone Number"
                            name='director2Phone'
                            type='text'
                            placeholder="Enter phone number"
                        />
                        <TextField
                            label="Residential Address"
                            name='director2Address'
                            type='text'
                            placeholder="Enter residential address"
                        />
                        <div className='flex items-center relative gap-x-2.5'>
                            <div className='w-[calc(100%-130px)]'>
                            <TextField
                                label="Email Address"
                                name='director2Email'
                                type='email'
                                placeholder="Enter email address"
                                extraClassName={clsx(
                                    verification.cons2==='processing'?'!border-cc-primary'
                                        : verification.cons2==='success'?'!border-[#12B76A]'
                                        : verification.cons2==='failed'?'!border-[#FDA29B]'
                                        : ''
                                )}
                            />
                            </div>
                            <div className='w-[120px] mt-0.5'>
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Get Consent"
                                    className="h-10 !rounded-[8px]"
                                    onClick={()=>{
                                        handleDirectorConsent(form.values)
                                    }}
                                    isLoading={verification?.cons2==='processing'}
                                />
                            </div>
                            {
                                verification.cons2 !== "none" &&
                                <p
                                    className={clsx(
                                        "absolute bottom-0 text-[0.85em]",
                                        verification.cons2==='processing' ? "text-[#7C7C7C]" 
                                            : verification.cons2==='success' ? "text-[#12B76A]"
                                            : "text-[#F04438]"
                                    )}
                                >
                                    {
                                        verification.cons2==='processing'?'Processing...'
                                        :verification.cons2==='success'?'Consent sent successfully'
                                        :'Consent request failed'
                                    }
                                </p>
                            }
                            {
                                verification.cons2!=="none" &&
                                <img 
                                    src={verification.cons2==='processing'?process:verification.cons2==='success'?success:failed} 
                                    alt=""
                                    className='absolute w-4 right-[140px] bottom-[36px]'
                                />
                            }
                        </div>
                    </div>
                    <hr/>
                    <div className='p-[20px] flex'>
                        <div className="w-[320px] ml-auto flex gap-x-2.5">
                            <div className='w-[120px]'>
                                <Button
                                    isValid={true}
                                    text="Go Back"
                                    isLoading={false}
                                    type="button"
                                    className="bg-[#A0A0A0]" 
                                    onClick={()=>{setPath('director1'); navigate(`/float/new-request/director1?amountType=${amountType}`)}}
                                />
                            </div>
                            <div className='w-[190px]'>
                                <Button
                                    isValid={verification.bvn2==='success' && verification.acc2==='success' && verification.cons2==='success' && form.isValid}
                                    text="Submit Application"
                                    isLoading={false}
                                    type="submit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormikProvider>
        </div>
    )
}

export default Director2;