import {Routes, Route} from "react-router-dom";
import { useEffect, useState } from "react";
import Main from "./Main";
import MainResult from "./results/MainResult";
import BVNResult from "./results/BVNResult";
import NINResult from "./results/NINResult";
import BankResult from "./results/BankResult";
import RCResult from "./results/RCResult";
import LicenseResult from "./results/LicenseResult";
import PVCResult from "./results/PVCResult";
import RadarDetails from './Radar/Details';
import PassportResult from "./results/PassportResult";


function Identity ({setDetailName, rolePermissions}) {
    const [permissionValues, setPermissionValues] = useState([]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    return(
        <div className="home">
            <div>
                <Routes>
                    <Route path="/" element={<Main setDetailName={setDetailName} permissionValues={permissionValues} />}/>
                    <Route path="/details/:bvn/:name/:id" element={<MainResult setDetailName={setDetailName} />} />
                    <Route path="/bvn-verification" element={<BVNResult />} />
                    <Route path="/nin-verification" element={<NINResult />} />
                    <Route path="/bank-verification" element={<BankResult />} />
                    <Route path="/cac-verification/:type" element={<RCResult />} />
                    <Route path="/license-verification" element={<LicenseResult />} />
                    <Route path="/pvc-verification" element={<PVCResult />} />
                    <Route path="/international-passport" element={<PassportResult />} />
                    <Route path="/radar" element={<RadarDetails setDetailName={setDetailName} />} />
                </Routes>
            </div>
        </div>
    )
}

export default Identity;