import Button from "components/shared/Button";
import { TextArea } from "components/Form/TextArea";
import { Formik, Form } from 'formik';
import { FloatService } from "services/float.service";
import { logToBin } from "lib/logs";
import {toast} from "react-toastify";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// import { DateField } from "components/Form/DateField";
import { TextField } from "components/Form/TextField";
import { useEffect, useState } from "react";
import { dateFormatter } from "utils/helpers";
import { SelectField } from "components/Form/SelectField";
import { DateField } from "components/Form/DateField";


export default function SkipColections ({handleSuccess}) {
    const initialValues ={
        remarks: '',
        newDate: '',
    }
    const params = useParams();
    const businessId = useSelector(state => state?.loginData?.data?.user?.businessId);
    const floatData = useSelector(state => state?.miscData?.data);
    const [repayments, setRepayments] = useState([])
    let navigate = useNavigate();

    useEffect(()=>{
        const newArray = [];
        floatData?.repaymentDetails?.forEach(item => {
            if(dateComparison(item.dueDate)){
                newArray.push(item.dueDate)
            }
        })
        setRepayments(newArray)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dateComparison = (date) => {
        var d1 = new Date();
        var d2 = new Date(date);
        var greater = d2.getTime() > d1.getTime();
        return greater
    }

    const doSubmit = (values) => {
        const payload = JSON.stringify({
            businessId,
            floatId: params.floatId,
            category: "changeBalance",
            remarks: values.remarks,
            newDate: values.newDate==="Custom" ? values.custom:repayments?.[0],
            nextScheduled: repayments?.[0],
        })
        FloatService.floatPatchRequest(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.status === 'success') {
                    handleSuccess()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 30000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    doSubmit(values)
                }}
            >  
                {(props) => (
                    <Form>
                        <TextField
                            name='nextScheduled'
                            label="Next Payment Schedule"
                            value={`${repayments.length>0 ? dateFormatter(repayments?.[0], "dd/mm/yyyy"):"--/--/----"}`}
                            disabled={true}
                        />

                        <SelectField 
                            name='newDate'
                            label="Select Date"
                            options={[
                                { value: 'Next Repayment', label: 'Next Repayment' },
                                { value: 'Custom', label: 'Custom' },
                            ]}
                            placeholder=""
                            onChange={(selected)=>{
                                props.setFieldValue( "newDate", selected.label )
                            }}
                            value={props?.values?.newDate}
                        />
                        {
                            props?.values?.newDate==="Custom" &&
                            <DateField
                                name='custom'
                                label="Select Custom Date"
                                onChange={(value)=>{
                                    props.setFieldValue( "custom", value )
                                }}
                                value={props?.values?.custom}
                                placeholder=""
                            />
                        }
                        <TextArea 
                            name='remarks'
                            label="Remarks"
                            placeholder="Justification notes..."
                        />
                        <hr/>
                        <div className="flex gap-x-3 justify-end mt-2.5">
                            <div className="w-[120px]">
                                <Button
                                    isValid={false}
                                    isLoading={false}
                                    type="button"
                                    text="Go Back"
                                    variant="secondary"
                                    className="mb-[30px] mt-2.5"
                                    onClick={()=>navigate(-1)}
                                />
                            </div>
                            <div className="w-[120px]">
                                <Button
                                    isValid={props.isValid}
                                    isLoading={false}
                                    type="submit"
                                    text="Submit"
                                    className="mb-[30px] mt-2.5"
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}