import {Row, Col} from "react-bootstrap";
import Back from "components/shared/back";
import { useNavigate } from "react-router-dom";

import info from "../../../../images/svg/identity/info.svg";
import user_null from "../../../../images/user_null.png";


export default function PVCResult () {
    const navigate = useNavigate();
    
    let pvcRes = {};
    try {
        pvcRes = JSON.parse(sessionStorage.getItem('identity'));
    } catch (err) {
        //err
    } 

    return(
        <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
            <div style={{margin:"-60px 0 0px"}}>
                <Back to={()=> navigate(-1)} />
            </div>
            <div className="identity_result">
                <div className="identity_result-top">
                    <div
                        style={{
                            backgroundImage: !!user_null ? `url(${user_null})` : "url('')"
                        }}
                        className="w-[130px] h-[130px] mr-[20px] border-[2px] border-white shrink-0 overflow-hidden rounded-full bg-no-repeat bg-center bg-cover md:w-[60px] md:h-[60px]"
                    />
                    <div>
                        <h5>{pvcRes?.first_name} {pvcRes?.last_name}</h5>
                        <p>Card Number: {pvcRes?.card_number}</p>
                    </div>
                    <img src={info} alt="" className="identity_result-top_button" />
                </div>
                <hr className="my-[30px]"/>
                <Row>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Gender</p>
                            <p className="text2">{pvcRes?.gender==="" || pvcRes?.gender===null ?"Not available":pvcRes.gender}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Date of Birth</p>
                            <p className="text2">{pvcRes?.date_of_birth==="" || pvcRes?.date_of_birth===null ?"Not available":pvcRes.date_of_birth}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">Occupation</p>
                            <p className="text2">{pvcRes?.occupation==="" || pvcRes?.occupation===null ?"Not available":pvcRes.occupation}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">State</p>
                            <p className="text2">{pvcRes?.state==="" || pvcRes?.state===null ?"Not available":pvcRes.state}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4} xs={6}>
                        <div className="identity_result-box1">
                            <p className="text1">LGA</p>
                            <p className="text2">{pvcRes?.lga==="" || pvcRes?.lga===null ?"Not available":pvcRes.lga}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Polling Unit</p>
                            <p className="text2">{pvcRes?.pollingUnit==="" || pvcRes?.pollingUnit===null ?"Not available":pvcRes.pollingUnit}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Polling Unit Code</p>
                            <p className="text2">{pvcRes?.pollingUnitCode==="" || pvcRes?.pollingUnitCode===null ?"Not available":pvcRes.pollingUnitCode}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Registration Area Ward</p>
                            <p className="text2">{pvcRes?.registrationAreaWard==="" || pvcRes?.registrationAreaWard===null ?"Not available":pvcRes.registrationAreaWard}</p>
                        </div>
                    </Col>
                    <Col lg={3} md={4}>
                        <div className="identity_result-box1">
                            <p className="text1">Time of Registration</p>
                            <p className="text2">{pvcRes?.timeOfRegistration==="" || pvcRes?.timeOfRegistration===null ?"Not available":pvcRes.timeOfRegistration}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}