import { createSlice } from '@reduxjs/toolkit'


export const miscSlice = createSlice({
    name: 'temporaryData',
    initialState: {
        data: {}
    },
    reducers: {
        miscDetails: (state, action) => {
            return { 
                ...state, 
                data: action.payload
            }
        },
    },
})

export const { miscDetails } = miscSlice.actions

export default miscSlice.reducer