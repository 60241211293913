import {Row, Col} from "react-bootstrap";
import { useEffect, useState } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import Back from "../../../components/shared/back";
import { AuthService } from "services/auth.service";
import { toast } from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import { logToBin } from "../../../lib/logs";
import { useSelector, useDispatch } from "react-redux";
import { setActiveAppStatus, setApps } from "redux/apps";
import { copyText, dateFormatter, timeFormatter } from "utils/helpers";
import WidgetSettings from "./components/WidgetSettings";

import appIdIcon from "../../../images/svg/apps/appId.svg";
import generate from "../../../images/svg/apps/generate.svg";
import copy from "../../../images/svg/apps/copy.svg";
import info from "../../../images/svg/apps/info.svg";
import clsx from "clsx";
import Toggle from "react-toggle";
import notvisible from "images/svg/not-visible.svg";
import visible from "images/svg/visible.svg";


function Keys ({setDetailName, permissionValues }) {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState(false)
    const [data, setData] = useState({})
    const [isActive, setIsActive] = useState(true);
    const [isLive, setIsLive] = useState(false );
    const {appId} = useParams();
    const b2b_data = useSelector(state => state?.loginData?.data)
    const activeAppId = useSelector(state => state?.app?.active?.appId)

    const navigate = useNavigate();
   
    useEffect(()=>{
        fetchApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchApp = () => {
        AuthService.getAppById(appId)
            .then((response) => response.data)
            .then((res) => {
                setDetailName(`${res?.data?.app?.appName}`)
                if (res.success === true) {
                    setData(res?.data)
                    setIsLive(res?.data?.app?.isLive)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const handleTestKeys = () => {
        AuthService.resetTestKeys({appId})
        .then((res) => {
            if(res.data.success === true){
                setShowModal(false)
                toast.success(res.data.message)
                fetchApp();
                window.location.reload();
            } else {
                toast.error(res.message)
            }
        }).catch((err) => {
            setShowModal(false)
            if(err.response){
                logToBin(err.response.data, window.location.href);
                toast.error(err.response.data.message);
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    }

    const handleLiveKeys = () => {
        AuthService.resetLiveKeys({appId})
        .then((res) => {
            if(res.data.success === true){
                setShowModal(false)
                toast.success(res.data.message)
                fetchApp();
                window.location.reload();
            } else {
                toast.error(res.message)
            }
        }).catch((err) => {
            setShowModal(false)
            if(err.response){
                logToBin(err.response.data, window.location.href);
                toast.error(err.response.data.message);
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    }

    const switchLive = () => {
        let isComplied = JSON.parse(sessionStorage.getItem('b2bCompliance')).isComplied

        if(permissionValues?.includes("edit")){
            if(isComplied === true){
                AuthService.toggleStatus({appId})
                .then((response) => response.data)
                .then((res) => {
                    if (res.success === true) {
                        toast.success(res.message)
                        if(appId === activeAppId){
                            dispatch(setActiveAppStatus(!isLive))
                        }
                        refetchApps();
                        setIsLive(!isLive)
                        setIsActive(!isActive)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                })
            }else{
                toast.error('Please complete compliance to go Live ')
            }
        }else{
            toast.error("You are not authorized to perform this action!!!")
        }
    }

    const refetchApps = () => {
        let payload = JSON.stringify({'businessId': b2b_data?.user?.businessId})
        AuthService.businessApps(payload)
            .then((response) => response.data)
            .then((res) => {
                dispatch(setApps(res.data))
            })
            .catch(() => {});
    }
    
    return(
        <div className="text-[#0F123F] box-border">
            <div className="flex justify-between">
                <Back to={()=> navigate("/apps")}/>
                <div className="flex items-center gap-x-2">
                    <h3 className="text-md font-semibold">App Status:</h3>
                    <label className="flex items-center gap-x-1">
                        <Toggle
                            checked={isLive}
                            className="apps-toggle"
                            icons={false}
                            onChange={()=>switchLive()}
                        />
                        <span className={clsx("text-sm", isLive?"text-[#2BBD35]":"text-[#DC6803]")}>{isLive ? 'Live':'Test'}</span>
                    </label>
                </div>
            </div>
            <div className="mt-2.5 mb-4">
                <p className="text-[20px] font-bold text-[#606060]">We have given you the power to build great lending apps that can securely integrate with our system, 
                but we only ask that you repay us the favour by keeping your passkeys safe and private.</p>
            </div>
            <div>
                <div className="flex items-center gap-x-2">
                    <img src={appIdIcon} alt="keys" className="w-8"/>
                    <p className="text-[20px] font-bold">App Details</p>
                </div>
                <div className="grid grid-cols-3 gap-3 text-md mt-3 mb-6 xl:grid-cols-2 sm:!grid-cols-1">
                    <div className="flex items-center gap-x-1">
                        <p className="font-semibold">ID:</p>
                        <div className="flex items-center gap-x-1">
                            <p className="truncate">{data?.app?._id}</p>
                            <img src={copy} alt="copy" className="w-[20px] ml-2.5 cursor-pointer" onClick={()=>copyText(data?.app?._id, "App ID copied!")} />
                        </div>
                    </div>
                    <p className="capitalize">Display Name: {data?.app?.displayName}</p>
                    <p>Created : {dateFormatter(data?.app?.createdAt, 'dd-mm-yyyy')}, {timeFormatter(data?.app?.createdAt)}</p>
                </div>
            </div>
            <Row>
                <KeysBox 
                    title="Live Keys"
                    publicKey={data?.app?.livePublicKey}
                    secretKey={data?.app?.liveSecretKey}
                    doGenerate={()=>{setType('live');setShowModal(true);}} 
                />
                <KeysBox 
                    title="Test Keys"
                    publicKey={data?.app?.sandboxPublicKey}
                    secretKey={data?.app?.sandboxSecretKey}
                    doGenerate={()=>{setType('test');setShowModal(true);}} 
                />
            </Row>
            <WidgetSettings data={data} fetchApp={()=>fetchApp()} />
            <div className="bg-[#F4F8FF] border-[1px] border-solid border-[#D8D8D8] rounded-[15px] p-[20px]">
                <div className="flex items-center gap-x-1.5 mb-[20px]">
                    <img src={info} alt="" />
                    <h5 className="text-[1.3em] font-medium text-[#505050]">App Keys usage guide</h5>
                </div>
                <div className="text-[#4A4A4A]">
                    <p>Both Live &#38; Test Keys when performing API Testing or customer App integration with this platform only accepts encrypted values, passed as header tokens.</p>
                    <p>Our Authentication Endpoint performs real-time encryption, decryption, authentication, and validation on each API request from customers.</p>
                    <p className="mb-[20px]">More details on <a href='https://docs.creditchek.africa' target='_blank' rel="noreferrer">https://docs.creditchek.africa</a></p>
                </div>
                <div className="flex items-center mb-2.5">
                    <img src={visible} alt="" className="w-4 mr-2" />
                    <p className="text-[#6C6C6C] text-[0.9em]">Display original Key value</p>
                </div>
                <div className="flex items-center">
                    <img src={copy} alt="" className="w-4 mr-2" />
                    <p className="text-[#6C6C6C] text-[0.9em]">Copy the encrypted key value that is directly passed as your unique header token</p>
                </div>
            </div>
            <ConfirmAction
                show={showModal}
                onCancel={()=>setShowModal(false)}
                onAction={()=>{
                    if(type==="test"){
                        handleTestKeys()
                    } else {
                        handleLiveKeys()
                    }
                }}
                loading={false}
                title="Generate New Keys"
                content="Are you sure you want to permanemtly replace the existing key pairs?"
            />
        </div>
    )
}

const KeysBox = ({title, publicKey, secretKey, doGenerate}) => {
    return(
        <Col xl={6} lg={12}>
            <div className="shadow-[rgba(0,0,0,0.24)_0px_3px_8px] box-border rounded-[10px] p-4 mb-[30px]">
                <div className="flex mb-[20px] items-center justify-between">
                    <h5 className="font-bold text-[20px]">{title}</h5>
                    <button 
                        onClick={doGenerate} 
                        className="flex items-center rounded-[5px] text-[0.8em] text-cc-primary py-[3px] px-[9px] bg-white gap-x-1"
                    > 
                        <img src={generate} alt="generate" className="w-3"  />
                        <p>Generate New Keys</p>
                    </button>
                </div>
                {
                    [publicKey, secretKey].map(item=>(
                        <div 
                            className={clsx(
                                "flex items-center gap-x-2",
                                item===publicKey && "mb-3"
                            )}
                        >
                            <p className="w-[100px] text-sm whitespace-nowrap">{item===publicKey ? "Public Key":"Secret Key"}</p>
                            <KeysInput item={item} />
                        </div>
                    ))  
                }
            </div>
        </Col>
    )
}

const KeysInput = ({item}) => {
    const [showKeys, setShowKeys] = useState(false);

    const toggleKeyVisibility = () => {
        setShowKeys(!showKeys)
    }

    return (
        <div className="w-full relative flex items-center gap-x-2.5 py-0.5 px-2.5 border-[1px] border-[#DADADA] rounded bg-white">
            <input 
                type={ showKeys ? "text" : "password"} 
                value={item} 
                className="border-none h-[30px] text-xs w-full bg-transparent"
            />
            <img src={showKeys ? notvisible:visible} alt="eye" className="w-[18px]" onClick={toggleKeyVisibility}/>
            <img src={copy} alt="copy" className="cursor-pointer" onClick={()=>copyText(item, 'Keys copied!')} />
        </div>
    )
}

export default Keys;