import { useEffect, useState } from "react";
import OldTable from "components/Table/OldTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IdentityService } from "services/identity.service";
import Radar from "./Radar";
import BasicVerificationResult from "./components/BasicVerificationResult";
import { useSelector } from "react-redux";
import BVNInterface from "./components/pageSections/BVNInterface";

import tableIcon from "../../../images/svg/table-icon.svg";
import NINInterface from "./components/pageSections/NINInterface";
import RCInterface from "./components/pageSections/RCInterface";
import BankInterface from "./components/pageSections/BankInterface";
import DriversLicense from "./components/pageSections/DriversInterface";
import VotersCard from "./components/pageSections/VotersCard";
import CustomerOnboarding from "./components/pageSections/CustomerOnboarding";
import PassportInterface from "./components/pageSections/PassportInterface";
import Tab from "components/shared/Tab";
import { handleRequestErrors } from "utils/helpers";
import HistoryTable from "./components/HistoryTable";
import { BillingService } from "services/billing.service";


export default function Main({ setDetailName, permissionValues }) {
  let history = useNavigate();
  const [data, setData] = useState({
    tableFor: "idService-history",
    headings: ["NAME", "MOBILE", "EMAIL", "BVN", ""],
    items: [],
    type: "bvn",
  });
  const [basicVerificationModal, setBasicVerificationModal] = useState(false);
  const [basicData, setBasicData] = useState({});
  const ngBanks = useSelector(state => state?.banksData?.banks)
  const [searchParams, setSearchParams] = useSearchParams();
  const path = searchParams.get("tab")
  const [price, setPrice] = useState("");


  useEffect(()=>{
    setDetailName("");
    setData({
      ...data,
      items: []
    })
    if(!!path && path!=="main" && path!=="radar" && path!=="passport"){
      getHistory()
    }
    getPricing()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[path])

  useEffect(()=>{
    if(!path){
      searchParams.set('tab', "main");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getHistory = () => {
    IdentityService.getVerifiedHistory(
      path==='license'?'drivers_license'
      :path==='pvc'?'voters_card'
      :path==='bank'?'bankAccount'
      :path==='rc'?'cac'
      :path
    )
      .then((response) => response.data)
      .then((res) => {
          const sortedData = res.data.sort(function(a,b){
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          })
          if (res.status === true) {
            setData({
              ...data,
              items: sortedData,
              type: path,
              headings: ["NAME", `${path==='pvc'?'LGA':path==='rc'?'REGISTERED ON':"MOBILE"}`, `${path==='pvc'?'STATE':"EMAIL"}`, `${path==='bvn'?'BVN':path==='pvc'?'CARD NUMBER':path==='rc'?'RC/BN VALUE':path==='bank'?'ACCOUNT NUMBER':path==='nin'?'NIN':'LICENSE NUMBER'}`, "DATE", ""]
            })
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
        handleRequestErrors(err)
      });
  }
  
  const openView = (item) => {
    sessionStorage.setItem("identity", JSON.stringify(item))
    if(path==="bvn"){
      history(`/identity/bvn-verification`)
    } else if(path==="nin"){
      history(`/identity/nin-verification`)
    } else if(path==="bank"){
      setBasicData(item)
      if(item.email===undefined){
        setBasicVerificationModal(true)
      } else {
        history(`/identity/bank-verification`)
      }
    } else if(path==="license"){
      history(`/identity/license-verification`)
    } else if(path==="pvc"){
      history(`/identity/pvc-verification`)
    } else if(path==="passport"){
      history(`/identity/international-passport`)
    } else {
      history(`/identity/cac-verification/${item?.directors?"advanced":"basic"}`)
    }
  }

  const getPricing = () => {
    if(path==="passport"){
      let payload = {
        action: "passport verification"
      }
      BillingService.getBillingPricing(payload)
        .then(response=> response.data)
        .then(res => {
            if(res?.success === true){
              setPrice(res?.data?.price)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
          handleRequestErrors(err, true)
        });
    }
  } 

  return (
    <div>
      <div className="mx-10">
        <Tab 
            tabs={[
                { label: "Customer Onboarding", key: "main" },
                { label: "BVN", key: "bvn" },
                { label: "NIN", key: "nin" },
                { label: "Radar", key: "radar" },
                { label: "Bank Account", key: "bank" },
                { label: "Driver's License", key: "license" },
                { label: "Voter's Card", key: "pvc" },
                { label: "CAC Number", key: "rc" },
                { label: "International Passport", key: "passport" },
            ]}
        />
      </div>
      <div className="py-[30px] px-10 sm:pl-2.5 sm:pr-[30px]">
        {
          path==="main"?<CustomerOnboarding permissionValues={permissionValues} />
          :
          path==="bvn"?<BVNInterface permissionValues={permissionValues} />
          :
          path==="bank"?<BankInterface permissionValues={permissionValues} />
          :
          path==="nin"?<NINInterface permissionValues={permissionValues} />
          :
          path==="radar"?<Radar permissionValues={permissionValues} />
          :
          path==="rc"?<RCInterface permissionValues={permissionValues} />
          :
          path==="license"?<DriversLicense permissionValues={permissionValues} />
          :
          path==="pvc"?<VotersCard permissionValues={permissionValues} />
          :
          <PassportInterface price={price} />
        }
        {
          (path!=="main" && path!=="radar") ?
          <div className="mt-10">
            <div className="home_main-topText">
              <h5>History</h5>
              <div className="home_main-topText-search -mb-5" >
                  <p>List of previously verified{" "}
                    {
                      path==="bvn"?"BVN"
                      :
                      path==="bank"?"Bank Accounts"
                      :
                      path==="nin"?"NIN"
                      :
                      path==="rc"?"RC Numbers"
                      :
                      path==="license"?"Driver's License"
                      :
                      path==="passport" ? "International Pasport"
                      :
                      "Voter's Card"
                    }
                  </p>
              </div>
            </div>
            {
              path!=="passport" ?
              <div>
                <div className="table_top">
                  <div className="home_main-topText-toggles">
                      <div className="flex items-center">
                          <img src={tableIcon} alt="table" />
                          <h4>
                            {
                              path==="bvn"?"BVN"
                              :
                              path==="bank"?"Bank Accounts"
                              :
                              path==="nin"?"NIN"
                              :
                              path==="rc"?"CAC"
                              :
                              path==="license"?"Driver's License"
                              :
                              "Voter's Card"
                            }
                          </h4>
                      </div>
                  </div>
                </div>
                <OldTable data={data} doAction={(item)=>openView(item)} />
              </div>
              :
              <HistoryTable price={price} openView={(item)=>openView(item)} path={path} />
            }
          </div> : <></>
        }
      </div>
      <BasicVerificationResult 
        show={basicVerificationModal}
        onCancel={()=>setBasicVerificationModal(false)}
        data={basicData}
        banksList={ngBanks}
      />
    </div>
  );
}