import {Routes, Route, useLocation} from "react-router-dom";
import Main from "./Main";
import InsightDetails from './insightDetails'
import LinkAccount from "./LinkAccount";
import Accounts from "./Accounts";
import { useEffect, useState } from "react";
import Tab from "components/shared/Tab";


function Income ({setDetailName, rolePermissions}) {
    const [permissionValues, setPermissionValues] = useState([]);
    const location = useLocation();

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])
    
    return(
        <div className="pt-[20px]">
            {
                location?.pathname!=="/income/insight" &&
                <div className="px-10 md:px-[20px]">
                    <p className="-mt-[50px] mb-5">The all new AI-powered service to transform complex Bank PDF statement to actionable Insight</p>
                    <Tab
                        tabs={[
                            { label: "Customers", key: "/income" },
                            { label: "Linked Accounts", key: `/income/link-account` },
                            { label: "Bank Accounts", key: "/income/bank-account" },
                        ]}
                        useAsLink
                    />
                </div>
            }
            <Routes>
                <Route path="/" element={<Main setDetailName={setDetailName} permissionValues={permissionValues} />}/>
                <Route path="/insight" element={<InsightDetails setDetailName={setDetailName} permissionValues={permissionValues} />} />
                <Route path="/link-account" element={<LinkAccount setDetailName={setDetailName} permissionValues={permissionValues} />} />
                <Route path="/bank-account" element={<Accounts setDetailName={setDetailName} />} />
            </Routes>
        </div>
    )
}

export default Income;