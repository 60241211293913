import {Modal} from "react-bootstrap";

import noData from "../../images/svg/credit/no-data.svg";
import close from "../../images/svg/credit/x-close.svg";


function NoCreditData ({type, show, cancel}) {
    return(
        <Modal show={show} onHide={cancel} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="m-[20px]">
                <div className="flex items-start">
                    <img src={noData} alt="" />
                    <img src={close} alt="" className="ml-auto cursor-pointer" onClick={cancel} />
                </div>
                <h5 className="text-[24px] font-semibold">No credit history data?</h5>
                <p className="mb-[30px]">This {type==='individual'?'BVN':type==='business'?"business":'RC Number'} has no existing data with the credit bureau.</p>
                <button className="box-border w-full py-[5px] px-[20px] rounded-[7px] text-[#344054] border border-[#D0D5DD] bg-white" onClick={cancel}>Go Back</button>
            </div>
        </Modal>
    )
}

export default NoCreditData;