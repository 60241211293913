import { WalletService } from "services/wallet.service";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Table from "../../../components/Table";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from "react-router-dom";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";
import Status from "components/shared/Status";
import { logToBin } from "../../../lib/logs";
import Button from "components/shared/Button";

import goback from "../../../images/svg/back.svg";
import view from "images/svg/income/view.svg";
import Filter from "components/Table/Filter";



function Invoices () {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [filterTerm, setFilterTerm] = useState('all');
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        loadHistory();
        setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterTerm]);

    const loadHistory = () => {
        WalletService.getWalletHistory(filterTerm)
            .then((response) => response.data)
            .then((res) => {
                setLoading(false);
                if (res.success === true) {
                    const items = res.data.filter(x => x.type === 'Credit');
                    setData(items)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoading(false);
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div className="m-10 md:mx-[20px] md:my-4" >
            <div onClick={()=>window.history.back()} className="flex gap-x-1 -mt-[30px] cursor-pointer">
                <img src={goback} alt="back" />
                <p className="font-bold text-[1.4em]">Invoices</p>
            </div>
            <p>Manage all invoices generated on your account</p>
            <Table
                noTitleIcon={true}
                hideSearch={true}
                tableTitle={"Invoices"}
                data={data}
                loading={loading}
                slot={
                    <Filter
                        filterValue={filterTerm}
                        onChange={(v)=>setFilterTerm(v)}
                        options={[
                            { label: "Filter", value: "all", showOnList: false },
                            { label: "Last 7 days", value: "seven_days", showOnList: true },
                            { label: "Last month", value: "last_month", showOnList: true },
                            { label: "Last 3 months", value: "last_three_months", showOnList: true },
                        ]}
                    />
                }
                rows={[
                    {
                        header:'Invoice Date',
                        view: (item) => (
                            <span className="text-base py-[10px] pl-[10px]">
                                {dateFormatter(item?.created_at, 'dd/mm/yyyy')} {timeFormatter(item?.created_at)}
                            </span>
                        ),
                    },
                    {
                        header:'Amount',
                        view: (item) => (
                            <span className="text-base py-[10px] pl-[10px]">{item?.type==="Credit" ? currencyFormatter(item.amountPaid) : currencyFormatter(item.amount)}</span>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <div className="p-2.5">
                                <Status 
                                    type={item?.status==="success"||item?.status==="SUCCESS"?"success":"fail"} 
                                    text={item?.status}
                                />
                            </div>
                        ),
                    },
                    {
                        header:'Type',
                        view: (item) => (
                            <div className="p-2.5">
                                <Status 
                                    type={"success"} 
                                    text={"Onetime"}
                                />
                            </div>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <div className="w-[70px]">
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="View"
                                    iconPosition="left"
                                    icon={
                                        <img src={view} alt="view" className="w-4" />
                                    }
                                    onClick={()=>{
                                        sessionStorage.setItem(
                                            "invoice", JSON.stringify(item)
                                        );
                                        history(`/home/wallet/invoices/${item.id}`)
                                    }}
                                    className="bg-[#47D800] p-1 text-sm"
                                />
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default Invoices;