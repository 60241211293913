import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { TextField } from 'components/Form/TextField'
import { useSteps } from 'react-step-builder'
import Button from 'components/shared/Button'
import { SelectField } from 'components/Form/SelectField'
import { CurrencyField } from 'components/Form/CurrencyField'
import { object, string, mixed } from "yup";
import { RecovaService } from 'services/recova.service'
import { handleRequestErrors } from 'utils/helpers'
import mark from "images/svg/recova/mark.svg";
import loaddot from "images/gif/load-dots.gif";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify'


export default function AccountDetails ({payout_balance, setAmountRequested}) {
    const { next } = useSteps();
    const [isLoading, setIsLoading] = useState(false);
    const [vfd_banks, setVfd_banks] = useState([])
    const b2b_data = useSelector(state => state?.loginData?.data);

    useEffect(()=>{
        RecovaService.getMicrodepositBanklist()
            .then((response) => response.data)
            .then((res) => {
                if(res.status===true){
                    setVfd_banks(res?.data ?? [])
                }
            })
            .catch((err) => {})
    },[])
    
    const form = useFormik({
        initialValues: {
            bank: "",
            accountNumber: "",
            accountName: "",
            amount_due: 0,
        },
        validationSchema: object({
            bank: mixed().nullable().required().label("Bank"),
            accountName: string().required().label("Account Name"),
            accountNumber: string().required().label("Account Number")
                .min(10, "Must be exactly 10 characters")
                .max(10, "Must be exactly 10 characters")
                .test(
                    "not-match", 
                    "Must contain only numbers", 
                    (value) => value.match(/^[0-9]+$/) !== null 
                ),
            "amount_due*": string().required().label("Amount")
                .test('amount_to_balance', 'Cannot be above payout balance', function(value) {
                    const { amount_due } = this.parent;
                    return amount_due <= payout_balance || !value;
                }),
        }), 
        onSubmit: (values) => {
            let payload = JSON.stringify({
                bankCode: values.bank.nip_code,
                accountNumber: values.accountNumber,
                amount: Number(values.amount_due),
                requestedBy: b2b_data?.user?.id,
            })
            setIsLoading(true)
            RecovaService.requestWithdrawal(payload)
                .then(response=> response.data)
                .then(res => {
                    setIsLoading(false)
                    if(res.status===true){
                        next()
                        setAmountRequested(Number(values.amount_due))
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    handleRequestErrors(err, true)
                });
        }
    })

    useEffect(()=>{
        if(!!form.values.bank && form.values.accountNumber.length===10){
            let payload = JSON.stringify({
                bankCode: form.values.bank.nip_code,
                accountNumber: form.values.accountNumber,
            })
            RecovaService.nameEnquiry(payload)
                .then(response=> response.data)
                .then(res => {
                    if(!!res?.data?.data){
                        form.setFieldValue("accountName", res.data.data.name);
                    } else {
                        toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        } else {
            form.setFieldValue("accountName", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.accountNumber])

    return(
        <div className='mx-5'>
            <div
                style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
                className='px-3 py-3 rounded-2xl my-4'
            >
                <h2 className='text-sm font-bold my-4 w-7/12'>
                Fill in correctly the bank details to enable  withdrawal 
                </h2>
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <div className='box-border'>
                            <SelectField
                                name='bank'
                                options={vfd_banks.map(item => ({
                                    value: item,
                                    label: item.name
                                }))}
                                value={form?.values?.bank}
                                searchable
                                label="Bank"
                                placeholder="Select bank"
                                onChange={(selected)=>{
                                    form.setFieldValue( "bank", selected.value )
                                }}
                                required
                            />

                            <TextField
                                label="Account Number"
                                name='accountNumber'
                                type='text'
                                placeholder="Account Number"
                                width={"92%"}
                            />

                            <div className='relative'>
                                <TextField
                                    label="Account Name"
                                    name='accountName'
                                    type='text'
                                    readOnly
                                    placeholder="Account Name"
                                    width={"92%"}
                                />
                                { 
                                    !!form.values.bank && form.values.accountNumber.length===10 &&
                                    <img src={loaddot} alt="load" className='top-10 w-[20px] right-[10px] absolute' /> 
                                }
                                { !!form.values.accountName && <img src={mark} alt="mark" className='top-10 w-[20px] right-[10px] absolute' /> }
                            </div>
                            <CurrencyField
                                onChange={(val)=>{
                                    form.setFieldValue("amount_due", val.value )
                                }}
                                value={form?.values?.amount_due}
                                name='amount_due*'
                                label="Enter amount to withdraw"
                            />
                            <Button
                                isValid={true}
                                isLoading={isLoading}
                                type="submit"
                                text="Submit Transfer Request"
                                onClick={()=>{
                                    form.handleSubmit();
                                }}
                            />
                        </div>
                    </form>
                </FormikProvider>
            </div>
        </div>
    )
}