import React, { useState } from "react";
import Table from "components/Table";
import { dateFormatter, timeFormatter } from "utils/helpers";
import { LineChart, Line, XAxis, YAxis, Legend } from 'recharts';

import smiley from "images/svg/recova/empty.svg";
import FAQModal from "./components/FAQModal";
import Filter from "components/Table/Filter";
import Status from "components/shared/Status";
import Button from "components/shared/Button";
import { useNavigate } from "react-router-dom";


function Disputes () {
    const [showFAQ, setShowFAQ] = useState(false)
    let history = useNavigate()

    const data = [
        {
          name: 'Page A',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
        {
          name: 'Page B',
          Settled: 0,
          Ongoing: 8,
          amt: 0,
        },
        {
          name: 'Page C',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
        {
          name: 'Page D',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
        {
          name: 'Page E',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
        {
          name: 'Page F',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
        {
          name: 'Page G',
          Settled: 0,
          Ongoing: 0,
          amt: 0,
        },
    ];

    return(
        <div>
            <div className="flex gap-x-[20px] mt-[30px] mb-5">
                <div className="w-full h-[200px] rounded-[10px] border-[1px] shadow-md border-cc-grey-1">
                    <LineChart
                        width={600}
                        height={200}
                        data={data}
                    >
                        <XAxis dataKey="name" hide={true} />
                        <YAxis hide={true} />
                        {/* <Tooltip /> */}
                        <Legend align="right" />
                        <Line type="monotone" dataKey="Settled" stroke="#0046E6"/>
                        <Line type="monotone" dataKey="Ongoing" stroke="#32D583" strokeDasharray="5 5" />
                    </LineChart>
                </div>
                <div className="w-[300px] h-[200px] flex flex-col justify-between rounded-[10px] p-[20px] border-[1px] shadow-md border-cc-grey-1 text-sm font-medium">
                    <p className="text-cc-grey-22">Support</p>
                    <p className="leading-6">Find answers to some of the most frequently asked questions about RecovaPRO.</p>
                    <p className="text-xs text-cc-primary underline cursor-pointer" onClick={()=>setShowFAQ(true)}>Get Answers</p>
                </div>
            </div>
            <Table
                noTitleIcon={true}
                tableTitle={"Dispute Resolution Activities"}
                data={[1]}
                hideSearch={false}
                trClassName={"relative"}
                searchPlaceHolder={"Search case by subjecct, severity type or status"}
                // onSearchChange={handleChange}
                searchContainerClass="w-[350px]"
                searchIconPosition="left"
                searchInputClass="!text-sm"
                theadClassName="!bg-[#EAECF0]"
                slot={
                    <Filter
                        filterValue={"all"}
                        onChange={(v)=>console.log(v)}
                        options={[
                            { label: "All Cases", value: "all", showOnList: false },
                        ]}
                    />
                }
                tableEmpty={
                    <div className="flex items-center my-[120px] mx-[20px] justify-center">
                        <p className="text-cc-grey-4 mr-3">Yay... No disputes found!</p>
                        <img src={smiley} alt="" className="w-[40px]" />
                    </div>
                }
                rows={[
                    {
                        header:'Case ID',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] !text-sm">
                                23456
                            </p>
                        ),
                    },
                    {
                        header:'Created By',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] !text-sm">
                                Tobi Olugbemi
                            </p>
                        ),
                    },
                    {
                        header:'Case Type',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] !text-sm">
                                Debit Error
                            </p>
                        ),
                    },
                    {
                        header:'Severity',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] !text-sm text-cc-grey-4">
                                High
                            </p>
                        ),
                    },
                    {
                        header:'Date Created',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] !text-sm text-cc-grey-4">
                                {dateFormatter("2023-06-06")} {timeFormatter(new Date())}
                            </p>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <Status text="Resolved" type="success"/>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <Button
                                isValid={true}
                                isLoading={false}
                                type="button"
                                text="View"
                                className="bg-cc-primary !py-2 !px-[20px] text-xs !my-2.5"
                                fullwidth={false}
                                onClick={()=>
                                    history('id')
                                    // history('create')
                                }
                            />
                        ),
                    },
                ]}
            />
            <FAQModal 
                show={showFAQ}
                handleClose={()=>setShowFAQ(false)}
            />
        </div>
    )
}

export default Disputes;