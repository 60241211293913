import {useState, useEffect} from "react";
import tableIcon from "../../../../images/svg/loan-performance.svg";
import Table from "components/Table";
import { currencyFormatter, dateFormatter } from "utils/helpers";
import Status from "components/shared/Status";
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from "clsx";
import dropdown from "images/svg/table_dropdown.svg";


export default function LoanPerformance ({data, currentPath}) {
    const [loanPerformance, setLoanPerformance] = useState([]);
    const [viewMore, setViewMore] = useState(null);

console.log(data)
    useEffect(()=>{
        setLoanPerformance(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath, data]);

    const handleChange = (e) => {
        const filteredData = data?.filter((item) => item?.loanProvider?.toLowerCase().includes(e.target.value.toLowerCase()) );
        setLoanPerformance(filteredData);
    }

    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Loan Performance"}
                data={loanPerformance}
                hideSearch={false}
                trClassName={"relative"}
                searchPlaceHolder={"Search loan provider"}
                onSearchChange={handleChange}
                tableClassName={
                    !!viewMore ? 
                        currentPath==="CREDIT_REGISTRY" ? "mb-[320px] xl:min-w-[1000px]":"mb-[200px] xl:min-w-[1000px]"
                        :
                        "xl:min-w-[1000px]"
                }
                rows={[
                    {
                        header:'Loan Provider',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.loanProvider || "Not Available"}
                            </p>
                        ),
                    },
                    {
                        header:'Loan Count',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.loanCount || 0}
                            </p>
                        ),
                    },
                    {
                        header:'Loan Amount',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {
                                    item?.loanAmount===null||item?.loanAmount===""?
                                    "Not available"
                                    :
                                    currentPath==="CRC"?`${item?.loanAmount}`:
                                        currentPath==="FIRST_CENTRAL"||currentPath==="CREDIT_REGISTRY"? 
                                            currencyFormatter(item?.loanAmount || 0)
                                            : `₦${item?.loanAmount}`
                                }
                            </p>
                        ),
                    },
                    {
                        header:'Outstanding Balance',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {
                                    item?.outstandingBalance===null||item?.outstandingBalance===""?
                                    "Not available"
                                    : currentPath==="FIRST_CENTRAL"||currentPath==="CREDIT_REGISTRY"? 
                                        currencyFormatter(item?.outstandingBalance || 0)
                                        :`₦${item?.outstandingBalance}`
                                }
                            </p>
                        ),
                    },
                    {
                        header:'Overdue Amount',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {
                                    item?.overdueAmount===null||item?.overdueAmount===""?
                                    "Not available"
                                    :
                                    currentPath==="FIRST_CENTRAL"||currentPath==="CREDIT_REGISTRY"?currencyFormatter(item?.overdueAmount ?? 0):`₦${item?.overdueAmount}`
                                }
                            </p>
                        ),
                    },
                    {
                        header:'Status',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                <Status type={item?.status==="Closed"?"success":"fail"} text={item?.status}/>
                            </p>
                        ),
                    },
                    {
                        header:'Performance Status',
                        view: (item) => (
                            <Status 
                                type={
                                    item?.performanceStatus==="Performing" || item?.performanceStatus?.includes("Closed") ?
                                    "success":"fail"
                                } 
                                text={item?.performanceStatus}
                            />
                        ),
                    },
                    {
                        header:'',
                        view: (item) => (
                            <div>
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        setViewMore(null)
                                    }}
                                >
                                    <img 
                                        src={dropdown} 
                                        alt="dropdown" 
                                        className={clsx(
                                            "w-[30px] cursor-pointer shrink-0 !mx-2 transition-all transform",
                                            viewMore===item && "rotate-180"
                                        )} 
                                        onClick={()=>{
                                            viewMore===item?setViewMore(null):setViewMore(item);
                                        }} 
                                    />
                                    {
                                        viewMore===item &&
                                        <div className="position shadow-lg grid grid-cols-4 absolute left-[10px] right-[10px] top-[55px] rounded-[4px] z-10 border border-solid border-emerald-600 bg-white px-2.5 pt-2.5">
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Provider</p>
                                                <p className="text-cc-black-3">{item.loanProvider}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Account Number</p>
                                                <p className="text-cc-black-3">{item?.accountNumber||"Not available"}</p>
                                            </div>
                                            {
                                                currentPath!=="FIRST_CENTRAL" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Account Type</p>
                                                    <p className="text-cc-black-3">{item?.type || "Not Available"}</p>
                                                </div>
                                            }
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Amount</p>
                                                <p className="text-cc-black-3">{currentPath==="CRC"?item.loanAmount:currencyFormatter(item?.loanAmount||0)}</p>
                                            </div>
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Installment Amount</p>
                                                    <p className="text-cc-black-3">{currencyFormatter(item?.monthlyInstallmentAmt||0)}</p>
                                                </div>
                                            }
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Outstanding Balance</p>
                                                <p className="text-cc-black-3">{currentPath==="CRC"?item.outstandingBalance:currencyFormatter(item?.outstandingBalance||0)}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Overdue Amount</p>
                                                <p className="text-cc-black-3">{currentPath==="CRC"?item.overdueAmount:currencyFormatter(item?.overdueAmount||0)}</p>
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Currency</p>
                                                <p className="text-cc-black-3">NGN</p>
                                            </div>
                                            {
                                                currentPath==="FIRST_CENTRAL" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">No of Performing</p>
                                                    <p className="text-cc-black-3">{item?.noOfPerforming||0}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="FIRST_CENTRAL" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">No of Non-Performing</p>
                                                    <p className="text-cc-black-3">{item?.noOfNonPerforming||0}</p>
                                                </div>
                                            }
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Account Status</p>
                                                {item.status==="" ? "Not available":<Status type={item?.status==="Closed"?"success":"fail"} text={item?.status}/>}
                                            </div>
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Performance Status</p>
                                                {
                                                    item.performanceStatus==="" ? 
                                                    "Not available"
                                                    :
                                                    <Status 
                                                        type={
                                                            item?.performanceStatus==="Performing" || item?.performanceStatus?.includes("Closed") ?
                                                            "success":"fail"
                                                        } 
                                                        text={item?.performanceStatus}
                                                    />
                                                }
                                            </div>
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Loan Duration</p>
                                                    <p className="text-cc-black-3">{item.loanDuration} days</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Date Account Opened</p>
                                                    <p className="text-cc-black-3">{dateFormatter(item.dateAccountOpened, 'dd-M-yyyy')}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Repayment Frequency</p>
                                                    <p className="text-cc-black-3">{item?.repaymentFrequency||"Not available"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Repayment Behaviour</p>
                                                    <p className="text-cc-black-3">{item?.repaymentBehavior || "None"}</p>
                                                </div>
                                            }
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Payment Profile</p>
                                                    <p className="text-cc-black-3">{item.paymentProfile}</p>
                                                </div>
                                            }
                                            <div className="text-sm pb-3">
                                                <p className="text-cc-grey-4">Loan Count</p>
                                                <p className="text-cc-black-3">{item.loanCount}</p>
                                            </div>
                                            {
                                                currentPath==="CREDIT_REGISTRY" &&
                                                <div className="text-sm pb-3">
                                                    <p className="text-cc-grey-4">Last Updated On</p>
                                                    <p className="text-cc-black-3">{dateFormatter(item?.lastUpdatedAt, 'dd-M-yyyy')}</p>
                                                </div>
                                            }
                                        </div>
                                    }
                                </OutsideClickHandler>
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}