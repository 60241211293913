import {Row, Col} from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Back from '../../../components/shared/back.js';

import tooltip from "../../../images/svg/spectrum/tooltip.svg";
import mark from "../../../images/svg/spectrum/mark.svg";


function Pricing () {
    let navigate = useNavigate()
    const [time, setTime] = useState('monthly');

    return(
        <div className="spectrum_pricing">
            <Back to={()=>navigate('/spectrum')} />
            <div className="spectrum_pricing-text">
                <p className="spectrum_pricing-text-1 text-center">Pricing</p>
                <p className="spectrum_pricing-text-2 text-center">Our pricing plan is simple and affordable. No hidden fees or long-term contracts.</p>
            </div>
            <div className="spectrum_pricing-tablets">
                <div className={time==="monthly"?'active':''} onClick={()=>setTime('monthly')}>
                    <p>Monthly billing</p>
                </div>
                <div className={time==="quarterly"?'active':''} onClick={()=>setTime('quarterly')}>
                    <p>Quarterly billing</p>
                </div>
                <div className={time==="annually"?'active':''} onClick={()=>setTime('annually')}>
                    <p>Annually billing</p>
                </div>
            </div>
            <Row className="justify-content-md-center">
                <Col md={6} lg={5} xl={4}>
                    <div className="spectrum_pricing-card">
                        <div className="spectrum_pricing-card-top">
                            <div className="d-flex flex-row">
                                <p className="text11">Basic plan</p>
                                <p className="text12">Popular</p>
                            </div>
                            <div className="spectrum_pricing-card-price">
                                <h2>USD {time==="annually"?45*11:time==="quarterly"?45*2.5:45}</h2>
                                <div>
                                    <img src={tooltip} alt="tip"/>
                                    <p>per {time==="annually"?'year':time==="quarterly"?"quarter":"month"}</p>
                                </div>
                            </div>
                            <button className="spectrum_pricing-card-start">Get Started</button>
                            <button className="spectrum_pricing-card-sales">Chat to Sales</button>
                        </div>
                        <div className="spectrum_pricing-card-features">
                            <h5>FEATURES</h5>
                            <p>Making African lending business better...</p>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Access to basic features</p>
                            </div>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Ability to perform up to 500 inquiries monthly</p>
                            </div>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Share(contribute), update, and track borrower debt profiles on the blockchain network</p>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6} lg={5} xl={4}>
                    <div className="spectrum_pricing-card">
                        <div className="spectrum_pricing-card-top">
                            <div className="d-flex flex-row">
                                <p className="text11">Business plan</p>
                                {/* <p className="text12">Popular</p> */}
                            </div>
                            <div className="spectrum_pricing-card-price">
                                <h2>USD {time==="annually"?95*11:time==="quarterly"?95*2.5:95}</h2>
                                <div>
                                    <img src={tooltip} alt="tip"/>
                                    <p>per {time==="annually"?'year':time==="quarterly"?"quarter":"month"}</p>
                                </div>
                            </div>
                            <button className="spectrum_pricing-card-start">Get Started</button>
                            <button className="spectrum_pricing-card-sales">Chat to Sales</button>
                        </div>
                        <div className="spectrum_pricing-card-features">
                            <h5>FEATURES</h5>
                            <p>Making African lending business better...</p>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Access to basic features</p>
                            </div>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Ability to perform unlimited inquiries monthly</p>
                            </div>
                            <div className="spectrum_pricing-card-features-item">
                                <img src={mark} alt="mark"/>
                                <p>Share(contribute), update, and track borrower debt profiles on the blockchain network</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Pricing;