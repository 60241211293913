import React, { useState } from "react";
import { Steps, StepsProvider } from "react-step-builder";
import AccountDetails from "./AccountDetails";
import SuccessModal from "../SuccessModal";
import ModalLayout from "../../../layout/ModalLayout";
import { currencyFormatter } from "utils/helpers";

const WithdrawModal = ({ payout_balance, modalOpen, setModalOpen }) => {
  const [amount, setAmount] = useState(0)
  return (
    <ModalLayout show={modalOpen} handleClose={setModalOpen} >
      <StepsProvider>
        <Steps>
            <AccountDetails
              payout_balance={payout_balance}
              setAmountRequested={(val)=>setAmount(val)}
            />
            <SuccessModal
              title="Withdrawal successful!"
              message={`${currencyFormatter(amount)} will be transferred into your bank account`}
              onClose={()=>setModalOpen()}
            />
        </Steps>
      </StepsProvider>
    </ModalLayout>
  );
};

export default React.memo(WithdrawModal);
