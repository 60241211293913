import Button from "components/shared/Button"
import Header from "../Header"
import { useSteps } from "react-step-builder";
import { useRecovaSetup } from "hooks/redux.hook";
import { useState } from "react";
import { RecovaService } from "services/recova.service";
import { handleRequestErrors } from "utils/helpers";
import { toast } from "react-toastify";

export default function LoanProductDef () {
    const {jump} = useSteps()
    const { recovaSetupData, updateSetupDispatch} = useRecovaSetup();
    const [definitions, setDefinitions] = useState(recovaSetupData?.loanProdDefs ?? [])
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("") 

    const handleSubmit = () => {
        updateSetupDispatch({
            currentStep: 19,
            loanProdDefs: definitions
        })
        jump(19)
        // setIsLoading(true)
        // RecovaService.addLoanProdDefs(JSON.stringify({creditDefination: definitions}))
        // .then(response=> response.data)
        // .then(res => {
        //     setIsLoading(false)
        //     if(res.status){
        //         updateSetupDispatch({
        //             currentStep: 19,
        //             loanProdDefs: definitions
        //         })
        //         jump(19)
        //     } else {
        //         toast.error(res.message)
        //     }
        // })
        // .catch((err) => {
        //     handleRequestErrors(err, true)
        //     setIsLoading(false)
        // });
    }

    const addDefs = (e) => {
        e.preventDefault();
        // if(!definitions.includes(value)){
        //     setDefinitions((prevArray) => [...prevArray, value])
        // }

        if(value!==""){
            const payload = JSON.stringify({
                name: value,
            })
            setIsLoading(false)
            RecovaService.createProduct(payload)
                .then(response=> response.data)
                .then(res => {
                    setIsLoading(false)
                    if(res.status){
                        document.getElementById("keywordForm").reset()
                        setValue("")
                        setDefinitions((prevArray) => [...prevArray, res.data])
                        updateSetupDispatch({
                            loanProdDefs: [...definitions, res.data]
                        })
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                    setIsLoading(false)
                });
        } else {
            handleSubmit()
        }
    }

    // const removeDefs = (val) => {
    //     setDefinitions((prevArray) => prevArray.filter((item) => item !== val));
    // }
    
    return (
        <div className="rounded-3xl px-2.5 py-4 sm:px-0">
            <Header 
                progress={70} 
                back={()=>{
                    updateSetupDispatch({
                        currentStep: 15
                    })
                    jump(15)
                }} 
            />
            <h2 className='text-xl font-bold leading-5'>Loan Product Definition</h2>
            <p className='mt-2'>Add your preferred Loan Product names(s) or what you want your Loan product to be recorded as during collections on the e-mandate, and reflect in the customer’s bank transaction statement.</p>
            <div
              style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
              className='px-3 py-8 rounded-2xl my-10 box-border'
            >
                <form className="box-border" id='keywordForm' onSubmit={addDefs}>
                    <label className="font-semibold mb-2.5">Type in correctly your Loan Product definition list</label>
                    <input 
                        placeholder="Type in the preferred product name..." 
                        className="w-full h-10 rounded-[5px] border-[1px] border-solid border-[#D0D5DD] text-[#667085] px-2.5" 
                        onChange={(e)=>setValue(e.target.value)} 
                    />
                </form>
                <div className="border-[1px] border-solid border-[#F2F4F7] rounded-[8px] font-medium p-2.5 mt-[20px]">
                    <div className="flex">
                        <p className="text-[#A5A5A5]">Recently added to list</p>
                        {/* <div onClick={()=>setDefinitions([])} className="text-[#B42318] ml-auto cursor-pointer flex items-center"><p>clear</p>
                            <svg style={{marginLeft: "4px", cursor:"pointer"}} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 3L3.5 9M3.5 3L9.5 9" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div> */}
                    </div>
                    <div className="flex flex-wrap gap-2.5 mt-2.5">
                        {
                            definitions.length===0 ?
                            <h6 style={{margin:"10px auto 20px"}}>No recently added definition</h6>
                            :
                            definitions.map((item, idx)=>(
                                <p key={idx} className="flex items-center bg-[#EEF4FF] rounded-[16px] px-2.5 py-[5px] text-[#3538CD]"><span>{item.name}</span>
                                    {/* <svg onClick={()=>removeDefs(item)} style={{marginLeft: "4px", cursor:"pointer"}}  width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 3L3.5 9M3.5 3L9.5 9" stroke="#3538CD" stroke-width="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg> */}
                                </p>
                            ))
                        }
                    </div>
                    <div className="mt-[60px]">
                        <Button
                            text={"Continue"}
                            onClick={addDefs}
                            isValid={definitions.length!==0}
                            // className="bg-cc-primary"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}