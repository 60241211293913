import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import OTPInput from "otp-input-react";
import { resendVerificationCode } from "../../lib/doVerification";
import { toast } from "react-toastify";
import { deviceDetect } from 'react-device-detect';
import { getGeolocation } from "../../lib/externalApis";
import { AuthService } from "services/auth.service";
import { useDispatch } from "react-redux";
import { initial } from "redux/login";
import { setActiveApp, setApps } from "redux/apps";

import otpHide from "../../images/svg/otp-hide.svg";
import otpShow from "../../images/svg/otp-view.svg";
import { logToBin } from "../../lib/logs";


function Otp (props) {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [OTP, setOTP] = useState("");
    const [secureOtp, setSecureOtp] = useState(true);
    const [ minutes, setMinutes ] = useState(5);
    const [seconds, setSeconds ] =  useState(0);
    const params = useParams();
    const [ipDeets, setIpDeets] = useState({
        country_name: "Nigeria",
        IPv4: "102.89.45.161",
        city: 'Lagos'
    });
    const [userAgent, setUserAgent] = useState('')

    useEffect( () => {
        if(deviceDetect().ua===undefined){
            setUserAgent(deviceDetect().userAgent)
        } else {
            setUserAgent(deviceDetect().ua)
        }
        getGeolocation()
        .then(response => response.data)
        .then((res) => {
            setIpDeets(res)
            // console.log(res)
        })
        .catch((err)=>{
            // console.log(err)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    const toggleOtpShow = () => {
        setSecureOtp(!secureOtp);
    }

    const sendOtp = (e) => {
        e.preventDefault();
        if(params.type==='email-verify'){
            emailVerify()
        } else {
            loginVerify()
        }
    }

    const emailVerify = () => {
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        let payload = JSON.stringify({
            otp: OTP,
            businessName : params.bussName,
            email : params.email.toLowerCase()
            // 
        })
        AuthService.verifyAction(payload)
            .then((response) => response.data)
            .then((res) => {
                btn.innerHTML=`Continue`;
                if (res.status === "success") {
                    toast.success("User successfully verified, redirecting to login...");
                    setTimeout( function (){
                        history(`/auth/login`);
                    }, 1500);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                btn.innerHTML=`Continue`;
                if(err.response){
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const loginVerify = () => {
        var btn = document.getElementById("actionBtn");
        btn.innerHTML=`Please Wait...`;
        let payload = JSON.stringify({
            email: params.email.toLowerCase(),
            password: sessionStorage.getItem('password'),
            token: OTP
        })
        AuthService.verifyLoginAction(payload, ipDeets, userAgent)
            .then((response) => response.data)
            .then((res) => {
                btn.innerHTML=`Continue`;
                if (res.status === "success") {
                    sessionStorage.removeItem("password");
                    toast.success(`Login successful!`);
                    dispatch(initial(res.data))
                    res?.data?.user?.roleId && sessionStorage.setItem("roleId", JSON.stringify(res?.data?.user?.roleId))
                    sessionStorage.setItem( "webhook", res.data.webhookUrl);

                    sessionStorage.setItem( "b2bStatus", JSON.stringify({ isActive: res.data.user.isActive })); 
                    sessionStorage.setItem( "b2bCompliance", JSON.stringify({ isComplied: res.data.isComplied }));
                    
                    dispatch(setApps(res.data.apps));
                    dispatch(setActiveApp(res.data.apps[0]))
                    
                    history('/')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                btn.innerHTML=`Continue`;
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const resendCode = () => {
        resendVerificationCode(params.email, params.id, params.userId)
    }

    return(
        <div>
            <form>
                <p>Please enter the code sent {params.email.substring(0,3)}******{params.email.split("@").pop()}</p>
                <div className="auth_otp">
                    <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={secureOtp} />
                    <img src={secureOtp ? otpHide : otpShow} onClick={toggleOtpShow} alt="otp" className="auth_otp-show" />
                </div>
                <button 
                    className={ OTP.length===6 ?"auth_signup-active":"auth_signup-submit"} 
                    onClick={sendOtp}
                    disabled={OTP.length !== 6}
                    id="actionBtn"
                >Continue</button>
                <p className="auth_otp-text1">Didn’t get code? {" "}
                    { minutes === 0 && seconds === 0
                        ? <span onClick={resendCode}>Resend code</span>
                        : <>Resend code in <span> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> </>
                    }
                </p>
            </form>
        </div>
    )
}

export default Otp;