import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { AuthService } from "services/auth.service";
import { logToBin } from "../../../lib/logs";
import { Mixpanel } from "../../../lib/mixpanel";
import ConfirmAction from "components/Modal/ConfirmAction";
import { useSelector, useDispatch } from "react-redux";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import { setApps } from "redux/apps";
import clsx from "clsx";

import creditChek from "../../../images/svg/logo-icon.svg";
import add from "../../../images/svg/apps/add.svg";
import deleteApp from "../../../images/svg/apps/deleteApp.svg";
import Toggle from "react-toggle";



function Main ({showLive, setShowLive, setDetailName}) {
    const [showWarning, setShowWarning] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const dispatch = useDispatch();
    const data = useSelector(state => state?.app?.apps);

    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);

    useEffect(()=>{
        getAllApps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllApps = () => {
        let payload = JSON.stringify({'businessId': b2b_data?.user?.businessId})
        AuthService.businessApps(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.data.length > 0) {
                    dispatch(setApps(res.data))
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const updateState =() =>{
        if(showLive){
            setShowLive(false)
        }       
    }

    const doDeleteApp = () => {
        AuthService.deleteSingleApp(selectedItem.appId)
            .then((response) => response.data)
            .then((res) => {
                setLoadingModal(false);
                setShowWarning(false)
                toast.success(res.message);
                getAllApps();
            })
            .catch((err) => {
                setLoadingModal(false)
                setShowWarning(false)
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <div className="grid grid-cols-3 xl:grid-cols-2 sm:!grid-cols-1 gap-[30px]">
                { 
                    data?.map((item, idx) => (
                        <div  key={item._id}>
                            <AppCard 
                                item={item} 
                                idx={idx} 
                                updateState={updateState} 
                                refetchApps={getAllApps} 
                                deleteAction={()=>{
                                    setShowWarning(true);
                                    setSelectedItem(item);
                                }}
                            />
                        </div>
                    ))
                }
                <Link to='/apps/create'>
                    <div
                        onClick={()=> Mixpanel.track(`Apps (create an app) button was clicked`, {
                            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                            bussinessName: b2b_data?.businessName
                        })} 
                        className="shadow-[rgba(0,0,0,0.24)_0px_3px_8px] box-border rounded-[10px] p-[20px] flex items-center gap-x-3 justify-center h-full min-h-[180px]"
                    >
                        <div className="w-7 h-7 overflow-hidden rounded-full bg-[#12B76A] flex items-center justify-center">
                            <img src={add} alt="logo" className="w-4" /> 
                        </div>
                        <p className="font-medium text-sm text-black">Create an App</p>
                    </div>
                </Link>
            </div>
            <ConfirmAction
                show={showWarning}
                onCancel={()=>setShowWarning(false)}
                onAction={()=>{
                    setLoadingModal(true);
                    doDeleteApp()
                }}
                loading={loadingModal}
                type='delete'
                title="Are you sure?"
                content={`Are you sure you want to delete '${selectedItem?.appName}'? This action is irreversible and cannot be undone.`}
            />
        </div>
    )
}

const AppCard = ({item, idx, updateState, refetchApps, deleteAction}) => {
    const [isLive, setIsLive] = useState(item?.isLive||false)

    useEffect(()=>{
        setIsLive(item.isLive)
    }, [item])

    const switchLive = (appId) => {
        setIsLive(!isLive)
        AuthService.toggleStatus({appId})
        .then((response) => response.data)
        .then((res) => {
            if (res.success === true) {
                toast.success(res.message)
                refetchApps();
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            handleRequestErrors(err)
        })   
    }

    return (
        <div className="relative">
            <label className="flex items-center gap-x-1 absolute top-4 right-4">
                <Toggle
                    defaultChecked={isLive}
                    className="apps-toggle"
                    icons={false}
                    onChange={()=>switchLive(item.appId)}
                />
                <span className={clsx("text-sm", isLive?"text-[#2BBD35]":"text-[#DC6803]")}>{isLive ? 'Live':'Test'}</span>
            </label>
            {
                idx!==0 && 
                <img 
                    src={deleteApp} 
                    alt="trash" 
                    className="absolute bottom-[15px] w-[25px] cursor-pointer right-[15px]" 
                    onClick={()=>{
                        deleteAction()
                    }}
                />
            }
            <Link to={`/apps/${item.appId}`}>
                <div onClick={()=>updateState()} className="shadow-[rgba(0,0,0,0.24)_0px_3px_8px] box-border rounded-[10px] p-[20px]">
                    <div className="flex items-center gap-x-1.5">
                        <div className="w-6 h-6 overflow-hidden bg-neutral-100 rounded-[50%] flex items-center justify-center">
                            <img src={item?.appLogo ? item?.appLogo : creditChek } alt="logo" className="w-full h-auto" />
                        </div>
                        <p className="font-semibold capitalize text-[18px] text-[#0F123F]">{item?.appName}</p> 
                    </div>
                    <p className="text-xs text-[#606060] mt-1">created on {dateFormatter(item.createdAt, 'MMM dd, yyyy')}</p>
                    <div className="flex items-center gap-x-2 mt-6">
                        <p className="text-sm text-[#606060]">SDK Linked:</p>
                        <p 
                            className={clsx(
                                "text-sm font-medium",
                                item?.widget?.createdAt ? "text-cc-green-8":"text-cc-red-11"
                            )}
                        >
                            {item?.widget?.createdAt ? 'YES':'NO'}
                        </p>
                    </div>
                    <div className="flex items-center gap-x-2 mt-2">
                        <p className="text-sm text-[#606060]">SDK Status:</p>
                        <p 
                            className={clsx(
                                "text-sm font-medium",
                                item?.widget?.status ? "text-cc-green-8":"text-cc-red-11"
                            )}
                        >
                            {item?.widget?.status ? 'ENABLED':'DISABLED'}
                        </p>
                    </div>
                    <div className="flex items-center gap-x-2 mt-2">
                        <p className="text-sm text-[#606060]">Webhook Status:</p>
                        <p 
                            className={clsx(
                                "text-sm font-medium",
                                item?.webhook?.status ? "text-cc-green-8":"text-cc-red-11"
                            )}
                        >
                            {item?.webhook?.status ? 'ACTIVE':'INACTIVE'}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Main;