import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';


export const PhoneField = ({label, required, floating, ...props}) => {
   const [field, meta] = useField(props);
   const [active, setActive] = useState(false);

   const handleChange = (status, phoneNumber, country) => {
        props.onChange(status, phoneNumber, country)
   }

    return(
        <div className='relative pb-[15px]'>
            {
                !!label &&
                <label 
                    htmlFor={props.name}
                    className={clsx( 
                        "text-base text-cc-black-4",
                        // (meta.touched && meta.error && !active) ? 'text-cc-red-4':'text-cc-black-4',
                        (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                        floating ? "absolute left-[16px] transition-all" : "font-semibold",
                    )}
                >
                    {label}
                    {
                        required &&
                        <span className='text-cc-red-4'>*</span>
                    }
                </label>
            }
            <IntlTelInput
                {...props}
                {...field}
                onPhoneNumberFocus={()=>setActive(true)}
                style={{width:"100%", margin:"5px 0"}}
                inputClassName={`w-full border-[1px] text-cc-grey-3 mt-1 border-solid py-2 pr-3 pl-[30px] rounded-[5px] focus:outline-none focus:border-cc-primary mb-6 ${(meta?.touched && meta?.error) ? 'border-cc-red-4':'border-cc-grey-12'}`}
                preferredCountries={["ng"]}
                onPhoneNumberChange={handleChange}
                useMobileFullscreenDropdown={false}
            />
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[4px]'>{meta.error}</p>
            }
        </div>
    )
}