
//Library methods
import React, { useState } from 'react'
import { useSteps } from 'react-step-builder'

//Components
import Header from './Header'

//Utilities
import blueTick from "images/svg/recova/blueTick.svg"
import selfService from "images/svg/recova/selfService.svg"
import automated from "images/svg/recova/automated.svg"
import { RecovaService } from "services/recova.service";
import { toast } from 'react-toastify'
import { useRecovaSetup } from 'hooks/redux.hook'
import Button from 'components/shared/Button'
import { handleRequestErrors } from 'utils/helpers'

const AddPayoutMethod = () => {
  const { jump } = useSteps();
  const [isLoading, setIsLoading] = useState({
    selfBtn: false,
    automatedBtn: false,
  })
  const { updateSetupDispatch } = useRecovaSetup()

  const handlePayoutSetup = (val) => {
    const payload = JSON.stringify({
      paymentOption: val
    })
    RecovaService.updatePaymentOption(payload)
    .then(response=> response.data)
      .then(res => {
        console.log(res)
        setIsLoading({
          selfBtn: false,
          automatedBtn: false
        })
        if(res.status){
          if (val === "self-service") {
            updateSetupDispatch({
              currentStep: 7,
              paymentOption: val,
              // set e-wallet data from response
            })
            jump(7)
          } else {
            updateSetupDispatch({
              currentStep: 9,
              paymentOption: val,
            })
            jump(9)
          }
        } else {
            toast.error("something went wrong, please try again")
        }
      })
      .catch((err) => {
        handleRequestErrors(err, true)
        setIsLoading({
          selfBtn: false,
          automatedBtn: false
        })
      });
  }

  return (
    <div className="rounded-3xl px-2.5 py-4 sm:px-0">
        <Header progress={20}
          back={()=> {
            updateSetupDispatch({
              currentStep: 1
            })
            jump(1)
          }}
        />

        <h2 className='text-xl font-bold leading-5'>
          Let’s add a Payout method
        </h2>
        <p className='mt-2'>
          To start, let us know where you’d like us to send your money
        </p>

        <div 
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}
          className='flex justify-center gap-[20px]  items-center h-48 p-[20px] rounded-2xl mt-10 sm:py-10 sm:flex-col'
        >
          <div className='flex flex-col items-center justify-around h-full w-1/2'>
            <img src={selfService} alt="icon" />
            <Button
              text={ "Self-service"}
              isValid={true}
              isLoading={isLoading.selfBtn}
              className="bg-cc-primary w-full"
              onClick={()=>{
                handlePayoutSetup("self-service")
                setIsLoading({
                  ...isLoading,
                  selfBtn: true
                })
              }}
            />
          </div>

          <div className='flex flex-col items-center justify-around h-full w-1/2'>
            <img src={automated} alt="icon" />
            <Button
              text={"Automated"}
              isValid={true}
              isLoading={isLoading.automatedBtn}
              className="bg-cc-primary w-full"
              onClick={()=>{
                handlePayoutSetup("auto-repayment")
                setIsLoading({
                  ...isLoading,
                  automatedBtn: true
                })
              }}
            />
          </div>

        </div>

        <div
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}
          className='px-2.5 py-3 rounded-2xl my-4'
        >
          <h2 className='text-xl font-bold'>Self- service</h2>
          <div className='flex mt-2'>
            <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
              You can withdraw into your bank account whenever you choose.
            </p>
          </div>

          <div className='flex mt-3'>
              <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
              Payouts will be settled in your e-Wallet with instant notifications.
            </p>
          </div>

          <h2 className='text-xl font-bold mt-4'>Automated Settlement</h2>
          <div className='flex mt-2'>
            <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
             Payouts will be settled in your linked bank account
            </p>
          </div>

          <div className='flex mt-3'>
              <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
              You can update your bank details whenever you choose
            </p>
          </div>
        </div>
    </div>
  )
}

export default AddPayoutMethod