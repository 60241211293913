import OTPInput from "otp-input-react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "components/shared/Button";
import { AuthService } from "services/auth.service";
import { toast } from "react-toastify";
import { handleRequestErrors } from "utils/helpers";


export default function ChangeEmailModal ({show, handleClose, user}) {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState({
        email: false,
        otp: false
    })
    const [OTP, setOTP] = useState("");
    const [showOtp, setShowOtp] = useState(false)
    const [ minutes, setMinutes ] = useState(10);
    const [seconds, setSeconds ] =  useState(0);

    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    const handleEmailChange = (e) => {
        e.preventDefault()
        const payload = JSON.stringify({
            email: email,
            userId: user.id
        })
        setLoading({...loading, email: true})
        AuthService.changeBusinessOwnerEmail(payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading({...loading, email: false})
                if (res.success === true) {
                    toast.success(res.message);
                    setShowOtp(true)
                    setMinutes(10)
                    setSeconds(0)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoading({...loading, email: false})
                handleRequestErrors(err)
            });
    }

    const handleVerify = (e) => {
        e.preventDefault()
        const payload = JSON.stringify({
            otp: OTP,
            userId: user.id
        })
        setLoading({...loading, otp: true})
        AuthService.verifyBusinessOwnerEmail(payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading({...loading, otp: false})
                if (res.success === true) {
                    toast.success(res.message);
                    handleClose()
                    setOTP("")
                    setEmail("")
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoading({...loading, otp: false})
                handleRequestErrors(err)
            });
    }

    return (
        <Modal show={show} onHide={()=>handleClose()} aria-labelledby="contained-modal-title-vcenter" centered>
            {
                !showOtp ?
                <form onSubmit={handleEmailChange} className="p-6">
                    <h2 className="text-xl font-bold">Change Email</h2>
                    <div className="settings-invite-mail -mb-6 -mt-3">
                        <label>Email</label>
                        <input type="email" required className="mail" placeholder="Enter new email" onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <Button
                        isValid={email!==""}
                        isLoading={loading.email}
                        type="submit"
                        text="Proceed"
                        className="border-[1px]"
                    />
                </form>
                :
                <form onSubmit={handleVerify} className="p-6">
                    <h2 className="text-xl font-bold">Verify Email</h2>
                    <div className="settings-invite-mail -mb-6 -mt-3">
                        <label>Enter OTP sent to new email</label>
                        <OTPInput className="flex justify-between" inputClassName="!w-[15%] !h-10 font-semibold text-xl" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure={false} />
                    </div>
                    <Button
                        isValid={OTP.length === 6}
                        isLoading={loading.otp}
                        type="submit"
                        text="Verify Email"
                        className="border-[1px]"
                    />
                    <p className="auth_otp-text1">Didn’t get code? {" "}
                        { minutes === 0 && seconds === 0
                            ? <span onClick={handleVerify}>Resend code</span>
                            : <>Resend code in <span> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> </>
                        }
                    </p>
                </form>
            }
        </Modal>
    )
}