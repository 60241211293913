import { useEffect, useState, useRef } from "react";
import CreateRole from "./CreateRole";
import EditRole from "./EditRole";
import ViewPermissions from "./ViewPermissions";
import {toast} from "react-toastify";
import { AuthService } from "services/auth.service";
import { logToBin } from "../../../../lib/logs";
import { Mixpanel } from "../../../../lib/mixpanel";
import { useSelector } from "react-redux";

import bin from "../../../../images/svg/settings/bin.svg";
import binInactive from "../../../../images/svg/settings/binInactive.svg";
import edit from "../../../../images/svg/settings/edit.svg";


function Roles ({permissionValues}) {
    const userData = useSelector(state => state?.loginData?.data?.user)
    const businessName = useSelector(state => state?.loginData?.data?.businessName)
    const [show, setShow] = useState(false);
    const [showPermission, setShowPermission] = useState(false);
    const [permissionData, setPermissionData] = useState([]);
    const [roles, setRoles] = useState([]);
    const [oneRole, setOneRole] = useState({});
    const [showOne, setShowOne]= useState(false);
    const [modules, setModules] = useState([]);
  
    const handleClose = () => setShow(false);

    const handleClosePermission = () => setShowPermission(false);
    const handleShowPermission = () => setShowPermission(true);
    
    const myRef = useRef([]);

    useEffect(()=>{
        fetchAllRoles();
        fetchAllModules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    const fetchAllModules = () => {
        AuthService.getModules()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    setModules(res.data);
                    // console.log(res.data);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const fetchAllRoles = () => {
        let payload = JSON.stringify({
            businessId: userData?.businessId
        })
        AuthService.getAllRoles(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success(res.message)
                    setRoles(res.data)
                    console.log('roles', res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const doDelete = (id) => {
        const pending = toast.loading("Deleting role...")
        toast.update(pending);
        AuthService.deleteRole(id)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.update(pending, { render: res.message, type: "success", isLoading: false });
                    setShowOne(false);
                    fetchAllRoles()
                } else {
                    toast.update(pending, { render: res.message, type: "error", isLoading: false });
                }
                setTimeout( function (){
                    toast.dismiss()
                }, 2000);
            })
            .catch((err) => {
                if(err.response){
                    toast.update(pending, { render: err.response.data.message, type: "error", isLoading: false });
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.update(pending, { render: 'Request timed out, please try again!', type: "error", isLoading: false });
                    } else if (err.message==="Network Error"){
                        toast.update(pending, { render: 'Network error, please make sure you are connected to the internet!', type: "error", isLoading: false });
                    } else{
                        toast.update(pending, { render: "Your request can't be processed at this time, please try again later!", type: "error", isLoading: false });
                    }
                }
                setTimeout( function (){
                    toast.dismiss()
                }, 2000);
            });
    }

  

    const viewPermission = (id) => {
        AuthService.getRolesById(id)
        .then(resp => resp.data)
        .then(res=> {
            if(res.success === true){
                setPermissionData(res.data);
                myRef.current = res?.data?.[0]?.permission
                handleShowPermission()
            }else{
                toast.error('Something went wrong!!!')
            } 
        })
        .catch((err) => {
            console.log(err.response)
            console.log(err)
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                toast.error("Something went wrong!!! Please make sure you have a good internet connection.");
            }
        });
        // window.location= `/settings/team/roles/permission/${id}`;
    }
      
    return(
        <div className="settings_members-table">
            <div className="settings_members-table-data">
                <div className="settings_members-table-top">
                    <h3 className="text-[1.2em]">Manage Roles</h3>
                    <div className="settings_members-table-top-btn">
                        <button 
                            className={permissionValues?.includes("edit")?"":"settings_members-table-top-btn-btnInactive"}
                            onClick={()=>{ 
                                Mixpanel.track(`Add custom roles button was clicked`, {
                                    name: `${userData?.firstName} ${userData?.lastName}`,
                                    bussinessName: businessName
                                });
                                permissionValues?.includes("edit") ? setShow(true) : toast.error("You are not authorized to perform this action!!!")
                            }}
                        >Add Custom Roles</button>
                    </div>
                </div>
                <hr className="my-[15px]"/>
                <div className="settings_roles">

                    <div>

                   
                    <p className="settings_roles-default">Default roles</p>
                   
                        {
                        roles && roles.map((item, idx)=>(
                            item?.businessId === "default" && (
                                <div key={idx}>
                                    <div className="settings_roles-item">
                                        <div className="settings_roles-item-text">
                                            <p className="title" style={{textTransform:"capitalize"}}>{item.name}</p>
                                            <p className="desc">{item.description}</p>
                                        </div>
                                        <div className="settings_roles-item-actions">
                                            <button onClick={()=>viewPermission(item?._id)} className="view">View Permission</button>
                                        </div>
                                    </div>
                                    <hr className="my-[15px]"/>
                                </div>
                            )
                        )) 
                        }
                        </div> 

                        <div>

                    <p className="settings_roles-default">Custom roles</p>
                        {
                            roles.map((item,idx)=>(
                                item?.businessId !== "default" && (
                                <div key={idx}>
                                    <div className="settings_roles-item">
                                        <div className="settings_roles-item-text">
                                            <p className="title" style={{textTransform:"capitalize"}}>{item.name}</p>
                                            <p className="desc">{item.description}</p>
                                        </div>
                                        <div className="settings_roles-item-actions">
                                            <button className="view" onClick={()=>viewPermission(item?._id)}>View Permission</button>
                                            <button 
                                                className="edit flex items-center"
                                                onClick={()=>{
                                                    setOneRole(item); 
                                                    permissionValues?.includes("edit") ? setShowOne(true) : toast.error("You are not authorized to perform this action!!!")
                                                }}
                                            >
                                                <img src={edit} alt="edit" />
                                                <p>Edit</p>
                                            </button>
                                            <button 
                                                onClick={()=>{
                                                    permissionValues?.includes("edit") ? doDelete(item._id) : toast.error("You are not authorized to perform this action!!!")
                                                }}
                                            >
                                                <img src={permissionValues?.includes("edit")?bin:binInactive} alt="delete" />
                                            </button>
                                        </div>
                                    </div>
                                    <hr className="my-[15px]"/>
                                </div>
                            )))
                        }
                    </div>
                </div>
            </div>
            <CreateRole show={show} handleClose={()=>{fetchAllRoles();handleClose();}} modules={modules}/>
            <ViewPermissions show={showPermission} handleClose={()=>{fetchAllRoles();handleClosePermission();}} handleShowPermission={handleShowPermission} permissionData={permissionData}/>
            <EditRole show={showOne} handleClose={()=>setShowOne(false)} roleData={oneRole} fetchAllRoles={()=>fetchAllRoles()} modules={modules} />
        </div>
    )
}

export default Roles;