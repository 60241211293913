import clsx from "clsx";


export default function Status ({
    text, type,
    hideDot=false,
    className,
}) {
    // Types::
    // success - green status
    // fail - red status
    // active - blue status
    // pending - yellow status
    // parsed - brown status
    return (
        <button 
            type="button"
            className={clsx(
                "text-[0.9em] capitalize cursor-default font-semibold rounded-[25px] text-center py-0.5 px-2.5 flex items-center whitespace-nowrap",
                type==="success" ? "text-cc-green-5 bg-cc-green-6"
                    : type==="active" ? "bg-cc-blue-15 text-cc-primary"
                    : type==="pending" ? "bg-cc-yellow-4 text-cc-yellow-3"
                    : type==="parsed" ? "bg-cc-brown-2 text-cc-brown-1"
                    :"text-cc-red-5 bg-cc-red-6",
                className,
            )} 
        >
            {
                !hideDot && 
                <div className={clsx(
                        "rounded-full cursor-default w-[5px] mr-1 h-[5px] text-[2em]",
                        type==="success" ? "bg-cc-green-5"
                            : type==="active" ? "bg-cc-primary"
                            : type==="pending" ? "bg-cc-yellow-3"
                            : type==="parsed" ? "bg-cc-brown-1"
                            :"bg-cc-red-5", 
                    )} 
                ></div>
            }
            {text}
        </button>
    )
}