import { PieChart, Pie, Cell, Tooltip } from 'recharts';


export default function DonutChart ({data, colors}) {
    console.log(data)
    return (
        <div style={{zIndex:'-1'}}>
            {data?.items?.every(item => item.value === 0) && <h5 style={{textAlign: 'center'}}>There is no spending analysis for this period</h5>}
            <PieChart width={250} height={250}>
                <Pie
                    data={data?.items}
                    innerRadius={80}
                    outerRadius={125}
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data?.items?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </div>
    );
}
