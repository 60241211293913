
//Library methods
import React from 'react'
import { useSteps } from 'react-step-builder'

//Components
import Header from './Header'

//Utilities
import blueTick from "images/svg/recova/blueTick.svg"
import { useRecovaSetup } from 'hooks/redux.hook'
import Button from 'components/shared/Button'

const SelfServiceActivation = () => {
  //hooks
  const { jump } = useSteps();
  const { recovaSetupData, updateSetupDispatch } = useRecovaSetup();
  
  return (
    <div className="rounded-3xl px-2.5 py-4 sm:px-0">
         <Header 
          progress={30} 
          back={()=> {
            updateSetupDispatch({
              currentStep: 5
            })
            jump(5)
          }}
        />

        <h2 className='text-xl font-bold leading-5'>
            Self-service mode activated
        </h2>
        <p className='mt-2'>
            Fantastic choice! Your e-Wallet wil be instantly credited automatically
            and you can be notified via email and Webhook service
        </p>

        <div 
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
          className='flex flex-col justify-between items-center p-4 rounded-2xl mt-16'
        >
            <p>Payout will now  be settled in your e- Wallet Account </p>
            <h3 className='pt-2.5 pb-4 text-2xl font-bold'>{recovaSetupData?.ewalletNumber}</h3>

            <Button
              text={"Continue"}
              onClick={()=>{
                updateSetupDispatch({
                  currentStep: 13
                })
                jump(13)
              }}
              isValid={true}
              className="bg-cc-primary"
            />

        </div>

        <div
          style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", border: `1px solid #D9D9D9`}}
          className='px-2.5 py-3 rounded-2xl my-4'
        >
          <div className='flex mt-2'>
            <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
                Leveraging on the NIBSS Interbank instant transfer service
            </p>
          </div>

          <div className='flex mt-3'>
              <img src={blueTick} alt="blue-tick icon" />
            <p className='text-sm ml-2'>
                NIP Transfer fees may apply.
            </p>
          </div>
        </div>
    </div>
  )
}

export default SelfServiceActivation