import { useState, useEffect } from "react";
import ConfirmAction from "components/Modal/ConfirmAction";
import { toast } from "react-toastify";
import { logToBin } from "lib/logs";
import { BillingService } from "services/billing.service";
import { IdentityService } from "services/identity.service";
import { Mixpanel } from "lib/mixpanel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Row, Col} from 'react-bootstrap';
import BasicVerificationResult from "../BasicVerificationResult";
import TypeSelect from "components/Form/TypeSelect";


export default function BankInterface ({permissionValues}) {
    let history = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [disableAction, setDisableAction] = useState(false);
    const [accNo, setAccNo] = useState("");
    const [bankName, setBankName] = useState("");
    const ngBanks = useSelector(state => state?.banksData?.banks)
    const [basicAcc, setBasicAcc] = useState("");
    const [basicBank, setBasicBank] = useState("");
    const [basicPrice, setBasicPrice] = useState("");
    const [advancedPrice, setAdvancedPrice] = useState("");
    const [type, setType] = useState('basic');
    const [basicVerificationModal, setBasicVerificationModal] = useState(false);
    const [basicData, setBasicData] = useState({});
    const b2b_data = useSelector(state => state?.loginData?.data)
  
    useEffect(() => {
      let payload = {
        action: "account number basic verification"
      }
      BillingService.getBillingPricing(payload)
        .then(response=> response.data)
        .then(res => {
            if(res.success === true){
              setBasicPrice(res.data.price)
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            if(err.response){
              logToBin(err.response.data, window.location.href);
                // toast.error(err.response.data.error.message);
            } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
            }
        });
    }, [])
  
    useEffect(() => {
      let payload = {
        action: "account number verification"
      }
      BillingService.getBillingPricing(payload)
      .then(response=> response.data)
      .then(res => {
          if(res.success === true){
            setAdvancedPrice(res.data.price)
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
            logToBin(err.response.data, window.location.href);
              // toast.error(err.response.data.error.message);
          } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
          }
      });
  }, [])
  
  
    const handleSubmit = () => {
      if(permissionValues?.includes("edit")){
        if(accNo.length===10){
          setShowModal(true);
          setType('advanced');
        } else {
          toast.error("Account number must be 10 characters long!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
  
    const doSubmit = () => {
  
      Mixpanel.track(`Identity Insight (Advanced verification) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
        bussinessName: b2b_data?.businessName
    });
        let bankCode = ngBanks.find(o => o.name === bankName)?.code;
  
        IdentityService.doBankVerification(accNo, bankCode)
          .then((response) => response.data)
          .then((res) => {
              if (res.status === true) {
                sessionStorage.setItem("identity", JSON.stringify(res.data))
                  toast.success("Request Successful!!!")
                  setTimeout( function (){
                      history(`/identity/bank-verification`)
                  }, 100);
              } else {
                  toast.error(res.message);
                  setDisableAction(false);
              }
          })
          .catch((err) => {
              setDisableAction(false);
              
              if(err.response){
                logToBin(err.response.data, window.location.href);
                  toast.error(err.response.data.message);
              } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
              }
          });
    }
    
    const handleBasicSubmit = () => {
      Mixpanel.track(`Identity Insight (Basic verification) button was clicked`, {
        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
        bussinessName: b2b_data?.businessName
      });
      if(permissionValues?.includes("edit")){
        if(basicAcc.length===10){
          if(basicBank.length){
            setShowModal(true);
            setType('basic');
          } else {toast.error('Select a bank!')}
        } else {
          toast.error("Account number must be 10 characters long!")
        }
      }else{
        toast.error("You are not authorized to perform this action!!!")
      }
    }
    
    const doBasicSubmit = () => {
      let bankCode = ngBanks.find(o => o.name === basicBank)?.code;
  
      IdentityService.basicBankVerification(basicAcc, bankCode)
        .then((response) => response.data)
        .then((res) => {
            setShowModal(false)
            if (res.status === true) {
                toast.success("Request Successful!!!")
                setDisableAction(false);
                setBasicVerificationModal(true);
                setBasicData(res.data)
                setShowModal(false)
            } else {
                toast.error(res.message);
                setDisableAction(false);
            }
        })
        .catch((err) => {
            setDisableAction(false);
            setShowModal(false)
            if(err.response){
              logToBin(err.response.data, window.location.href);
                toast.error(err.response.data.message);
            } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
            }
        });
    }
  
    return(
      <div className="box-border" style={{margin:'50px 0'}}>
        <Row>
          <Col md={6}>
            <div className="settings_profile-form  w-[400px] md:w-full md_min:mb-0 mb-[30px]" style={{border:'1px solid #E6E6E6', borderRadius:'10px', padding:'15px'}}>
              <div className="identity_bank-top">
                <h5 className="font-medium text-[1.3em]">Basic Verification
                  {/* <img src={info} alt=""/> */}
                  </h5>
              </div>
              <label>Input Bank Account Number</label><br/>
              <input className="apps_create-input" type="text" placeholder="Account Number" onChange={(e)=>setBasicAcc(e.target.value)} /><br/>
              <label>Select Bank</label><br/>
              <div className="auth_signup" style={{marginTop:'0px'}}>
                <TypeSelect 
                  placeholder={"Select Bank"}
                  options={ngBanks}
                  arrayType={"countryObject"}
                  filled={true}
                  selectChange={(item, id)=>setBasicBank(item)}
                />
              </div>
              <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleBasicSubmit()} id="basicActionBtn">Verify Bank Account</button>
            </div>
          </Col>
          <Col md={6}>
            <div className="settings_profile-form  w-[400px] md:w-full md_min:mb-0 mb-[30px]" style={{border:'1px solid #E6E6E6', borderRadius:'10px', padding:'15px'}}>
              <h5 className="font-medium text-[1.3em]">Advanced Verification</h5>
              <label>Input Bank Account Number</label><br/>
              <input className="apps_create-input" type="text" placeholder="Account Number" onChange={(e)=>setAccNo(e.target.value)} /><br/>
              <label>Select Bank</label><br/>
              <div className="auth_signup" style={{marginTop:'0px'}}>
                <TypeSelect 
                  placeholder={"Select Bank"}
                  options={ngBanks}
                  arrayType={"countryObject"}
                  filled={true}
                  selectChange={(item, id)=>setBankName(item)}
                />
              </div>
              <button className={permissionValues?.includes("edit")?"apps_create-button":"apps_create-btnInactive"} onClick={()=>handleSubmit()}>Verify Bank Account</button>
            </div>
          </Col>
        </Row>
        <ConfirmAction
          show={showModal}
          onCancel={()=>setShowModal(false)}
          onAction={()=>{
            type==='basic'? doBasicSubmit():doSubmit()
            setDisableAction(true);
          }}
          loading={disableAction}
          title={`${type==='basic'? 'Basic': 'Advanced'} Bank Account Verification`}
          content={`Using this bank account service would attract a charge of ₦${type==='basic'?basicPrice:advancedPrice}.`}
        />
        <BasicVerificationResult 
          show={basicVerificationModal}
          onCancel={()=>setBasicVerificationModal(false)}
          data={basicData}
          banksList={ngBanks}
        />
      </div>
    )
}