import Table from "components/Table";
import tableIcon from "../../../../images/svg/loan-performance.svg";


function Creditors ({data}) {
    return(
        <div>
            <Table
                titleIcon={tableIcon}
                tableTitle={"Creditors"}
                data={data}
                hideSearch={true}
                tableClassName="min-w-[700px]"
                rows={[
                    {
                        header: "Name",
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.Name}
                            </p>
                        ),
                    },
                    {
                        header: "Phone",
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.Phone||"Not available"}
                            </p>
                        ),
                    },
                    {
                        header: "Address",
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px]">
                                {item?.Address}
                            </p>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default Creditors;