import { object, string, number, bool, mixed, ref, date } from "yup";


export const loginSchema = object({
    email: string().required().email().label("Email address"),
    password: string().required("Password is required"),
})

export const signUpSchema = object({
    businessType: string().required().label("Business Type"),
    businessName: string().required().label("Business Name"),
    businessSector: string().required().label("Business Sector"),
    country: string().required().label("Country"),
    state: string().required().label("State"),
    lga: string().required().label("LGA"),
    address: string().required().label("Address"),
    email: string().email().required().label("Company Email Address"),
    // .test(
    //     "not-match",
    //     "Public personal email domains are not allowed.",
    //     (value) => {
    //       const allowedDomains = [
    //         /@gmail\.com$/,
    //         /@hotmail\.com$/,
    //         /@yahoo\.com$/,
    //         /@outlook\.com$/,
    //         /@live\.com$/,
    //         /@yahoo\.co\.uk$/,
    //         /@icloud\.com$/,
    //         /@aol\.com$/,
    //         /@protonmail\.com$/,
    //         /@zoho\.com$/,
    //         /@mail\.com$/,
    //         /@yandex\.com$/,
    //         /@msn\.com$/,
    //       ];
  
    //       return !allowedDomains.some((domainRegex) => domainRegex.test(value));
    //     }),
    phoneNumber: string().required().label("Phone Number"),
    password: string().required().label("Password")
})

export const forgotPasswordSchema = object({
    email: string().required().email().label("Email address")
})

export const inviteSchema = object({
    first_name: string().required().label("First name"),
    last_name: string().required().label("Last name"),
    email: string().required().email().label("Email address"),
    role: string().required().label("Role"),
    password: string().required("Password is required"),
})

export const radarSchema = object({
    name: string().required("Required").label("Name"),
    radarNumber: string().required("Required").label("Name").min(11, 'Must be at 11 characters long').max(11, 'Must be at 11 characters long'),
})

export const recovaSettlement = object({
    bank: mixed().nullable().required().label("Bank Name"),
    accountNumber: string().required().label("Account Number"),
    accountName: string().required().label("Account Name"),
})

export const changeBalanceSchema = object({
    remarks: string().required("Required").label("Remarks"),
    principal: string().required("Required").label("Principal"),
    tenure: number().required("Required").label("Tenure"),
})

export const freezeCollectionSchema = object({
    remarks: string().required("Required").label("Remarks"),
    duration: number().required("Required").label("Freeze Duration"),
})

export const customerOnboardingSchema = (condition) => {
    return object({
        isLong: bool(),
        firstName: string().required("First Name is required").label("firstName"),
        lastName: string().required("Last Name is required").label("lastName"),
        phoneNumber: string().required("Phone Number is required").label("phoneNumber"),
        email: string().required("Email Address is required").email().label("email"),
        gender: string().required("Gender is required").label("gender"),
        bvn: string().required("BVN is required").label("BVN").min(11, "BVN must be exactly 11 characters").max(11, "BVN must be exactly 11 characters").test("not-match", "BVN should contain only numbers", (value) => value.match(/^[0-9]+$/) !== null ),
        dateOfBirth: string().required("Date of birth is required").label("dateOfBirth"),
        address: string().required("Residential Address is required").label("address"),
        residentType: string().required("Resident Type is required").label("residentType"),
        maritalStatus: string().required("Marital status is required").label("maritalStatus"),
        occupation: condition && string().required("Occupation is required").label("occupation"),
        guarantorName: condition && string().required("Guarantor Name is required").label("guarantorName"),
        guarantorEmail: condition && string().required("Guarantor Email is required").label("guarantorEmail"),
        guarantorAddress: condition && string().required("Guarantor Address is required").label("guarantorAddress"),
        guarantorPhoneNumber: condition && string().required("Guarantor phone number is required").label("guarantorPhoneNumber"),
        employerName: condition && string().required("Employer Name is required").label("employerName"),
        employerAddress: condition && string().required("Employer Address is required").label("employerAddress"),
        loanAmount: condition && string().required("Loan Amount is required").label("loanAmount"),
        loanTenure: condition && string().required("Loan Tenure is required").label("loanTenure")
    })
}


export const complianceProfile = object({
    businessName: string().required().label("Business Type"),
    ownersName: string().required().label("Owner's Full Name"),
    dob: string().required().label("Owner's Date of Birth"),
    registrationType: string().required().label("Registration Type"),
    size: string().required().label("Size"),
    industry: string().required().label("Business Sector"),
    incorporationDate: string().required().label("Incorporation Date"),
    directorsBVN: string().required("Directors BVN is required").label("BVN").min(11, "BVN must be exactly 11 characters").max(11, "BVN must be exactly 11 characters").test("not-match", "BVN should contain only numbers", (value) => value.match(/^[0-9]+$/) !== null ),
})

export const complianceContact = object({
    email: string().email().required().label("Email Address"),
    phone: string().required().label("Phone Number"),
    address: string().required().label("Office Address"),
})

export const complianceDocument = object({
    CACRegisteredBusinessName: string().required().label("Registered Business Name"),
    regType: string().required().label("Registration Type"),
    regNo: string().required().label("Registration Number"),
})

export const createMandateSchema = object({
    product_id: string().required().label("Product ID"),
    bvn: string().required().label("BVN").min(11, "BVN must be exactly 11 characters").max(11, "BVN must be exactly 11 characters").test("not-match", "BVN should contain only numbers", (value) => value.match(/^[0-9]+$/) !== null ),
    amount_due: string().required().label("Amount due"),
    frequency: string().required().label("Frequency"),
    start_date: date().required().label("Start date").min(new Date(Date.now() + 6*24*60*60*1000), "Must be at least 7 days from today"),
    end_date: date().required().label("End date").min(ref('start_date'), 'Cannot be before start date'),
    collection_mode: string().required().label("Collection mode"),
    debitType: string().required().label("Debit type"),
    mandateType: string().required().label("Mandate type"),
    debitDate: string().label("Debit date"),
    firstRepaymentPercentage: number().label("First Repayment Percentage"),
    acc_type: string().required().label("Account type"),
    rc: string().when("acc_type", {
        is: "business",
        then: (schema) => schema.required().label("RC/BN"),
        otherwise: (schema) => schema.optional(),
    }),
    businessName: string().when("acc_type", {
        is: "business",
        then: (schema) => schema.required().label("Business Name"),
        otherwise: (schema) => schema.optional(),
    }),
    businessEmail: string().when("acc_type", {
        is: "business",
        then: (schema) => schema.required().email().label("Business Email"),
        otherwise: (schema) => schema.optional(),
    }),
    businessPhone: string().when("acc_type", {
        is: "business",
        then: (schema) => schema.required().label("Business Phone Number"),
        otherwise: (schema) => schema.optional(),
    }),
})

export const selectMandateBankSchema = object({
    accountNumber: string().required().label("Account Number"),
    accountName: string().required().label("Account Name"),
    bank: object().required().label("Bank"),
    paymentType: string().required().label("Payment Type"),
})

export const createProductRecova = object({
    name: string().required().label("Product name"),
})

export const standAloneCreditInsightSchema = object({
    reportType: string().required().label("Report Type"),
    bvn: string().required().label("BVN").min(11, "BVN must be exactly 11 characters").max(11, "BVN must be exactly 11 characters").test("not-match", "BVN should contain only numbers", (value) => value.match(/^[0-9]+$/) !== null ),
})

export const smeFCSearchSchema = object({
    searchType: string().required().label("Search Type"),
    regNumber: string()
        .when("searchType", {
            is: "Registration Number",
            then: (schema) => schema
                .required().label("Registration Number"),
                // .test(
                //     "contains-prefix", 
                //     "Must include 'RC' or 'BN' prefix", 
                //     (value) => value.toLowerCase().includes('rc') || value.toLowerCase().includes('bn')
                // ),
            otherwise: (schema) => schema.optional(),
        }),
    businessName: string()
        .when("searchType", {
            is: "Business Name",
            then: (schema) => schema.required().label("Business Name"),
            otherwise: (schema) => schema.optional(),
        }),
})

export const newWalletCreation = object({
    CAC: string().required().label("RC/BN")
            .test(
                "contains-prefix", 
                "Must include 'RC'/'BN'/'IT' prefix", 
                (value) => value.toLowerCase().includes('rc') || value.toLowerCase().includes('bn') || value.toLowerCase().includes('it')
            ),
    incorporationDate: string().required().label("Incorporation Date"),
    directorBVN: string().required().label("BVN"),
})

export const passportVerificationSchema = object({
    last_name: string().required().label("Last name"),
    passport_number: string().required().label("Passport number"),
})

export const initiatedebitSchema = object({
    amount: string().required().label("Amount"),
    debitDate: string().required().label("Debit date"),
})

export const initializeOpenBankingSchema = object({
    account_number: string().required().label("Account number"),
    account_type: string().required().label("Account type"),
    bank: object().required().label("Bank"),
    duration:string().required().label("Statement duration"),
    account_name: string().required().label("Account name"),
})