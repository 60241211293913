import {Routes, Route, Navigate} from "react-router-dom";
import Authentication from "./Authentication";
import ApiLogs from "./ApiLogs";
import Webhook from "./Webhook";
import Tab from "components/shared/Tab";


function AuditLogs () {
    return (
        <div className=" my-7 mx-10 md:mx-[20px]">
            <Tab 
                tabs={[
                    { label: "API Logs", key: "/auditLogs/api-logs" },
                    { label: "Authentication Events", key: "/auditLogs/authentication-events" },
                    { label: "Webhook Events", key: "/auditLogs/webhooks" },
                ]}
                useAsLink
            />
            <Routes>
                <Route path="/" element={<Navigate to="/auditLogs/api-logs" />}/>
                <Route path="/authentication-events" element={<Authentication />} />
                <Route path="/webhooks" element={<Webhook />} />
                <Route path="/api-logs" element={<ApiLogs />} />
            </Routes>
        </div>
    )
}

export default AuditLogs;