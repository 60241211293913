import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import MultipleValueTextInput from 'react-multivalue-text-input';


export const MultipleValueTextField = ({label, required, floating, values, onChange, ...props}) => {
   const [field, meta] = useField(props);
   const [active, setActive] = useState(false);

   console.log(field)
   
    return(
        <div className='relative'>
            <label 
                htmlFor={props.name}
                className={clsx( 
                    "text-base text-cc-black-4",
                    // (meta.touched && meta.error && !active) ? 'text-cc-red-4':'text-cc-black-4',
                    (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                    floating ? "absolute left-[16px] transition-all" : "font-semibold",
                )}
            >
                {label}
                {
                    required &&
                    <span className='text-cc-red-4'>*</span>
                }
            </label>
            <MultipleValueTextInput
                // {...field} 
                {...props} 
                onItemAdded={(item, allItems) => onChange(allItems)}
                onItemDeleted={(item, allItems) => onChange(allItems)}
                placeholder="Enter values and separate them with COMMA"
                onFocus={()=>setActive(true)}
                name={field.name}
                values={values}
                className={`w-full border-[1px] text-cc-grey-3 mt-1 border-solid py-[7px] pr-1 pl-4 rounded-[5px] focus:outline-none focus:border-cc-primary mb-6 ${meta.touched && meta.error ? 'border-cc-red-4':'border-cc-grey-12'}`}
            />
            {
                (meta.touched && meta.error) &&
                <p className='text-cc-red-1 text-xs absolute bottom-[7px]'>{meta.error}</p>
            }
        </div>
    )
}