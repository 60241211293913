import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentStep: 1,
  useCase: "",
  paymentOption: "",
  ewalletNumber: "",
  linkedAccount: null,
  // linked account object structure:
  // {
  //   accountNumber: "",
  //   accountName: "",
  //   bankName: "",
  //   bankCode: "",
  // },
  enabledCreditInsurance: null, // true or false
  creditUseCase: null, // should be an array of string
  loanProdDefs: null, // should be an array of string
  termsAccepted: false,
  termsSignedBy: "",
  isCompleted: false,
};

const recovaSetupSlice = createSlice({
  name: "recovaSetup",
  initialState,
  reducers: {
    updateSetupState: (state, { payload }) => {
      return{
        ...state, 
        ...payload // payload is an object and would update any key added in the object
      }
    },
  },
});

export const {  
  updateSetupState,
} = recovaSetupSlice.actions;
export default recovaSetupSlice.reducer;
