import { useNavigate } from "react-router-dom";
import BankAccounts from "components/shared/BankAccounts";
import Back from 'components/shared/back'
import { useSelector } from "react-redux";
import Name from "components/shared/Name";

function Details ({setDetailName}) {
    const ngBanks = useSelector(state => state?.banksData?.banks)
    let navigate = useNavigate()
    const radarData = useSelector(state => state.radarData.details)

    setDetailName("Radar")

    console.log(radarData)

    return (
        <div className="my-[30px] mx-10 md:mx-[20px]">
            <div className="box-border">
                <Back to={()=> navigate(-1)} />
                <Name data={radarData.name} />
                <div className="border-[0.25px] border-solid border-cc-primary box-border shadow-md rounded-[10px] w-[350px] p-[20px] mt-6 sm:w-full">
                    <div className="text-[#6C6C6C]">
                        <p>Full Name: <span className="text-[1.2em] text-black font-medium capitalize">{radarData.name}</span></p>
                        <p>Phone Number: <span className="text-[1.2em] text-black font-medium capitalize">{radarData.type==="mobile_number"?radarData?.mobile_number:radarData?.banks?.[0]?.mobile_number}</span></p>
                        {radarData.type==="bvn" && <p>BVN: <span className="text-[1.2em] text-black font-medium capitalize">{radarData.bvn}</span></p>}
                        <hr className="my-[15px]"/>
                        <p>Bank Accounts</p>
                    </div>
                    <div>
                        {
                            radarData?.banks?.map((bank, index) =>
                                <BankAccounts 
                                    key={index}
                                    bankImage={ngBanks.find(o => o.code === (bank?.bankCode||bank?.BankCode))?.logo?.url }
                                    bankName={bank?.bankName ? bank?.bankName.split(' ').slice(0,2).join(' '):bank?.BankName.split(' ').slice(0,2).join(' ')} 
                                    bankAcc={bank?.accountDescription || bank?.accountNumber || bank?.accountnumber}
                                />
                            )
                        }
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details;