import {useNavigate} from "react-router-dom";
import Back from "../../../components/shared/back";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { SelectField } from "components/Form/SelectField";
import Button from "components/shared/Button";
import { complianceDocument } from "utils/validationSchemas";
import { useState } from "react";

function Document ({handleChange, info, nextButton, pathBase}) {
    let navigate = useNavigate();
    const [isForm, setIsForm] = useState(true)

    const form = useFormik({
        initialValues: {
            CACRegisteredBusinessName: info?.CACRegisteredBusinessName || "",
            regType: info?.regType || "",
            regNo: info?.regNo || "",
        },
        validationSchema: complianceDocument, 
        onSubmit: (values) => {
            console.log(values)
            handleChange({
                regType: values.regType,
                regNo: values.regNo,
                CACRegisteredBusinessName: values.CACRegisteredBusinessName,
            })
            setIsForm(false)
            sessionStorage.setItem("compliance_document", JSON.stringify(values))
        }
    })
 
    return(
        <div className="box-border">
            <div className="-mb-[30px]">
                <Back to={()=> isForm?navigate(`${pathBase}/contact`):setIsForm(true)} />
            </div>
            <h5 className="text-xl font-medium mb-4">Document</h5>
            {isForm ?
                <FormikProvider value={form}>
                    <form onSubmit={form.handleSubmit}>
                        <TextField
                            label="Enter Registered Business Name"
                            name='CACRegisteredBusinessName'
                            type='text'
                            placeholder="Registered Business Name"
                            required
                        />
                        <SelectField
                            name='regType'
                            options={[
                                { value: 'BN', label: 'Business Name' },
                                { value: 'RC', label: 'Registered Company' },
                            ]}
                            required
                            label="Select Registration"
                            onChange={(selected)=>{
                                form.setFieldValue( "regType", selected.value )
                            }}
                            value={form?.values?.regType}
                        />
                        <TextField
                            label="Enter Registration Number"
                            name='regNo'
                            type='text'
                            required
                            placeholder="Registration number"
                        />
                        <p style={{margin:"15px 0", color:"blue"}}>NB: Please make sure the registered business name entered is exactly the same as it is when registered with CAC</p>
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="submit"
                            text="Save"
                            className="mt-10 bg-cc-primary"
                        />
                    </form>
                </FormikProvider>
                :
                <div>
                    <div>
                        <h6 className="text-[#5B5B5B] text-[1em]">Registered Business Name</h6>
                        <p className="text-[#353535]">{info.CACRegisteredBusinessName}</p>
                    </div>
                    <div className="mt-3 mb-6">
                        <h6 className="text-[#5B5B5B] text-[1em]">Registration Number</h6>
                        <p className="text-[#353535]">{info.regType}{info.regNo}</p>
                    </div>
                    <div className="flex justify-between">
                        <button onClick={()=>setIsForm(true)}  className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Edit</button>
                        <button onClick={()=>nextButton()} className="w-[100px] bg-[#EAEAEA] border-[1px] border-[#696969] rounded-[8px] text-center py-2 px-4 font-bold">Next &#62;</button>
                    </div>
                </div>
            }
        </div>
    )
}


export default Document;