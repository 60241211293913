import React from "react";
import { Modal } from "react-bootstrap";
import cancelIcon from '../../images/svg/credit/cancelBlue.svg'


function RadarNumberModal ({show, cancel, doAction, setMobileNumber }) {
    return(
        <Modal show={show} size="sm" onHide={cancel} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="income_radarNumber">
                <img src={cancelIcon} className="income_radarNumber-cancel" alt="cancel" onClick={cancel} />
                <div className="income_radarNumber-form">
                    <h4>Phone Number</h4>
                    <input type="text" placeholder="Input Phone Number" onChange = {(e) => setMobileNumber(e.target.value)} /> 
                    <button onClick={ doAction }>Search</button>
                </div>
            </div>
        </Modal>
    )
}

export default RadarNumberModal;