import { Modal } from 'react-bootstrap';
import { useState } from 'react';

import thresholdIcon from "images/svg/settings/thresholdUpdate.svg";
import Button from 'components/shared/Button';
import { WalletService } from 'services/wallet.service';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setAlertLimit } from "redux/wallet";
import { handleRequestErrors } from 'utils/helpers';


export default function SetWalletThreshold ({show, onClose, value}) {
    const [amount, setAmount] = useState(value);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const doSubmit = () => {
        setLoading(true)
        let payload = JSON.stringify({
            threshold: amount
        })
        WalletService.updateThreshold(payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading(false)
                if (res.success === true) {
                    toast.success(res.message);
                    onClose()
                    dispatch(setAlertLimit(res?.data?.alert_limit))
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                handleRequestErrors(err)
            });
    }

    return (
        <Modal show={show} onHide={onClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="p-[30px] box-border">
                <img src={thresholdIcon} alt="" />
                <h5 className="text-[24px] font-semibold mt-4">Set wallet threshold</h5>
                <p className='text-[#667085] mb-2.5'>Set wallet threshold for notification prompt</p>
                <div>
                    <label className='mb-1'>Amount</label><br/>
                    <input className='rounded-[7px] w-[100%] border-[1px] border-solid border-[#D0D5DD] py-2 px-2.5' placeholder="Input amount" type="number" defaultValue={value} onChange={(e)=>setAmount(e.target.value)} />
                </div>
                <div className='flex justify-between mt-10'>
                    <div className='w-[120px]'>
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="button"
                            text="Cancel"
                            className="bg-white !border-[1px] border-[#D0D5DD] !text-[#344054]"
                            onClick={onClose}
                        />
                    </div>
                    <div className='w-[120px]'>
                        <Button
                            isValid={true}
                            isLoading={loading}
                            type="button"
                            text="Confirm"
                            className="bg-cc-primary"
                            onClick={doSubmit}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}