import {Modal} from "react-bootstrap";
import { SelectField } from "components/Form/SelectField";
import { Formik, Form } from 'formik';
import { useSelector } from "react-redux";
import Button from "components/shared/Button";

import switchTop from "images/svg/switch_icon.svg";
import cancel from "images/svg/x-close.svg";


export default function SelectApp ({show, handleClose, openModal}) {
    const apps = useSelector(state => state?.app);

    const initialValues = {
        activeApp: apps?.active,
    }

    return(
        <Modal show={show} size="sm" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div className="my-[30px] mx-4">
                <img src={cancel} alt="switch" className=" absolute top-[10px] cursor-pointer right-[10px] w-[28px]" onClick={()=>handleClose()}/>
                <img src={switchTop} alt="switch" className="block mx-auto w-10"/>
                <h5 className="font-semibold text-[18px] text-center mt-2.5">Select App to Customize</h5>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        openModal(values)
                    }}
                >  
                    {(props) => (
                        <Form>
                            <div className="mt-[50px] mb-[70px]">
                                <SelectField
                                    name='activeApp'
                                    options={apps?.apps?.map(item => ({
                                        value: item.appName,
                                        label: item.appName,
                                        obj: item
                                    }))}
                                    label="Select App"
                                    searchable={false}
                                    onChange={(selected)=>{
                                        props.setFieldValue( "activeApp", selected.obj )
                                    }}
                                    value={props?.values?.activeApp?.appName}
                                />
                            </div>
                            <Button
                                isValid={props.isValid}
                                type="submit"
                                text="Continue"
                                className="mb-[30px] mt-2.5"
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}