import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import NewShare from "../Modal/NewShare";
import { Mixpanel } from "../../lib/mixpanel";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";
import { useSelector } from "react-redux";

import view from "../../images/svg/income/view.svg";
import see from "../../images/svg/income/see.svg";
import creditChek from "../../images/svg/apps/creditChek.svg";


function OldTable ({
        data, 
        doAction, 
        changeView, 
        changeSee, 
    }) {
    const [displayData, setDisplayData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const userData = useSelector(state => state?.loginData?.data?.user)
    const businessName = useSelector(state => state?.loginData?.data?.businessName)
    const ngBanks = useSelector(state => state?.banksData?.banks)

    useEffect(()=>{
        setDisplayData(data.items)
    },[data])

    var formatMoney = new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        //minimumFractionDigits: 0, 
        maximumFractionDigits: 2, 
    });

    return(
        <div>
            <div className="table table_scroll-general">
                {
                    displayData.length > 0 ?
                    <div>
                        <table>
                            <thead>
                                <tr>
                                    {data.headings.map((item,idx)=>(<th key={idx}>{item}</th>))}
                                </tr>
                            </thead>
                            { 
                                data.tableFor==="idService-history" ? 
                                <tbody>
                                    {
                                        displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td style={{width: data?.type==="rc"?'20%':'auto'}}>
                                                    {
                                                        data?.type==="bvn"? `${item?.firstName} ${item?.middleName} ${item?.lastName}`
                                                        :data?.type==="nin"? `${item?.firstname} ${item?.surname}`
                                                        :data?.type==="pvc"? `${item?.fullName}`
                                                        :data?.type==="bank"? item.account_name
                                                        :data?.type==="license"? item.name
                                                        :`${item?.company_name}`
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        data?.type==="bvn"? `${item?.phones?.[0]}`
                                                        :data?.type==="nin"? `${item?.telephoneno}`
                                                        :data?.type==="pvc"? item.lga
                                                        :data?.type==="bank"||data?.type==="license" ? `${item?.phone ? item.phone:'Nil'}`
                                                        :`${item?.date_of_registration===""?'Not Available':item?.date_of_registration}`
                                                    }
                                                </td>
                                                <td style={{textTransform:data?.type==='pvc'?"capitalize":"lowerCase"}}>
                                                    {
                                                        data?.type==="rc"? `${item?.email_address===null||item?.email_address===""? 'Not available':item?.email_address}`
                                                        :data?.type==="pvc"? item.state
                                                        :`${item?.email ? item?.email:'Not available'}`
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        data?.type==="bvn"? `${item?.bvn}`
                                                        :data?.type==="nin"? `${item?.vnin ? item?.vnin : 'Nil'}`
                                                        :data?.type==="bank"? `${item?.account_number}`
                                                        :data?.type==="license"? item.drivers_license
                                                        :data?.type==="pvc"? item.card_number
                                                        :`${item?.rc_number}`
                                                    }
                                                </td>
                                                <td>{dateFormatter(item?.updatedAt, 'dd/mm/yyyy')} {timeFormatter(item?.updatedAt)}</td>
                                                <td className="table_income-btn">
                                                    <button className="view flex items-center" onClick={()=>{
                                                        doAction(item);
                                                        Mixpanel.track(`View ${data?.type} identity button was clicked`, {
                                                            name: `${userData?.firstName} ${userData?.lastName}`,
                                                            bussinessName: businessName
                                                        });
                                                    }}><img src={view} alt="view" /><p>View</p></button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="linked-accounts" ? 
                                <tbody>
                                    {
                                    displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td>{item.fullname}</td>
                                                <td>{item.phones?.[0]}</td>
                                                <td style={{textTransform:"lowercase"}}>{item.emails?.[0]}</td>
                                                <td>{item.bvn}</td>
                                                <td>{item.maritalStatus}</td>
                                                <td className="table_income-btn">
                                                    <button className="link flex items-center" onClick={()=>{changeView(item.bvn, item.businessId, item.fullname)}}><img src={view} alt="link" /><p>Income Insight</p></button>
                                                
                                                <button className="share flex items-center" onClick={()=>changeSee(item._id)}><img src={see} alt="share" /><p>View</p></button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="registered-custs" ? 
                                <tbody>
                                    {
                                    displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td>{item.fullname}</td>
                                                <td>{item.phones?.[0]}</td>
                                                <td style={{textTransform:"lowercase"}}>{item.emails?.[0]}</td>
                                                <td>{item.bvn}</td>
                                                <td>{item.maritalStatus}</td>
                                                <td className="table_income-btn">
                                                    {/* <button className="link" onClick={()=>changeView(item._id)}><img src={view} alt="link" />Link</button> */}
                                                    <button className="share flex items-center" onClick={doAction}><img src={see} alt="share" /><p>Share</p></button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="user-history" ? 
                                <tbody>
                                    {
                                        displayData?.map((item, idx) =>(
                                            <tr key={idx} className="table_audit">
                                                <td className={item.type==="debit"?"red":"green"}>{item.type}</td>
                                                <td>{item?.narration?.slice(0,30)}</td>
                                                <td>{formatMoney.format(item.amount/100)}</td>
                                                <td>{dateFormatter(item.date, 'dd/mm/yyyy')} {timeFormatter(item.date)}</td>
                                                <td>
                                                    <img src={ngBanks.find(o => o.code === item.bankCode)?.logo.url ? ngBanks.find(o => o.code === item.bankCode)?.logo?.url : creditChek} alt="banklogo" width="40px" />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="authentication" ?  
                                <tbody>
                                    {
                                        displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td>{dateFormatter(item.logDate, 'dd/mm/yyyy')} {timeFormatter(item.logDate)}</td>
                                                <td style={{textTransform:"lowercase"}}>{item.email}</td>
                                                <td>{item.event}</td>
                                                <td>{item?.country}</td>
                                                <td>{item?.ipAddress}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="float-request-history" ? 
                                <tbody>
                                    {
                                        displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td className="lowercase">{item.requestCode}</td>
                                                <td>{currencyFormatter(item?.loanAmount, item?.amountType === "usd" ? "USD":"NGN")}</td>
                                                <td>{ item?.approvedFloat?.approvedAmount ? currencyFormatter(item?.approvedFloat?.approvedAmount, item?.amountType === "usd" ? "USD":"NGN") : "-"}</td>
                                                <td>{item?.loanDuration } </td>
                                                <td>{item?.approvedFloat?.loanTenure ? `${item?.approvedFloat?.loanTenure} Months` : "-" }  </td>
                                                <td>{dateFormatter(item?.createdAt,'dd/mm/yyyy')} - {timeFormatter(item?.createdAt)}</td>
                                                
                                                {/* <td>{item?.interest ? item?.interest : '-'}</td> */}
                                                <td>
                                                    <button className={item?.isApproved==="approved" || item?.isApproved==="accepted"  ?"closed":"open"} style={{textTransform:'capitalize'}}  >
                                                        {item?.isApproved}
                                                    </button>
                                                </td>
                                                <td className="table_income-btn">
                                                    <button 
                                                        className={item?.isApproved==="approved"?"reload" : 'reload inactive'}
                                                        style={{fontSize: '1em', width:'70px', textAlign:'center'}}
                                                        onClick={()=> doAction(item?._id, item?.isApproved)}
                                                    >
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :
                                data.tableFor==="float-paymentHistory" ? 
                                <tbody>
                                    {
                                        displayData?.map((item, idx) =>(
                                            <tr key={idx}>
                                                <td>{item._id?.slice(-8)}</td>
                                                <td>{formatMoney.format(item?.totalAmount)}</td>
                                                <td>{dateFormatter(item?.paidOn,'dd/mm/yyyy')}, {timeFormatter(item?.paidOn)}</td>

                                                <td>
                                                    <button className={item?.paymentStatus ===true ?"closed":"inProgress"} style={{textTransform:'capitalize'}}  >
                                                        {item?.paymentStatus ===true ?"Paid":"Pending"}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                :<div></div>
                            }
                        </table>
                        <div className="mt-1">
                            <Pagination
                                setDisplayData={(items)=>setDisplayData(items)}
                                data={data.items}
                            />  
                        </div>
                    </div>
                    :
                    <div>
                        <h4 style={{textAlign:"center"}}>No data to show</h4>
                    </div>
                }    
            </div>  
            {
                showModal && 
                <NewShare 
                    type={"share-whatsapp"} 
                    cancel={()=>setShowModal(false)}
                    doAction={()=>{
                        setShowModal(false);
                    }}
                />
            } 
        </div>
    )
}

export default OldTable;