// Library Methods
import { useState } from "react";

//Components
import SetupBox from "./components/SetupBox";

//Utilities
import { useRecovaSetup } from "hooks/redux.hook";
import Button from "components/shared/Button";
import useCaseSetup from "images/svg/recova/unhcr.svg"
import disputeSetup from "images/svg/recova/opposite-opinion.svg"
import payoutSetup from "images/svg/recova/cashathand.svg"
import ModalLayout from "./layout/ModalLayout";
import RecovaSetup from "./components/recovaSetup";


 function HomeSetup() {
   const [isOpen, setIsOpen] = useState(false)
   const [step, setStep] = useState(1)

   const { recovaSetupData } = useRecovaSetup();

   const handleModalOpen = ()=> {
    setStep(recovaSetupData?.currentStep || 1)
    setIsOpen(true)
   }

  return(
      <div>
        <h1 className="text-2xl font-semibold">RecovaPRO Setup</h1>
        <p>
          Activate your Business profile to start automating debt repayments from your customers. <br />
          Only takes less than a minute, any progress you make will be saved, so you can always finish up later
        </p>
        <div className="flex justify-end mt-6 mb-10" >
          <div className="w-[260px]">
            <Button
            isValid={true}
            text="Activate your RecovaPRO profile"
            className="bg-cc-primary"
            onClick={handleModalOpen}
            />
          </div>
        </div>
        <h1 className="text-2xl font-semibold">Get started with these easy steps</h1>
        <div className="mt-8 mb-[80px]">
          <div className="flex justify-center">
            <SetupBox 
              title={"1. Choose Use-Case"}
              linkText={"Start"}
              onClick={()=> setIsOpen(true)}
              icon={useCaseSetup}
              content={<p>Let us know how RecovaPRO will be used, and if you want extra protection on your funds using our embedded risk management capabilities.</p>}
            />
          </div>
          <div className="flex justify-center gap-[60px] mt-[100px] md:mt-[80px] md:flex-col md:items-center">
            <div>
              <SetupBox 
                title={"2. Setup Payouts"}
                linkText={"Next"}
                onClick={()=> setIsOpen(true)}
                icon={payoutSetup}
                content={
                  <div>
                    <p>Initiate payouts of value recovered from your wallet at any time during the process</p>
                    <p>Request statement for all payment recovered </p>
                  </div>
                }
              />
            </div>
            <div className="md:mt-[20px]">
              <SetupBox 
                title={"3. Dispute resolution"}
                linkText={"Final"}
                onClick={()=> setIsOpen(true)}
                icon={disputeSetup}
                content={
                  <div>
                    <p>Report your issues seamlessly for review and resolution</p>
                    <p>Check the status of your dispute to determine if it has been won or lost</p>
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <ModalLayout
          show={isOpen}
          handleClose={() => {setIsOpen(false)}}
        >
          <RecovaSetup
            step={step}
            closeModal={()=>setIsOpen(false)}
          />
        </ModalLayout>
      </div>
    )
  }
  
  export default HomeSetup;