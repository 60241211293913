import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import GenerateModal from "components/Modal/GenerateReport";
import ConfirmAction from "components/Modal/ConfirmAction";
import NoCreditData from "components/Modal/NoCreditData";
import { BillingService } from "services/billing.service";
import { CreditService } from "services/credit.service";
import { useSelector } from "react-redux";
import Table from "components/Table";
import { logToBin } from "lib/logs";
import { Mixpanel } from "lib/mixpanel";
import clsx from "clsx";
import { currencyFormatter, dateFormatter, timeFormatter } from "utils/helpers";

import view from "images/svg/income/view.svg";
import reload from "images/svg/income/reload.svg";
import deleteIcon from "images/svg/income/delete.svg";
import deleteIconInactive from "images/svg/income/deleteInactive.svg";
import scoreIcon from "images/svg/credit/credit-score.svg"
import FormSection from "../../components/FormSection";


export default function CreditScore ({rolePermissions}) {
    let history = useNavigate();
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [scoreType, setScoreType] = useState("fico");
    const [isReload, setIsReload] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [disableAction, setDisbleAction] = useState(false);
    const [insightLoading, setInsightLoading] = useState(true);
    const [showNoCredit, setShowNoCredit] = useState(false);
    const [price, setPrice] = useState("");
    const [permissionValues, setPermissionValues] = useState([]);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const [bvnValue, setBvnValue] = useState({
        summary: '',
        full: '',
        currentType: 'full',
        scoreBvn: '',
        score: 300,
    })
    const [searchParams] = useSearchParams();
    const path = searchParams.get("tab")

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "home"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions])

    useEffect(()=>{
        setInsightLoading(true);
        doHistoryFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, searchTerm, scoreType])

    const doHistoryFetch = () => {
        CreditService.getCreditHistory(
                scoreType==="fico" ? "crc-fico":"first-central-iscore"
            )
            .then((response) => response.data)
            .then((res) => {
                setInsightLoading(false);
                if (res.status === true) {
                    const filteredData = res.data?.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
                    filteredData?.sort(function(a,b){
                        return new Date(b.searchedDate) - new Date(a.searchedDate);
                    })

                    setData(filteredData)

                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setInsightLoading(false)
                setData([])
                if(err.response){
                    toast.error(err.response.data.message);
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 120000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const showCreditFromHistory = (item) => {
        Mixpanel.track(`View credit insight button was clicked`, {
            name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
            bussinessName: b2b_data?.businessName
        });
        if(item?.score){
            sessionStorage.setItem( "report", JSON.stringify({ 
                data: item?.scorePremium?.totalNoOfLoans ? item.scorePremium:item?.score,
                name: item?.name,
                gender: item?.gender,
                phone: item?.phone,
                address: item?.address,
                dob: item?.dateOfBirth,
                bvn: item?.bvn
            })); 
            history(`/credit-insight/customer/details/${path}?type=summary`)
        } else {
            toast.info("Report hasn't been generated for this user")
        }
    }

    const deleteChange = () => {
        let payload = {
            type: scoreType==="fico" ? "crc-fico" : "first-central-iscore",
            bvn: isReload
        }
        CreditService.deleteCreditData(payload)
            .then(response =>response.data)
            .then(res => {
                if(res.status === true){
                    setShowDeleteModal(false);
                    setDisbleAction(false);
                    toast.success("Deleted Successfully!")
                    doHistoryFetch()
                }
            })
            .catch((err) => {
                setShowDeleteModal(false);
                setDisbleAction(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 120000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        getPrice()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, scoreType, bvnValue.currentType])

    const getPrice = () => {
        let payload = {
            action: scoreType==="fico" ? "credit crc fico" : "credit first central iScore"
        }
        BillingService.getBillingPricing(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.success === true){
                    setPrice(res.data.price)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const getScore = () => {
        if( bvnValue?.scoreBvn?.length === 11){
            setShowModal(true);
        } else {
            toast.error('BVN must be 11 characters long.')
        }
    }

    const callScoreService = () => {
        if(scoreType==="fico"){
            CreditService.getCRCReport(bvnValue.scoreBvn, "score")
                .then((response) => response.data)
                .then((res) => {
                    setShowModal(false);
                    setDisbleAction(false);
                    console.log(res)
                    if (res.status === true) {
                        sessionStorage.setItem( "report", JSON.stringify({ 
                            data: res?.data?.score,
                            name: res?.data?.name,
                            gender: res?.data?.gender,
                            phone: res.data.phone,
                            address: res?.data?.address,
                            dob: res?.data?.dateOfBirth,
                            bvn: res?.data?.bvn
                        })); 
                        toast.success("Request Successful!")
                        history(`/credit-insight/customer/score/${scoreType}`)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setShowModal(false);
                    setDisbleAction(false);
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        if(err.response.status===404){
                            setShowNoCredit(true)
                        } else {
                            toast.error(err.response.data.message);
                        }
                    } else{
                        if(err.message === "timeout of 120000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        } else {
            CreditService.getFirstCentralReport(bvnValue.scoreBvn, "score")
                .then((response) => response.data)
                .then((res) => {
                    setShowModal(false);
                    setDisbleAction(false);
                    console.log(res)
                    if (res.status === true) {
                        sessionStorage.setItem( "report", JSON.stringify({ 
                            data: res?.data?.score,
                            name: res?.data?.name,
                            gender: res?.data?.gender,
                            phone: res.data.phone,
                            address: res?.data?.address,
                            dob: res?.data?.dateOfBirth,
                            bvn: res?.data?.bvn
                        })); 
                        toast.success("Request Successful!")
                        history(`/credit-insight/customer/score/${scoreType}`)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setShowModal(false);
                    setDisbleAction(false);
                    if(err.response){
                        logToBin(err.response.data, window.location.href);
                        if(err.response.status===404){
                            setShowNoCredit(true)
                        } else {
                            toast.error(err.response.data.message);
                        }
                    } else{
                        if(err.message === "timeout of 120000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }
    }

    const viewScore = (item) => {
        sessionStorage.setItem( "report", JSON.stringify({ 
            data: item?.score,
            name: item?.name,
            gender: item?.gender,
            phone: item?.phone,
            address: item?.address,
            dob: item?.dateOfBirth,
            bvn: item?.bvn
        })); 
        history(`/credit-insight/customer/score/${scoreType}`)
    }
    
    return (
        <div>
            <div>
                <div className="flex gap-x-[30px]">
                    <div className="w-[200px] mt-[50px]">
                        <div className={clsx(
                                "flex items center gap-x-2.5 p-2.5 cursor-pointer",
                                scoreType==="fico" && "bg-cc-grey-1 border-l-[4px] border-cc-primary",
                            )}
                            onClick={()=>{
                                setScoreType("fico");
                                setBvnValue({
                                    ...bvnValue, score: 300
                                })
                            }}
                        >
                            <img src={scoreIcon} alt="" className="w-[20px]" />
                            <p className="text-base font-semibold">FICO Score</p>
                        </div>
                        <div className={clsx(
                                "flex items center gap-x-2.5 p-2.5 cursor-pointer",
                                scoreType==="iScore" && "bg-cc-grey-1 border-l-[4px] border-cc-primary",
                            )}
                            onClick={()=>{
                                setScoreType("iScore");
                                setBvnValue({
                                    ...bvnValue, score: 300
                                })
                            }}
                        >
                            <img src={scoreIcon} alt="" className="w-[20px]" />
                            <p className="text-base font-semibold">i-Score</p>
                        </div>
                    </div>
                    <div>
                        <FormSection
                            title={scoreType==="fico"?`FICO Score`:'i-Score'}
                            onInputChange={(value)=>setBvnValue({...bvnValue, scoreBvn: value})}
                            onButtonClick={()=>getScore()}
                            buttonClass={clsx(
                                permissionValues?.includes("edit") ? "credit-bvn-btn":'credit-bvn-btnInactive',
                                "bg-cc-primary px-[20px] !py-[10px]"
                            )}
                        />
                    </div>
                </div>
                <div>
                    <div className="home_main-topText mt-[50px]">
                        <h5>History</h5>
                        <div className="home_main-topText-search" >
                            <p>List of previous Credit Insights</p>
                        </div>
                    </div>
                    <Table
                        tableTitle={"Credit Insights"}
                        searchPlaceHolder={"Search by customer name"}
                        onSearchChange={handleChange}
                        data={data}
                        loading={insightLoading}
                        hideSearch={false}
                        trClassName="!border-none"
                        theadClassName="!border-none"
                        tableClassName="bg-[#F9FAFB] 2xl:min-w-[1200px]"
                        rows={[
                            {
                                header:'CUSTOMER NAME',
                                view: (item) => (
                                    <span className="uppercase py-[10px] pl-[10px] pr-[5px]">
                                        {item?.name}
                                    </span>
                                ),
                            },
                            {
                                header:'BVN',
                                view: (item) => (
                                    <span className="py-[10px] pl-[10px] pr-[5px]">
                                        {item?.bvn}
                                    </span>
                                ),
                            },
                            {
                                header:'DATE',
                                view: (item) => (
                                    <span className="py-[10px] pl-[10px] pr-[5px]">
                                        {item.searchedDate===undefined ? `${dateFormatter(item?.score?.lastUpdatedAt, 'dd/mm/yyyy')}, ${timeFormatter(item?.score?.lastUpdatedAt)}`:`${dateFormatter(item?.searchedDate, 'dd/mm/yyyy')}, ${timeFormatter(item?.searchedDate)}`}
                                    </span>
                                ),
                            },
                            {
                                header:'ACTION',
                                view: (item) => (
                                    <div className="flex py-2.5">
                                        <button 
                                            className="text-[0.8em] px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center bg-cc-green-4 mr-2.5 w-[80px] h-8" 
                                            onClick={()=>{
                                                if(path==="scores"){ viewScore(item) } else { showCreditFromHistory(item) }
                                            }}
                                        >
                                            <img src={view} alt="view" className="w-[16px] mr-1" />
                                            <p>View</p>
                                        </button>
                                        <button 
                                            className={clsx(
                                                "text-[0.8em] px-1.5 py-0.5 text-white border rounded-[4px] flex items-center justify-center mr-2.5 w-[120px] h-8",
                                                permissionValues?.includes("edit") ? 'bg-cc-primary':'bg-cc-grey-3', 
                                            )}
                                            onClick={()=>{
                                                if(path==="scores"){ 
                                                    setBvnValue({
                                                        ...bvnValue,
                                                        scoreBvn: item.bvn
                                                    })
                                                } else {
                                                    setIsReload(item.bvn)
                                                }
                                                permissionValues?.includes("edit") ? setShowModal(true) : toast.error("You are not authorized to perform this action!!!")
                                            }}
                                        >
                                            <img src={reload} alt="reload" className="w-[16px] mr-1" />
                                            <p>Reload Data</p>
                                        </button>
                                        { path!=="scores" &&
                                            <img 
                                                className="cursor-pointer"
                                                onClick={()=>{
                                                    setIsReload(item.bvn)
                                                    permissionValues?.includes("edit") ? setShowDeleteModal(true) : toast.error("You are not authorized to perform this action!!!")
                                                    Mixpanel.track(`Delete credit insight button was clicked`, {
                                                        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                                        bussinessName: b2b_data?.businessName
                                                    });
                                                }} 
                                                src={permissionValues?.includes("edit")?deleteIcon:deleteIconInactive} 
                                                alt="delete" 
                                            />
                                        }
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <GenerateModal 
                show={showModal}
                type={"generate-report"} 
                cancel={()=>setShowModal(false)} 
                data={price}
                doAction={()=>{
                    callScoreService();
                    setDisbleAction(true);
                }} 
                reportType={path}
                message={`Please be informed that this action attracts a charge of ${currencyFormatter(price)} from your eWallet. This also include if the assessment is successful or not`}
                disableAction={disableAction}
            />
            <ConfirmAction
                show={showDeleteModal}
                onCancel={()=>setShowDeleteModal(false)}
                onAction={()=>{
                    deleteChange()
                    setDisbleAction(true);
                }}
                type="delete"
                loading={disableAction}
                title="Delete Credit Report?"
                content="Are you sure you want to delete this borrower’s credit record? This is an irreversible action, and would require you to perform a fresh assessment for this borrower."
            />
            <NoCreditData 
                type={'individual'}
                show={showNoCredit}
                cancel={()=>setShowNoCredit(false)}
            />
        </div>
    )
}