import Radar from "../Identity/Radar";
import { useEffect, useState } from "react";

export default function RadarMain ({rolePermissions}) {
    const [permissionValues, setPermissionValues] = useState([]);

    useEffect(() => {
        rolePermissions?.map((item) => {
            let key = Object.keys(item)
            if(key[0] === "insights"){
                let values = Object.values(item)
                setPermissionValues(values[0])
            }
            return key
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rolePermissions]);

    return(
        <div className="my-[50px] mx-10 md:mx-[20px]">
            <Radar permissionValues={permissionValues} />
        </div>
    )
}