import tableIcon from "../../../../images/svg/loan-performance.svg";
import Table from "components/Table";


function Directors ({data}) {
    return(
        <Table
            titleIcon={tableIcon}
            tableTitle={"Directors"}
            data={data}
            hideSearch={true}
            tableClassName="min-w-[600px]"
            rows={[
                {
                    header:'Name',
                    view: (item) => (
                        <p className="py-[10px] pl-[10px] pr-[5px]">
                            {item?.name || "Not Available"}
                        </p>
                    ),
                },
                {
                    header:'Date of Birth',
                    view: (item) => (
                        <p className="py-[10px] pl-[10px] pr-[5px]">
                            {item?.dateofBirth || "Not Available"}
                        </p>
                    ),
                },
                {
                    header:'Date Appointed',
                    view: (item) => (
                        <p className="py-[10px] pl-[10px] pr-[5px]">
                            {item?.dateAppointed || "Not Available"}
                        </p>
                    ),
                },
            ]}
        />
    )
}

export default Directors;