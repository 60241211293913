import React from 'react'
import success from "images/svg/recova/success.svg"
import Button from 'components/shared/Button'
import { useRecovaSetup } from 'hooks/redux.hook';


const SuccessPage = ({closeModal}) => {
  const { updateSetupDispatch } = useRecovaSetup();

  return (
    <div className="flex flex-col items-center rounded-3xl px-12 py-24">
        <img src={success} alt="icon" />

        <h1 className='text-3xl text-cc-primary font-bold'>Congratulations!</h1>
        <h3 
            className='text-2xl text-cc-primary font-bold mt-2 mb-24'
        > 
            Recova has been {""}
            <span className='text-cc-green-9'> Activated</span>  
        </h3>

        <Button
            isValid={true}
            className="bg-cc-primary"
            text="Finish"
            onClick={()=>{
                updateSetupDispatch({
                    isCompleted: true
                })
                closeModal()
            }}
        />
    </div>
  )
}

export default SuccessPage