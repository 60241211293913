import { useField } from 'formik';
import clsx from 'clsx';
import {useState} from 'react';
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import calendar from "images/svg/calendar.svg"


export const DateField = ({
    label, required, floating, icon, onChange, placeholder, value, errorMessageClass,
    calendarIcon=null, //optional
    ...props
}) => {
    const [field, meta] = useField(props);
    const [active, setActive] = useState(false);
    const [focused, setFocused] = useState(false);
   
    return(
        <div className='relative'>
            <label 
                htmlFor={props.name}
                className={clsx( 
                    "text-base text-cc-black-4",
                    // (meta.touched && meta.error && !Focused) ? 'text-cc-red-4':'text-cc-black-4',
                    (active && floating) ? "text-xs top-[4px]":"top-[12px]",
                    floating ? "absolute left-[16px] transition-all" : "font-semibold",
                )}
            >
                {label}
                {
                    required &&
                    <span className='text-cc-red-4'>*</span>
                }
            </label>
            <DatePicker
                {...field} 
                {...props} 
                onFocus={()=>{setFocused(true); setActive(true)}}
                onBlur={()=>setFocused(false)}
                onChange={(value)=>{
                    onChange(value);
                }} 
                value={value}
                className={clsx(
                    `w-full bg-white border-[1px] text-cc-grey-3 mt-1 border-solid py-[7px] pr-1 pl-3 rounded-[5px] mb-6`,
                    meta.touched && meta.error ? 'border-cc-red-4':'border-cc-grey-12',
                    focused && "outline-none border-cc-primary",
                )}
                clearIcon={null}
                format="dd/MM/y"
                calendarIcon={
                    !!calendarIcon ?
                    calendarIcon
                    :
                    <img 
                        src={calendar} 
                        alt="calendar"
                        className='w-[20px]'
                    />
                }
            />
            {
                !value &&
                <div className='bg-white absolute text-base text-cc-grey-3 bottom-[34px] left-3 h-[24px] truncate overflow-hidden min-w-[100px] max-w-[calc(100%-50px)]'>
                    <p>{placeholder}</p>
                </div>
            }
            {
                (meta.touched && meta.error) &&
                <p 
                    className={clsx(
                        'text-cc-red-1 text-xs absolute bottom-[7px]',
                        errorMessageClass
                    )}
                >
                    {meta.error}
                </p>
            }
        </div>
    )
}