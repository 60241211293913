import clsx from "clsx"
import { toast } from "react-toastify";

export const CheckPermission = ({show, children}) => {
    return (
        <div
            className={clsx(
                !show && "hidden"
            )}
        >
            {children}
        </div>
    )
}

export const hasPermission = (check, callback) => {
    if(check){
        callback();
    }else{
        toast.error("You are not authorized to perform this action!!!")
    }
}
